import { Center } from '@chakra-ui/react';

export default function ImageContainer({ children, ...rest }) {
  return (
    <Center
      minW={'full'}
      width={'full'}
      height={'full'}
      children={children}
      {...rest}
    />
  );
}
