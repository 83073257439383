import { CopyIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  ListItem,
  OrderedList,
  SimpleGrid,
  Spacer,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  TagLabel,
  TagRightIcon,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { MdDialpad } from 'react-icons/md';
import { useQuery } from 'react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  modalPaymentStateAtom,
  orderAtom,
  cartAtom,
  paymentAtom,
  stepAtom,
} from '../../../recoil/atoms';
import { formatPrice } from '../../../utils/functions';
import { ButtonPrimary } from '../../buttons';
import { paths } from '../../../utils/constants';
import { queryPayment } from '../../../api/queries';
import { Status } from '../../../API';
/**
 *
 * @returns Payment content when the modal is visible
 */

//NOTE: payment content component
const PaymentContent: React.FC = (): JSX.Element => {
  //NOTE: initialize component state
  const order = useRecoilValue(orderAtom);
  const setIsOpen = useSetRecoilState(modalPaymentStateAtom);
  const setCart = useSetRecoilState(cartAtom);
  const payment = useRecoilValue(paymentAtom);
  const setStep = useSetRecoilState(stepAtom);

  const navigate = useNavigate();

  //NOTE: get payment from order
  /*   let payment = order?.payment ? order?.payment : null; */

  // NOTE: initialize toast function
  const toast = useToast();
  const tabBG = useColorModeValue('gray.100', 'gray.800');

  const { data, isLoading } = useQuery(
    ['get-payment', { paymentId: order?.payment?.id }],
    queryPayment,
    {
      enabled: !!order?.payment?.id,
    },
  );
  console.log('data::', data);

  //ANCHOR create payment if it does not exist

  //ANCHOR: navigate to orders page to empty the cart
  const goToOrdersPage = () => {
    if (payment) setCart([]);
    setStep(1);
    setIsOpen(false);
    navigate(paths.orders);
  };

  //NOTE: dial btn responsive styles
  const dialPadButton = useBreakpointValue({
    base: (
      <Button
        as={'a'}
        href={'tel:*115#'}
        colorScheme={'blue'}
        width={'full'}
        my={3}
        rightIcon={<MdDialpad />}
        justifyContent={'space-between'}
        borderRadius={'4px'}
      >
        {' '}
        Dial now{' '}
      </Button>
    ),
    md: '',
  });

  //NOTE: check if createPayment is for loading
  if (isLoading || !order) {
    return (
      <HStack width={'full'} height={'full'} spacing={4} align={'center'}>
        <Text fontSize={'sm'} fontWeight={'medium'}>
          {' '}
          loading, please wait.
        </Text>
        <Spinner size={'xs'} />
      </HStack>
    );
  }

  return (
    <VStack
      height={'full'}
      as={VStack}
      spacing={4}
      maxH={'fit-container'}
      overflow={'scroll'}
      width={'full'}
      className={'no-scroll'}
      alignItems={'center'}
      justifyContent={'flex-start'}
    >
      <HStack spacing={5} width={'full'}>
        <Text fontSize={'sm'} fontWeight={'bold'}>
          {' '}
          Status{' '}
        </Text>
        <Spacer />
        <Tag
          colorScheme={
            (data &&
              data.payment &&
              data.payment.status === Status.IN_PROGRESS) ||
            payment?.payment.status === Status.IN_PROGRESS
              ? 'blue'
              : 'brand'
          }
          fontSize={'10'}
          fontWeight={'semibold'}
          minW={'min-content'}
        >
          {' '}
          {data
            ? data.payment.status
              ? 'awaiting-payment'
              : 'CLOSED'
            : payment?.payment.status === 'OPEN'
            ? 'awaiting-payment'
            : 'CLOSED'}{' '}
        </Tag>
      </HStack>
      <Tabs
        isLazy
        lazyBehavior="keepMounted"
        variant={'soft-rounded'}
        height={'full'}
        width={'full'}
        p={0}
      >
        <SimpleGrid
          spacing={2}
          as={TabList}
          columns={{
            base: 1,
          }}
          width={'full'}
        >
          <Tab
            as={Flex}
            flexDirection={'column'}
            alignItems={'center'}
            borderRadius={'lg'}
            cursor={'pointer'}
            bg={tabBG}
            width={'full'}
            height={32}
            _focus={{
              outline: 'none',
            }}
          >
            <Image
              src={'https://www.airtel.co.zm/assets/images/airtel-logo.png'}
              height={30}
              objectFit={'contain'}
            />
            <Text mt={3} fontSize={'sm'} textAlign={'center'}>
              Airtel Money
            </Text>
          </Tab>
        </SimpleGrid>

        <TabPanels mt={3}>
          <TabPanel p={0}>
            <Text
              fontWeight={'bold'}
              fontSize={'sm'}
              textTransform={'capitalize'}
              mb={3}
            >
              Follow these steps:
            </Text>
            <OrderedList spacing={2} fontSize={'sm'} fontWeight={'normal'}>
              <ListItem>Dial *115#</ListItem>
              <ListItem>
                Option 4 <Icon as={AiOutlineArrowRight} size={'12px'} /> Make
                Payment
              </ListItem>
              <ListItem>
                Option 6 <Icon as={AiOutlineArrowRight} size={'12px'} /> Goods &
                Services
              </ListItem>

              <ListItem>
                Enter merchant code{' '}
                <Tag
                  colorScheme={'blue'}
                  cursor={'pointer'}
                  fontSize={'10px'}
                  fontWeight={'extrabold'}
                  letterSpacing={'widest'}
                  onClick={() => {
                    if (!navigator.clipboard) return;
                    navigator.clipboard
                      .writeText('RIAWORX')
                      .then(() => {
                        toast({
                          status: 'info',
                          title:
                            'Merchant code RIAWORX has been copied to clipboard',
                          duration: 2000,
                          position: 'bottom',
                          isClosable: true,
                        });
                      })
                      .catch(error => {});
                  }}
                >
                  <TagLabel> RIAWORX </TagLabel>
                  <TagRightIcon as={CopyIcon} />
                </Tag>
              </ListItem>

              <ListItem>
                Pay{' '}
                <b>
                  {formatPrice(
                    data
                      ? data.payment.fee.finalFee
                      : payment?.payment.fee.finalFee,
                  )}
                </b>{' '}
                amount
              </ListItem>

              <ListItem>
                Enter{' '}
                <Tag
                  colorScheme={'blue'}
                  cursor={'pointer'}
                  fontSize={'10px'}
                  fontWeight={'extrabold'}
                  letterSpacing={'widest'}
                  onClick={() => {
                    console.log(payment);
                    if (!navigator.clipboard) return;
                    navigator.clipboard
                      .writeText(payment?.payment.referenceId || '')
                      .then(() => {
                        toast({
                          status: 'info',
                          title: `Reference ID  

                            ${
                              data
                                ? data.payment.referenceId
                                : payment?.payment.referenceId
                            } 

                             has been copied to clipboard`,
                          duration: 2000,
                          position: 'bottom',
                          isClosable: true,
                        });
                      })
                      .catch(error => {});
                  }}
                >
                  <TagLabel>
                    {' '}
                    {data
                      ? data.payment.referenceId
                      : payment?.payment.referenceId}{' '}
                  </TagLabel>
                  <TagRightIcon as={CopyIcon} />
                </Tag>{' '}
                as the reference.
              </ListItem>

              <ListItem>
                Return to riaparts.com/orders as your payment is being processed
              </ListItem>
            </OrderedList>

            {dialPadButton}
            <ButtonPrimary
              data-btn="payment-complete"
              width={'full'}
              onClick={goToOrdersPage}
            >
              Payment Completed
            </ButtonPrimary>
          </TabPanel>
          <TabPanel></TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export default PaymentContent;
