import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logo from '../../../assets/logo.png';

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: 'row',
    marginTop: 1,
  },
  subtitle: {
    color: '#ff7b00',
    fontSize: 9,
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  companyTitle: {
    color: '#ff7b00',
    letterSpacing: 4,
    marginLeft: 5,
    fontSize: 25,
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  quote: {
    color: '#ff7b00',
    marginLeft: 5,
    fontSize: 25,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  logo: {
    width: 55,
    height: 49,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

const Title = () => (
  <View style={styles.titleContainer}>
    <View style={styles.titleContainer}>
      <Image style={styles.logo} src={logo} />
      <View>
        <Text style={styles.companyTitle}>RIAPARTS</Text>
        <Text style={styles.subtitle}>
          You Name It. We Find It. We Deliver It.
        </Text>
      </View>
    </View>
  </View>
);

export default Title;
