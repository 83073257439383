import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { Customer } from '../../../API';
import { capitalizeEveryFirstLetter } from '../../../utils/functions';

const styles = StyleSheet.create({
  container: {
    marginTop: 36,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  left: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  right: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  label: {
    fontWeight: 'heavy',
  },
});

interface AdressesProps {
  customer?: Customer | undefined;
}

/**
 ** Element returns the adress section of the quote PDF
 */

const Adresses = ({ customer }: AdressesProps) => (
  <View style={styles.container}>
    <View>
      <View style={styles.left}>
        <Text style={{ ...styles.label, fontFamily: 'Helvetica-Oblique' }}>
          FROM
        </Text>
      </View>
      <View style={styles.left}>
        <Text style={styles.label}>Riaparts</Text>
      </View>
      <View style={styles.left}>
        <Text style={styles.label}>Nalikwapa Cross</Text>
      </View>
      <View style={styles.left}>
        <Text style={styles.label}>Salama Park</Text>
      </View>
      <View style={styles.left}>
        <Text style={styles.label}>Email: info@riaworx.com</Text>
      </View>
      <View style={styles.left}>
        <Text style={styles.label}>WhatsApp: 0760822241</Text>
      </View>
    </View>
    {customer && (
      <View>
        <View style={styles.right}>
          <Text style={{ ...styles.label, fontFamily: 'Helvetica-Oblique' }}>
            MADE FOR
          </Text>
        </View>
        <View style={styles.right}>
          <Text style={styles.label}>
            {capitalizeEveryFirstLetter(customer.firstName)}{' '}
            {capitalizeEveryFirstLetter(customer.familyName)}
          </Text>
        </View>
        <View style={styles.right}>
          <Text style={styles.label}>{customer.phoneNumber}</Text>
        </View>
        {customer.email && (
          <View style={styles.right}>
            <Text style={styles.label}>{customer.email}</Text>
          </View>
        )}
      </View>
    )}
  </View>
);

export default Adresses;
