import {
  Box,
  HStack,
  SimpleGrid,
  Text,
  useColorModeValue,
  VStack,
  Button,
} from '@chakra-ui/react';
import { PDFDownloadLink } from '@react-pdf/renderer';

import { Fragment, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { QuoteResponse, VehicleResponse } from '../../API';
import { getRequest, getRequestQuotes } from '../../api/queries';
import FilterControl from '../../components/buttons/filter';
import Link from '../../components/buttons/link';
import Quote from '../../components/cards/quote';
import Main from '../../components/layouts/Main';
import Skeletons from '../../components/loaders/skeletons';
import RequestQuotesPDF from '../../components/pdf/quotes';
import { selectedRequestAtom } from '../../recoil/atoms';
import { paths } from '../../utils/constants';
import { capitalizeEveryFirstLetter } from '../../utils/functions';
import { flatten } from 'lodash';

import { DownloadIcon } from '@chakra-ui/icons';

//ANCHOR: quotes component container
const Quotes: React.FC = (): JSX.Element => {
  //ANCHOR: initialize useful component state
  const selectedRequest = useRecoilValue(selectedRequestAtom);
  const limit = 6;
  const params = useParams();
  const requestTextColor = useColorModeValue('gray.600', 'gray.200');

  //ANCHOR: query the selected requested
  const request = useQuery(
    ['request', { ...params, request: selectedRequest }],
    getRequest,
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );

  //ANCHOR: get all the quotes submitted by partners
  const quotes = useInfiniteQuery(
    ['request-quotes', { request, limit }],
    getRequestQuotes,
    {
      enabled: request.isSuccess,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      getNextPageParam: previousPage => {
        const nextParam = previousPage.pageParam + limit;
        return nextParam < request.data.request.quoteIds.length
          ? nextParam
          : false;
      },
    },
  );

  //ANCHOR: destructure properties from intersection observer hook
  const { ref, inView } = useInView();

  useEffect(() => {
    //ANCHOR: fetch more autoparts whenever the last autopart comes into view;
    inView && quotes.hasNextPage && quotes.fetchNextPage();
    //ANCHOR: eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, quotes.hasNextPage]);

  const vehicle = request.data
    ? request.data.vehicle
      ? (request.data.vehicle as VehicleResponse)
      : null
    : null;

  const shouldDisplayQuote = quotes.data && quotes.data.pages;
  const quoteList = shouldDisplayQuote
    ? flatten(quotes.data.pages.map(x => x.quotes))
    : [];

  return (
    <Main>
      <HStack width={'full'} spacing={4} align={'start'}>
        <VStack spacing={'1px'} width={'full'} align={'start'}>
          <Text
            fontSize={'xl'}
            fontWeight={'bold'}
            color={useColorModeValue('gray.900', 'gray.50')}
          >
            {' '}
            Quotations{' '}
          </Text>
          {request.data && (
            <Text fontSize={'sm'} fontWeight={'bold'} color={requestTextColor}>
              <Link
                href={paths.request + '/' + request.data.request.id}
                color={'brand-blue.500'}
                fontWeight={'bold'}
                fontSize={'sm'}
              >
                {request.data.autoPart.quantity} &times;{' '}
                {request.data.autoPart.autoPart.name}
              </Link>{' '}
            </Text>
          )}
          {vehicle && (
            <HStack spacing={'1'} fontSize={'xs'}>
              <Text>
                {vehicle.vehicle.make} {vehicle.vehicle.model}
              </Text>
              |
              {vehicle.vehicle.plateNumber && (
                <Text>| {vehicle.vehicle.plateNumber} </Text>
              )}
              <Text>
                | {capitalizeEveryFirstLetter(vehicle.vehicle.gearbox)}
              </Text>
              <Text>| {vehicle.vehicle.fuel}</Text>
              {vehicle.vehicle.year && vehicle.vehicle.year > 0 && (
                <Text>| {vehicle.vehicle.year}</Text>
              )}
            </HStack>
          )}
        </VStack>

        <Box py={1}>
          <FilterControl />
        </Box>
      </HStack>{' '}
      <VStack width="full">
        {' '}
        {
          //* Download Quotes Button
          quoteList.length && (
            <PDFDownloadLink
              document={<RequestQuotesPDF quoteList={quoteList} />}
              fileName={(vehicle?.vehicle.id ?? 'quotations') + '.pdf'}
            >
              {({ blob, url, loading, error }) => (
                <Button width="full" bg={'brand.400'}>
                  {loading ? 'Loading...' : 'Download Quotation(s)'}
                </Button>
              )}
            </PDFDownloadLink>
          )
        }
      </VStack>
      <SimpleGrid
        spacing={2}
        width={'full'}
        columns={{
          base: 1,
          sm: 2,
          md: 3,
        }}
      >
        {(request.isLoading || quotes.isLoading) && (
          <Skeletons count={12} height={'200px'} borderRadius={'4px'} />
        )}

        {shouldDisplayQuote &&
          quotes.data.pages.map((page, pageIndex) => {
            return (
              <Fragment key={pageIndex}>
                {page.quotes.map((quote: QuoteResponse, quoteIndex) => {
                  return (
                    <Quote
                      key={quote.quote.id}
                      pos={quoteIndex}
                      ref={
                        pageIndex === quotes.data.pages.length - 1 &&
                        quoteIndex === page.quotes.length - 1
                          ? ref
                          : null
                      }
                      QUOTE={quote}
                    />
                  );
                })}
              </Fragment>
            );
          })}
      </SimpleGrid>
    </Main>
  );
};

export default Quotes;
