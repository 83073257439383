import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 10,
  },
  billTo: {
    paddingBottom: 3,
    fontFamily: 'Helvetica-Oblique',
  },
});

const BillTo = () => (
  <View style={styles.headerContainer}>
    <Text style={styles.billTo}>Payments:</Text>
    <Text>Airtel Money Merchant Code: RIAWORK</Text>
    <Text>MoMo Merchant Code: 448338</Text>
  </View>
);

export default BillTo;
