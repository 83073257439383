import { Document, Page, StyleSheet } from '@react-pdf/renderer';
import { groupBy } from 'lodash';
import { Customer, QuoteResponse } from '../../../API';
import Adresses from './Adresses';
import PartnerQuotesTable from './PartnerQuotesTable';
import PaymentDetails from './PaymentDetails';
import ThankYouMsg from './ThankYouMsg';
import Title from './Title';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
});

interface RequestQuotesPDFProps {
  quoteList: QuoteResponse[];
}
/**
 ** This is the parent element for creating pdf quotations
 *
 *
 */
const RequestQuotesPDF = ({ quoteList }: RequestQuotesPDFProps) => {
  //* Group All qutes by partner, quotes from same partner will have their own table in the PDF
  const quotesByPartner = groupBy(quoteList, 'partner.id');

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Title />
        <Adresses customer={quoteList[0].request.vehicle?.owner as Customer} />
        <PaymentDetails />
        {Object.entries(quotesByPartner).map(([partnerId, quotes]) => (
          <PartnerQuotesTable key={partnerId} quotesByPartner={quotes} />
        ))}
        <ThankYouMsg />
      </Page>
    </Document>
  );
};

export default RequestQuotesPDF;
