/* eslint-disable no-irregular-whitespace */
import { Heading, Text, VStack } from '@chakra-ui/react';
import Main from '../components/layouts/Main';

/**
 *
 * @returns Refund component for the refund policy
 */
//ANCHOR: Refund component
const Refund: React.FC = (): JSX.Element => {
  return (
    <Main>
      <VStack
        width={{
          base: '100%',
          sm: '90%',
          md: '70%',
          lg: '60%',
        }}
        height="auto"
        mx={'auto'}
      >
        <Heading fontSize={{ base: 'xl', md: '2xl', lg: '4xl' }}>
          OUR RETURN POLICY
        </Heading>
        <Text fontSize={{ base: 13, sm: 15, md: 16, lg: 18 }}>
          All parts are purchased from independent suppliers, so parts must be
          returned to them direct and NOT to All parts are purchased from
          independent suppliers, so parts must be returned to them direct and
          NOT to Riaparts
          <br />
          <br />
          The item must be returned in the same condition you received it (i.e.
          item not dismantled or parts removed).
          <br />
          <br />
          Ensure the item is well packaged to avoid damage in transit, as you
          are responsible for the items condition and safe delivery.
          <br />
          <br />
          Check with the supplier for the correct address to return the item to
          and always include your Riaparts reference number. If you cannot
          remember who you purchased from, please check your quote emails/texts
          or login online.
          <br />
          <br />
          Ensure to include inside your parcel a note detailing who the item has
          been returned from and the Riaparts reference number from your parts
          request. Also attach your from address onto the parcel in case the
          item does not get delivered to its destination so it can be returned
          to you.
          <br />
          <br />
          Finally, ensure you send the item via a signed for tracked service
          which will provide you with facility to confirm receipt of goods by
          the supplier. Obtain a receipt for proof of postage. We would also
          advise to take out cover/insurance to protect you against damage or
          loss.
          <br />
          <br />
          The item must be returned in the same condition you received it (i.e.
          item not dismantled or parts removed). Ensure the item is well
          packaged to avoid damage in transit, as you are responsible for the
          items condition and safe delivery.
          <br />
          <br />
          Check with the supplier for the correct address to return the item to
          and always include your Riaparts reference number. If you cannot
          remember who you purchased from, please check your quote emails/texts
          or login online.
          <br />
          <br />
          Ensure to include inside your parcel a note detailing who the item has
          been returned from and the Riaparts reference number from your parts
          request. Also attach your from address onto the parcel in case the
          item does not get delivered to its destination so it can be returned
          to you.
          <br />
          <br />
          Finally, ensure you send the item via a signed for tracked service
          which will provide you with facility to confirm receipt of goods by
          the supplier. Obtain a receipt for proof of postage. We would also
          advise to take out cover/insurance to protect you against damage or
          loss.
        </Text>
        <br />
        <Heading fontSize={{ base: 'xl', md: '2xl', lg: '4xl' }}>
          OUR REFUND POLICY
        </Heading>
        <Text fontSize={{ base: 13, sm: 15, md: 16, lg: 18 }}>
          Any payment you have made via the Riaparts service, goes directly to
          the independent supplier. Any refunds can only be processed by the
          supplier directly.
          <br />
          <br />
          You will need to contact the independent supplier direct to discuss
          any refunds. Please check your quote emails/texts or login online for
          your suppliers contact details.
          <br />
          <br />
          Any potential refunds are not processed until the items are returned
          to, and inspected by, the supplier first.  Refunds can take up to 14
          days from the day the supplier receives the item back.
          <br />
          <br />
          If no items are being returned suppliers should begin the refund
          process as soon as possible. Once the refund has been processed,
          refunds can take several days to clear as per banking timescales.
        </Text>
      </VStack>
    </Main>
  );
};

export default Refund;
