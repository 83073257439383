import { IconButton, Image, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import { FaTrash } from 'react-icons/fa';
import { getPinpointButtonClickEventProp } from '../../../utils/functions-ts';
import { useLocation } from 'react-router-dom';
interface IProps {
  photos: string[];
  callback?: (url: string) => void;
  showDeleteIcon?: boolean;
}

const PhotoList: React.FC<IProps> = ({
  photos,
  callback,
  showDeleteIcon,
}): JSX.Element => {
  //console.log('photos::', photos);
  const { pathname } = useLocation();
  return (
    <>
      {photos.length > 0 ? (
        <SimpleGrid
          p={3}
          spacing={4}
          borderRadius={'8px'}
          columns={{
            base: 4,
            md: 5,
          }}
          width={'full'}
          maxH={'80px'}
          overflowY={'scroll'}
          data-component="autopart-form-images-place-holder"
        >
          {photos.map((photo, index) => {
            return (
              <div
                key={index}
                className="border border-gray-200 shadow-md rounded-lg p-1 relative"
              >
                {showDeleteIcon ? (
                  <IconButton
                    variant={'outline'}
                    pos={'absolute'}
                    top={'0'}
                    right={'0'}
                    bg={'gray.300'}
                    isRound={true}
                    size={'xs'}
                    aria-label="delete photo"
                    onClick={() => {
                      //console.log('clicked delete photo', photo);
                      if (callback) callback(photo);
                    }}
                    {...getPinpointButtonClickEventProp({
                      command: 'delete photo',
                      currentPage: pathname,
                    })}
                    isLoading={false}
                    icon={<FaTrash color="#FE6401" size={'14px'} />}
                  />
                ) : null}

                <Image
                  src={photo}
                  key={index}
                  width={'full'}
                  height={'40px'}
                  objectFit={'cover'}
                  borderRadius={'4px'}
                />
              </div>
            );
          })}
        </SimpleGrid>
      ) : null}
    </>
  );
};

export default React.memo(PhotoList);
