import React from 'react';
import {
  Button,
  forwardRef,
  useColorModeValue,
  useColorMode,
  IconButton,
} from '@chakra-ui/react';

import { FaMoon, FaSun } from 'react-icons/fa';

// Check forwardRef docs: https://chakra-ui.com/guides/as-prop

//
// Custom Icon Component which forwards IconProps ThemeLogo themeToggleLogo
//
export const ButtonBrandMode = forwardRef((props, ref) => (
  <Button ref={ref} {...props} />
));

//
// Button Primary
//
export const ButtonPrimary = forwardRef((props, ref) => (
  <Button
    color="black"
    bg="brand.400"
    borderRadius="4px"
    _hover={{
      bg: 'brand.400',
      boxShadow: 'xs',
    }}
    _focus={{
      color: 'black',
      bg: 'brand.400',
      outline: '0',
      boxShadow: 'xs',
    }}
    ref={ref}
    {...props}
  >
    {props.children}
  </Button>
));

//
// Button Soft Primary
//
export const ButtonSoftPrimary = forwardRef((props, ref) => (
  <Button
    color="orange.500"
    bg="brand.50"
    borderWidth="2px"
    borderColor="orange.50"
    borderRadius="4px"
    _hover={{
      color: 'white',
      bg: 'orange.500',
      borderColor: 'orange.500',
      boxShadow: useColorModeValue(
        '0px 0.25rem 0.75rem 0px rgb(190 194 255 / 88%)',
        '0px 0.25rem 0.75rem 0px rgb(0 46 230 / 35%)',
      ),
      leftIconColor: 'white',
    }}
    _active={{
      color: 'white',
      bg: 'orange.600',
      borderColor: 'orange.600',
      leftIconColor: 'white',
    }}
    _focus={{
      color: 'white',
      bg: 'orange.600',
      borderColor: 'orange.600',
      outline: '0',
      boxShadow: useColorModeValue(
        '0px 0.25rem 0.75rem 0px rgb(190 194 255 / 88%)',
        '0px 0.25rem 0.75rem 0px rgb(0 46 230 / 35%)',
      ),
    }}
    ref={ref}
    {...props}
  >
    {props.children}
  </Button>
));

//
// Button Soft Success
//
export const ButtonSoftSuccess = forwardRef((props, ref) => (
  <Button
    color="#36b37e"
    bg="#d7f0e5"
    borderWidth="2px"
    borderColor="#d7f0e5"
    borderRadius="4px"
    _hover={{
      color: 'white',
      bg: '#36b37e',
      borderColor: '#36b37e',
      boxShadow: useColorModeValue(
        '0px 0.25rem 0.75rem 0px rgb(54 179 126 / 26%)',
        '0px 0.25rem 0.75rem 0px rgb(54 179 126 / 26%)',
      ),
    }}
    _active={{
      color: 'white',
      bg: '#36b37e',
      borderColor: '#36b37e',
    }}
    _focus={{
      color: 'white',
      bg: '#2c8f5d',
      borderColor: '#36b37e',
      outline: '0',
      boxShadow: useColorModeValue(
        '0px 0.25rem 0.75rem 0px rgb(54 179 126 / 26%)',
        '0px 0.25rem 0.75rem 0px rgb(54 179 126 / 26%)',
      ),
    }}
    ref={ref}
    {...props}
  >
    {props.children}
  </Button>
));

//
// Button Primarywhite
//
export const ButtonPrimaryWhite = forwardRef((props, ref) => (
  <Button
    color="orange.500"
    bg="white"
    borderWidth="2px"
    borderColor="white"
    borderRadius="4px"
    _hover={{
      color: 'white',
      bg: 'orange.500',
      borderColor: 'orange.500',
      boxShadow: useColorModeValue(
        '0px 0.25rem 0.75rem 0px rgb(190 194 255 / 88%)',
        '0px 0.25rem 0.75rem 0px rgb(0 46 230 / 35%)',
      ),
    }}
    _active={{
      color: 'white',
      bg: 'orange',
      borderColor: 'orange',
    }}
    _focus={{
      color: 'white',
      bg: 'orange',
      borderColor: 'orange',
      outline: '0',
      boxShadow: useColorModeValue(
        '0px 0.25rem 0.75rem 0px rgb(190 194 255 / 88%)',
        '0px 0.25rem 0.75rem 0px rgb(0 46 230 / 35%)',
      ),
    }}
    ref={ref}
    {...props}
  >
    {props.children}
  </Button>
));

//
// Button Primary Outline
//
export const ButtonPrimaryOutline = forwardRef((props, ref) => (
  <Button
    color={useColorModeValue('orange.500', 'orange.100')}
    bg="trasparent"
    borderWidth="2px"
    borderColor={useColorModeValue('orange.500', 'orange.100')}
    borderRadius="4px"
    _hover={{
      color: 'white',
      bg: 'orange.500',
      borderColor: 'orange.500',
      boxShadow: useColorModeValue(
        '0px 0.25rem 0.75rem 0px rgb(190 194 255 / 88%)',
        '0px 0.25rem 0.75rem 0px rgb(0 46 230 / 35%)',
      ),
    }}
    _active={{
      color: 'white',
      bg: 'orange.600',
      borderColor: 'orange.600',
    }}
    _focus={{
      color: 'white',
      bg: 'orange.600',
      borderColor: 'orange.600',
      outline: '0',
      boxShadow: useColorModeValue(
        '0px 0.25rem 0.75rem 0px rgb(190 194 255 / 88%)',
        '0px 0.25rem 0.75rem 0px rgb(0 46 230 / 35%)',
      ),
    }}
    ref={ref}
    {...props}
  >
    {props.children}
  </Button>
));

//
// Button Outlinewhite
//
export const ButtonOutlineWhite = forwardRef((props, ref) => (
  <Button
    color="orange"
    bg="trasparent"
    borderWidth="2px"
    borderColor="orange"
    borderRadius="4px"
    _hover={{
      color: 'white',
      bg: 'orange',
      boxShadow: '0px 0.25rem 0.75rem 0px rgb(190 194 255 / 88%)',
    }}
    _active={{ color: 'white', bg: 'orange.600', borderColor: 'orange.600' }}
    _focus={{
      color: 'white',
      bg: 'orange.600',
      borderColor: 'orange.600',
      outline: '0',
      boxShadow: '0px 0.25rem 0.75rem 0px rgb(190 194 255 / 88%)',
    }}
    ref={ref}
    {...props}
  >
    {props.children}
  </Button>
));

export const ButtonSoftPrimaryGreen = forwardRef((props, ref) => (
  <Button
    color="green.500"
    bg="green.100"
    borderWidth="2px"
    borderColor="green.100"
    borderRadius="4px"
    _hover={{
      color: 'white',
      bg: 'green.500',
      borderColor: 'green.500',
      leftIconColor: 'white',
    }}
    _active={{}}
    _focus={{
      color: 'white',
      bg: 'green.600',
      borderColor: 'green.600',
      outline: '0',
    }}
    ref={ref}
    {...props}
  >
    {props.children}
  </Button>
));

export const ColorModeSwitcher = props => {
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue('dark', 'light');
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);

  return (
    <IconButton
      size="sm"
      fontSize="sm"
      aria-label={`Switch to ${text} mode`}
      variant="ghost"
      color="current"
      marginLeft="2"
      onClick={toggleColorMode}
      bg={useColorModeValue('gray.200', 'gray.700')}
      icon={<SwitchIcon />}
      {...props}
    />
  );
};
