import { HStack, Spacer } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { getOrder } from '../../api/queries';
import Main from '../../components/layouts/Main';
import { orderAtom } from '../../recoil/atoms';
import OrderDetailsContent from './content';
import Loading from './loading';
/**
 *
 * @returns  the orders page containing all the orders a user has made
 */
//ANCHOR: main component for orders page
const Order: React.FC = (): JSX.Element => {
  //ANCHOR: initialize component state
  const [order, setOrder] = useRecoilState(orderAtom);
  const [orderId, setOrderId] = useState<string | undefined>('');

  /**
   * destructure properties from the url
   */
  const params = useParams();

  /**
   * @returns returns the orders the user has made
   */
  const getOrderQuery = useQuery(['order', { orderId }], getOrder, {
    enabled: !!orderId,
    refetchInterval: 0,
    cacheTime: 0,
    onSuccess: data => setOrder(data),
  });

  //ANCHOR: check for order and trigger getOrderQuery if necessary
  useEffect(() => {
    if (order && order.order && order.order.id === params.id) return;
    setOrderId(params.id);
  }, [order, setOrderId, params.id]);

  return (
    <Main>
      <HStack spacing={3}>
        <Spacer />
      </HStack>
      {getOrderQuery.isLoading ? (
        <Loading />
      ) : (
        order && <OrderDetailsContent ORDER={order} />
      )}
    </Main>
  );
};

export default Order;
