import {
  Button,
  HStack,
  Input,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { AiOutlineSend } from 'react-icons/ai';
import { useMutation } from 'react-query';
import useWebSocket from 'react-use-websocket';
import { useRecoilState, useRecoilValue } from 'recoil';
import { sendMessage as SendMessageMut } from '../../../../api/mutations';
import config from '../../../../config/backend-config';
import {
  cognitoUserAtom,
  lastMessageAtom,
  newMessage,
  selectedConversationAtom,
} from '../../../../recoil/atoms';
import { unStringfy } from '../../../../utils/functions';

export default function SendMessageControl() {
  const user = useRecoilValue(cognitoUserAtom);
  const conversation = useRecoilValue(selectedConversationAtom);
  const lastMessage = useRecoilValue(lastMessageAtom);
  const [, setnewMessageIncome] = useRecoilState(newMessage);

  const toast = useToast();

  const { handleSubmit, register, reset, formState } = useForm();

  const sendMessageMutation = useMutation(SendMessageMut);

  const { sendJsonMessage } = useWebSocket(
    config.websocket.URL,
    {
      queryParams:
        conversation && user
          ? {
              conversationId: conversation.conversation.id,
              creatorId: user.sub || user.id,
              creatorType: 'CUSTOMER',
            }
          : null,

      onMessage: e => {
        setnewMessageIncome(JSON.parse(e.data));
      },
      retryOnError: true,

      onError: e => {
        console.log(e);
      },

      reconnectAttempts: 10,
      reconnectInterval: 2000,
    },
    !!conversation,
  );

  async function sendProposal({ proposedAmount }) {
    if (proposedAmount === 0) return;
    try {
      const quote = unStringfy(lastMessage.message.message).quote;
      if (!quote) throw new Error('malformed quote');
      const message = {
        type: 'proposal',
        proposedAmount,
        quote,
      };
      sendJsonMessage({
        message: JSON.stringify(message),
        senderId: user.sub || user.id,
        senderType: 'CUSTOMER',
        conversationId: conversation.conversation.id,
      });
      reset();
    } catch (error) {
      console.log(error);
      toast({
        status: 'error',
        title: 'Network error',
        description: 'Please try again',
        duration: 2000,
        position: 'bottom',
      });
    }
  }

  return (
    <HStack
      as={'form'}
      spacing={4}
      p={3}
      width={'full'}
      borderTopWidth={'1px'}
      borderTopColor={useColorModeValue('gray.50', 'gray.600')}
      align={'center'}
      justify={'center'}
      onSubmit={handleSubmit(sendProposal)}
    >
      <Text fontWeight={'bold'} fontSize={'lg'}>
        {' '}
        K{' '}
      </Text>
      <Input
        type={'number'}
        variant={'unstyled'}
        placeholder={'Enter your price proposal here'}
        fontSize={'sm'}
        width={'full'}
        color={useColorModeValue('gray.600', 'gray.300')}
        _focus={{
          color: useColorModeValue('gray.900', 'gray.100'),
        }}
        {...register('proposedAmount')}
      />
      <Button
        type={'submit'}
        minW={'32px'}
        width={'32px'}
        height={'32px'}
        p={0}
        rounded={'full'}
        colorScheme={'brand-blue'}
        color={'white'}
        isLoading={sendMessageMutation.isLoading}
        disabled={!formState.isDirty}
      >
        {' '}
        <AiOutlineSend size={'14px'} />{' '}
      </Button>
    </HStack>
  );
}

/*


      await sendMessageMutation.mutateAsync({
        user, conversation,
        body: JSON.stringify(message)
      });

*/
