import { useToast } from '@chakra-ui/react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  Creator,
  ErrorCode,
  Status,
  VehicleResponse,
  createAutoPartInput,
  createRequestInput,
} from '../API';
import {
  compressAndUploadPhotos,
  createAutoPart,
  createRequest,
} from '../api/mutations';
import {
  AutopartListAtom,
  cognitoUserAtom,
  makeValueAtom,
  modelValueAtom,
  yearValueAtom,
  variantValueAtom,
  engineValueAtom,
  openCreateVehicle,
  partialVehicleAtom,
  requestStepAtom,
  requestPrevPhotosAtom,
  requestUploadPhotosAtom,
  CreateRequestErrorResultsAtom,
} from '../recoil/atoms';
import { paths } from '../utils/constants';
import { ICreateRequestError, IAutopartList } from '../types/interfaces';

export default function useCreateVehicle() {
  const setAutopartList = useSetRecoilState(AutopartListAtom);
  const setPartialVehicle = useSetRecoilState(partialVehicleAtom);
  const setMakeValue = useSetRecoilState(makeValueAtom);
  const setModelValue = useSetRecoilState(modelValueAtom);
  const setYear = useSetRecoilState(yearValueAtom);
  const setVariantValue = useSetRecoilState(variantValueAtom);
  const setEngineValue = useSetRecoilState(engineValueAtom);
  const setShowForm = useSetRecoilState(openCreateVehicle);
  const setActiveStep = useSetRecoilState(requestStepAtom);
  const setRequestPreviousPhotos = useSetRecoilState(requestPrevPhotosAtom);
  const setRequestUploadPhotos = useSetRecoilState(requestUploadPhotosAtom);
  const setCreateRequestErrors = useSetRecoilState(
    CreateRequestErrorResultsAtom,
  );
  //const [failedRequests, setFailedRequests] = useState<IAutopartList[]>([]);

  const createRequestMutation = useMutation(createRequest);
  //const isAuthenticated = useRecoilValue(isAuthenticatedAtom);
  const user = useRecoilValue(cognitoUserAtom);

  //NOTE: initialize create request mutation
  const uploadPhoto = useMutation(compressAndUploadPhotos);

  //NOTE: initialize create autopart mutation
  const createAutoPartMutation = useMutation(createAutoPart);

  const requestIsLoading =
    createRequestMutation.isLoading ||
    uploadPhoto.isLoading ||
    createAutoPartMutation.isLoading;

  const toast = useToast();
  const navigate = useNavigate();

  const resetFunc = () => {
    setMakeValue('');
    setModelValue('');
    setYear(new Date().getFullYear() + 1);
    setVariantValue('');
    setEngineValue('');
    setShowForm(false);
  };

  type TOASTSTATUS =
    | 'error'
    | 'info'
    | 'warning'
    | 'success'
    | 'loading'
    | undefined;

  const customToastHook = ({
    status,
    msg,
  }: {
    status?: TOASTSTATUS;
    msg: string;
  }) => {
    toast({
      status: status || 'info',
      title: msg,
      duration: 3000,
      isClosable: true,
      position: 'bottom',
    });
  };

  const stateReset = () => {
    navigate(paths.requests);
    setRequestPreviousPhotos([]);
    setRequestUploadPhotos([]);
    setActiveStep(1);
    setAutopartList([]);
    setPartialVehicle(null);
    resetFunc();
  };

  /*
        **************************** SECTION ************************
        Fetch Vehicles from the database and set the return type to a list of Vehicles using UseQueryResult
*/
  const createRequests = async (
    selectV: VehicleResponse,
    myAutopartList: IAutopartList[],
  ) => {
    try {
      customToastHook({
        status: 'info',
        msg: 'creating order...',
      });
      //SECTION - Creating a requests
      let errors: ICreateRequestError[] = [];
      //SECTION - loop through the autopart list array
      for (const autopart of myAutopartList) {
        console.log('sample part', autopart);
        //NOTE - extract photos from autopart
        const photosToUpload = autopart?.requestPhotos ?? [];
        let prevPhotos = autopart?.uploadedPhotos ?? [];
        //NOTE - upload photos and add to prevPhotos array
        if (photosToUpload.length > 0) {
          const resultPhotos = await uploadPhoto.mutateAsync({
            user,
            files: prevPhotos,
          });
          if (!resultPhotos) {
            errors = [
              ...errors,
              {
                uploadedPhotos: photosToUpload,
                errorResponse: {
                  __typename: 'ErrorResponse',
                  code: ErrorCode.CREATE_FAILED,
                  error: 'photo upload failed',
                },
                autopart,
              },
            ];
            continue;
          }
          prevPhotos = [
            ...prevPhotos,
            ...resultPhotos.map(img => img.imageS3Name),
          ];
        }
        //SECTION - create autoparts
        const createAutopartInput: createAutoPartInput = {
          name: autopart.name,
          OEM: autopart.OEM,
          description: autopart.description,
        };
        if (prevPhotos.length > 0) {
          createAutopartInput.src = prevPhotos;
        }

        const autopartResult = await createAutoPartMutation.mutateAsync(
          createAutopartInput,
        );

        if (
          'error' in autopartResult ||
          autopartResult.__typename === 'ErrorResponse'
        ) {
          errors = [
            ...errors,
            {
              prevPhotos,
              autopartInput: createAutopartInput,
              errorResponse: autopartResult,
              autopart,
            },
          ];
          continue;
        }

        const createRequestObj: createRequestInput = {
          requestedPart: {
            autoPartId: autopartResult.id,
            quantity: autopart.quantity as number,
          },
          status: Status.OPEN,
          creatorId: (user as any).sub || user?.id,
          creatorType: Creator.CUSTOMER,
          make: selectV?.vehicle.make as string,
          model: selectV?.vehicle.model as string,
          gearbox: selectV?.vehicle.gearbox,
          year: selectV?.vehicle.year,
          fuel: selectV?.vehicle.fuel,
          variant: selectV?.vehicle.variant,
          vehicleId: selectV?.vehicle.id,
          contactDetails: JSON.stringify({
            email: user?.email,
            phoneNumber: user?.phoneNumber,
          }),
          otherDetails: JSON.stringify({ description: '' }),
        };

        const requestResult = await createRequestMutation.mutateAsync(
          createRequestObj,
        );
        if (!requestResult) {
          errors = [
            ...errors,
            {
              errorResponse: {
                code: ErrorCode.CREATE_FAILED,
                error: 'Failed to create request',
                __typename: 'ErrorResponse',
              },
              createRequestInput: createRequestObj,
              autopart,
            },
          ];
          continue;
        }
        customToastHook({
          status: 'success',
          msg: `Created order for ${autopart.name}`,
        });
      }
      setCreateRequestErrors(errors);
      stateReset();
    } catch (error) {
      customToastHook({
        status: 'error',
        msg: 'Failed creating order... please try again',
      });
    }
  };

  /*FIXME - 
  Old create request function
  */
  /*
  const requestCreateFunc = async (selectV: VehicleResponse) => {
    await Promise.all(
      autopartList.map(async autopart => {
        const photos = [
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...(autopart?.uploadedPhotos ?? []),
          ...(autopart?.requestPhotos ?? []),
        ];

        console.log(autopart);

        if (photos.length < 1) {
          if (!autopart.name) throw new Error('Auto Part missing name');
          createAutoPartMutation
            .mutateAsync({
              name: autopart.name,
              OEM: autopart.OEM,
              description: autopart.description,
            })
            .then(data => {
              if (data.__typename === 'ErrorResponse')
                throw new Error(data.error || data.code);

              //NOTE: create request with the newly created autopart
              createRequestMutation
                .mutateAsync({
                  requestedPart: {
                    autoPartId: data.id,
                    quantity: autopart.quantity as number,
                  },
                  status: Status.OPEN,
                  creatorId: (user as any).sub || user?.id,
                  creatorType: Creator.CUSTOMER,
                  make: selectV?.vehicle.make as string,
                  model: selectV?.vehicle.model as string,
                  gearbox: selectV?.vehicle.gearbox,
                  year: selectV?.vehicle.year,
                  fuel: selectV?.vehicle.fuel,
                  variant: selectV?.vehicle.variant,
                  vehicleId: selectV?.vehicle.id,
                  contactDetails: JSON.stringify({
                    email: user?.email,
                    phoneNumber: user?.phoneNumber,
                  }),
                  otherDetails: JSON.stringify({ description: '' }),
                })
                .then(data => {
                  //NOTE: rest request state
                  if (data === null) {
                    customToastHook({
                      status: 'error',
                      msg: 'Sorry, Could not create request try again...',
                    });
                    throw new Error('Error, Could not create request ');
                  }
                  stateRest();
                });
            });

          return;
        }

        if (
          autopart &&
          autopart.requestPhotos &&
          autopart.requestPhotos.length < 1
        ) {
          //NOTE: create an autopart with the photo selected from the previous-uploaded photos list
          if (!autopart.name) throw new Error('Auto Part missing name');
          createAutoPartMutation
            .mutateAsync({
              name: autopart.name,
              OEM: autopart.OEM,
              src: autopart.uploadedPhotos,
              description: autopart.description,
            })
            .then(data => {
              if (data.__typename === 'ErrorResponse')
                throw new Error(data.error || data.code);
              if (!user)
                throw new Error('User not found before creating a request');
              //NOTE: create request with the newly created autopart
              createRequestMutation
                .mutateAsync({
                  requestedPart: {
                    autoPartId: data.id,
                    quantity: autopart.quantity as number,
                  },
                  status: Status.OPEN,
                  creatorId: (user as any).sub || user?.id,
                  creatorType: Creator.CUSTOMER,
                  make: selectV.vehicle.make,
                  model: selectV.vehicle.model,
                  gearbox: selectV.vehicle.gearbox,
                  year: selectV.vehicle.year,
                  fuel: selectV.vehicle.fuel,
                  variant: selectV.vehicle.variant,
                  vehicleId: selectV.vehicle.id,
                  contactDetails: JSON.stringify({
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                  }),
                  otherDetails: JSON.stringify({ description: '' }),
                })
                .then(data => {
                  //NOTE: rest request states
                  navigate(paths.requests);
                  stateRest();
                });
            });
          //NOTE: if it's the first time the user is uploading a photo, then upload it first then create an autopart and then create a request photo
        } else {
          //NOTE: Get Blob from Object url
          let files: any[] = [];
          if (autopart && autopart.requestPhotos) {
            files = await Promise.all(
              autopart.requestPhotos.map(
                async (url: string) => await fetch(url).then(r => r.blob()),
              ),
            );
          }

          //NOTE: upload photo mutation
          uploadPhoto.mutateAsync({ user, files }).then(res => {
            //NOTE: create autopart mutation{ imageS3Name, id: photoId }
            if (!autopart.name) throw new Error('Auto Part missing name');

            const images = res.map(image => {
              return image.imageS3Name;
            });

            createAutoPartMutation
              .mutateAsync({
                name: autopart.name,
                OEM: autopart.OEM,
                src: [...(autopart?.uploadedPhotos ?? []), ...images],
                description: autopart.description,
              })
              .then(data => {
                //NOTE: Create request mutation
                if (data.__typename === 'ErrorResponse')
                  throw new Error(data.error || data.code);
                if (!user)
                  throw new Error('User not found before creating a request');
                if (!selectV)
                  throw new Error(
                    'Vehicle not selected before creating a request',
                  );

                createRequestMutation
                  .mutateAsync({
                    requestedPart: {
                      autoPartId: data.id,
                      quantity: autopart.quantity as number,
                    },
                    status: Status.OPEN,
                    creatorId: (user as any).sub || user?.id,
                    creatorType: Creator.CUSTOMER,
                    make: selectV.vehicle.make,
                    model: selectV.vehicle.model,
                    gearbox: selectV.vehicle.gearbox,
                    year: 0,
                    fuel: selectV.vehicle.fuel,
                    variant: selectV.vehicle.variant,
                    vehicleId: selectV.vehicle.id,
                    contactDetails: JSON.stringify({
                      email: user.email,
                      phoneNumber: user.phoneNumber,
                    }),
                    otherDetails: '{}',
                  })
                  .then(data => {
                    console.log('request response', data);
                    //NOTE: reset create request state
                    navigate(paths.requests);
                    stateRest();
                  });
              });
          });
        }
        console.log('ma spare', photos);
      }),
    );
  };
*/
  return { requestIsLoading, createRequests };
}
