import { isAuthenticatedAtom } from '../recoil/atoms';
import { Navigate, useLocation } from 'react-router-dom';
import { paths } from '../utils/constants';
import { useRecoilValue } from 'recoil';

// not really an HOC
export default function withAuthentication(Component) {
  return (() => {
    const isAuthenticated = useRecoilValue(isAuthenticatedAtom);
    const location = useLocation();
    return isAuthenticated ? (
      <Component />
    ) : (
      <Navigate
        to={paths.login + '?from=' + location.pathname}
        replace={true}
      />
    );
  })();
}
