import {
  Badge,
  Box,
  HStack,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { forwardRef } from 'react';
import { HiDotsVertical, HiMinusCircle } from 'react-icons/hi';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import {
  cartAtom,
  shouldDeliverAtom,
  paymentProcessorAtom,
} from '../../recoil/atoms';
import { paths } from '../../utils/constants';
import { formatPrice } from '../../utils/functions';
import Link from '../buttons/link';
import Carousel from '../carousel';
import ImageContainer from '../carousel/image-container';
import { CARTITEM } from '../../types/interfaces';
import {
  getPaymentOptionByProcessorAndDelivery,
  getPinpointButtonClickEventProp,
} from '../../utils/functions-ts';
import { useLocation } from 'react-router-dom';

//NOTE: types for cartitem props
interface Params {
  item: CARTITEM;
  isExpired: boolean;
}

const CartItem: React.ForwardRefExoticComponent<Params> = forwardRef(function (
  { item, isExpired },
  ref: React.LegacyRef<HTMLDivElement>,
): JSX.Element {
  //NOTE: initialize set cart recoil state
  const setCart = useSetRecoilState(cartAtom);
  const shouldDeliver = useRecoilValue(shouldDeliverAtom);
  const { pathname } = useLocation();

  //NOTE: initialize responsive properties
  const processor = useRecoilValue(paymentProcessorAtom);
  const partNameColor = useColorModeValue('gray.900', 'gray.100');
  const mmvColor = useColorModeValue('gray.700', 'gray.300');
  const priceColor = useColorModeValue('gray.700', 'gray.200');
  const fee = getPaymentOptionByProcessorAndDelivery(
    processor,
    item.paymentOptions,
    shouldDeliver,
  );

  return (
    <HStack
      spacing={2}
      width={'full'}
      align={'start'}
      ref={ref}
      opacity={isExpired ? 0.4 : 1}
    >
      {item && item.photos && item.photos.length && item.photos.length > 0 && (
        <Carousel
          automatic={false}
          displayButtons={false}
          showModal={true}
          height={'62px'}
          minW={'68px'}
          width={'72px'}
          py={'4px'}
        >
          {item.photos.map((photo: any) => (
            <ImageContainer
              key={photo.imageS3Name}
              bg={'blackAlpha.500'}
              borderRadius={'4px'}
            >
              <Box
                minH={'12px'}
                minW={'12px'}
                rounded={'full'}
                bg={'gray.100'}
                position={'relative'}
                display={'none'}
              ></Box>
              <Image
                src={photo.imageS3Name}
                borderRadius={'4px'}
                width={'full'}
                height={'full'}
                objectFit={'cover'}
              />
            </ImageContainer>
          ))}
        </Carousel>
      )}

      <VStack spacing={'1px'} p={0} m={0} alignItems={'start'}>
        <Text fontWeight={'medium'} fontSize={'xs'} color={partNameColor}>
          {item.quantity}
          <Text as={'span'} fontWeight={'light'}>
            {' '}
            &times;{' '}
          </Text>
          {item.partName}{' '}
        </Text>
        <Text fontSize={'11px'} color={mmvColor}>
          {' '}
          <>
            {item.carDetails.make} {item.carDetails.model} -{' '}
            {item.carDetails.year}
          </>{' '}
        </Text>
        <Text fontWeight={'extrabold'} fontSize={'12px'} color={priceColor}>
          {' '}
          {formatPrice(fee.finalFee)}{' '}
        </Text>
        {shouldDeliver ? (
          <Badge colorScheme="green">With Delivery</Badge>
        ) : (
          <Badge colorScheme="purple">No Delivery</Badge>
        )}
      </VStack>
      <Spacer />

      <VStack
        flexDir={'column'}
        height={'full'}
        justifyContent={'space-between'}
        px={0}
        py={1}
      >
        <Menu>
          <MenuButton>
            <HiDotsVertical className="w-3 h-3" />
          </MenuButton>
          <MenuList fontSize={'xs'}>
            <MenuItem
              {...getPinpointButtonClickEventProp({
                command: 'view quote details',
                currentPage: pathname,
              })}
              as={Link}
              href={paths.quote + '/' + item.quoteId}
            >
              {' '}
              View Quote Details{' '}
            </MenuItem>

            <MenuItem
              as={'button'}
              onClick={() =>
                setCart(cart =>
                  cart.filter(cartItem => cartItem.quoteId !== item.quoteId),
                )
              }
              {...getPinpointButtonClickEventProp({
                command: 'remove item from cart from menu',
                currentPage: pathname,
              })}
            >
              {' '}
              Remove Item From Cart{' '}
            </MenuItem>
            <MenuItem
              as={'button'}
              onClick={() => setCart([item])}
              {...getPinpointButtonClickEventProp({
                command: 'remove all other items from cart',
                currentPage: pathname,
              })}
            >
              Remove All Other Items
            </MenuItem>
          </MenuList>
        </Menu>

        <Spacer />
        <Icon
          as={HiMinusCircle}
          color={'red'}
          cursor={'pointer'}
          onClick={() => {
            setCart(cart =>
              cart.filter(cartItem => cartItem.quoteId !== item.quoteId),
            );
          }}
          {...getPinpointButtonClickEventProp({
            command: 'remove item from cart using icon button',
            currentPage: pathname,
          })}
        />
      </VStack>
    </HStack>
  );
});

export default CartItem;
