type STARTDate = Date | number | string;

type ENDDate = Date | number | string;

const useDateCompare = (
  startDate: STARTDate,
  endDate: ENDDate,
): {
  displayPromotionCalculation: boolean;
  displayPromotionBanner: boolean;
} => {
  const date1 = new Date(startDate).getTime();
  const today = new Date().getTime();
  const date2 = new Date(endDate).getTime();

  return {
    displayPromotionCalculation: today >= date1 && today < date2,
    displayPromotionBanner: today <= date2,
  };
};

export default useDateCompare;
