import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Spinner,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { mobileMoneyAccountsAtom } from '../../../recoil/atoms';

import MobileMoneyForm from '../../../components/forms/mobile-money';

export default function MobileMoneyAccounts() {
  const mobileMoneyAccounts = useRecoilValue(mobileMoneyAccountsAtom);

  const accordionItemBG = useColorModeValue('white', 'blackAlpha.400');

  return (
    <VStack
      width={'full'}
      minH={'full'}
      p={0}
      px={{
        base: 0,
        sm: 3,
      }}
    >
      {mobileMoneyAccounts === null ? (
        <Spinner />
      ) : Array.isArray(mobileMoneyAccounts) ? (
        mobileMoneyAccounts.length > 0 ? (
          <>
            <Accordion
              width={'full'}
              allowToggle={true}
              allowMultiple={true}
              m={0}
              p={0}
            >
              {mobileMoneyAccounts.map(mobileMoneyAccount => (
                <AccordionItem
                  key={mobileMoneyAccount.paymentMethod.id}
                  width={'full'}
                  borderTopWidth={0}
                  borderBottomWidth={2}
                  m={0}
                  mb={2}
                  _focusWithin={{}}
                >
                  <AccordionButton
                    mb={3}
                    _focus={{
                      bg: accordionItemBG,
                      color: 'blue.600',
                    }}
                    _hover={{
                      color: 'blue.600',
                      bg: accordionItemBG,
                    }}
                    borderRadius={'lg'}
                    outline={'none'}
                    border={0}
                    px={2}
                  >
                    <Text
                      width={'full'}
                      textAlign={'left'}
                      textOverflow={'ellipsis'}
                      overflow={'hidden'}
                      whiteSpace={'nowrap'}
                    >
                      {' '}
                      <b>
                        {mobileMoneyAccount.paymentMethod.mobileMoneyProvider}
                      </b>{' '}
                      | {mobileMoneyAccount.paymentMethod.name} |{' '}
                      {mobileMoneyAccount.paymentMethod.phoneNumber}{' '}
                    </Text>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel
                    px={4}
                    py={6}
                    mv={4}
                    borderRadius={'lg'}
                    bg={accordionItemBG}
                  >
                    <MobileMoneyForm account={mobileMoneyAccount} />
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </>
        ) : (
          <Box
            bg={'blackAlpha.200'}
            p={10}
            width={'full'}
            borderRadius={'lg'}
            textAlign={'center'}
          >
            You have not added any mobile money accounts
          </Box>
        )
      ) : (
        <Text> Something went wrong </Text>
      )}
      <Accordion allowToggle={true} width={'full'}>
        <AccordionItem my={4}>
          <AccordionButton
            as={Button}
            colorScheme={'green'}
            _hover={{
              bg: 'maintain',
            }}
          >
            Add Mobile Money
          </AccordionButton>
          <AccordionPanel
            m={0}
            p={0}
            py={6}
            my={4}
            borderRadius={'lg'}
            bg={accordionItemBG}
          >
            <MobileMoneyForm p={3} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </VStack>
  );
}
