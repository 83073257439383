import React from 'react';
import { Image, Tag } from '@chakra-ui/react';
import { IAutopartList } from '../../types/interfaces';

interface Prop {
  autopart: IAutopartList;
}

const AutoPartCardOrder: React.FC<Prop> = ({ autopart }): JSX.Element => {
  const photos = [
    ...(autopart.requestPhotos ?? []),
    ...(autopart.uploadedPhotos ?? []),
  ];
  //console.log(photos);
  return (
    <div className="relative w-full overflow-hidden my-2 flex gap-2 rounded-lg border border-gray-100 p-2 pb-4">
      <div className="absolute top-1 right-1">
        <Tag colorScheme={'gray'} size={'sm'}>
          Quantity {autopart.quantity}
        </Tag>
      </div>
      <span className="absolute inset-x-0 bottom-0 h-1 bg-gradient-to-r from-green-300 via-brand-300 to-brand-500"></span>
      {photos.length > 0 && (
        <div className="w-[40px] justify-center items-center h-[40px] border border-gray-300 rounded-md overflow-hidden">
          <Image
            width={'100%'}
            height={'100%'}
            objectFit={'cover'}
            src={photos[0]}
          />
        </div>
      )}
      <div className="w-[80%] h-auto">
        <div className="justify-between sm:flex">
          <div>
            <h3 className="text-sm font-medium capitalize text-gray-900">
              {autopart.name}
            </h3>
          </div>
        </div>
        {autopart.description && (
          <p className="text-xs">{autopart.description}</p>
        )}
        {autopart.OEM && <p className="text-xs">{autopart.OEM}</p>}
      </div>
    </div>
  );
};

export default AutoPartCardOrder;
