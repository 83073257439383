import {
  Box,
  Flex,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from '@chakra-ui/react';
import { useRef, useState, useEffect } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { useSetRecoilState } from 'recoil';
import { disableCheckoutAtom } from '../../recoil/atoms';

interface Params {
  phoneNumber: string | undefined;
  changeNumber: (e: string) => void;
}

interface NumberError {
  isError: boolean;
  message: string;
}

const PhoneNumberSelect: React.FC<Params> = ({
  phoneNumber,
  changeNumber,
}): JSX.Element => {
  const [numberError, setNumberError] = useState<NumberError>({
    isError: false,
    message: '',
  });
  const phone = useRef<HTMLInputElement | null>(null);
  const setDisableCheckout = useSetRecoilState(disableCheckoutAtom);

  useEffect(() => {
    if (!phoneNumber) {
      setNumberError({
        isError: true,
        message: 'Add a Mobile Money Number',
      });
      return;
    }

    if (phoneNumber.length !== 9) {
      setNumberError({
        isError: true,
        message: 'Must be 9 digits',
      });
      return;
    }

    if (!['96', '76', '97', '77'].includes(phoneNumber.slice(0, 2))) {
      setNumberError({
        isError: true,
        message: 'Only MTN Momo or Airtel Money Allowed',
      });
      return;
    }

    setNumberError({
      isError: false,
      message: ['96', '76'].includes(phoneNumber.slice(0, 2))
        ? 'You Chose MTN MoMo'
        : 'You Chose Airtel Money',
    });
  }, [phoneNumber]);

  useEffect(() => {
    setDisableCheckout(numberError.isError);
  }, [numberError]);

  return (
    <Flex
      w={'full'}
      h={'auto'}
      borderWidth={'thin'}
      rounded={'xl'}
      justifyContent={'space-between'}
      alignItems={'center'}
      py={'2'}
      px={'2'}
    >
      <Box gap={'2'}>
        <FormLabel fontSize={'xx-small'} htmlFor="account number">
          {' '}
          Mobile Money Account Number
        </FormLabel>
        <InputGroup>
          <InputLeftAddon p={'1'} fontSize={'xs'}>
            +260
          </InputLeftAddon>
          <Input
            fontSize={'lg'}
            placeholder="97/6 000000"
            variant="unstyled"
            value={phoneNumber}
            fontWeight={'semibold'}
            data-input="phoneNumber"
            ref={phone}
            pl={'5'}
            bg={'gray.50'}
            maxLength={9}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              changeNumber(e.target.value);
            }}
          />
          <InputRightAddon>
            <Icon w={4} h={4} as={AiFillEdit} />
          </InputRightAddon>
        </InputGroup>
      </Box>
    </Flex>
  );
};

export default PhoneNumberSelect;
