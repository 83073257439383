import { Spinner } from '@chakra-ui/spinner';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import TextLoop from 'react-text-loop';
import { useRecoilValue } from 'recoil';
import { getPartners } from '../../api/queries';
import { isAuthenticatedAtom } from '../../recoil/atoms';
import './AutoScrollingText.css';
import { capitalizeEveryFirstLetter } from '../../utils/functions';

const InfiniteHorizontalScroller = () => {
  const isAuthenticated = useRecoilValue(isAuthenticatedAtom);
  const { data, isLoading } = useQuery(
    ['get-partner-names', { isAuthenticated }],
    getPartners,
  );

  const partnerNames: string[] =
    data &&
    data.length &&
    data
      .filter((x: any) => x.name)
      .map((partner: any) => {
        return capitalizeEveryFirstLetter(partner.name);
      });

  return (
    <div className="container relative my-auto mx-auto w-full flex justify-center items-center h-[90px]">
      {/*Tailwind CSS classes for styling */}
      {!isLoading && data && data.length > 0 ? (
        <div className="w-full h-auto justify-center pl-3 items-center">
          <TextLoop
            children={partnerNames}
            className="block"
            mask={true}
            fade={false}
          />
        </div>
      ) : (
        <Spinner
          justifySelf={'self-start'}
          thickness="2px"
          speed="0.65s"
          emptyColor="gray.300"
          color="brand.500"
          size="sm"
        />
      )}
      <h1 className="absolute bottom-0 right-0 font-bold text-sm tracking-wide">
        Our Partners
      </h1>
    </div>
  );
};

export default React.memo(InfiniteHorizontalScroller);
