import { Box, useColorModeValue } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

export default function NoScrollBar({ as, children, ...rest }) {
  return (
    <Box
      as={as ? as : 'div'}
      overflow={'scroll'}
      css={{
        '&::-webkit-scrollbar': {
          width: '0px',
          height: 0,
          display: 'none',
        },
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}

export const ScrollFade = ({ ...rest }) => {
  const [width, setWidth] = useState(0);
  const elementRef = useRef();
  const didRender = useRef();

  useEffect(() => {
    if (!didRender.current) {
      if (elementRef.current) {
        setWidth(elementRef.current.parentElement.clientWidth);
        didRender.current = true;
      }
    }
    function handleResize() {
      if (elementRef.current) {
        setWidth(elementRef.current.parentElement.clientWidth);
      }
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box
      width={width + 'px'}
      position={'absolute'}
      backgroundImage={useColorModeValue(
        'linear-gradient(to bottom, transparent, white)',
        'linear-gradient(to bottom, transparent, #2D3748)',
      )}
      ref={elementRef}
      {...rest}
    />
  );
};
