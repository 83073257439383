/* eslint-disable no-unsafe-optional-chaining */
import {
  Alert,
  Heading,
  List,
  VStack,
  AlertDescription,
  ListItem,
  ListIcon,
  Button,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import AutoPartList from '../../../components/autopartList';
import NextBtn from '../../../components/Stepper/next';
import {
  AutopartListAtom,
  selectedVehicle,
  partialVehicleAtom,
  requestStepAtom,
  incognitoSignUpAtom,
  isAuthenticatedAtom,
  cognitoUserAtom,
  isVehicleSelectedAtom,
} from '../../../recoil/atoms';
import { MdCheckCircle } from 'react-icons/md';
import { paths } from '../../../utils/constants';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { createRequest, createVehicle } from '../../../api/mutations';
import { VehicleResponse } from '../../../API';
import useCreateVehicle from '../../../hooks/useCreateRequest';
import { getPinpointButtonClickEventProp } from '../../../utils/functions-ts';
import AutoPartCard from '../../../components/autopartList/autopartcard';

const ConfirmRequest: React.FC = (): JSX.Element => {
  const autopartList = useRecoilValue(AutopartListAtom);
  const [selectV, setSelectV] = useRecoilState(selectedVehicle);
  const partialVehicle = useRecoilValue(partialVehicleAtom);
  const [activeStep, setActiveStep] = useRecoilState(requestStepAtom);
  const [isVehicleSelected, setIsVehicleSelected] = useRecoilState(
    isVehicleSelectedAtom,
  );
  //NOTE: initialize create request mutation
  const createRequestMutation = useMutation(createRequest);
  const isAuthenticated = useRecoilValue(isAuthenticatedAtom);
  const user = useRecoilValue(cognitoUserAtom);
  console.log('from verify', autopartList);

  //NOTE: initialize create request mutation
  //const uploadPhoto = useMutation(compressAndUploadPhotos);

  //NOTE: initialize create autopart mutation
  //const createAutoPartMutation = useMutation(createAutoPart);

  const createCar = useMutation(createVehicle);
  const { pathname } = useLocation();

  const setIncognitoSignUp = useSetRecoilState(incognitoSignUpAtom);
  // secondary hooks & refs
  const toast = useToast();
  const navigate = useNavigate();
  //const createRequestButtonRef = useRef<HTMLButtonElement>(null);

  //here I start refactoring again
  const { createRequests, requestIsLoading } = useCreateVehicle();

  type TOASTSTATUS =
    | 'error'
    | 'info'
    | 'warning'
    | 'success'
    | 'loading'
    | undefined;

  const customToastHook = ({
    status,
    msg,
  }: {
    status?: TOASTSTATUS;
    msg: string;
  }) => {
    toast({
      status: status || 'info',
      title: msg,
      description: 'please try again..',
      duration: 3000,
      isClosable: true,
      position: 'bottom',
    });
  };
  //NOTE: Photo sources for display

  useEffect(() => {
    //check if the request was created successfully!

    if (createRequestMutation.isSuccess) {
      //NOTE: navigate to the request page when the request is successfully created
      navigate(paths.requests);
      toast({
        status: 'info',
        title: 'Order created successfully!',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
    } else if (createRequestMutation.isError) {
      //NOTE: check if the request was not successfully created
      toast({
        status: 'error',
        title: 'Could not create order',
        description: 'please try again..',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
    }
  }, [createRequestMutation.isSuccess, createRequestMutation.isError]);

  useEffect(() => {
    if (createCar.isSuccess) {
      toast({
        status: 'info',
        title: 'Vehicle  created successfully!',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
    } else if (createCar.isError) {
      toast({
        status: 'error',
        title: 'Could not create Vehicle',
        description: 'please try again..',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
    }
  }, [createCar.isSuccess, createCar.isError]);

  //NOTE: initiate state reset for the component

  async function createRequestInitiator(
    e: React.MouseEvent<HTMLButtonElement>,
  ) {
    try {
      if (!isAuthenticated) {
        setIncognitoSignUp(true);
        navigate(paths.login);
        throw new Error('User not  authenticated');
      }

      if (!user) {
        customToastHook({
          status: 'error',
          msg: 'User not found before creating a order',
        });
        throw new Error('User not found before creating a order');
      }
      //if (partialVehicle !== null && !isVehicleSelected) {}
      // await createCar.mutateAsync(partialVehicle)
      //console.log('partialVehicle', partialVehicle);
      const vehicleResult =
        partialVehicle !== null && !isVehicleSelected
          ? await createCar.mutateAsync(partialVehicle)
          : (selectV as VehicleResponse);
      console.log('Vehicle result::', vehicleResult);
      if (!vehicleResult) {
        throw new Error('We could not create vehicle');
      }
      setSelectV(vehicleResult);
      createRequests(vehicleResult, autopartList);
      setIsVehicleSelected(true);
    } catch (error) {
      toast({
        status: 'error',
        title: (error as Error).message,
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
      console.log(error);
    }
    //NOTE: check if the user is not authenticated then direct them to the login page
  }
  /*
  {autopartList && autopartList.length > 0 && <AutoPartList />}
  */
  return (
    <VStack
      w={{
        base: 'full',
        md: '30%',
      }}
      bg={'white'}
      h={'auto'}
      justifyContent={'space-between'}
      alignItems={'center'}
      p={'2'}
      rounded={'lg'}
      data-component="verify-request"
    >
      <div className="w-full flex flex-col items-center">
        <h2 className="pt-1 text-left self-start">Autoparts</h2>
        {autopartList &&
          autopartList.length > 0 &&
          autopartList.map(autopart => (
            <AutoPartCard key={autopart.id} autopart={autopart} />
          ))}
        <h2 className="pt-1 text-left self-start">Vehicle details</h2>
        {((selectV && selectV?.vehicle) || partialVehicle) && (
          <Alert
            status={'info'}
            variant={'subtle'}
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            rounded={'md'}
            bg={'gray.200'}
            data-component="verify-vehicle-details"
          >
            <AlertDescription fontSize={'xs'} lineHeight={'12px'}>
              <List spacing={1}>
                {!isVehicleSelected && (
                  <>
                    {partialVehicle?.make && partialVehicle?.make !== null && (
                      <ListItem
                        letterSpacing={'widest'}
                        data-text="verify-partial-vehicle-make"
                      >
                        <ListIcon as={MdCheckCircle} color="green.500" />
                        Make: <b>{partialVehicle?.make}</b>
                      </ListItem>
                    )}
                    {partialVehicle?.model &&
                      partialVehicle?.model !== null && (
                        <ListItem
                          letterSpacing={'widest'}
                          data-text="verify-partial-vehicle-model"
                        >
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          Model: <b>{partialVehicle?.model}</b>
                        </ListItem>
                      )}
                    {partialVehicle?.engineNumber &&
                      partialVehicle?.engineNumber !== null && (
                        <ListItem
                          letterSpacing={'widest'}
                          data-text="verify-partial-vehicle-engine"
                        >
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          Engine Type: <b>{partialVehicle?.engineNumber}</b>
                        </ListItem>
                      )}
                    {partialVehicle?.year && partialVehicle?.year !== null && (
                      <ListItem
                        letterSpacing={'widest'}
                        data-text="verify-partial-vehicle-year"
                      >
                        <ListIcon as={MdCheckCircle} color="green.500" />
                        Year: <b>{partialVehicle?.year}</b>
                      </ListItem>
                    )}
                  </>
                )}

                {isVehicleSelected && (
                  <>
                    {selectV?.vehicle?.make &&
                      selectV?.vehicle?.make !== null && (
                        <ListItem
                          letterSpacing={'widest'}
                          data-text="verify-selected-vehicle-make"
                        >
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          Make: <b>{selectV.vehicle?.make}</b>
                        </ListItem>
                      )}
                    {selectV?.vehicle?.model &&
                      selectV?.vehicle?.model !== null && (
                        <ListItem
                          letterSpacing={'widest'}
                          data-text="verify-selected-vehicle-model"
                        >
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          Model: <b>{selectV?.vehicle?.model}</b>
                        </ListItem>
                      )}
                    {selectV?.vehicle?.year &&
                      selectV?.vehicle?.year !== null && (
                        <ListItem
                          letterSpacing={'widest'}
                          data-text="verify-selected-vehicle-year"
                        >
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          Year: <b>{selectV?.vehicle?.year}</b>
                        </ListItem>
                      )}
                    {selectV?.vehicle?.variant &&
                      selectV?.vehicle.variant !== '' && (
                        <ListItem
                          letterSpacing={'widest'}
                          data-text="verify-selected-vehicle-variant"
                        >
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          Variant: <b>{selectV?.vehicle.variant}</b>
                        </ListItem>
                      )}
                    {selectV?.vehicle?.engineNumber &&
                      selectV.vehicle?.engineNumber !== '' && (
                        <ListItem
                          letterSpacing={'widest'}
                          data-text="verify-selected-vehicle-engine"
                        >
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          Engine Type: <b>{selectV.vehicle?.engineNumber}</b>
                        </ListItem>
                      )}
                    {selectV?.vehicle?.gearbox &&
                      selectV.vehicle?.gearbox !== 'HYBRID' && (
                        <ListItem
                          letterSpacing={'widest'}
                          data-text="verify-selected-vehicle-gearbox"
                        >
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          Gear Box: <b>{selectV.vehicle?.gearbox}</b>
                        </ListItem>
                      )}
                    {selectV?.vehicle?.fuel &&
                      selectV.vehicle?.fuel !== null && (
                        <ListItem
                          letterSpacing={'widest'}
                          data-text="verify-selected-vehicle-fuel"
                        >
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          Fuel: <b>{selectV.vehicle?.fuel}</b>
                        </ListItem>
                      )}
                    {selectV?.vehicle?.plateNumber &&
                      selectV.vehicle?.plateNumber !== '' && (
                        <ListItem
                          letterSpacing={'widest'}
                          data-text="verify-selected-vehicle-plateNumber"
                        >
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          Plate Number: <b>{selectV.vehicle?.plateNumber}</b>
                        </ListItem>
                      )}
                    {selectV?.vehicle?.VIN && selectV.vehicle?.VIN !== '' && (
                      <ListItem
                        letterSpacing={'widest'}
                        data-text="verify-selected-vehicle-vin"
                      >
                        <ListIcon as={MdCheckCircle} color="green.500" />
                        VIN: <b>{selectV.vehicle?.VIN}</b>
                      </ListItem>
                    )}
                  </>
                )}
              </List>
            </AlertDescription>
          </Alert>
        )}
      </div>
      <div className="w-full p-2 flex flex-row items-center justify-between">
        <NextBtn
          isPrev
          callback={() => setActiveStep(activeStep - 1)}
          dataAtr="verify-request-back"
          {...getPinpointButtonClickEventProp({
            command: 'confirm requests on step ' + activeStep,
            currentPage: pathname,
          })}
        />
        <Button
          mb={'1'}
          size={'sm'}
          colorScheme={'green'}
          onClick={(e: any) => {
            createRequestInitiator(e);
            console.log('order created here');
          }}
          isLoading={createCar.isLoading || requestIsLoading}
          data-btn="verify-request-create"
          {...getPinpointButtonClickEventProp({
            command: 'create requests for autopart',
            currentPage: pathname,
          })}
        >
          Create Order
        </Button>
      </div>
    </VStack>
  );
};

export default React.memo(ConfirmRequest);
//https://docs.google.com/document/d/1r3-R8x3r0H9br8TXH8DQJZZriFGjqXgc-6TeTJ4oh7E/edit?usp=sharing
