import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  HStack,
  Spinner,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { creditCardsAtom } from '../../../recoil/atoms';

import PaymentIcon from 'react-payment-icons';
import CreditCardForm from '../../../components/forms/credit-card';

export default function CreditCards() {
  const creditCards = useRecoilValue(creditCardsAtom);

  const accordionItemBG = useColorModeValue('gray.50', 'gray.800');

  return (
    <VStack
      width={'full'}
      p={0}
      px={{
        base: 0,
        sm: 3,
      }}
    >
      {creditCards === null ? (
        <Spinner />
      ) : Array.isArray(creditCards) ? (
        creditCards.length > 0 ? (
          <Accordion
            width={'full'}
            allowToggle={true}
            allowMultiple={true}
            m={0}
            p={0}
          >
            {creditCards.map(creditCard => (
              <AccordionItem
                key={creditCard.paymentMethod.id}
                width={'full'}
                borderTopWidth={0}
                borderBottomWidth={2}
                mb={2}
                _focusWithin={{}}
              >
                <AccordionButton
                  _focus={{
                    bg: 'white',
                    color: 'blue.600',
                  }}
                  _hover={{
                    color: 'blue.600',
                    bg: 'white',
                  }}
                  borderRadius={'lg'}
                  outline={'none'}
                  border={0}
                  px={2}
                >
                  <HStack width={'full'}>
                    <PaymentIcon
                      id={creditCard.paymentMethod.cardProvider.toLowerCase()}
                      style={{ width: 50 }}
                      className="payment-icon"
                    />
                    <Text
                      width={'full'}
                      textAlign={'left'}
                      fontWeight={'semibold'}
                      textOverflow={'ellipsis'}
                      overflow={'hidden'}
                      whiteSpace={'nowrap'}
                    >
                      {' '}
                      {creditCard.paymentMethod.name} -{' '}
                      {creditCard.paymentMethod.last4}{' '}
                    </Text>
                  </HStack>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel
                  px={4}
                  py={6}
                  my={4}
                  borderRadius={'lg'}
                  bg={accordionItemBG}
                >
                  <CreditCardForm card={creditCard} />
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        ) : (
          <Box
            bg={'blackAlpha.200'}
            p={10}
            width={'full'}
            borderRadius={'lg'}
            textAlign={'center'}
          >
            You have not added any credit cards
          </Box>
        )
      ) : (
        <Text> Something went wrong </Text>
      )}
      <Accordion allowToggle={true} width={'full'}>
        <AccordionItem my={4}>
          <AccordionButton
            as={Button}
            colorScheme={'green'}
            _hover={{
              bg: 'maintain',
            }}
          >
            Add Card
          </AccordionButton>
          <AccordionPanel
            px={4}
            py={6}
            my={4}
            borderRadius={'lg'}
            bg={accordionItemBG}
          >
            <CreditCardForm />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </VStack>
  );
}
