import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { onCreateMessage } from '../graphql/subscriptions';
import { authModes } from '../utils/constants';

export default function useMessageSubscription({ conversationId }) {
  const [incomingMessage, setIncomingMessage] = useState(null);

  function subscribe() {
    const subscription = API.graphql({
      query: onCreateMessage,
      variables: {
        conversationId,
      },
      authMode: authModes.AMAZON_COGNITO_USER_POOLS,
    }).subscribe({
      next: async ({ value }) => {
        const data = value.data.onCreateMessage;
        const messageResponse = { message: data, date: data.created };
        // console.log(messageResponse);
        setIncomingMessage(messageResponse);
      },
      error: error => {
        console.log(error);
      },
    });
    return () => {
      API.cancel(subscription);
    };
  }

  console.log(incomingMessage);

  useEffect(subscribe, []);

  return { incomingMessage };
}
