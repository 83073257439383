import {
  Button,
  HStack,
  Spacer,
  Stack,
  Tag,
  TagLabel,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { forwardRef, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import {
  EventType,
  OrderResponse,
  PaymentProcessors,
  QuoteResponse,
  Status,
} from '../../../API';
import { reInitializePayment } from '../../../api/mutations';
import OrderDetailsQuote from '../../../pages/Order/quote';
import {
  methodPayment,
  methodPaymentSelected,
  modalPaymentStateAtom,
  orderAtom,
} from '../../../recoil/atoms';
import { paths } from '../../../utils/constants';
import { formatPrice } from '../../../utils/functions';
import Link from '../../buttons/link';
import { MomentsAgo } from '../../miscellaneous/index';
import { getPinpointButtonClickEventProp } from '../../../utils/functions-ts';

//ANCHOR: TYPES FOR COMPONENT PROPS
interface Params {
  order: OrderResponse;
}
//ANCHOR: order component
const Order: React.ForwardRefExoticComponent<
  Params & React.RefAttributes<HTMLDivElement>
> = forwardRef(
  ({ order }: Params, ref: React.LegacyRef<HTMLDivElement>, ...rest) => {
    //ANCHOR: set component state
    const setSelectedOrder = useSetRecoilState(orderAtom);
    const setMethodPayment = useSetRecoilState(methodPayment);
    const setPaymentSelected = useSetRecoilState(methodPaymentSelected);
    const setPaymentModalOpen = useSetRecoilState(modalPaymentStateAtom);
    const [, setCartVersion] = useState<any[]>([]);
    const toast = useToast();
    const { pathname } = useLocation();

    //ANCHOR: destructure useful properties from order prop methodPayment
    const { quotes, payment } = order;

    //ANCHOR: string transform to lower case letters
    let status = order.order.status.toLowerCase();

    const paymentNotTrue: boolean = payment === null || payment === undefined;

    //ANCHOR: check the status and assign it meaning value for users
    status =
      status === 'open'
        ? 'awaiting payment'
        : status === 'in_progress'
        ? 'delivering'
        : 'closed';

    //ANCHOR: initialize navigator func
    const navigate = useNavigate();

    useEffect(() => {
      //ANCHOR: set new cart version when new quote has been added
      if (quotes) {
        setCartVersion(
          quotes.map(({ autoPart, request }: QuoteResponse) => {
            return {
              price: autoPart.quotedPrice,
              quantity: request.autoPart.quantity,
            };
          }),
        );
      }
    }, [quotes]);

    const onMoreDetails = (url: string) => {
      setSelectedOrder(order);
      navigate(url);
    };

    const toastPosition = window.innerWidth;

    const retryPaymentmutation = useMutation(reInitializePayment);

    //console.log(order)

    const reIntiallize = (id: string) => {
      retryPaymentmutation.mutateAsync({ id: id }).then(res => {
        if (
          !res ||
          'code' in order ||
          res?.__typename === 'RetryPaymentResponse'
        )
          throw Error('could not initialize retry payment');

        if (res?.hasRetryBeenInitialized === true) {
          toast({
            status: 'success',
            title: res?.message,
            duration: 3000,
            isClosable: true,
            position: toastPosition > 500 ? 'bottom-left' : 'bottom',
          });
        }

        if (res?.hasRetryBeenInitialized === false) {
          toast({
            status: 'error',
            title: res?.message,
            duration: 3000,
            isClosable: true,
            position: toastPosition > 500 ? 'bottom-left' : 'bottom',
          });
        }
      });
    };

    const paymentEvents = payment && payment.event?.split('_').join(' ');
    //console.log(payment?.id)

    //ANCHOR: SELECT payment method
    const selectMethod = () => {
      if (payment) {
        setMethodPayment(true);
        setPaymentSelected(payment?.id);
      }

      if (paymentNotTrue) {
        toast({
          status: 'error',
          title: 'Payment Could Not Be found',
          duration: 3000,
          isClosable: true,
          position: toastPosition > 500 ? 'bottom-left' : 'bottom',
        });
      }
    };

    return (
      <Stack
        spacing={3}
        p={4}
        ref={ref}
        bg={useColorModeValue('white', 'gray.700')}
        borderRadius={'lg'}
        width={'full'}
      >
        <HStack>
          {payment?.processor !== 'MTN' && (
            <Tag
              colorScheme={'blue'}
              cursor={'pointer'}
              fontSize={'10px'}
              fontWeight={'semibold'}
              letterSpacing={'widest'}
            >
              {payment?.referenceId}
            </Tag>
          )}

          <Spacer />
          <Tag
            colorScheme={
              status === 'awaiting payment'
                ? 'red'
                : status === 'delivering'
                ? 'green'
                : 'gray'
            }
            fontSize={'xx-small'}
            textTransform={'uppercase'}
          >
            <TagLabel> {paymentEvents} </TagLabel>
          </Tag>
        </HStack>

        <HStack
          width={'full'}
          overflowX={'scroll'}
          className={'no-scroll'}
          p={'1px'}
          align={'start'}
          justify={'start'}
        >
          <Spacer />
          {quotes?.map(quote => {
            return (
              <OrderDetailsQuote
                key={quote.quote.id}
                QUOTE={quote}
                order={order}
              />
            );
          })}
          <Spacer />
        </HStack>

        <Text fontWeight={'bold'}>
          {' '}
          TOTAL: {formatPrice(order.payment?.fee.finalFee)}{' '}
        </Text>
        <HStack>
          <MomentsAgo date={order.order.created} />
          <Spacer />
          <Link
            onClick={() => {
              onMoreDetails(`${paths.order}/${order.order.id}`);
            }}
            {...getPinpointButtonClickEventProp({
              command: 'show order details',
              currentPage: pathname,
            })}
            fontSize={'sm'}
          >
            {' '}
            <u>more details </u>
          </Link>
        </HStack>

        {(!payment || payment.status === Status.IN_PROGRESS) && (
          <Text fontSize={'xs'} fontWeight={'medium'}>
            {payment?.processor !== PaymentProcessors.MTN &&
              'You have not made the payment for this order yet.'}
            <b />
            {payment?.event !== EventType.PAYMENT_CONFIRMED && (
              <>
                <Button
                  variant={'solid'}
                  fontSize={'sm'}
                  bg={'blue.400'}
                  fontWeight={'semibold'}
                  width={'full'}
                  color={'aliceblue'}
                  alignSelf={'center'}
                  textTransform={'capitalize'}
                  {...getPinpointButtonClickEventProp({
                    command: 'pay now or retry',
                    currentPage: pathname,
                  })}
                  onClick={() => {
                    if (
                      payment?.processor === PaymentProcessors.MTN &&
                      payment.event === EventType.MTN_PAYMENT_FAILED
                    ) {
                      reIntiallize(payment.id);
                      //
                      return;
                    } else if (
                      payment?.processor === PaymentProcessors.MTN &&
                      payment.event === EventType.WAITING_FOR_CUSTOMER_PAYMENT
                    ) {
                      window.parent.location.href = 'tel:*115#';
                      return;
                    }
                    setSelectedOrder(order);
                    setPaymentModalOpen(true);
                  }}
                >
                  {payment?.processor === 'MTN'
                    ? payment.event === EventType.MTN_PAYMENT_FAILED
                      ? 'Retry Payment'
                      : 'Pay Now'
                    : 'Pay Now'}
                </Button>
                <Stack mt={'2'}>
                  <Button
                    colorScheme={'brand'}
                    width={'full'}
                    variant={'link'}
                    size={'xs'}
                    onClick={selectMethod}
                    {...getPinpointButtonClickEventProp({
                      command: 'change payment method',
                      currentPage: pathname,
                    })}
                  >
                    Change Payment Method
                  </Button>
                </Stack>
              </>
            )}
          </Text>
        )}
      </Stack>
    );
  },
);

export default Order;
