/* eslint-disable no-unused-expressions */
import {
  Box,
  Button,
  Flex,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { HiTrash } from 'react-icons/hi';
import { API } from 'aws-amplify';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilState, useRecoilValue } from 'recoil';
import CountrySelect from '../../components/inputs/selects';
import {
  createPaymentMethod,
  deletePaymentMethod,
  updatePaymentMethod,
} from '../../graphql/mutations';
import { cognitoUserAtom, creditCardsAtom } from '../../recoil/atoms';
import { unStringfy } from '../../utils/functions';

export default function CreditCard({ card = { paymentMethod: {} } }) {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm();

  const partner = useRecoilValue(cognitoUserAtom);
  const [creditCards, setCreditCards] = useRecoilState(creditCardsAtom);
  const [name, setName] = useState(card.paymentMethod.name || '');
  const country = useState(card.paymentMethod.country)[0];
  const [cardProvider, setCardProvider] = useState(
    card.paymentMethod.cardProvider || '',
  );
  const [expMonth, setExpMonth] = useState(card.paymentMethod.expMonth || '');
  const [expYear, setExpYear] = useState(card.paymentMethod.expYear || '');
  const [last4, setLast4] = useState(card.paymentMethod.last4 || '');

  const updateCreditCardInfo = async formData => {
    formData.country = unStringfy(formData.country).name;
    try {
      const input = {
        creditCard: {
          id: card.paymentMethod.id,
          ...formData,
          creatorId: partner.sub,
          creatorType: 'PARTNER',
        },
      };

      const { data } = await API.graphql({
        query: updatePaymentMethod,
        variables: {
          input,
        },
      });
      if (data.updatePaymentMethod) {
        // updating mobileMoneyAccounts by mapping and filtering out the account to be updated and then updating it.
        let newCreditCards = [...creditCards].map(card1 => {
          if (card1.paymentMethod.id === card.paymentMethod.id) {
            console.clear();
            let paymentMethod = Object.assign(
              {},
              card1.paymentMethod,
              formData,
            );
            return Object.assign({}, card1, { paymentMethod });
          } else return card1;
        });
        setCreditCards(newCreditCards);
      } else {
        // show toast
      }
    } catch (error) {
      error => {
        console.log(error);
      };
    }
  };

  const deleteCreditCard = async () => {
    try {
      const variables = {
        creator: {
          id: partner.sub,
          creatorType: 'PARTNER',
        },
        provider: {
          id: card.paymentMethod.id,
          type: 'CARD',
        },
      };
      const { data } = await API.graphql({
        query: deletePaymentMethod,
        variables,
      });
      if (data.deletePaymentMethod) {
        setCreditCards(
          creditCards.filter(
            card1 => card1.paymentMethod.id !== card.paymentMethod.id,
          ),
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addCreditCard = async formData => {
    formData.country = unStringfy(formData.country).name;
    try {
      const input = {
        creditCard: {
          ...formData,
          creatorId: partner.sub,
          creatorType: 'PARTNER',
        },
      };
      const { data } = await API.graphql({
        query: createPaymentMethod,
        variables: {
          input,
        },
      });
      let card = data.createPaymentMethod[0];
      setCreditCards([{ paymentMethod: card }, ...creditCards]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      minW={'full'}
      as={'form'}
      onSubmit={handleSubmit(
        card.paymentMethod.id ? updateCreditCardInfo : addCreditCard,
      )}
    >
      <VStack spacing={3} width={'full'} as={Flex} align={'flex-start'}>
        <HStack spacing={2} width={'full'}>
          <Text py={0} as={FormLabel} width={'full'} htmlFor="name">
            {' '}
            Name:{' '}
          </Text>
          <InputGroup>
            <InputRightElement> </InputRightElement>
            <Input
              backgroundColor={useColorModeValue('white', 'gray.900')}
              _placeholder={{
                color: useColorModeValue('gray.700', 'gray.400'),
              }}
              id="name"
              type={'text'}
              placeholder="Enter Name For Card"
              disabled={isSubmitting}
              value={name}
              {...register('name', {
                onChange: e => {
                  setName(e.target.value);
                },
              })}
            />
          </InputGroup>
        </HStack>
        <HStack spacing={2} width={'full'}>
          <Text py={0} as={FormLabel} width={'full'} htmlFor="name">
            {' '}
            Country:{' '}
          </Text>
          <CountrySelect
            register={register}
            value={country}
            disabled={isSubmitting}
            backgroundColor={useColorModeValue('white', 'gray.900')}
          />
        </HStack>

        <HStack spacing={3} width={'full'}>
          <Text as={FormLabel} width={'full'} htmlFor="cardProvider">
            {' '}
            Card Provider:
          </Text>
          <InputGroup>
            <InputRightElement></InputRightElement>
            <Select
              backgroundColor={useColorModeValue('white', 'gray.900')}
              _placeholder={{
                color: useColorModeValue('gray.700', 'gray.400'),
              }}
              id="cardProvider"
              type={'name'}
              value={cardProvider}
              disabled={isSubmitting}
              placeholder="Card Provider"
              {...register('cardProvider', {})}
              onChange={e => {
                setCardProvider(e.target.value);
              }}
            >
              <option value={'VISA'}> VISA </option>
              <option value={'MASTERCARD'}> MASTER CARD </option>
            </Select>
          </InputGroup>
        </HStack>
        <HStack spacing={3} width={'full'}>
          <Text as={FormLabel} width={'full'} htmlFor="expYear">
            {' '}
            Expire Date:{' '}
          </Text>
          <HStack width={'full'}>
            <Input
              min={1}
              max={31}
              width={'full'}
              backgroundColor={useColorModeValue('white', 'gray.900')}
              _placeholder={{
                color: useColorModeValue('gray.700', 'gray.400'),
              }}
              id="expMonth"
              type={'number'}
              placeholder="Month"
              value={expMonth}
              disabled={isSubmitting}
              {...register('expMonth', {
                onChange: e => {
                  setExpMonth(e.target.value);
                },
              })}
            />
            <Input
              min={2022}
              max={2030}
              width={'full'}
              backgroundColor={useColorModeValue('white', 'gray.900')}
              _placeholder={{
                color: useColorModeValue('gray.700', 'gray.400'),
              }}
              id="expYear"
              type={'number'}
              placeholder="Enter Expiration Year"
              value={expYear}
              disabled={isSubmitting}
              {...register('expYear', {
                onChange: e => {
                  setExpYear(e.target.value);
                },
              })}
            />
          </HStack>
        </HStack>
        <HStack spacing={3} width={'full'}>
          <Text as={FormLabel} width={'full'} htmlFor="last4">
            {' '}
            Last 4 Digits:
          </Text>
          <InputGroup>
            <InputRightElement></InputRightElement>
            <Input
              backgroundColor={useColorModeValue('white', 'gray.900')}
              _placeholder={{
                color: useColorModeValue('gray.700', 'gray.400'),
              }}
              id="last4"
              type={'number'}
              placeholder="Enter Last 4"
              value={last4}
              disabled={isSubmitting}
              {...register('last4', {
                onChange: e => {
                  setLast4(e.target.value);
                },
              })}
            />
          </InputGroup>
        </HStack>
        <HStack width={'full'}>
          {card.paymentMethod.id && (
            <IconButton
              size="md"
              fontSize="xs"
              p={2}
              colorScheme={'red'}
              disabled={isSubmitting}
              onClick={handleSubmit(deleteCreditCard)}
              icon={<HiTrash />}
            />
          )}
          <Button
            type="submit"
            width={'full'}
            my={5}
            backgroundColor={'blue.500'}
            color={'white'}
            _hover={{
              bg: 'blue.500',
            }}
            isLoading={isSubmitting}
          >
            {' '}
            Submit{' '}
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
}
