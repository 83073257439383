import { Box, Heading, Text, IconButton, useToast } from '@chakra-ui/react';
import { FaCheckCircle, FaTrash } from 'react-icons/fa';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { VehicleResponse } from '../../API';
import {
  selectedVehicle,
  vehicleDeletedAtom,
  isVehicleSelectedAtom,
} from '../../recoil/atoms';
import { deleteVehicle as deleteVehicleMutation } from '../../api/mutations';

import { useMutation } from 'react-query';
import { truncate } from '../../utils/functions-ts';

//NOTE: types for vehicle card component
interface Params {
  vehicle: VehicleResponse;
  enableDelete?: boolean;
  selectVehicleCard: (vehicle: VehicleResponse) => void;
  dataAtr: string;
}

//NOTE: vehicle card element
const VehicleCard: React.FC<Params> = ({
  vehicle,
  selectVehicleCard,
  enableDelete,
  dataAtr,
}) => {
  const selectV = useRecoilValue(selectedVehicle);
  const setVehicleDeleted = useSetRecoilState(vehicleDeletedAtom);
  const ActiveVehicle: boolean = vehicle.vehicle.id === selectV?.vehicle.id;
  const deleteVehicle = useMutation(deleteVehicleMutation);
  const [isVehicleSelected, setIsVehicleSelected] = useRecoilState(
    isVehicleSelectedAtom,
  );

  //NOTE: initialize a toast component
  const toast = useToast();

  const deleteSelectedVehicle = async (id: string) => {
    deleteVehicle.mutateAsync(id).then(data => {
      if (data) {
        setVehicleDeleted(true);
        toast({
          status: 'info',
          title: 'Vehicle  Deleted! Successfully',
          duration: 3000,
          isClosable: true,
          position: 'bottom',
        });
      } else {
        toast({
          status: 'error',
          title: 'Could not Delete Vehicle',
          description: 'please try again..',
          duration: 3000,
          isClosable: true,
          position: 'bottom',
        });
        throw new Error('Could not Delete Vehicle');
      }
    });
  };

  return (
    <Box
      key={vehicle.vehicle.id}
      my={'2'}
      height={'80px'}
      width={'80px'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      flexDirection={'column'}
      bg={'gray.200'}
      rounded={'xl'}
      marginX={'2'}
      cursor={'pointer'}
      pos={'relative'}
      shadow={'sm'}
      border={ActiveVehicle && isVehicleSelected ? '2px' : '0px'}
      borderColor={ActiveVehicle && isVehicleSelected ? 'brand.400' : ''}
      data-component={`${dataAtr}`}
    >
      <IconButton
        variant={'ghost'}
        pos={'absolute'}
        top={'1'}
        right={'1'}
        bg={'aliceblue'}
        isRound={true}
        size={'xs'}
        aria-label="delete vehicle"
        onClick={() => {
          console.log('clicked delete vehicle');
          deleteSelectedVehicle(vehicle.vehicle.id);
        }}
        isLoading={deleteVehicle.isLoading}
        icon={<FaTrash color="red" size={'10px'} />}
      />
      <Box
        width={'full'}
        height={'full'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        onClick={() => {
          if (!enableDelete) {
            selectVehicleCard(vehicle);
            setIsVehicleSelected(true);
          }
        }}
      >
        <Heading fontSize={'xs'} textAlign={'center'}>
          {vehicle.vehicle.make}
        </Heading>
        <Text textAlign={'center'} fontSize={'xx-small'}>
          {' '}
          {truncate(vehicle.vehicle.model, 14)}
          <br />
          {truncate(vehicle.vehicle.engineNumber || '', 14)}
        </Text>
        {ActiveVehicle && (
          <Box
            rounded={'full'}
            pos={'absolute'}
            bottom={'2'}
            right={'1'}
            bg={'green.200'}
          >
            <FaCheckCircle size={'16px'} color="blue" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default VehicleCard;

/*
<Box 
            onClick={() => selectVehicleCard(vehicle)}
            key={vehicle.vehicle.id} my={'2'} 
            height={"120px"}
            width={'420px'} 
            px={'4'} 
            py={'1'}
            bg={'gray.300'}
            rounded={"xl"}
            marginX={"2"}
            >
            <Heading fontSize={'sm'}>{vehicle.vehicle.make}</Heading>
            <Text fontSize={'sm'}>{vehicle.vehicle.model} | {vehicle.vehicle.engineNumber}</Text>
        </Box>

        */
