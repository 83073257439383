import { useQuery, UseQueryResult } from 'react-query';
import { useRecoilState } from 'recoil';
import { VehiclePage } from '../API';
import { getVehiclesByCreator } from '../api/queries';
import { selectedVehicle, vehicleDeletedAtom } from '../recoil/atoms';

export default function useVehicle() {
  const [selectV, setSelectV] = useRecoilState(selectedVehicle);
  const [vehicleDeleted, setVehicleDeleted] =
    useRecoilState(vehicleDeletedAtom);

  /*
        **************************** SECTION ************************
        Fetch Vehicles from the database and set the return type to a list of Vehicles using UseQueryResult
*/
  const {
    data,
    isLoading,
    isError,
    refetch,
  }: UseQueryResult<VehiclePage, Error> = useQuery(
    ['get-my-cars'],
    getVehiclesByCreator,
    {
      onSuccess(data) {
        //console.log('xxxx', data);
        if (
          data &&
          data.vehicles &&
          data.vehicles.length > 0 &&
          selectV !== null
        ) {
          if (vehicleDeleted) {
            setSelectV(data?.vehicles[0]);
            setVehicleDeleted(false);
            return;
          }
        }
      },
    },
  );

  //NOTE: filter out the selected vehicle from the list of vehicles

  //NOTE: select vehicle if there's no selected vehicle in localStorage  or recoil state
  if (data && data.vehicles && data.vehicles.length > 0 && selectV === null) {
    console.log('from the hook here', data.vehicles[0]);
    setSelectV(data?.vehicles[0]);
  }

  return { data, isLoading, isError, refetch };
}
