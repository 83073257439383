import {
  Divider,
  ScaleFade,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { forwardRef } from 'react';
import { useRecoilValue } from 'recoil';
import Link from '../../../../../components/buttons/link';
import { cognitoUserAtom } from '../../../../../recoil/atoms';
import { paths } from '../../../../../utils/constants';

export default forwardRef(function (
  { message, isLastMessageBySenderInSubMessageList, ...rest },
  ref,
) {
  const user = useRecoilValue(cognitoUserAtom);
  const { data } = message;
  console.log(data);
  const quantity =
    data &&
    data.quote &&
    data.quote.request &&
    data.quote.request.autoPart.quantity;

  return (
    <VStack
      width={'full'}
      align={'center'}
      justify={'center'}
      height={'220px'}
      spacing={2}
      as={ScaleFade}
      in={true}
      px={6}
      pt={2}
      maxW={'500px'}
      alignSelf={'center'}
      ref={ref}
      position={'sticky'}
      top={'0'}
      left={'0'}
      zIndex={'1'}
      bg={'gray.200'}
      rounded={'md'}
      {...rest}
    >
      <Text color={useColorModeValue('gray.700', 'gray.300')} fontSize={'xs'}>
        Negotiate for{` `}
        <Link
          href={paths.quote}
          as={'span'}
          color={'inherit'}
          fontWeight={'semibold'}
        >
          {data &&
            data.quote &&
            data.quote.autoPart &&
            data.quote.autoPart.autoPart.name}{' '}
          {quantity > 1 && <>&times;</>} {quantity > 1 && quantity}{' '}
        </Link>
        .
      </Text>
      <Divider />
    </VStack>
  );
});
