import {
  HStack,
  Heading,
  Spacer,
  Text,
  VStack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';

import { useMutation } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { EventType, OrderResponse, PaymentProcessors } from '../../API';
import { reInitializePayment } from '../../api/mutations';
import { ButtonPrimary } from '../../components/buttons';
import { modalPaymentStateAtom } from '../../recoil/atoms';
import { formatPrice } from '../../utils/functions';
import { getPinpointButtonClickEventProp } from '../../utils/functions-ts';
import OrderDetailsQuote from './quote';
import { useLocation } from 'react-router-dom';

//ANCHOR: declare types for props passed in
interface Params {
  ORDER: OrderResponse;
}

/**
 *
 * @param {Params}
 * @returns Returns detail section component for the order
 */
const OrderDetailsContent: React.FC<Params> = ({ ORDER }): JSX.Element => {
  //ANCHOR: initialize component state
  const setPaymentModalIsOpen = useSetRecoilState(modalPaymentStateAtom);
  const { quotes, payment } = ORDER;
  const { pathname } = useLocation();
  const toast = useToast();

  const paymentEvents = payment && payment.event?.split('_').join(' ');

  const toastPosition = window.innerWidth;

  const { mutateAsync, isLoading, isSuccess } =
    useMutation(reInitializePayment);

  const reIntiallize = (id: string) => {
    mutateAsync({ id: id }).then(res => {
      console.log('trying to initialize an otp weee', res);
      if (res?.hasRetryBeenInitialized) {
        toast({
          title: res?.message,
          duration: 2000,
          isClosable: true,
          position: toastPosition > 500 ? 'bottom-left' : 'bottom',
        });
      } else {
        toast({
          status: 'error',
          title: res?.message,
          duration: 2000,
          isClosable: true,
          position: toastPosition > 500 ? 'bottom-left' : 'bottom',
        });
      }
    });
  };

  return (
    <VStack
      width={{
        base: 'full',
        md: 'auto',
      }}
      p={5}
      spacing={4}
      bg={useColorModeValue('white', 'gray.700')}
      shadow={'md'}
      rounded={'md'}
    >
      <Heading
        fontSize={'xl'}
        fontWeight={'bold'}
        color={useColorModeValue('gray.900', 'gray.50')}
      >
        {' '}
        Order Details{' '}
      </Heading>

      <HStack
        width={'full'}
        overflowX={'scroll'}
        className={'no-scroll'}
        p={'1px'}
        align={'start'}
        justify={'start'}
      >
        <Spacer />
        {quotes?.map(quote => {
          return (
            <OrderDetailsQuote
              key={quote.quote.id}
              QUOTE={quote}
              order={ORDER}
            />
          );
        })}
        <Spacer />
      </HStack>

      <HStack width={'full'}>
        <Text fontSize={'sm'}> Status: </Text>
        <Spacer />
        <Text fontWeight={'medium'} fontSize={'xs'}>
          {' '}
          {paymentEvents}{' '}
        </Text>
      </HStack>

      <HStack width={'full'}>
        <Text> Items: </Text>
        <Spacer />
        <Text> {quotes?.length} </Text>
      </HStack>

      <HStack width={'full'}>
        <Text fontWeight={'medium'}> Total: </Text>
        <Spacer />
        <Text fontWeight={'black'}> {formatPrice(payment?.fee.finalFee)} </Text>
      </HStack>
      {payment?.event !== EventType.PAYMENT_CONFIRMED && (
        <ButtonPrimary
          size={'sm'}
          width={'full'}
          disabled={isLoading && !isSuccess ? true : false}
          {...getPinpointButtonClickEventProp({
            command:
              payment?.processor === PaymentProcessors.MTN
                ? payment?.event === EventType.MTN_PAYMENT_FAILED
                  ? 'Retry Payment'
                  : 'Pay now'
                : 'Pay Now',
            currentPage: pathname,
          })}
          onClick={() => {
            if (payment?.processor === PaymentProcessors.MTN) {
              reIntiallize(payment.id);
              //window.parent.location.href = "tel:*115#"
              console.log('Generate new prompt for the customer');
              return;
            }
            setPaymentModalIsOpen(true);
          }}
        >
          {' '}
          {payment?.processor === PaymentProcessors.MTN
            ? payment?.event === EventType.MTN_PAYMENT_FAILED
              ? 'Retry Payment'
              : 'Pay now'
            : 'Pay Now'}{' '}
        </ButtonPrimary>
      )}
    </VStack>
  );
};

export default OrderDetailsContent;

/**
 * 
          <HStack
            width={"full"}
          >
            <Text> Total Paid: </Text>
            <Spacer />
            <Text> {formatPrice(total)} </Text>
          </HStack>
 */
