import { extendTheme } from '@chakra-ui/react';

export default extendTheme({
  colors: {
    brand: {
      50: '#ffeedc',
      100: '#ffd0ae',
      200: '#ffb17e',
      300: '#ff934c',
      400: '#FE6401',
      500: '#e55a01',
      600: '#b34500',
      700: '#803100',
      800: '#4e1c00',
      900: '#1f0700',
    },
    'brand-blue': {
      50: '#e1edff',
      100: '#b1c8ff',
      200: '#80a3ff',
      300: '#4f7efc',
      400: '#1e59fa',
      500: '#053fe1',
      600: '#0031b0',
      700: '#00237f',
      800: '#00154f',
      900: '#000720',
    },
    'brand-green': {
      50: '#deffe2',
      100: '#b0feb8',
      200: '#7ffc8c',
      300: '#4efb5f',
      400: '#1efa33',
      500: '#05e119',
      600: '#00af11',
      700: '#007d09',
      800: '#004c03',
      900: '#001b00',
    },
    'brand-pink': {
      50: '#ffe2ef',
      100: '#ffb2cd',
      200: '#fe80ac',
      300: '#fc4f8a',
      400: '#fa1e69',
      500: '#e10550',
      600: '#b0003e',
      700: '#7f002c',
      800: '#4e001a',
      900: '#20000a',
    },
    'light-background': 'rgb(240, 245,250)',
    'dark-background': '#121212',
  },
});

/*
brand: {
      50: '#fff8db',
      100: '#ffe9ae',
      200: '#feda7f',
      300: '#fccc4e',
      400: '#fbbd1e',
      500: '#e1a404',
      600: '#af7f00',
      700: '#7d5b00',
      800: '#4c3700',
      900: '#1c1100',
    },
*/
