import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { Partner } from '../../../API';
import { removeNonAlphanumerics } from '../../../utils/functions-ts';

const borderColor = '#FFA500';
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: '#FFA500',
    backgroundColor: '#FFA500',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  },
  description: {
    width: '48%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  qty: {
    width: '7%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  rate: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  amount: {
    width: '15%',
  },
  left: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
});

interface QuoteTableHeaderProps {
  partner: Partner;
}

const QuoteTableHeader = ({ partner }: QuoteTableHeaderProps) => (
  <View>
    <View style={styles.left}>
      <Text>STORE ID: {removeNonAlphanumerics(partner.id).toUpperCase()}</Text>
    </View>
    <View style={styles.container}>
      <Text style={styles.description}>Item Description</Text>
      <Text style={styles.qty}>Qty</Text>
      <Text style={styles.rate}>Price</Text>
      <Text style={styles.rate}>Valid Till</Text>
      <Text style={styles.amount}>Amount</Text>
    </View>
  </View>
);

export default QuoteTableHeader;
