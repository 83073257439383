import {
  Button,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Tag,
  Text,
  VStack,
  useBreakpointValue,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import moment from 'moment';
import { forwardRef, useState } from 'react';
import Countdown from 'react-countdown';
import { HiDotsVertical } from 'react-icons/hi';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Quote, QuoteResponse, RequestResponse } from '../../API';
import { fetchRequestQuotes } from '../../api/queries';
import Carousel from '../../components/carousel';
import ImageContainer from '../../components/carousel/image-container';
import { selectedRequestAtom, selectedVehicle } from '../../recoil/atoms';
import { paths } from '../../utils/constants';
import { orderBy } from 'lodash';
import { getPinpointButtonClickEventProp } from '../../utils/functions-ts';

const ExpireComponent = ({
  total,
  days,
  hours,
  minutes,
  seconds,
  completed,
}: TIMER) => {
  //console.l8og("remaining sec",total
  if (completed) {
    return (
      <Tag colorScheme={'red'} fontSize={'xs'} pos={'absolute'} mb={'3'}>
        expired
      </Tag>
    );
  } else {
    // Render a countdown
    return (
      <Tag
        colorScheme={days > 5 ? 'green' : 'brand'}
        fontSize={'xs'}
        pos={'absolute'}
        className={'transition duration-700 ease-in-out'}
      >
        {days < 1 && (
          <Text
            pos={'absolute'}
            w={'10px'}
            h={'10px'}
            right={'-0.5'}
            top={'-0.5'}
            rounded={'full'}
            bg={'orange.400'}
          >
            <Text
              w={'full'}
              h={'full'}
              bg={'blue.400'}
              rounded={'full'}
              className={'animate-ping transition duration-700 ease-in-out'}
            ></Text>
          </Text>
        )}
        {/*expires: {hours}:{minutes}:{seconds}*/}
        Expires {moment(Date.now() + total).fromNow()}
      </Tag>
    );
  }
};

type TIMER = {
  hours: any;
  minutes: any;
  seconds: any;
  completed: any;
  total: any;
  days: any;
};

//ANCHOR: declare prop types
type Params = {
  request: RequestResponse;
  pos?: number;
};

//ANCHOR: request which show details about a particular request

const Request: React.ForwardRefExoticComponent<
  Params & React.RefAttributes<HTMLDivElement>
> = forwardRef(
  ({ request, pos }: Params, ref: React.LegacyRef<HTMLDivElement>) => {
    //ANCHOR: initialize component state
    const setSelectedRequest = useSetRecoilState(selectedRequestAtom);
    const setSelectedVehicle = useSetRecoilState(selectedVehicle);
    const [quote, setQuote] = useState<Quote | null>(null);

    //ANCHOR: initialize funcs
    const toast = useToast();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    //ANCHOR: destructure useful properties from the request passed in
    const {
      quoteIds: myQuoteIds,
      model,
      make,
      year,
      status,
      created,
    } = request.request;
    const { autoPart, vehicle } = request;
    //console.log('🚀 ~ file: request.tsx:124 ~ request', request);

    const quoteIds = myQuoteIds ? myQuoteIds.filter(id => id) : [];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    const fetchedQuotes = useQuery(
      ['request-quotes', { quoteIds }],
      fetchRequestQuotes,
      {
        enabled: Boolean(quoteIds.length),
        refetchOnWindowFocus: false,
        onSuccess(data: QuoteResponse[]) {
          if (Boolean(data) && Boolean(data.length > 0)) {
            const dbQuotes = orderBy(data, 'quote.expiryDate');
            setQuote(dbQuotes[0].quote);
          }
        },
        onError(error) {
          console.log('🚀 ~ file: request.tsx:141 ~ onError ~ error', error);
          return toast({
            status: 'error',
            title: 'There was an issue fetching Quotes',
            description: 'Refresh the page',
            duration: 9000,
            isClosable: true,
          });
        },
      },
    );

    /*
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    const quotes = useInfiniteQuery(
      ['request-quotes', { request, limit }],
      getRequestQuotes,
      {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        getNextPageParam: previousPage => {
          const nextParam = previousPage.pageParam + limit;
          return nextParam < request.data.request.quoteIds.length
            ? nextParam
            : false;
        },
      },
    );
*/
    /**
     *
     * @returns a toast
     */

    //ANCHOR: navigate to the view quotes page if a user has quotes submitted to him/her
    const viewQuotes = () => {
      if (quoteIds.length <= 0) {
        return toast({
          status: 'info',
          title: 'No quotes for this request yet',
          description: "We'll notify you as soon as one is made",
          duration: 4000,
          isClosable: true,
        });
      }
      setSelectedRequest(request);
      navigate(paths.quotes + '/' + request.request.id);
    };

    //ANCHOR: navigate to view more details about the request
    const viewDetails = () => {
      setSelectedRequest(request);
      navigate(pathname + '#request-' + request.request.id);
    };

    /*********************** SECTION ***************************
     *NOTE: initialize responsive component styles through chakra ui
     *
     */
    //const linkColor = useColorModeValue('blue', 'blue.300');

    const carouselWidth = useBreakpointValue({
      base: '80px',
      md: 'full',
    });

    const carouselHeight = useBreakpointValue({
      base: '68px',
      md: '132px',
    });

    //NOTE: construct bool to check the status of the request
    const isInProgress = status === 'IN_PROGRESS';

    return (
      <Stack
        ref={ref}
        direction={'column'}
        spacing={3}
        boxShadow={'sm'}
        width={'full'}
        p={3}
        bg={useColorModeValue('white', 'gray.700')}
        borderRadius={'0px'}
        alignItems={'center'}
        justifyContent={'center'}
        pos={'relative'}
      >
        {isInProgress && (
          <>
            {Boolean(quote) && quote?.expiryDate && (
              <Stack w={'full'} p={'1'}>
                <Countdown
                  date={quote?.expiryDate}
                  renderer={ExpireComponent}
                />
              </Stack>
            )}
          </>
        )}
        <Stack
          direction={{ base: 'row', md: 'column' }}
          spacing={{ base: 3, md: 5 }}
          width={'full'}
          p={3}
        >
          <Carousel
            width={carouselWidth}
            height={carouselHeight}
            minW={carouselWidth}
            displayButtons={false}
            automatic={false}
          >
            {request.autoPart.autoPart.src === null && (
              <ImageContainer>
                <Image
                  src={'/filler.png'}
                  width={'full'}
                  height={'full'}
                  objectFit={'cover'}
                  borderRadius={'4px'}
                />
              </ImageContainer>
            )}
            {request.autoPart.autoPart.src?.map(imageS3Name => {
              return (
                <ImageContainer maxWidth="full" key={imageS3Name}>
                  <Image
                    src={imageS3Name || undefined}
                    width={'full'}
                    height={'full'}
                    objectFit={'cover'}
                    borderRadius={'4px'}
                  />
                </ImageContainer>
              );
            })}
          </Carousel>

          <HStack
            width={'full'}
            height={{ base: 'full', height: 'auto' }}
            justifyContent={'start'}
            spacing={{ base: '1px', md: 4 }}
          >
            <Stack spacing={{ base: 1, md: 3 }} width={'full'}>
              <HStack width={'full'}>
                <VStack spacing={0} align={'start'} justify={'start'}>
                  <Text
                    fontWeight={'medium'}
                    fontSize={{
                      base: '12px',
                    }}
                    color={useColorModeValue('gray.700', 'gray.100')}
                  >
                    {' '}
                    {autoPart.autoPart.name}{' '}
                  </Text>
                  <Text
                    fontSize={{
                      base: '10px',
                    }}
                    color={useColorModeValue('gray.700', 'gray.100')}
                  >
                    {' '}
                    {vehicle?.vehicle.year ||
                      (year ? (year > 0 ? year : '') : '')}{' '}
                    {make} {model}
                  </Text>
                </VStack>
                <Spacer />
                <Menu>
                  <MenuButton>
                    <HiDotsVertical className="w-6 h-6" />
                  </MenuButton>
                  <MenuList>
                    <MenuItem>
                      <Text
                        onClick={e => {
                          if (request.vehicle) {
                            setSelectedVehicle(request.vehicle);
                          }
                          setSelectedRequest(request);
                          navigate(
                            paths.requests + '#request-' + request.request.id,
                          );
                        }}
                        {...getPinpointButtonClickEventProp({
                          command: 'edit request',
                          currentPage: pathname,
                        })}
                      >
                        {' '}
                        {status === 'OPEN'
                          ? 'edit request'
                          : 'more details'}{' '}
                      </Text>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </HStack>

              <HStack width={'full'} p={0} spacing={1}>
                <Text fontSize={'xs'}>Created {moment(created).fromNow()}</Text>
                <Spacer />
              </HStack>
            </Stack>
          </HStack>
        </Stack>
        {quoteIds.length ? (
          <Button
            w={'full'}
            variant={quote !== null ? 'solid' : 'outline'}
            size={'sm'}
            colorScheme={'brand'}
            onClick={viewQuotes}
            {...getPinpointButtonClickEventProp({
              command: 'view request quotes',
              currentPage: pathname,
            })}
            data-text={`view-quotes${pos?.toString()}`}
          >
            See Replies ({quoteIds?.length})
          </Button>
        ) : (
          <Button
            variant={'outline'}
            size={'sm'}
            w={'full'}
            colorScheme={'brand'}
            disabled={true}
          >
            no replies
          </Button>
        )}
      </Stack>
    );
  },
);

export default Request;

/*
{isInProgress && (
                <Text
                  fontSize={'xs'}
                  fontWeight={'medium'}
                  color={linkColor}
                  onClick={status === 'IN_PROGRESS' ? viewQuotes : viewDetails}
                  data-text={`view-quotes${pos?.toString()}`}
                >
                  {' '}
                  <Tag fontSize={"sm"} colorScheme={"blue"}>
                    Replies ({quoteIds?.length})
                  </Tag>
                  {' '}
                </Text>
)
}

*/
