import { DefaultValue } from 'recoil';
import { unStringfy } from '../utils/functions';

export const localPersistEffect = ({ onSet, setSelf, node }) => {
  const storedItems = localStorage.getItem(node.key);
  if (storedItems != null) {
    setSelf(unStringfy(storedItems));
  }

  onSet(newItems => {
    if (newItems instanceof DefaultValue) {
      localStorage.removeItem(node.key);
    } else {
      localStorage.setItem(node.key, JSON.stringify(newItems));
    }
  });
};
