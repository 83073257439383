import {
  Box,
  Flex,
  IconButton,
  Input,
  ScaleFade,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdAddAPhoto, MdClose } from 'react-icons/md';
import { useMutation } from 'react-query';
import { compressAndUploadPhoto } from '../../api/mutations';
import { ButtonPrimary } from '../buttons';
import { useLocation } from 'react-router-dom';
import { getPinpointButtonClickEventProp } from '../../utils/functions-ts';

interface Params {
  callback: (data: any) => void;
  max: number;
  onClose: () => void;
}

type FormValues = {
  files: File[];
};

const PhotoUpload: React.FC<Params> = ({
  callback = () => {},
  max,
  onClose,
}): JSX.Element => {
  const [previewFiles, setPreviewFiles] = useState<string[]>([]);
  const [tempFiles, setTempFiles] = useState<string[]>([]);
  const { pathname } = useLocation();
  const { handleSubmit, register } = useForm<FormValues>();

  const toast = useToast();

  const uploadPhoto = useMutation(compressAndUploadPhoto);

  useEffect(() => {
    if (uploadPhoto.isSuccess) {
      //callback(uploadPhoto.data.id);
    } else if (uploadPhoto.isError) {
      toast({
        status: 'error',
        title: 'Failed to upload your image.',
        description: 'Something went wrong, please try again.',
        duration: 3000,
        position: 'bottom',
      });
    }
  }, [uploadPhoto.status]);

  const uploadBoxBG = useColorModeValue('gray.100', 'blue.100');

  const handleImageSelection = () => {
    if (!tempFiles) return;

    callback(tempFiles);

    onClose();
  };

  return (
    <Stack
      as={'form'}
      onSubmit={handleSubmit(({ files }) => {
        console.log(files);
        /*
        if (files === null) return;
        if (files) {
          const imageFiles = Array.from(files).map(file => {
            return URL.createObjectURL(file);
          });
          console.log(imageFiles);

          callback(imageFiles);
        }
        */
      })}
      data-component="upload-photo"
    >
      <label htmlFor={'fileInput'}>
        <VStack
          alignItems={'center'}
          py={20}
          borderRadius={'12px'}
          bg={uploadBoxBG}
          cursor={'pointer'}
          backgroundImage={previewFiles[0]}
          backgroundPosition={'center'}
          backgroundSize={'cover'}
          backgroundRepeat={'no-repeat'}
          opacity={uploadPhoto.isLoading ? '0.5' : '1'}
        >
          <Flex
            w={10}
            h={10}
            rounded={'full'}
            bg={'brand.400'}
            align={'center'}
            justify={'center'}
          >
            {' '}
            <MdAddAPhoto size={'18px'} />{' '}
          </Flex>
        </VStack>
      </label>
      <Input
        id={'fileInput'}
        type={'file'}
        multiple={max > 1}
        w={'0px'}
        height={'0px'}
        border={'none'}
        disabled={uploadPhoto.isLoading}
        data-input="upload-photo"
        accept="image/*"
        {...register('files')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e === null) return;
          if (e && e.currentTarget && e.currentTarget.files) {
            const files = Array.from(e.currentTarget.files).map(file => {
              return URL.createObjectURL(file);
            });

            setTempFiles(files);
            setPreviewFiles(files);
          }

          /*
          if (e && e.currentTarget && e.currentTarget.files) {
            const files = Array.from(e.currentTarget.files).map(file => {
              return URL.createObjectURL(file);
            });

           
            callback(files);
            //setPreviewPhoto(file);
            
            if (e && e.currentTarget && e.currentTarget.files && e.currentTarget.files[0]) { 
              setSelectedPhotos((oldPhotos: File[]) => {
                return [...oldPhotos, e?.currentTarget?.files![0]];
              });
            }
           
          }
           */
          return;
        }}
      />
      {max > 1 && previewFiles.length && (
        <Text fontSize={'md'} fontWeight={'medium'}>
          {' '}
          Selected photos{' '}
        </Text>
      )}
      <SimpleGrid
        columns={{
          base: 3,
          md: 5,
        }}
        gap={3}
      >
        {max > 1 &&
          previewFiles.map(url => {
            return (
              <Box
                key={url}
                as={ScaleFade}
                in={true}
                backgroundImage={url}
                backgroundPosition={'center'}
                backgroundSize={'cover'}
                backgroundRepeat={'no-repeat'}
                width={'full'}
                height={'84px'}
                borderRadius={'4px'}
                onLoad={() => {
                  // free up memory
                  URL.revokeObjectURL(url);
                }}
              >
                <Flex width={'full'} p={1}>
                  <Spacer />
                  <IconButton
                    aria-label={'icon button'}
                    size={'xs'}
                    rounded={'full'}
                    icon={<MdClose size={'12px'} />}
                    colorScheme={'gray'}
                    onClick={() =>
                      setPreviewFiles(files =>
                        files.filter(file => file !== url),
                      )
                    }
                    {...getPinpointButtonClickEventProp({
                      command: 'upload photo modal close',
                      currentPage: pathname,
                    })}
                  />
                </Flex>
              </Box>
            );
          })}
      </SimpleGrid>

      {previewFiles.length >= 1 && (
        <ButtonPrimary
          type={'submit'}
          size={'sm'}
          isLoading={uploadPhoto.isLoading}
          onClick={handleImageSelection}
          data-btn="upload-photo-btn"
          {...getPinpointButtonClickEventProp({
            command: 'trigger photo upload',
            currentPage: pathname,
          })}
        >
          {' '}
          Upload{' '}
        </ButtonPrimary>
      )}
    </Stack>
  );
};

export default PhotoUpload;

/*
max > 1 ? oldFiles => oldFiles.concat(files) : 
*/
