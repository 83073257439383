import { Divider } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { isAuthenticatedAtom, requestStepAtom } from '../../recoil/atoms';
import PromoCard from '../../components/cards/promo';
//import Stepper from '../../components/Stepper';
import MyCars from './search/MyCars';
import AutopartForm from './search/AutopartForm';
import ConfirmRequest from './search/CornfirmRequest';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../utils/constants';
import StepBanner from '../../components/banner';
import PartnerNames from '../../components/partner-names';

export default function Structure() {
  //const placement = window.innerWidth;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isAuthenticated = useRecoilValue(isAuthenticatedAtom);
  const step = useRecoilValue(requestStepAtom);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isAuthenticated && step === 3) {
      navigate(paths.login);
    } else {
      navigate(paths.home);
    }
  }, [step]);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div className="w-full h-full bg-white px-4 pb-24 flex justify-between items-center flex-col overflow-hidden">
        <div className="w-full h-auto md:flex md:justify-center md:items-center md:flex-col gap-4">
          <div className="w-full mb-1 mt-2 flex justify-center items-center">
            <PromoCard />
          </div>
          <StepBanner />
          {step === 1 && <AutopartForm />}
          {step === 2 && <MyCars />}
          {isAuthenticated && step === 3 && <ConfirmRequest />}
        </div>
        <div className="w-full h-20"></div>
        {screenWidth <= 640 ? (
          <div className="w-full my-auto mx-auto h-auto flex justify-center items-center p-2 border border-gray-300 shadow-sm rounded-md">
            <PartnerNames />
          </div>
        ) : (
          <div className="w-[30%] my-auto mx-auto h-auto flex justify-center items-center p-2 border border-gray-300 shadow-sm rounded-md">
            <PartnerNames />
          </div>
        )}
      </div>
    </>
  );
}
