import { isAuthenticatedAtom } from '../recoil/atoms';
import { Navigate } from 'react-router-dom';
import { paths } from '../utils/constants';
import { useRecoilValue } from 'recoil';

// not really an HOC
export default function withoutAuthentication(Component) {
  return (() => {
    const isAuthenticated = useRecoilValue(isAuthenticatedAtom);
    return !isAuthenticated ? (
      <Component />
    ) : (
      <Navigate to={paths.home} replace={true} />
    );
  })();
}
