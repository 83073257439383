import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  IconButton,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { forwardRef, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { Fees, Photo as PHOTOS, QuoteResponse, Status } from '../../API';
import { cartAtom } from '../../recoil/atoms';
import { addToCart, formatPrice } from '../../utils/functions';
//import Negotiate from '../buttons/negotiate';
import moment from 'moment';
import { BiDislike, BiLike } from 'react-icons/bi';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { useLocation } from 'react-router-dom';
import { getPinpointButtonClickEventProp } from '../../utils/functions-ts';
//ANCHOR: define prop types for the component
interface Params {
  QUOTE: QuoteResponse;
  pos?: number;
}

//ANCHOR: quotes co/*  */mponent container
const Quote: React.ForwardRefExoticComponent<
  Params & React.RefAttributes<HTMLDivElement>
> = forwardRef(
  ({ QUOTE, pos }: Params, ref?: React.LegacyRef<HTMLDivElement>) => {
    /* ************************ SECTION ******************************** */
    //ANCHOR: initialize component state
    const [cart, setCart] = useRecoilState(cartAtom);
    let { autoPart, quote, partner, photos, request } = QUOTE;
    const { pathname } = useLocation();

    const quotePhotosAvailable: () => PHOTOS[] | null | undefined = ():
      | PHOTOS[]
      | null
      | undefined => {
      const photos1 = photos ? photos : [];
      const photos2 =
        request && request.photos && request.photos.length
          ? request.photos
          : [];

      const allPhotos = [...photos1, ...photos2];
      if (allPhotos.length) return allPhotos;
      return undefined;
    };

    //********************* FILTER OUT PAYMENT OPTIONS ************************* */

    const MTNProcessor = autoPart.paymentOptions.filter(
      (option: Fees) =>
        !option.isDeliveryIncluded && option.processor === 'MTN',
    )[0];

    //ANCHOR: get only unique quotes and remove duplicate
    const quoteIsInCart = useMemo(
      () => cart.some(item => item.quoteId === quote.id),
      [cart],
    );

    const isExpired = new Date(quote.expiryDate) < new Date();
    const isOpen = quote.status === Status.OPEN;

    //ANCHOR: add item s to cart
    function addItemToCart() {
      setCart(
        addToCart(cart, {
          quoteId: quote.id,
          carDetails: {
            make: request.request.make,
            model: request.request.model,
            year: request.request.year,
          },
          photos: photos,
          partName: autoPart.autoPart.name,
          quantity: request.autoPart.quantity,
          price: autoPart.quotedPrice,
          partnerId: partner.id,
          partnerName: partner.name,
          expiryDate: quote.expiryDate,
          paymentOptions: autoPart.paymentOptions,
          requestId: request.request.id,
        }),
      );
    }

    //ANCHOR: INITIALIZE func to remove an item from the cart
    function removeItemFromCart() {
      setCart(cart => cart.filter(item => item.quoteId !== quote.id));
    }

    return (
      <VStack
        ref={ref}
        spacing={4}
        p={3}
        align={'start'}
        borderRadius={'4px'}
        boxShadow={'xs'}
        bg={useColorModeValue('white', 'gray.700')}
      >
        <HStack width={'full'}>
          {partner.imageS3Name && (
            <>
              <Avatar size={'md'} src={`${partner.imageS3Name}`} />
              <Spacer />
            </>
          )}

          <VStack ml={2} spacing={0} align={'start'}>
            {isExpired && <Badge colorScheme="red">EXPIRED</Badge>}
            {quoteIsInCart && <Badge colorScheme="green">ACCEPTED</Badge>}
            <HStack>
              {quote.status !== Status.CLOSED ? (
                <>
                  <Text
                    fontSize={'sm'}
                    fontWeight={'bold'}
                    color="transparent"
                    textShadow={'0 0 8px #000'}
                  >
                    {' '}
                    {partner.name}{' '}
                  </Text>
                  <Popover>
                    <PopoverTrigger>
                      <IconButton
                        aria-label="info button"
                        padding={0}
                        size={'xs'}
                        bg={'transparent'}
                        icon={<InfoOutlineIcon />}
                      />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverBody>
                        The store name will be revealed after purchase
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </>
              ) : (
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {' '}
                  {partner.name}{' '}
                </Text>
              )}
            </HStack>

            <Text fontSize={'xs'}>
              Created {moment(quote.created).fromNow()}{' '}
            </Text>
            {!isExpired && (
              <Text fontWeight={'bold'} color={'red'} fontSize={'xs'}>
                Expires {moment(quote.expiryDate).fromNow()}{' '}
              </Text>
            )}
          </VStack>
          <Spacer />
          <VStack fontSize={'md'} ml={2} spacing={1} align={'center'}>
            <b> {formatPrice(MTNProcessor.finalFee)}</b>
            {!isExpired && isOpen && (
              <>
                <Button
                  size={'sm'}
                  width={'full'}
                  borderRadius={'4px'}
                  fontSize={'xs'}
                  colorScheme={quoteIsInCart ? 'brand-pink' : 'brand-green'}
                  leftIcon={quoteIsInCart ? <BiDislike /> : <BiLike />}
                  onClick={quoteIsInCart ? removeItemFromCart : addItemToCart}
                  {...getPinpointButtonClickEventProp({
                    command: (quoteIsInCart ? 'Decline' : 'Accept') + ' quote',
                    currentPage: pathname,
                  })}
                  data-btn={`add-to-cart${pos}`}
                >
                  {' '}
                  {quoteIsInCart ? 'Decline' : 'Accept'}{' '}
                </Button>
              </>
            )}
          </VStack>
        </HStack>
        <Divider />
        {quotePhotosAvailable() && (
          <>
            <HStack
              width={'full'}
              maxWidth={'full'}
              height={'52px'}
              overflowX={'scroll'}
              className={'no-scroll'}
            >
              {quotePhotosAvailable()?.map((photo: PHOTOS) => {
                return (
                  <Image
                    src={photo.imageS3Name}
                    key={photo.imageS3Name}
                    width={'64px'}
                    minW={'64px'}
                    height={'full'}
                    objectFit={'cover'}
                  />
                );
              })}
            </HStack>
            <Divider />
          </>
        )}
        <Box width={'full'} px={'2'} my={'3'}>
          <Flex mt={1} fontSize={'sm'} width={'full'} pb={'1'}>
            Is the part brand new? <Spacer />
            <Spacer /> {quote.isNew ? 'YES' : 'NO'}
          </Flex>
          {autoPart.autoPart.OEM && (
            <Flex mt={1} fontSize={'sm'} width={'full'} pb={'1'}>
              Part Number
              <Spacer />
              <Spacer /> {autoPart.autoPart.OEM}
            </Flex>
          )}
        </Box>

        <HStack width={'full'} spacing={3}>
          {/* <Negotiate partner={partner} quote={QUOTE.quote} /> */}
        </HStack>
      </VStack>
    );
  },
);
export default Quote;
