import { useBreakpointValue, useToast, HStack } from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
// import { ServoiceFeedback } from 'react-feedback-widget';
import { FiBriefcase, FiTruck, FiSettings } from 'react-icons/fi';
import { GoHome } from 'react-icons/go';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { cognitoUserAtom, isAuthenticatedAtom } from '../../recoil/atoms';
import { paths } from '../../utils/constants';
import { clearCustomAuthSession } from '../../utils/functions';
import Cart from '../cart';
import NavigationItem from './item';

export default function NavigationContent(props) {
  const [, setIsAuthenticated] = useRecoilState(isAuthenticatedAtom);
  const [, setUser] = useRecoilState(cognitoUserAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const signOutMutation = useMutation(async () => await Auth.signOut());

  useEffect(() => {
    if (signOutMutation.isError) {
      toast({
        title: 'Error Occured!',
        description: 'Failed Sign Out',
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'bottom',
      });
      return;
    }
    if (signOutMutation.isSuccess) {
      setUser(null);
      setIsAuthenticated(false);
      clearCustomAuthSession();
      localStorage.clear();
      window.location.reload();
      navigate(paths.home);
    }
  }, [signOutMutation.status]);

  const iconSize = useBreakpointValue({
    base: '18px',
    md: '14px',
  });

  /*

  const ROUTES = [
    { path: paths.home, text: "Home", icon: <GoHome size={iconSize} /> },
    { path: paths.requests, text: "Requests", icon: <FiBriefcase size={iconSize} /> },
   
    { path: paths.chat, text: "Chats", icon: <FiUsers size={iconSize} /> },
    { path: paths.orders, text: "Orders", icon: <FiTruck size={iconSize} /> }
  ];
  */

  const ROUTES = [
    { path: paths.home, text: 'Home', icon: <GoHome size={iconSize} /> },
    {
      path: paths.requests,
      text: 'Requests',
      icon: <FiBriefcase size={iconSize} />,
    },
    {
      path: location.pathname + '#cart',
      text: 'Cart',
      children: <Cart key={location.pathname} />,
    },
    /*     {
      path: paths.negotiations,
      text: 'Negotiate',
      icon: <FiUsers size={iconSize} />,
    }, */
    { path: paths.orders, text: 'Orders', icon: <FiTruck size={iconSize} /> },
    {
      path: paths.settings,
      text: 'Settings',
      icon: <FiSettings size={iconSize} />,
    },
  ];

  return (
    <HStack
      direction={{
        base: 'row',
      }}
      justifyContent={{
        base: 'center',
        sm: 'center',
      }}
      alignItems={{
        base: 'center',
        md: 'center',
      }}
      spacing={{
        base: 0,
        md: 2,
      }}
      width={'full'}
      px={{
        base: 0,
        md: 0,
      }}
      py={{
        base: '10px',
        md: 0,
      }}
      boxShadow={useBreakpointValue({ base: 'xs', md: '' })}
      {...props}
      data-component="nav"
    >
      {ROUTES.map((route, i) => {
        return route.children ? (
          route.children
        ) : (
          <NavigationItem key={route.path + i} route={route} />
        );
      })}
    </HStack>
  );
}
