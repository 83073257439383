import { Box, Heading, Text, Flex, Stack } from '@chakra-ui/react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  cognitoUserAtom,
  selectedVehicle,
  openCreateVehicle,
  vehicleDeletedAtom,
  partialVehicleAtom,
  isVehicleSelectedAtom,
} from '../../recoil/atoms';
import VehicleCard from './vehicle';
import { HiOutlineViewGridAdd } from 'react-icons/hi';
import Skeletons from '../loaders/skeletons';
import { VehicleResponse } from '../../API';
import { useEffect, useState } from 'react';
import { truncate } from '../../utils/functions-ts';

interface Params {
  vehicles?: (VehicleResponse | null)[];
  isLoading?: boolean;
  isError?: boolean;
  refetch?: () => any;
}

const VehicleList: React.FC<Params> = ({
  vehicles,
  isLoading,
  isError,
  refetch,
}): JSX.Element => {
  // SECTION: initialize and set the state of the component
  const setSelectV = useSetRecoilState(selectedVehicle);
  const user = useRecoilValue(cognitoUserAtom);
  const [isVehicleSelected, setIsVehicleSelected] = useRecoilState(
    isVehicleSelectedAtom,
  );
  const vehicleDeleted = useRecoilValue(vehicleDeletedAtom);
  const [showForm, setShowForm] = useRecoilState(openCreateVehicle);
  const enableDelete = useState<boolean>(false)[0];
  const partialVehicle = useRecoilValue(partialVehicleAtom);
  /*
        **************************** SECTION ************************
        Fetch Vehicles from the database and set the return type to a list of Vehicles using UseQueryResult
*/
  //ANCHOR - const { data, isError, isLoading, refetch } = useVehicle();

  useEffect(() => {
    if (refetch) refetch();
  }, [vehicleDeleted]);

  // NOTE: this function should select the vehicle when fired on a vehicle item

  // NOTE: this function should select the vehicle when fired on a vehicle item
  const VCardSelect = (vehicle: VehicleResponse) => {
    setSelectV(vehicle);
    //setPartialVehicle(null);
  };

  /*
  if user is not logged in don't show the Vehicle list or add button
  if user is signed in and no vehicles then show the form
  if has a partialVehicle show the list list and a edit button
  */

  return (
    <Box width={{ base: 'full', md: '60%' }} padding={'1'} my={'2'}>
      <Stack
        width={'full'}
        overflowX={'scroll'}
        alignItems={'flex-start'}
        justifyContent={'space-around'}
        padding={'1'}
        my={'2'}
        data-component="vehicle-list"
      >
        {/*NOTE: check if the query returned an error and render a message alerting the user that no vehicle was found isVehicleSelected*/}
        {isError && (
          <Heading fontSize={'sm'}>Couldn't find your vehicles</Heading>
        )}
        {/* NOTE: check if vehicles where returned from the database and render the List of vehicles */}
        <Flex alignItems={'center'}>
          {partialVehicle !== null && (
            <div
              className={`w-[80px] h-[80px] flex flex-col justify-center items-center rounded-xl bg-gray-300 mx-2 pointer p-2
              ${!isVehicleSelected ? 'border-2' : 'border-0'}
               ${
                 !isVehicleSelected
                   ? 'border-blue-400'
                   : 'border-[rgba(203, 220, 203, 0)]'
               }`}
              onClick={() => {
                setIsVehicleSelected(false);
              }}
              data-component="vehicle-list-partial-vehicle"
            >
              <Heading fontSize={'xs'} textAlign={'center'}>
                {partialVehicle.make}
              </Heading>
              <Text textAlign={'center'} fontSize={'xx-small'}>
                {' '}
                {truncate(partialVehicle.model, 14)}
                <br />
                {truncate(partialVehicle.engineNumber || '', 14)}
              </Text>
            </div>
          )}
          {/*NOTE: check if the query is currently fetching vehicles */}
          {isLoading && (
            <Flex gap={2}>
              <Skeletons count={10} width={'120px'} height={'120px'} />
            </Flex>
          )}
          {user &&
            vehicles &&
            vehicles.length > 0 &&
            vehicles.map((vehicle, i) =>
              vehicle ? (
                <VehicleCard
                  selectVehicleCard={VCardSelect}
                  key={i}
                  vehicle={vehicle}
                  enableDelete={enableDelete}
                  dataAtr={`vehicle-list-vehicle-${i + 1}`}
                />
              ) : null,
            )}
          <div
            data-btn="vehicle-list-add-vehicle"
            className="w-[80px] h-[80px] flex flex-col justify-center items-center rounded-xl bg-gray-300 mx-2 pointer p-2 "
            onClick={() => setShowForm(!showForm)}
          >
            <Box>
              <HiOutlineViewGridAdd size={'18px'} />
            </Box>
            <Text fontSize={'xs'}>
              {' '}
              {partialVehicle !== null ? 'Edit vehicle' : 'Add vehicle'}
            </Text>
          </div>
        </Flex>
      </Stack>
    </Box>
  );
};

export default VehicleList;

/*
      ***!SECTION
      border={ActiveVehicle ? '2px' : '0px'}
      borderColor={ActiveVehicle ? 'brand.400' : ''}
{user &&
  data &&
  data.vehicles &&
  data.vehicles.length > 0 &&
  data.vehicles.map((vehicle, i) =>
    vehicle ? (
      <VehicleCard
        selectVehicleCard={VCardSelect}
        key={i}
        vehicle={vehicle}
        enableDelete={enableDelete}
      />
    ) : null,
  )}

*/
