import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  AutopartListAtom,
  AutopartSelectedAtom,
  requestPrevPhotosAtom,
  requestUploadPhotosAtom,
} from '../../recoil/atoms';
import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import PhotoList from '../../pages/Home/search/PhotoList';
import { Photo } from '../../API';
import PhotoSelect from '../modals/select-and-upload-image';
import { ImCamera } from 'react-icons/im';

const EditAutopart: React.FC = (): JSX.Element => {
  const [selectedAutopart, setSelectedAutopart] =
    useRecoilState(AutopartSelectedAtom);
  const [autoNameInput, setAutoNameInput] = useState<string>('');
  const [autoNumberInput, setAutoNumberInput] = useState<string>('');
  const [autoDescription, setAutoDescription] = useState<string>('');
  const [photosArray, setPhotosArray] = useState<string[]>([]);
  const [photosArrayUpload, setPhotosArrayUpload] = useState<string[]>([]);
  const [photosArrayRequest, setPhotosArrayRequest] = useState<string[]>([]);
  const [quantityNumber, setQuantityNumber] = useState<number>(1);
  const [autopartList, setAutopartList] = useRecoilState(AutopartListAtom);
  const [requestPreviousPhotos, setRequestPreviousPhotos] = useRecoilState(
    requestPrevPhotosAtom,
  );
  const [requestUploadPhotos, setRequestUploadPhotos] = useRecoilState(
    requestUploadPhotosAtom,
  );
  //const [partialPhotos, setPartialPhotos] = useState<string[]>([]);

  const photosDisclosure = useDisclosure();

  const photos = [
    ...requestPreviousPhotos.map(({ imageS3Name }) => imageS3Name),
    ...requestUploadPhotos,
    ...photosArrayUpload,
    ...photosArrayRequest,
  ];

  const photosObj = {
    uploadPhotos: [
      ...requestPreviousPhotos.map(({ imageS3Name }) => imageS3Name),
      ...photosArrayRequest,
    ],
    requestPhotos: [...requestUploadPhotos, ...photosArrayRequest],
  };

  //Handle a partial photo deletion
  const handleDeletePhoto = (photoURL: string) => {
    console.log('I am logging here for the photo', photoURL);
    let isLocalPhoto: boolean = false;
    let isPrevPhoto: boolean = false;
    let isAutoPartUpload: boolean = false;
    let isAutoPartRequest: boolean = false;
    const newLocalPhotoArray: string[] = requestUploadPhotos.filter(url => {
      const isTrue: boolean = url !== photoURL;
      if (!isTrue) {
        isLocalPhoto = true;
      }
      return isTrue;
    });
    if (isLocalPhoto) {
      console.log('here', newLocalPhotoArray);
      setRequestUploadPhotos([...newLocalPhotoArray]);
      return;
    }

    const newNetworkPhotoArray: Photo[] = requestPreviousPhotos.filter(
      photo => {
        const isTrue: boolean = photo.imageS3Name !== photoURL;
        if (!isTrue) {
          isPrevPhoto = true;
        }
        return isTrue;
      },
    );

    if (isPrevPhoto) {
      setRequestPreviousPhotos([...newNetworkPhotoArray]);
      return;
    }

    const newAutoPartPhotoUpload: string[] = photosArrayUpload.filter(url => {
      const isTrue: boolean = url !== photoURL;
      if (!isTrue) {
        isAutoPartUpload = true;
      }
      return isTrue;
    });

    if (isAutoPartUpload) {
      setPhotosArrayUpload([...newAutoPartPhotoUpload]);
      return;
    }

    const newAutoPartPhotoRequest: string[] = photosArrayUpload.filter(url => {
      const isTrue: boolean = url !== photoURL;
      if (!isTrue) {
        isAutoPartRequest = true;
      }
      return isTrue;
    });

    if (isAutoPartRequest) {
      setPhotosArrayRequest([...newAutoPartPhotoRequest]);
      return;
    }
  };

  const toast = useToast();

  useEffect(() => {
    if (selectedAutopart !== null) {
      setAutoNameInput(selectedAutopart?.name);
      setAutoNumberInput(selectedAutopart?.OEM ?? '');
      setQuantityNumber(selectedAutopart?.quantity ?? 1);
      setAutoDescription(selectedAutopart?.description ?? '');
      setPhotosArray([
        ...(selectedAutopart.uploadedPhotos ?? []),
        ...(selectedAutopart.requestPhotos ?? []),
        ...photos,
      ]);
      setPhotosArrayUpload([...(selectedAutopart.uploadedPhotos ?? [])]);
      setPhotosArrayRequest([...(selectedAutopart.requestPhotos ?? [])]);
    } else {
      setAutoNameInput('');
      setAutoNumberInput('');
      setQuantityNumber(1);
    }
  }, [selectedAutopart]);

  const resetState = () => {
    setAutoNameInput('');
    setAutoNumberInput('');
    setRequestPreviousPhotos([]);
    setRequestUploadPhotos([]);
    setQuantityNumber(1);
  };

  const updateAutoPart = () => {
    if (!autoNameInput || quantityNumber < 1) {
      toast({
        // NOTE: else show a toast when the user is not signed in successfully
        title: 'Autopart is required and Quantity greater than 0',
        status: 'info',
        duration: 1500,
        isClosable: true,
        position: 'bottom',
      });
      return;
    }
    if (selectedAutopart !== null) {
      let newAutopartArray = autopartList.map((autopart, index) => {
        if (autopart.id === selectedAutopart.id) {
          return {
            ...autopart,
            name: autoNameInput || autopart.name,
            OEM: autoNumberInput || autopart.OEM,
            quantity: quantityNumber || autopart.quantity,
            description: autoDescription || autopart.description,
            uploadedPhotos: Array.isArray(autopart.requestPhotos)
              ? [
                  ...autopart.requestPhotos,
                  ...requestPreviousPhotos.map(
                    ({ imageS3Name }) => imageS3Name,
                  ),
                ]
              : requestPreviousPhotos.map(({ imageS3Name }) => imageS3Name),

            requestPhotos: Array.isArray(autopart.uploadedPhotos)
              ? [...autopart.uploadedPhotos, ...requestUploadPhotos]
              : requestUploadPhotos,
            src: photos,
          };
        }
        return autopart;
      });
      setAutopartList(newAutopartArray);
      setSelectedAutopart(null);
      resetState();
      return;
    }
  };

  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        data-component="autopart-form-autopart-edit"
      >
        <div className="relative min-w-[330px] mx-auto max-w-4xl flex justify-center items-center">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-[80%] h-[50%] bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-2 border-b border-solid border-slate-200 rounded-t">
              <h3
                className="text-sm font-semibold"
                data-heading="autopart-form-autopart-edit"
              >
                Edit Autopart
              </h3>
            </div>
            {/*body*/}
            <div className="relative p-2 flex-auto">
              <div className="relative my-1 w-full pb-1 leading-relaxed">
                <label
                  htmlFor="autopartName"
                  className="block mb-1 text-xs font-thin text-gray-900"
                  data-text="autopart-form-autopart-edit-name"
                >
                  Name *
                </label>
                <div className="w-full relative flex items-center bg-white border border-gray-300 rounded-sm overflow-hidden">
                  <ImCamera
                    onClick={photosDisclosure.onOpen}
                    className="text-gray-600 absolute right-2"
                    size={24}
                  />
                  <input
                    type="text"
                    id="autopartName"
                    className="bg-gray-50 border capitalize border-gray-300 text-gray-900 text-xs rounded-sm focus:border-2 focus:border-blue-500 block w-full p-1"
                    placeholder="e.g Brake pad"
                    required
                    onChange={(e: any) => setAutoNameInput(e.target.value)}
                    value={autoNameInput}
                    data-input="autopart-form-autopart-edit-name"
                  />
                </div>
              </div>

              <div className="relative my-1 w-full pb-1 leading-relaxed">
                <label
                  htmlFor="autopartName"
                  className="block mb-1 text-xs font-thin text-gray-900"
                  data-text="autopart-form-autopart-edit-partnumber"
                >
                  Part number
                </label>
                <input
                  type="text"
                  id="autopartName"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-sm focus:border-2 focus:border-blue-500 block w-full p-1"
                  placeholder="e.g EMd3422"
                  required
                  onChange={(e: any) => setAutoNumberInput(e.target.value)}
                  value={autoNumberInput}
                  data-input="autopart-form-autopart-edit-partnumber"
                />
              </div>
              <div className="w-full flex justify-between items-center">
                <label
                  htmlFor="autopartQuantity"
                  className="block w-full text-xs font-thin text-gray-900"
                  data-text="autopart-form-autopart-edit-quantity"
                >
                  {' '}
                  Quantity:{' '}
                </label>
                <NumberInput
                  size={'sm'}
                  width={'100px'}
                  borderRadius={'16px'}
                  value={quantityNumber}
                  variant={'filled'}
                  onChange={(value: string, valueNum: number) =>
                    setQuantityNumber(valueNum)
                  }
                  data-input="autopart-form-autopart-edit-quantity"
                >
                  <NumberInputField borderRadius={'12px'} />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </div>
              <div className="relative my-1 w-full pb-1 leading-relaxed">
                <label
                  htmlFor="description"
                  className="block text-sm font-thin text-gray-900 dark:text-white"
                  data-text="autopart-form-autopart-edit-description"
                >
                  Description
                </label>
                <textarea
                  id="message"
                  className="block p-2.5 capitalize w-full text-sm text-gray-900 bg-gray-50 rounded-sm border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter more auto part details..."
                  onChange={(e: any) => setAutoDescription(e.target.value)}
                  value={autoDescription}
                  data-input="autopart-form-autopart-edit-description"
                ></textarea>
              </div>
            </div>
            <PhotoList
              photos={photos}
              showDeleteIcon
              callback={e => handleDeletePhoto(e)}
            />
            <PhotoSelect
              onOpen={photosDisclosure.onOpen}
              isOpen={photosDisclosure.isOpen}
              onClose={photosDisclosure.onClose}
              heading={'Add Image to request'}
              max={Infinity}
              data-component="photo-select"
            />
            {/*footer*/}
            <div className="flex items-center justify-between p-2 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setSelectedAutopart(null)}
                data-btn="autopart-form-autopart-edit-cancel"
              >
                Cancel
              </button>
              <button
                className="bg-[#e1a404] text-xs text-white active:bg-emerald-600 px-3 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => updateAutoPart()}
                data-btn="autopart-form-autopart-edit-save"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="opacity-25 fixed inset-0 z-40 bg-black "
        onClick={() => setSelectedAutopart(null)}
      ></div>
    </>
  );
};

export default EditAutopart;
