import { Heading, Stack, Tag } from '@chakra-ui/react';
import { ImPriceTag } from 'react-icons/im';
import React from 'react';
import useDateCompare from '../../hooks/use-date';

function PromoCard() {
  const todayDate2 = Date.now();

  const { displayPromotionBanner } = useDateCompare(todayDate2, '2022-11-28');

  return (
    <>
      {displayPromotionBanner ? (
        <Stack
          w={{
            base: '100%',
            md: '30%',
          }}
          bg={'rgba(0,0,0,0.9)'}
          padding={'30px'}
          alignItems={'center'}
          justifyContent={'center'}
          gap={4}
          rounded={'lg'}
          position={'relative'}
        >
          <Stack
            position={'absolute'}
            top={'2'}
            right={'2'}
            padding={'1'}
            className={'animate-spin'}
          >
            <ImPriceTag color="#fbbd1e" size={20} />
          </Stack>
          <Heading
            fontWeight={'medium'}
            fontSize={'lg'}
            color={'rgba(250,250,250,0.9)'}
          >
            Black Friday Special
          </Heading>
          <Heading color={'aliceblue'} fontSize={'3xl'}>
            GET{' '}
            <Tag
              fontSize={'2xl'}
              fontWeight="bold"
              px={'5'}
              py={'2'}
              size={'xl'}
              variant={'solid'}
              colorScheme={'blue'}
            >
              10%
            </Tag>{' '}
            OFF
          </Heading>
        </Stack>
      ) : (
        <></>
      )}
    </>
  );
}

export default PromoCard;
