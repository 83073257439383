/* eslint-disable react/display-name */
import React, { useEffect, useState, memo } from 'react';
import { useCombobox } from 'downshift';
import {
  Input,
  List,
  ListItem,
  Flex,
  useColorModeValue,
  InputProps,
} from '@chakra-ui/react';

const ComboboxInput = React.forwardRef(({ ...props }: InputProps, ref: any) => {
  return <Input value={''} {...props} ref={ref} />;
});

const ComboboxList = React.forwardRef(({ isOpen, ...props }: any, ref) => {
  return (
    <List
      position={'absolute'}
      w={'full'}
      top={'100%'}
      left={'0'}
      bg={'gray.100'}
      maxH={'150px'}
      borderRadius={'8px'}
      zIndex={'99'}
      display={isOpen ? null : 'none'}
      {...props}
      ref={ref}
    />
  );
});

const ComboboxItem = React.forwardRef(
  ({ itemIndex, highlightedIndex, ...props }: any, ref: any) => {
    const isActive = itemIndex === highlightedIndex;

    return (
      <ListItem
        transition="background-color 220ms, color 220ms"
        bg={isActive ? 'teal.100' : 'none'}
        px={4}
        py={2}
        cursor="pointer"
        {...props}
        ref={ref}
      />
    );
  },
);

export interface ComboProps {
  items: string[];
  callback: (item: any) => void;
  placeholder: string;
  clear: boolean;
  dataAttr?: string;
  isEnabled?: boolean;
  defaultValue?: string | number;
}

const Combobox = ({
  items,
  callback,
  placeholder,
  clear,
  dataAttr,
  isEnabled,
  defaultValue,
}: ComboProps) => {
  const [inputItems, setInputItems] = useState<string[]>(items);
  const inputBg = useColorModeValue('gray.50', 'gray.200');
  const {
    isOpen,
    selectedItem,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useCombobox({
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      //console.log(inputValue);
      let filtered = items.filter(item =>
        item.toLowerCase().startsWith((inputValue || '').toLowerCase()),
      );

      if (filtered.length < 1) {
        filtered = [inputValue || ''];
      }
      //console.log(inputValue);
      setInputItems(filtered);
      callback(inputValue);
    },
  });

  useEffect(() => {
    if (selectedItem) callback(selectedItem);
  }, [selectedItem]);

  useEffect(() => {
    selectItem(null);
  }, [clear]);

  return (
    <Flex width={'full'} direction="column" align="center">
      <Flex
        position={'relative'}
        width={'full'}
        direction="column"
        flex="1 1 auto"
      >
        <Flex width={'full'} direction="row" alignItems="baseline">
          <ComboboxInput
            {...getInputProps()}
            placeholder={placeholder}
            flex="0 0 auto"
            width={'full'}
            mt={1}
            bg={inputBg}
            rounded={'sm'}
            border={isEnabled ? '2px' : '1px'}
            borderColor={isEnabled ? 'green.400' : 'red.100'}
            data-input={dataAttr}
            value={defaultValue}
          />
          {/*           <IconButton
            {...getToggleButtonProps()}
            aria-label={'toggle menu'}
            variantColor={isOpen ? 'gray' : 'teal'}
            icon={`arrow-${isOpen ? 'up' : 'down'}`}
          /> */}
        </Flex>
        <ComboboxList
          isOpen={isOpen}
          {...getMenuProps()}
          flex={1}
          overflowY="auto"
          mt={0}
        >
          {inputItems.map((item, index) => (
            <ComboboxItem
              {...getItemProps({ item, index })}
              itemIndex={index}
              highlightedIndex={highlightedIndex}
              key={index}
            >
              {item}
            </ComboboxItem>
          ))}
        </ComboboxList>
      </Flex>
    </Flex>
  );
};

export default memo(Combobox);
