import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useMemo, useRef } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { editAutopart, editRequest } from '../../../api/mutations';
import { getRequest } from '../../../api/queries';
import {
  partUpdateAtom,
  requestDrawerIsOpenAtom,
  selectedAutopartQuantityAtom,
  selectedRequestAtom,
  updateRequestInputAtom,
} from '../../../recoil/atoms';
import { paths } from '../../../utils/constants';
import { ButtonPrimary } from '../../buttons';
import Content from './content';
import Loading from './loading';
import { getPinpointButtonClickEventProp } from '../../../utils/functions-ts';

// THIS PAGE IS SUPPOSED TO BE THE EDIT REQUEST PAGE || REQUEST MORE DETAILS PAGE IF THE REQUEST HAS QUOTATIONS.

export default function RequestDrawer() {
  // states

  const selectedRequest = useRecoilValue(selectedRequestAtom);
  const updateRequestInput = useRecoilValue(updateRequestInputAtom);
  const isOpen = useRecoilValue(requestDrawerIsOpenAtom);
  const [, setQuantity] = useRecoilState(selectedAutopartQuantityAtom);
  const partUpdate = useRecoilValue(partUpdateAtom);
  //const selectedV = useRecoilValue(selectedVehicle);

  // secondary hooks & refs
  const toast = useToast();
  const { hash } = useLocation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const createRequestButtonRef = useRef<HTMLButtonElement | null>(null);

  // functions

  const close = () => navigate(-1);

  const requestId = useMemo(() => {
    if (hash && hash.length) {
      return hash.split('-')[1];
    }
  }, [hash]);

  //const hashSplited  = hash.split("-")[1]

  // queries & mutations

  const getRequestQuery = useQuery(
    ['request', { id: requestId, request: selectedRequest }],
    getRequest,
    {
      enabled: !!requestId,
      cacheTime: Infinity,
      retryDelay: 0,
    },
  );

  const isInProgress =
    getRequestQuery.data &&
    getRequestQuery.data.request &&
    getRequestQuery.data.request.status === 'IN_PROGRESS'; // has quotes

  useEffect(() => {
    if (getRequestQuery.isSuccess && getRequestQuery.data)
      setQuantity(getRequestQuery.data.autoPart.quantity);
  }, [getRequestQuery.isSuccess]);

  const editRequestMutation = useMutation(editRequest);

  const editAutopartMutation = useMutation(editAutopart);
  //console.log('selectedRequest::', selectedRequest);

  async function editRequestInitiator() {
    console.log(updateRequestInput);
    if (isInProgress) {
      navigate(paths.requests);
    } else {
      if (!selectedRequest) throw new Error('No Request');
      editAutopartMutation.mutate({
        id: selectedRequest?.autoPart.autoPart.id,
        name: partUpdate.name,
        description: partUpdate.description,
        OEM: partUpdate.partNumber,
      });
      editRequestMutation.mutate(updateRequestInput);
    }
  }

  if (editRequestMutation.data)
    console.log('editRequestMutation::', editRequestMutation.data);

  useEffect(() => {
    if (editRequestMutation.isSuccess) {
      toast({
        status: 'info',
        title: 'Request updated successfully!',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
      close();
    } else if (editRequestMutation.isError)
      toast({
        status: 'error',
        title: 'Could not updated request, ',
        description: 'please try again.',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
  }, [editRequestMutation.isSuccess, editRequestMutation.isError]);

  // NOTE: check for screen size
  const screenSize: number = window.innerWidth;

  return (
    <Drawer
      isOpen={isOpen}
      placement={screenSize <= 500 ? 'bottom' : 'right'}
      onClose={close}
      initialFocusRef={createRequestButtonRef}
    >
      <DrawerOverlay />
      <DrawerContent
        borderTopRadius={{
          base: '20px',
          md: '0',
        }}
        p={0}
        width={'full'}
      >
        <DrawerBody as={VStack} spacing={4} py={6} width={'full'} px={4}>
          {getRequestQuery.isLoading ? (
            <Loading />
          ) : (
            getRequestQuery.isSuccess && (
              <Content requestSelected={getRequestQuery.data} />
            )
          )}
        </DrawerBody>

        <DrawerFooter width={'full'} padding={4}>
          {getRequestQuery.isSuccess && (
            <ButtonPrimary
              width={'full'}
              ref={createRequestButtonRef}
              isLoading={editRequestMutation.isLoading}
              onClick={editRequestInitiator}
              {...getPinpointButtonClickEventProp({
                command: 'save request changes',
                currentPage: pathname,
              })}
            >
              {' '}
              Save Changes{' '}
            </ButtonPrimary>
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
