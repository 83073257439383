import { flatten } from 'lodash';
import { useEffect, useState } from 'react';
import { PaymentProcessors, Fees } from '../API';
import { CostCalculationState } from '../types/interfaces';

const defaultState: CostCalculationState = {
  total: 0,
  delivery: 0,
  withDelivery: {
    chargeFee: 0,
    deliveryFee: 0, //withdrawal + fee
    finalFee: 0,
    serviceFee: 0,
  },
  withoutDelivery: {
    chargeFee: 0,
    finalFee: 0,
    serviceFee: 0,
  },
};

const useCostCalculation = (
  cart: any,
  shouldDeliver: boolean,
  processor: PaymentProcessors,
) => {
  const [state, setState] = useState<CostCalculationState>(defaultState);

  const getPaymentOptionsByProcessor = (
    myProcessor: PaymentProcessors,
  ): Fees[] => {
    const paymentOptions = flatten(
      cart.map((item: any) => item.paymentOptions),
    ) as Fees[];

    return paymentOptions.filter(option => option.processor === myProcessor);
  };

  const getTotalFees = (
    fees: Fees[],
    withDelivery: boolean,
  ): CostCalculationState => {
    //* Calculate the totals

    if (fees.length < 1) return defaultState;

    const totalWithDelivery = fees
      .filter(f => f.isDeliveryIncluded)
      .reduce((acc, val) => ({
        chargeFee: acc.chargeFee + val.chargeFee,
        deliveryFee: acc.deliveryFee + val.deliveryFee,
        deliveryWithdrawalFee:
          acc.deliveryWithdrawalFee + val.deliveryWithdrawalFee,
        finalFee: acc.finalFee + val.finalFee,
        processor: acc.processor,
        processorRate: acc.processor,
        quotedPrice: acc.quotedPrice + val.quotedPrice,
        serviceFee: acc.serviceFee + val.serviceFee,
        serviceFeeDate: acc.serviceFeeDate,
        withdrawalFee: acc.withdrawalFee + val.withdrawalFee,
        processorChargePercentage: acc.processorChargePercentage,
        processorChargeRate: acc.processorChargeRate,
        processorPayoutPercentage: acc.processorPayoutPercentage,
        processorPayoutRate: acc.processorPayoutRate,
        partnerPayoutAmount: acc.partnerPayoutAmount + val.partnerPayoutAmount,
        deliveryPayoutAmount:
          acc.deliveryPayoutAmount + val.deliveryPayoutAmount,
        isDeliveryIncluded: acc.isDeliveryIncluded,
        payoutFee: acc.payoutFee + val.payoutFee,
        __typename: 'Fees',
      }));

    const totalWithoutDelivery = fees
      .filter(f => !f.isDeliveryIncluded)
      .reduce((acc, val) => ({
        chargeFee: acc.chargeFee + val.chargeFee,
        deliveryFee: acc.deliveryFee + val.deliveryFee,
        deliveryWithdrawalFee:
          acc.deliveryWithdrawalFee + val.deliveryWithdrawalFee,
        finalFee: acc.finalFee + val.finalFee,
        processor: acc.processor,
        processorCPercentage: acc.processorChargePercentage,
        processorRate: acc.processor,
        quotedPrice: acc.quotedPrice + val.quotedPrice,
        serviceFee: acc.serviceFee + val.serviceFee,
        serviceFeeDate: acc.serviceFeeDate,
        withdrawalFee: acc.withdrawalFee + val.withdrawalFee,
        processorChargePercentage: acc.processorChargePercentage,
        processorChargeRate: acc.processorChargeRate,
        processorPayoutPercentage: acc.processorPayoutPercentage,
        processorPayoutRate: acc.processorPayoutRate,
        partnerPayoutAmount: acc.partnerPayoutAmount + val.partnerPayoutAmount,
        deliveryPayoutAmount:
          acc.deliveryPayoutAmount + val.deliveryPayoutAmount,
        isDeliveryIncluded: acc.isDeliveryIncluded,
        payoutFee: acc.payoutFee + val.payoutFee,
        __typename: 'Fees',
      }));

    const chosenTotal = withDelivery ? totalWithDelivery : totalWithoutDelivery;

    return {
      total: chosenTotal.finalFee,
      delivery: chosenTotal.deliveryFee + chosenTotal.deliveryWithdrawalFee,
      withDelivery: {
        chargeFee: totalWithDelivery.chargeFee,
        deliveryFee: totalWithDelivery.deliveryFee, //withdrawal + fee
        finalFee: totalWithDelivery.finalFee,
        serviceFee: totalWithDelivery.serviceFee,
      },
      withoutDelivery: {
        chargeFee: totalWithoutDelivery.chargeFee,
        finalFee: totalWithoutDelivery.finalFee,
        serviceFee: totalWithoutDelivery.serviceFee,
      },
    };
  };

  useEffect(() => {
    const fees = getPaymentOptionsByProcessor(processor);
    setState(getTotalFees(fees, shouldDeliver));
  }, [cart, shouldDeliver, processor]);

  return state;
};

export default useCostCalculation;
