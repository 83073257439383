import { Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../utils/constants';

export default function Logo(props) {
  const navigate = useNavigate();

  return (
    <Image
      src={'/thick-logo.svg'}
      alt={'riaparts logo'}
      height={'32px'}
      width={'32px'}
      cursor={'pointer'}
      onClick={() => navigate(paths.home)}
      {...props}
    />
  );
}
