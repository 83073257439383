import {
  Box,
  Button,
  Flex,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Text,
  VStack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { updateVehicle as updateVehicleMutation } from '../../api/mutations';
import { selectedVehicle } from '../../recoil/atoms';
import { getPinpointButtonClickEventProp } from '../../utils/functions-ts';

function EditVehicle() {
  const toast = useToast();
  const [selectV, setSelectV] = useRecoilState(selectedVehicle);
  const [makeValue, setMakeValue] = useState('');
  const { pathname } = useLocation();
  const [modelValue, setModelValue] = useState('');
  const [variantValue, setVariantValue] = useState('');
  const [engineValue, setEngineValue] = useState('');
  const inputMg = '0';
  const inputColor = useColorModeValue('gray.900', 'gray.200');
  const inputBg = useColorModeValue('gray.50', 'gray.200');
  const [inValid, setInValid] = useState(false);

  const [gearBox, setGearbox] = useState('');
  const [fuelType, setFuel] = useState('');
  const [plateNumberSet, setPlateNumber] = useState('');
  const [vin, setVIN] = useState('');
  const [year, setYear] = useState(selectV.vehicle.year || 1969);

  const emptyInputs = () => {
    setMakeValue('');
    setModelValue('');
    setVariantValue('');
    setEngineValue('');
    setGearbox('');
    setFuel('');
    setPlateNumber('');
    setYear(1970);
    setVIN('');
  };
  const initInputs = () => {
    const {
      make,
      model,
      variant,
      year,
      engineNumber,
      gearbox,
      fuel,
      plateNumber,
      VIN,
    } = selectV.vehicle;
    setMakeValue(make || '');
    setModelValue(model || '');
    setVariantValue(variant || '');
    setEngineValue(engineNumber || '');
    setGearbox(gearbox || '');
    setFuel(fuel || 'Diesel');
    setPlateNumber(plateNumber || '');
    setYear(year || 1970);
    setVIN(VIN || '');
  };

  const updatedVehicle = {
    id: selectV.vehicle.id,
    make: makeValue ? makeValue : selectV.vehicle.make,
    model: modelValue ? modelValue : selectV.vehicle.model,
    variant: variantValue ? variantValue : selectV.vehicle.variant,
    engineNumber: engineValue ? engineValue : selectV.vehicle.engineNumber,
    fuel: fuelType ? fuelType : selectV.vehicle.fuel,
    gearbox: gearBox ? gearBox : selectV.vehicle.gearbox,
    plateNumber: plateNumberSet ? plateNumberSet : selectV.vehicle.plateNumber,
    VIN: vin ? vin : selectV.vehicle.VIN,
    year,
  };

  const vehicleUpdated = useMutation(updateVehicleMutation);

  const createUpdate = async () => {
    if (
      makeValue !== '' &&
      modelValue !== '' &&
      variantValue !== '' &&
      engineValue !== ''
    ) {
      vehicleUpdated.mutateAsync({ ...updatedVehicle }).then(data => {
        console.log('xxx 1', data);
        setSelectV(data);
        emptyInputs();
        setInValid(false);
        toast({
          status: 'info',
          title: 'Vehicle Updated Successfully',
          duration: 4000,
          isClosable: true,
          position: 'bottom',
        });
      });
      return;
    } else if (
      makeValue === '' &&
      modelValue === '' &&
      variantValue === '' &&
      engineValue === '' &&
      (gearBox !== '' || fuelType !== '' || plateNumberSet !== '' || vin !== '')
    ) {
      vehicleUpdated.mutateAsync(updatedVehicle).then(data => {
        console.log('xxx 123', data);
        setSelectV(data);
        emptyInputs();
        setInValid(false);
        toast({
          status: 'info',
          title: 'Vehicle Updated Successfully',
          duration: 4000,
          isClosable: true,
          position: 'bottom',
        });
      });

      return;
    } else {
      setInValid(true);
      emptyInputs();
    }
  };

  useEffect(() => {
    setInValid(false);
    initInputs();
  }, [selectV]);

  useEffect(() => {
    if (vehicleUpdated.isError) {
      toast({
        status: 'error',
        title: 'Please fIll in First the make -> model -> variant -> engine',
        description: "Can't update vehicle",
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    }
  }, [vehicleUpdated.isError]);

  return (
    <Box padding={'2'}>
      <VStack width={'full'} as={Flex} align={'flex-start'}>
        {/* make values*/}
        <VStack width={'full'}>
          <Text
            py={0}
            as={FormLabel}
            fontSize={'xs'}
            width={'full'}
            htmlFor="make"
          >
            {' '}
            Make:{' '}
          </Text>
          <InputGroup>
            variant
            <Input
              size={'sm'}
              fontSize={'md'}
              width={'full'}
              bg={inputBg}
              color={inputColor}
              placeholder={selectV.vehicle.make}
              textTransform={'capitalize'}
              rounded={'sm'}
              py={{ md: '5' }}
              mb={inputMg}
              border={'1px'}
              borderColor={inValid ? 'red.300' : 'blue.100'}
              value={makeValue}
              onChange={e => {
                setMakeValue(e.target.value);
              }}
              list={'make-list'}
              onFocus={() => {
                if (inValid) {
                  console.log('new one');
                  setInValid(false);
                }
              }}
            />
          </InputGroup>
        </VStack>

        {/* model value*/}

        <VStack width={'full'}>
          <Text
            py={0}
            as={FormLabel}
            fontSize={'xs'}
            width={'full'}
            htmlFor="model"
          >
            {' '}
            Model:{' '}
          </Text>
          <InputGroup>
            <Input
              size={'sm'}
              fontSize={'md'}
              bg={inputBg}
              color={inputColor}
              py={{ md: '5' }}
              rounded={'sm'}
              placeholder={selectV.vehicle.model}
              mb={inputMg}
              border={'1px'}
              borderColor={inValid ? 'red.300' : 'blue.100'}
              value={modelValue}
              onChange={e => {
                setModelValue(e.target.value);
              }}
              _placeholder={{
                color: useColorModeValue('gray.500', 'gray.200'),
              }}
              textTransform={'capitalize'}
              list={'model-list'}
              onFocus={() => {
                if (inValid) {
                  console.log('new one');
                  setInValid(false);
                }
              }}
            />
          </InputGroup>
        </VStack>
        {/*variant vlues */}
        <HStack width={'full'}>
          <Text
            data-text="year-text"
            fontSize={'sm'}
            fontWeight={'medium'}
            width={'full'}
          >
            {' '}
            Year:{' '}
          </Text>
          <NumberInput
            onChange={e => {
              setYear(parseInt(e));
            }}
            defaultValue={year}
            min={1970}
            max={new Date().getFullYear()}
            size={'sm'}
            borderRadius={'16px'}
            value={year}
            variant={'filled'}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </HStack>

        <VStack width={'full'}>
          <Text
            py={0}
            as={FormLabel}
            fontSize={'xs'}
            width={'full'}
            htmlFor="variant"
          >
            {' '}
            Specification:{' '}
          </Text>
          <InputGroup>
            <Input
              size={'sm'}
              fontSize={'md'}
              bg={inputBg}
              color={inputColor}
              py={{ md: '5' }}
              placeholder={selectV.vehicle.variant}
              rounded={'sm'}
              mb={inputMg}
              border={'1px'}
              borderColor={inValid ? 'red.300' : 'blue.100'}
              value={variantValue}
              onChange={e => {
                setVariantValue(e.target.value);
              }}
              list={'variant-list'}
              onFocus={() => {
                if (inValid) {
                  console.log('new one');
                  setInValid(false);
                }
              }}
            />
          </InputGroup>
        </VStack>

        {/*engine*/}
        <VStack width={'full'}>
          <Text
            py={0}
            as={FormLabel}
            fontSize={'xs'}
            width={'full'}
            htmlFor="variant"
          >
            {' '}
            Engine:{' '}
          </Text>
          <InputGroup w={'full'}>
            <Input
              type={'text'}
              size={'sm'}
              fontSize={'md'}
              bg={inputBg}
              color={inputColor}
              py={{ md: '5' }}
              placeholder={selectV.vehicle.engineNumber}
              rounded={'sm'}
              mb={'4'}
              border={'1px'}
              borderColor={inValid ? 'red.300' : 'blue.100'}
              value={engineValue}
              onChange={e => {
                setEngineValue(e.target.value);
              }}
              list={'engine-list'}
              onFocus={() => {
                if (inValid) {
                  console.log('new one');
                  setInValid(false);
                }
              }}
            />
          </InputGroup>
        </VStack>

        <Select
          defaultValue={selectV.vehicle.gearbox}
          onChange={e => setGearbox(e.target.value)}
          color={inValid ? 'red.300' : 'black'}
          onFocus={() => {
            if (inValid) {
              console.log('new one');
              setInValid(false);
            }
          }}
        >
          <option value="MANUAL">MANUAL</option>
          <option value="AUTOMATIC">AUTOMATIC</option>
        </Select>
        <Select
          defaultValue={selectV.vehicle.fuel}
          placeholder={selectV.vehicle.fuel}
          onChange={e => setFuel(e.target.value)}
          color={inValid ? 'red.300' : 'black'}
          onFocus={() => {
            if (inValid) {
              console.log('new one');
              setInValid(false);
            }
          }}
        >
          <option value="Diesel">Diesel</option>
          <option value="Petro">Petrol</option>
          <option value="Electric">Electric</option>
          <option value="CNG">CNG</option>
        </Select>
        <Input
          size={'sm'}
          fontSize={'md'}
          width={'full'}
          bg={inputBg}
          color={inputColor}
          placeholder={selectV.vehicle.plateNumber || 'Plate Number'}
          textTransform={'capitalize'}
          rounded={'sm'}
          py={{ md: '5' }}
          mb={inputMg}
          border={'1px'}
          borderColor={inValid ? 'red.300' : 'blue.100'}
          value={plateNumberSet}
          onChange={e => {
            setPlateNumber(e.target.value);
          }}
          onFocus={() => {
            if (inValid) {
              console.log('new one');
              setInValid(false);
            }
          }}
        />
        <Input
          size={'sm'}
          fontSize={'md'}
          width={'full'}
          bg={inputBg}
          color={inputColor}
          placeholder={selectV.vehicle.VIN || 'VIN'}
          textTransform={'capitalize'}
          rounded={'sm'}
          py={{ md: '5' }}
          mb={inputMg}
          border={'1px'}
          borderColor={inValid ? 'red.300' : 'blue.100'}
          value={vin}
          onChange={e => {
            setVIN(e.target.value);
          }}
          onFocus={() => {
            if (inValid) {
              console.log('new one');
              setInValid(false);
            }
          }}
        />
      </VStack>

      <Button
        my={6}
        color={'white'}
        width={'full'}
        colorScheme={'green'}
        isLoading={vehicleUpdated.isLoading}
        onClick={createUpdate}
        {...getPinpointButtonClickEventProp({
          command: 'edit vehicle',
          currentPage: pathname,
        })}
      >
        Save Changes
      </Button>
    </Box>
  );
}

export default EditVehicle;
