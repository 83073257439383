import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useRecoilState, useRecoilValue } from 'recoil';
import { requestStepAtom } from '../../recoil/atoms';
import { StepText } from '../../types/enums';
import BannerLoader from './loader';
import StepHead from './stepHead';

const renderText = (step: number): string => {
  if (step === 1) {
    return StepText.AUTOPART;
  } else if (step === 2) {
    return StepText.VEHICLE;
  } else if (step === 3) {
    return StepText.VERIFY;
  } else {
    return '';
  }
};

const StepBanner: React.FC = () => {
  const [activeStep, setActiveStep] = useRecoilState(requestStepAtom);

  return (
    <div className="sm:w-[100%] md:w-[30%] h-auto flex items-center justify-between p-2 shadow-sm border border-gray-200 rounded-md">
      <BannerLoader step={activeStep} value={activeStep / 3} />
      <div className="w-full p-2">
        <StepHead currentStep text={renderText(activeStep)} />
        <StepHead
          currentStep={false}
          textRight
          text={renderText(activeStep + 1)}
          isNext
        />
      </div>
    </div>
  );
};

export default React.memo(StepBanner);

/*
<StepHead
          isNext={false}
          currentStep={false}
          text={renderText(activeStep - 1)}
        />
*/
