import { Box, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { getConversation } from '../../../api/queries';
import ErrorAlert from '../../../components/error/alert';
import { selectedConversationAtom } from '../../../recoil/atoms';
import Content from './content';
import Loading from './loading';

export default function Conversation() {
  const [conversation, setConversation] = useRecoilState(
    selectedConversationAtom,
  );
  const [searchParams] = useSearchParams();

  const [shouldFetchConversation, setShouldFetchConversation] = useState(false);
  const conversationIsSelected = searchParams.has('conversation');

  useEffect(() => {
    const shouldFetch =
      !conversation ||
      (conversationIsSelected &&
        conversation.conversation.id !== searchParams.get('conversation'));
    if (shouldFetch !== shouldFetchConversation)
      setShouldFetchConversation(shouldFetch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation, searchParams]);

  const getConversationQuery = useQuery(
    ['conversation', { id: searchParams.get('conversation') }],
    getConversation,
    {
      enabled: shouldFetchConversation,
      refetchOnWindowFocus: false,
      retryDelay: 0,
      cacheTime: Infinity,
      onSuccess: data => setConversation(data),
    },
  );

  const selectSupplierText = useColorModeValue('gray.700', 'gray.100');

  return (
    <VStack
      display={{ base: conversationIsSelected ? 'flex' : 'none', md: 'flex' }}
      align={'center'}
      justify={'center'}
      flex={{ base: conversationIsSelected ? 1 : 2, md: 2 }}
      width={{ base: conversationIsSelected ? '100vw' : 'full', md: '55%' }}
      height={{ base: '100%', md: '650px' }}
      position={{
        base: conversationIsSelected ? 'fixed' : 'relative',
        md: 'relative',
      }}
      top={0}
      left={0}
      borderRadius={{ base: 0, md: '12px' }}
      zIndex={{ base: conversationIsSelected ? 1000 : 1, md: 1 }}
      bg={useColorModeValue('white', 'gray.700')}
    >
      {!conversationIsSelected && (
        <Text fontSize={'3xl'} fontWeight={'medium'} color={selectSupplierText}>
          {' '}
          Select a supplier to start negotiating{' '}
        </Text>
      )}

      {conversationIsSelected &&
        (getConversationQuery.isLoading ? (
          <Loading />
        ) : getConversationQuery.isError ? (
          <Box p={5}>
            <ErrorAlert width={'full'} height={'full'} />
          </Box>
        ) : (
          conversation && <Content />
        ))}
    </VStack>
  );
}
