import { Flex, HStack, Icon, Stack } from '@chakra-ui/react';
import { Children, cloneElement, useEffect, useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import {
  modalCarouselChildrenAtom,
  modalCarouselStateAtom,
} from '../../recoil/atoms';
// import withParentDimensions from '../../hocs/with-parent-dimensions';

const Carousel = function ({
  automatic = false,
  buttonPosition = 'middle',
  time = 4000,
  displayButtons = true,
  showModal = true,
  children,
  ...rest
}) {
  const setModalCarouselChildren = useSetRecoilState(modalCarouselChildrenAtom);
  const setModalCarouselOpenState = useSetRecoilState(modalCarouselStateAtom);

  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    function windowResize() {
      if (elementRef.current) {
        setDimensions({
          height: elementRef.current.clientHeight,
          width: elementRef.current.clientWidth,
        });
      }
    }
    windowResize();
    window.addEventListener('resize', windowResize);
    if (automatic) intervalRef.current = setInterval(scroll, time);
    return () => {
      window.removeEventListener('resize', windowResize);
      window.clearInterval(intervalRef.current);
    };
  }, []);

  const [index, setIndex] = useState(0);

  const intervalRef = useRef();
  const elementRef = useRef();

  const childrenLength = children ? children.length : 0;

  const scroll = (plus = 1) => {
    setIndex(index => {
      index = index + plus;
      index =
        index >= childrenLength ? 0 : index < 0 ? children.length - 1 : index;
      return index;
    });
  };

  useEffect(() => {
    elementRef.current.scrollLeft = index * elementRef.current.clientWidth;
  }, [index]);

  const openModal = function () {
    setModalCarouselOpenState(true);
    setModalCarouselChildren(children);
  };

  //const toggleButtonsDarkBg = useColorModeValue('blue.100', 'blue.50');
  //const imageCountBG = useColorModeValue('gray.100', 'gray.700');

  return (
    <Flex
      maxW={'full'}
      width={'full'}
      align={'flex-start'}
      justify={'start'}
      overflowX={'hidden'}
      className={'smooth-scroll'}
      ref={elementRef}
      // onMouseEnter={() => window.clearInterval(intervalRef.current)}
      // onMouseLeave={() => {
      //  window.clearInterval(intervalRef.current);
      //  intervalRef.current = window.setInterval(scroll, time);
      // }}
      {...rest}
    >
      {Children.toArray(children).map(child => {
        return cloneElement(child, {
          width: 'full',
          height: 'full',
          onClick: showModal
            ? openModal
            : child.props
            ? child.props.onClick
            : undefined,
        });
      })}

      {displayButtons && buttonPosition === 'bottom' && (
        <HStack
          alignItems={'center'}
          justifyContent={'center'}
          position={'absolute'}
          marginTop={dimensions.height - 40 + 'px'}
          height={'30px'}
          width={dimensions.width}
          spacing={3}
        >
          <Stack
            px={'4'}
            py={'2'}
            bg={'aliceblue'}
            rounded={'md'}
            onClick={() => {
              setIndex(prev => {
                console.log('decrease', prev);
                if (prev === 0) return 0;
                return prev - 1;
              });
              window.clearInterval(intervalRef.current);
              if (automatic)
                intervalRef.current = window.setInterval(scroll, time);
            }}
          >
            <Icon as={GrFormPrevious} />
          </Stack>

          <Stack
            px={'4'}
            py={'2'}
            bg={'aliceblue'}
            rounded={'md'}
            onClick={() => {
              setIndex(prev => {
                console.log('increase', prev);
                if (prev === children.length - 1) return prev;
                return prev + 1;
              });
              window.clearInterval(intervalRef.current);
              if (automatic)
                intervalRef.current = window.setInterval(scroll, time);
            }}
          >
            <Icon as={GrFormNext} />
          </Stack>
        </HStack>
      )}
    </Flex>
  );
};

export default Carousel;

/*

{children.map((child, i) => {
            return (
              <Box
                cursor={'pointer'}
                display={'block'}
                key={i}
                bg={index === i ? 'blue.300' : toggleButtonsDarkBg}
                minWidth={index === i ? '15px' : '10px'}
                minHeight={index === i ? '15px' : '10px'}
                rounded={'full'}
                onClick={() => {
                  setIndex(i);
                  window.clearInterval(intervalRef.current);
                  if (automatic)
                    intervalRef.current = window.setInterval(scroll, time);
            }}
              />
            );
          })}

*/
