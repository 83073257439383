import { Flex, useColorModeValue } from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import PWAPrompt from 'react-ios-pwa-prompt';
import { useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { getCustomer } from './api/queries';
import CarouselModal from './components/carousel/modal';
import Header from './components/header';
import PaymentModal from './components/make-payment/modal';
import useVehicle from './hooks/useVehicle';
import {
  cartIsOpenAtom,
  cognitoUserAtom,
  isAuthenticatedAtom,
  momoPhoneNumberAtom,
  requestDrawerIsOpenAtom,
} from './recoil/atoms';
import Routes from './Routes';
import { storeCustomAuthSession } from './utils/functions';

export default function App() {
  const setCognitoUser = useSetRecoilState(cognitoUserAtom);
  const setMomoPhoneNumber = useSetRecoilState(momoPhoneNumberAtom);
  const [isAuthenticated, setAuthentication] =
    useRecoilState(isAuthenticatedAtom);
  const [cartIsOpen, setCartIsOpen] = useRecoilState(cartIsOpenAtom);
  const [requestDrawerIsOpen, setRequestDrawerIsOpen] = useRecoilState(
    requestDrawerIsOpenAtom,
  );
  const [go, setGo] = useState(null);

  const location = useLocation();
  const onLoadMutation = useMutation(async () => {
    await Auth.currentSession();
    return Auth.currentAuthenticatedUser();
  });

  useEffect(() => onLoadMutation.mutate({}), []);
  useEffect(() => {
    if (onLoadMutation.isSuccess) {
      const user = onLoadMutation.data;
      if (user) {
        setGo(user.attributes);
        storeCustomAuthSession(user);
      }
    } else if (onLoadMutation.isError) {
      //console.log("Something went wrong");
    }
  }, [onLoadMutation.isSuccess]);

  const getCustomerDetailsQuery = useQuery(
    ['user', { user: go }],
    getCustomer,
    {
      enabled: !!go,
      retryDelay: 0,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );

  useEffect(() => {
    if (getCustomerDetailsQuery.isSuccess) {
      const user = getCustomerDetailsQuery.data;
      if (user) {
        /*
        setCognitoUser(prevValue =>
          prevValue ? { ...prevValue, ...user } : { ...user },
        );
        */
        setCognitoUser(user);

        setMomoPhoneNumber(user.phoneNumber.substr(-9, 9));
        setAuthentication(true);
      }
    } else if (getCustomerDetailsQuery.isError) {
      console.log('Something went wrong');
    }
  }, [getCustomerDetailsQuery.isSuccess, getCustomerDetailsQuery.isError]);

  useEffect(() => {
    const hashHalf = location.hash.split('-')[0];
    if (hashHalf === '#cart') {
      setCartIsOpen(true);
    } else if (cartIsOpen && hashHalf !== '#cart') {
      setCartIsOpen(false);
    } else if (hashHalf === '#request') {
      setRequestDrawerIsOpen(true);
    } else if (requestDrawerIsOpen && hashHalf !== '#request') {
      setRequestDrawerIsOpen(false);
    }
  }, [location.hash]);

  useEffect(() => {
    if (isAuthenticated && process.env.NODE_ENV === 'development') {
      try {
        //e2e_autoparts(false)
        //e2e_quotes(false)
      } catch (error) {
        console.log(error);
      }
    }
  }, [isAuthenticated]);

  useVehicle();

  return (
    <Flex
      direction={{
        base: 'column',
      }}
      width={'100vw'}
      maxW={'100vw'}
      minH={'100vh'}
      bg={useColorModeValue('light-background', 'dark-background')}
    >
      <Header />
      <Routes />
      <CarouselModal />
      <PaymentModal />
      <PWAPrompt
        promptOnVisit={1}
        timesToShow={3}
        copyClosePrompt="Close"
        permanentlyHideOnDismiss={false}
      />
    </Flex>
  );
}
