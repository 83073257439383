import { Box, Divider, Flex, HStack, VStack } from '@chakra-ui/react';
import Skeletons from '../../../components/loaders/skeletons';

export default function Loading() {
  return (
    <VStack
      spacing={5}
      p={5}
      height={'full'}
      width={'full'}
      overflowY={'scroll'}
    >
      <HStack width={'full'} spacing={2}>
        <Box>
          <Skeletons
            count={1}
            width={'56px'}
            height={'56px'}
            rounded={'full'}
          />
        </Box>
        <VStack width={'full'} align={'start'}>
          <Skeletons
            count={1}
            width={'80%'}
            height={'16px'}
            borderRadius={'4px'}
          />
          <Skeletons
            count={1}
            width={'40%'}
            height={'12px'}
            borderRadius={'12px'}
          />
        </VStack>
      </HStack>

      <Divider width={'80%'} />

      <VStack width={'full'} spacing={5}>
        {Array(10)
          .fill(null)
          .map((...loopValues) => {
            // randomize the width of the skeleton and whether it is place to the left or right.
            const width = Math.round(Math.random() * 100) + '%';
            const height = Math.round(Math.random() * 120) + 'px';
            const placementProps =
              Math.random() * 2 > 1
                ? { pr: 10, justify: 'start' }
                : { pl: 10, justify: 'flex-end' };
            return (
              <Flex key={loopValues[1]} width={'full'} {...placementProps}>
                <Skeletons
                  count={1}
                  minW={'70%'}
                  width={width}
                  minH={'60px'}
                  height={height}
                  borderRadius={'12px'}
                />
              </Flex>
            );
          })}
      </VStack>
    </VStack>
  );
}
