import { HStack } from '@chakra-ui/react';
import Skeletons from '../../components/loaders/skeletons';

const Loading: React.FC = (): JSX.Element => {
  return (
    <>
      <HStack
        width={'full'}
        overflowX={'scroll'}
        spacing={4}
        className={'no-scroll'}
      >
        <Skeletons
          count={12}
          width={'200px'}
          minW={'200px'}
          height={'200px'}
          borderRadius={'4px'}
        />
      </HStack>

      <Skeletons count={1} width={'full'} height={'2px'} />

      <Skeletons count={1} width={'full'} height={'400px'} />

      <Skeletons count={1} width={'full'} height={'2px'} />
    </>
  );
};

export default Loading;
