import React from 'react';

interface Props {
  currentStep: boolean;
  callback?: () => void;
  text: string;
  textRight?: boolean;
  isNext?: boolean;
}

const StepHead: React.FC<Props> = ({
  currentStep,
  callback,
  text,
  textRight,
  isNext,
}): JSX.Element => {
  return (
    <>
      {currentStep ? (
        <h1
          className="text-xl tracking-wider text-center text-stone-800 font-semibold"
          data-heading="autopart-form"
        >
          {text}
        </h1>
      ) : (
        <p className={`${textRight && 'justify-end'} flex items-center gap-1`}>
          {text.length > 0 && (
            <p className="text-[12px] text-gray-400">
              {isNext ? 'Next:' : 'Prev:'}
            </p>
          )}
          <p className="font-medium text-[12px] text-gray-500">{text}</p>
        </p>
      )}
    </>
  );
};

export default React.memo(StepHead);
