import { API } from 'aws-amplify';
import {
  getAutoPartCategories as getAutoPartCategoriesQuery,
  getAutoPartSubCategoriesByCategory as getAutoPartSubCategoriesByCategoryQuery,
  getConversation as getConversationQuery,
  getConversationsByCreator,
  getCustomer as getCustomerQuery,
  getMessage,
  getMessagesByConversation,
  getOrder as getOrderQuery,
  getPayment,
  getPaymentMethods as getPaymentMethodsQuery,
  getPhotosByCreator,
  getQuote,
  getRequest as getRequestQuery,
  getVehicleCategoryByType,
  getVehicleCategoryTree,
  getVehiclesByCreator as getMyVehicles,
  searchOrders as searchOrdersQuery,
  searchRequests as searchRequestsQuery,
  getPartners as getPartnersQuery,
} from '../graphql/queries';
import searchClient from '../typesense/client';
import { authModes } from '../utils/constants';

/*  
    *******SECTION ******
    Get vehicles by creator query 
*/
//NOTE: This the interface for getvehiclesbycreator queryKey by defining the type for the user

export async function getPartners({ queryKey }) {
  const { isAuthenticated } = queryKey[1];
  const { data } = await API.graphql({
    query: getPartnersQuery,
    variables: {
      input: {},
    },
    authMode: isAuthenticated
      ? authModes.AMAZON_COGNITO_USER_POOLS
      : authModes.AWS_IAM,
  });
  //console.log('GET PARTNERLIST::', data.getPartners.partners);
  return data.getPartners.partners;
}

export async function queryPayment({ queryKey }) {
  const { paymentId } = queryKey[1];
  const { data } = await API.graphql({
    query: getPayment,
    variables: {
      id: paymentId,
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  });

  if (
    'code' in data.getPayment ||
    data.getPayment.__typename === 'ErrorResponse'
  ) {
    //console.log(data.getPayment);
    throw new Error("Couldn't get payment information");
  }
  return data.getPayment;
}

export async function getVehiclesByCreator() {
  const { data } = await API.graphql({
    query: getMyVehicles,
    variables: {
      input: {},
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  });
  //console.log('🅱️ - vehicle query - line 57', data);
  return data.getVehiclesByCreator;
}

export async function getCustomer({ queryKey }) {
  const { user } = queryKey[1];
  const { data } = await API.graphql({
    query: getCustomerQuery,
    variables: {
      id: user.sub || user.id,
    },
    authMode: authModes.AMAZON_COGNITO_USER_POOLS,
  });
  return data.getCustomer;
}

export async function getCarMakes({ queryKey }) {
  const { isAuthenticated } = queryKey[1];
  const { data } = await API.graphql({
    query: getVehicleCategoryByType,
    variables: {
      input: {
        type: 'MAKE',
      },
    },
    authMode: isAuthenticated
      ? authModes.AMAZON_COGNITO_USER_POOLS
      : authModes.AWS_IAM,
  });
  return data.getVehicleCategoryByType.categories;
}

export async function getCarModels({ queryKey }) {
  // get isAuthenticated, carMakes and makeValue variables passed to this query function.
  const { isAuthenticated, carMakes, makeValue } = queryKey[1];
  // get car make object from carMakes variable which is equal to the input makeValue the user enters
  let make;
  // eslint-disable-next-line array-callback-return
  carMakes.some(m => {
    if (m.name.toLowerCase() === makeValue.toLowerCase()) {
      make = m;
      // eslint-disable-next-line array-callback-return
      return;
    }
  });
  if (!make) return [];
  const { data } = await API.graphql({
    query: getVehicleCategoryTree,
    variables: {
      input: {
        id: make.id,
        entityType: 'MAKE',
        descendants: {
          depth: 1,
        },
      },
    },
    authMode: isAuthenticated
      ? authModes.AMAZON_COGNITO_USER_POOLS
      : authModes.AWS_IAM,
  });
  return data.getVehicleCategoryTree.descendants[0].categories;
}

export async function getCarModelVariants({ queryKey }) {
  // get isAuthenticated, carModels and modelValue variables passed to this query function.
  const { isAuthenticated, carModels, modelValue } = queryKey[1];
  let model;
  // get car model object from carModels variable which is equal to the input modelValue the user enters
  // eslint-disable-next-line array-callback-return
  carModels.some(m => {
    if (m.name.toLowerCase() === modelValue.toLowerCase()) {
      model = m;
      // eslint-disable-next-line array-callback-return
      return;
    }
  });
  if (!model) return [];
  const { data } = await API.graphql({
    query: getVehicleCategoryTree,
    variables: {
      input: {
        id: model.id,
        entityType: 'MODEL',
        descendants: {
          depth: 1,
        },
      },
    },
    authMode: isAuthenticated
      ? authModes.AMAZON_COGNITO_USER_POOLS
      : authModes.AWS_IAM,
  });
  return data.getVehicleCategoryTree.descendants[0].categories;
}

export async function getCarEngines({ queryKey }) {
  const { isAuthenticated, carVariants, variantValue } = queryKey[1];

  let engine;

  // eslint-disable-next-line array-callback-return
  carVariants.some(e => {
    if (e.name.toLowerCase() === variantValue.toLowerCase()) {
      engine = e;
      // eslint-disable-next-line array-callback-return
      return;
    }
  });
  if (!engine) return [];
  const { data } = await API.graphql({
    query: getVehicleCategoryTree,
    variables: {
      input: {
        id: engine.id,
        entityType: 'VARIANT',
        descendants: {
          depth: 1,
        },
      },
    },
    authMode: isAuthenticated
      ? authModes.AMAZON_COGNITO_USER_POOLS
      : authModes.AWS_IAM,
  });

  if (data.getVehicleCategoryTree.descendants.length > 0) {
    return data.getVehicleCategoryTree.descendants[0].categories;
  } else {
    return [];
  }
}

export async function searchAutoParts({ pageParam = 1, queryKey }) {
  // get filters and search key variables;
  let { filters: readOnlyFilters, key, limit } = queryKey[1];
  let filters = Object.assign({}, readOnlyFilters);
  // configure typesense search parameters;
  let parameters = {
    q: key || '',
    query_by:
      'name,make,model,engineModel,transmissionType,VIN,OEM,category,subCategory',
    page: pageParam,
    per_page: limit,
  };
  // if some filters exist, add them to the typesense search parameters in their right format;
  if (filters) {
    //filters.variant = ""  //todo: Handle variant information
    // turn filters into typesense accepted text format for filters;
    let textFilters = ''; // typesense recognized format for filters;
    filters = Object.entries(filters);
    filters.forEach((filter, i) => {
      if (!filter[1]) return;
      textFilters += `${i !== 0 ? ' &&' : ''} ${filter[0]}:\`${filter[1]}\``;
    });
    // filter variable could be an empty object or the filters may be empty strings so check check if any where set;
    if (textFilters) parameters.filter_by = textFilters;
    // perform search;
  }
  const results = await searchClient
    .collections('autoparts')
    .documents()
    .search(parameters, {});
  // destructure hits to return only the documents;
  results.hits = results.hits.map(({ document }) => document);

  /*
# NOTE: This object is created to return only the values needed by the useInfinteQuery
*/
  const autoPartsModel = {
    autoparts: results.hits,
    page: results.page,
    found: results.found,
  };

  // REVIEW: return autoPartsModel and pageParam to the useInfinteQuery
  return {
    ...autoPartsModel,
    next: pageParam + 1,
  };
}

export async function getAutoPartCategories({ queryKey }) {
  const { isAuthenticated } = queryKey[1];
  const { data } = await API.graphql({
    query: getAutoPartCategoriesQuery,
    authMode: isAuthenticated
      ? authModes.AMAZON_COGNITO_USER_POOLS
      : authModes.AWS_IAM,
  });
  return data.getAutoPartCategories.autoPartCategories;
}

export async function getAutoPartSubCategoriesByCategory({ queryKey }) {
  const { isAuthenticated, activeCategory } = queryKey[1];
  const { data } = await API.graphql({
    query: getAutoPartSubCategoriesByCategoryQuery,
    variables: {
      categoryId: activeCategory.id,
    },
    authMode: isAuthenticated
      ? authModes.AMAZON_COGNITO_USER_POOLS
      : authModes.AWS_IAM,
  });
  return data.getAutoPartSubCategoriesByCategory.autoPartSubCategories;
}

export async function searchRequests({ pageParam = null, queryKey }) {
  const { dateRange, status, user, limit } = queryKey[1];

  let convertedDate = Object.assign(
    {},
    {
      start: new Date(dateRange.start).toJSON(),
      end: new Date(dateRange.end).toJSON(),
    },
  );

  let input = {};
  const limitAndToken = {
    limit,
    nextToken: pageParam,
  };
  const creator = {
    creatorType: 'CUSTOMER',
    id: user.sub || user.id,
  };
  if (dateRange.start && status !== 'all') {
    Object.assign(input, {
      byStatusAndDateRangeAndCreator: {
        creator,
        status: 'OPEN',
        dateRange: { ...convertedDate },
        limitAndToken,
      },
    });
  } else if (dateRange.start) {
    Object.assign(input, {
      byCreatorAndDateRange: {
        creator,
        dateRange: { ...convertedDate },
        limitAndToken,
      },
    });
  } else if (status !== 'all') {
    Object.assign(input, {
      byCreatorAndStatus: {
        creator,
        status: status.toUpperCase(),
        limitAndToken,
      },
    });
  } else {
    Object.assign(input, {
      byCreator: {
        ...creator,
        limitAndToken,
      },
    });
  }
  let { data } = await API.graphql({
    query: searchRequestsQuery,
    variables: {
      input,
    },
    authMode: authModes.AMAZON_COGNITO_USER_POOLS,
  });
  // eslint-disable-next-line array-callback-return
  Object.values(data.searchRequests).some(value => {
    if (value !== null) {
      data = value;
      return true;
    }
  });
  if (data) return data;
  else throw new Error('Error fetching requests');
}

export async function getOrder({ queryKey }) {
  const { orderId } = queryKey[1];
  const { data } = await API.graphql({
    query: getOrderQuery,
    variables: { id: orderId },
    authMode: authModes.AMAZON_COGNITO_USER_POOLS,
  });
  return data.getOrder;
}

export async function searchOrders({ pageParam = null, queryKey }) {
  const { dateRange, status, user, limit } = queryKey[1];

  let input = {};
  const limitAndToken = {
    limit,
    nextToken: pageParam,
  };

  const creator = {
    creatorType: 'CUSTOMER',
    id: user.sub || user.id,
  };
  if (dateRange?.start && status !== 'all') {
    Object.assign(input, {
      byStatusAndDateRangeAndCreator: {
        creator,
        status: status,
        dateRange,
        limitAndToken,
      },
    });
  } else if (dateRange?.start) {
    Object.assign(input, {
      byCreatorAndDateRange: {
        creator,
        dateRange,
        limitAndToken,
      },
    });
  } else if (status !== 'all') {
    Object.assign(input, {
      byCreatorAndStatus: {
        creator,
        status: status.toUpperCase(),
        limitAndToken,
      },
    });
  } else {
    Object.assign(input, {
      byCreator: {
        ...creator,
        limitAndToken,
      },
    });
  }
  let { data } = await API.graphql({
    query: searchOrdersQuery,
    variables: {
      input,
    },
    authMode: authModes.AMAZON_COGNITO_USER_POOLS,
  });

  data = Object.values(data.searchOrders).filter(value => value !== null)[0];

  if (data) return data;
  else throw new Error('Error fetching order');
}

export async function getPhotos({ pageParam = null, queryKey }) {
  const { limit, user } = queryKey[1];
  const { data } = await API.graphql({
    query: getPhotosByCreator,
    variables: {
      input: {
        creatorType: 'CUSTOMER',
        id: user.sub || user.id,
        limitAndToken: {
          limit,
          nextToken: pageParam,
        },
      },
    },
    authMode: authModes.AMAZON_COGNITO_USER_POOLS,
  });
  return data.getPhotosByCreator;
}

export async function getNegotiations({ pageParam, queryKey }) {
  const { user, limit } = queryKey[1];
  const { data } = await API.graphql({
    query: getConversationsByCreator,
    variables: {
      id: user.sub || user.id,
      senderType: 'CUSTOMER',
      limit,
      nextToken: pageParam,
    },
    authMode: authModes.AMAZON_COGNITO_USER_POOLS,
  });
  return data.getConversationsByCreator;
}

export async function getNegotiationMessage({ queryKey }) {
  const { id } = queryKey[1];
  return (
    await API.graphql({
      query: getMessage,
      variables: { id },
      authMode: authModes.AMAZON_COGNITO_USER_POOLS,
    })
  ).data.getMessage;
}

export async function getNegotiationMessages({ pageParam, queryKey }) {
  const { limit, conversation } = queryKey[1];
  const { data } = await API.graphql({
    query: getMessagesByConversation,
    variables: {
      id: conversation.conversation.id,
      limit,
      nextToken: pageParam,
    },
  });
  return data.getMessagesByConversation;
}

export async function getConversation({ queryKey }) {
  const { id } = queryKey[1];
  const { data } = await API.graphql({
    query: getConversationQuery,
    variables: {
      id,
    },
    authMode: authModes.AMAZON_COGNITO_USER_POOLS,
  });
  return data.getConversation;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function getConversationsByCustomer({ pageParam, queryKey }) {
  const { user } = queryKey[1];
  const { data } = await API.graphql({
    query: getConversationsByCreator,
    variables: {
      senderType: 'CUSTOMER',
      id: user.sub || user.id,
    },
    authMode: authModes.AMAZON_COGNITO_USER_POOLS,
  });
  return data.getConversationsByCreator;
}

export async function getRequestQuotes({ pageParam = 0, queryKey }) {
  const { request, limit } = queryKey[1];
  const subQuoteIds = request.data.request.quoteIds.slice(
    pageParam,
    pageParam + limit,
  );
  let quotes = await Promise.all(
    subQuoteIds
      .reverse()
      .filter(x => x)
      .map(async id => {
        return await API.graphql({
          query: getQuote,
          variables: {
            id,
          },
        });
      }),
  );
  quotes = quotes.map(quote => quote.data.getQuote);
  return { pageParam, quotes };
}

export async function fetchRequestQuotes({ queryKey }) {
  const { quoteIds } = queryKey[1];

  let quotes = await Promise.all(
    quoteIds
      .filter(x => x)
      .map(async id => {
        return await API.graphql({
          query: getQuote,
          variables: {
            id,
          },
        });
      }),
  );
  quotes = quotes.map(quote => quote.data.getQuote);

  const filteredQuotes = quotes.filter(quote => {
    if (quote && quote.quote) {
      return new Date(quote.quote.expiryDate).getTime() > new Date().getTime();
    }
    return false;
  });

  return filteredQuotes;
}

export async function getRequestQuote({ queryKey }) {
  const { id } = queryKey[1];
  //console.log("getId",id.toLowerCase());

  const data = await API.graphql({
    query: getQuote,
    variables: {
      id,
    },
  });
  //console.log("xxx",data.data.getQuote)
  return data.data.getQuote;
}

// GET ENTITY TYPE QUERIES
export async function getRequest({ queryKey }) {
  const { id, request } = queryKey[1];
  if (request && request.request && request.request.id === id) return request;
  const { data } = await API.graphql({
    query: getRequestQuery,
    variables: {
      id,
    },
    authMode: authModes.AMAZON_COGNITO_USER_POOLS,
  });
  return data.getRequest;
}

export async function getPaymentMethods({ queryKey }) {
  const { user } = queryKey[1];
  const { data } = await API.graphql({
    query: getPaymentMethodsQuery,
    variables: {
      input: {
        creatorType: 'CUSTOMER',
        id: user.id || user.sub,
      },
    },
  });
  return data.getPaymentMethods;
}
