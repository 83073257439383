import React from 'react';
import {
  CircularProgressbar,
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
interface Props {
  value: number;
  step: number;
}

const BannerLoader: React.FC<Props> = ({ value, step }) => {
  return (
    <div
      style={{
        width: 70,
        height: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgressbarWithChildren
        value={value}
        maxValue={1}
        styles={buildStyles({
          // Text size
          textSize: '26px',
          backgroundColor: '#3e98c7',
          pathColor: '#FE6401',
          trailColor: 'rgb(229 231 235)',
        })}
      >
        <p className="font-medium text-sm">{step} of 3</p>
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default React.memo(BannerLoader);
