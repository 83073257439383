import { Route, Routes } from 'react-router-dom';
import withAuthentication from './hocs/with-authentication';
import withoutAuthentication from './hocs/without-authentication';
import Home from './pages/Home';
import Login from './pages/NewLogin';
import Negotiations from './pages/Negotiations';
import NotFound from './pages/NotFound';
import Order from './pages/Order';
import Orders from './pages/Orders';
import Quotes from './pages/Quotes';
import Requests from './pages/Requests';
import Settings from './pages/Settings';
import Terms from './pages/Terms';
import Refund from './pages/Refund';
import { paths } from './utils/constants.js';

const Index = () => {
  return (
    <Routes>
      <Route path={paths.home} element={<Home />} />
      <Route path={paths.login} element={withoutAuthentication(Login)} />
      <Route path={paths.register} element={withoutAuthentication(Login)} />
      <Route path={paths.otp} element={withoutAuthentication(Login)} />
      <Route path={paths.requests} element={withAuthentication(Requests)} />
      <Route path={paths.orders} element={withAuthentication(Orders)} />
      <Route
        path={paths.negotiations}
        element={withAuthentication(Negotiations)}
      />
      <Route
        path={paths.quotes + '/:id'}
        element={withAuthentication(Quotes)}
      />
      <Route path={paths.order + '/:id'} element={withAuthentication(Order)} />
      <Route path={paths.settings} element={withAuthentication(Settings)} />
      <Route path={paths.terms} element={<Terms />} />
      <Route path={paths.refund} element={withAuthentication(Refund)} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Index;
