import { atom } from 'recoil';
import {
  AutoPart,
  createVehicleInput_,
  Customer,
  OrderResponse,
  PaymentProcessors,
  PaymentResponse,
  Photo,
  QuoteResponse,
  RequestResponse,
  updateRequestInput,
  VehicleResponse,
} from '../API';
import {
  CARTITEM,
  EditablePartDetails,
  ICreateRequestError,
  IAutopartList,
} from '../types/interfaces';
import { localPersistEffect } from './atom-effects';

const dangerouslyAllowMutability =
  process.env.REACT_APP_STAGE === 'prod' ? false : true;

/*
 * creatables are entities created when a user is unauthenticated, they should be created when are user finally signs in.
 */
export const CreateRequestErrorResultsAtom = atom<ICreateRequestError[]>({
  key: 'CreateRequestErrorResultsAtom',
  default: [],
  dangerouslyAllowMutability,
  effects_UNSTABLE: [localPersistEffect],
});

//NOTE: methodPayment atom
export const AutopartListAtom = atom<IAutopartList[]>({
  key: 'AutopartListAtom',
  default: [],
  dangerouslyAllowMutability,
  effects_UNSTABLE: [localPersistEffect],
});

export const AutopartSelectedAtom = atom<IAutopartList | null>({
  key: 'AutopartSelectedAtom',
  default: null,
});

export const isVehicleSelectedAtom = atom<boolean>({
  key: 'isVehicleSelectedAtom',
  default: false,
  dangerouslyAllowMutability,
  effects_UNSTABLE: [localPersistEffect],
});

export const partialVehicleAtom = atom<createVehicleInput_ | null>({
  key: 'partialVehicle',
  default: null,
  dangerouslyAllowMutability,
  effects_UNSTABLE: [localPersistEffect],
});

export const vehicleDeletedAtom = atom<boolean>({
  key: 'vehicleDeleted',
  default: false,
});

export const methodPayment = atom<boolean>({
  key: 'methodPayment',
  default: false,
});

//NOTE: methodPayment atom
export const makeValueAtom = atom<string>({
  key: 'makeValue',
  default: '',
});
export const modelValueAtom = atom<string>({
  key: 'modelValue',
  default: '',
});
export const yearValueAtom = atom<number>({
  key: 'yearValue',
  default: new Date().getFullYear() + 1,
});
export const variantValueAtom = atom<string>({
  key: 'variantValue',
  default: '',
});
export const fuelAtom = atom<string>({
  key: 'fuelAtom',
  default: '',
});
export const engineValueAtom = atom<string>({
  key: 'engineValue',
  default: '',
});
export const methodPaymentSelected = atom<string>({
  key: 'methodPaymentSelected',
  default: '',
});

//NOTE: step name atom
export const stepAtom = atom<number>({
  key: 'step-name',
  default: 1,
});

export const requestStepAtom = atom<number>({
  key: 'requestStepAtom',
  default: 1,
  dangerouslyAllowMutability,
  effects_UNSTABLE: [localPersistEffect],
});

//NOTE: auto part name atom
export const autoName = atom<string | null>({
  key: 'auto-part-name',
  default: null,
});

export const phoneNumberAtom = atom<string | null>({
  key: 'phoneNumber.custmomer',
  default: null,
});

//NOTE: new message atom
export const newMessage = atom({
  key: 'incomming-message',
  default: '',
});

//NOTE: check if socket is open
export const openSocket = atom<boolean>({
  key: 'open-socket',
  default: false,
});

//NOTE: set previous uploaded photo when creating a request
export const requestPrevPhotosAtom = atom<Photo[]>({
  key: 'requestPrevPhotosAtom',
  default: [],
});

//NOTE: check if it is a custom request
export const noRequestedPart = atom<boolean>({
  key: 'noRequestedPart',
  default: false,
});

export const disableCheckoutAtom = atom<boolean>({
  key: 'disableCheckout',
  default: false,
});

//NOTE: upload file atom stores the on hand file to be uploaded
export const requestUploadPhotosAtom = atom<string[]>({
  key: 'requestUploadPhotos',
  default: [],
});

//NOTE: url for selected photo when creating a request
export const requestPhotoPreview = atom({
  key: 'requestPhotoPreview',
  default: '',
});

//NOTE: searching array of vehicles
export const searchVehicle = atom({
  key: 'vehicle-search',
  default: [],
});

//NOTE: selected vehicle state
export const selectedVehicle = atom<VehicleResponse | null>({
  key: 'V-selected',
  default: null,
  dangerouslyAllowMutability,
  effects_UNSTABLE: [localPersistEffect],
});

export const refetchVehiclesAtom = atom<boolean>({
  key: 'refetch-vehciles',
  default: false,
});
//NOTE: checks/set if create vehicle form is open or not
export const openCreateVehicle = atom<boolean>({
  key: 'vehicle-form',
  default: false,
});

//NOTE: checks for vehicle is list if open
export const openCar = atom<boolean>({
  key: 'cars-open',
  default: false,
});

//NOTE: list of created vehicles
export const vehicleCreatablesAtom = atom({
  key: 'vehicle-creatables',
  default: [],
  effects_UNSTABLE: [localPersistEffect],
});

//NOTE : check/sets if user has signed up
export const incognitoSignUpAtom = atom<boolean>({
  key: 'incognito-sign-up',
  default: false,
});

//NOTE: checks/sets the image model if open
export const modalCarouselStateAtom = atom<boolean>({
  key: 'modal-carousel-state',
  default: false,
});

//NOTE: carousel images state
export const modalCarouselChildrenAtom = atom({
  key: 'image-modal-carousel-children',
  default: null,
});

//NOTE: checks/sets the payment model if open
export const modalPaymentStateAtom = atom<boolean>({
  key: 'modal-payment-state',
  default: false,
});

//NOTE: messages list state
export const messageStateAtom = atom({
  key: 'messageState',
  default: [],
  effects_UNSTABLE: [localPersistEffect],
  dangerouslyAllowMutability,
});

//NOTE: state for checking and the chat on negotiation
export const chatAtom = atom({
  key: 'chatState',
  default: {
    selectedChat: null,
    notification: [],
    chats: null,
  },
  effects_UNSTABLE: [localPersistEffect],
  dangerouslyAllowMutability,
});

//NOTE: requests list on request page
export const requestsAtom = atom({
  key: 'requests-101-9',
  default: [],
  effects_UNSTABLE: [],
});

//NOTE: show if user has more requests when reaches the bottom page
export const requestsNextTokenAtom = atom({
  key: 'requests-next-token',
  default: null,
});

export const requestsHasMoreAtom = atom({
  key: 'requests-101',
  default: false,
});

export const homeRequestsAtom = atom({
  key: 'home-requests',
  default: [],
  effects_UNSTABLE: [],
});

export const homeRequestsTokenAtom = atom({
  key: 'home-requests-token-1',
  default: null,
  effects_UNSTABLE: [],
});
export const homeRequestsHasMoreAtom = atom({
  key: 'home-requests-has-more',
  default: false,
  effects_UNSTABLE: [],
});

export const homeQuotesAtom = atom({
  key: 'home-quotes',
  default: [],
  effects_UNSTABLE: [],
});

export const homeQuotesTokenAtom = atom({
  key: 'home-quotes-token-1',
  default: null,
  effects_UNSTABLE: [],
});
export const homeQuotesHasMoreAtom = atom({
  key: 'home-quotes-has-more',
  default: false,
  effects_UNSTABLE: [],
});

export const homeOrdersAtom = atom({
  key: 'home-orders',
  default: [],
  effects_UNSTABLE: [],
});

export const homeOrdersTokenAtom = atom({
  key: 'home-orders-token-1',
  default: null,
  effects_UNSTABLE: [],
});
export const homeOrdersHasMoreAtom = atom({
  key: 'home-orders-has-more',
  default: false,
  effects_UNSTABLE: [],
});

export const selectedRequestIndexAtom = atom({
  key: 'selectedRequestIndexAtom',
  default: 0,
  effects_UNSTABLE: [localPersistEffect],
});

//NOTE: selected request state
export const selectedRequestAtom = atom<RequestResponse | null>({
  key: 'selected-request',
  default: null,
  effects_UNSTABLE: [
    // localPersistEffect
  ],
});

export const partUpdateAtom = atom<EditablePartDetails>({
  key: 'part-update-atom',
  default: {
    name: '',
    description: '',
    partNumber: '',
  },
});

export const updateRequestInputAtom = atom<updateRequestInput>({
  key: 'eupdate-request-input',
  default: { id: '' },
});

//NOTE: order item state
export const orderAtom = atom<OrderResponse | null>({
  key: 'selected-order',
  default: null,
});

//NOTE: after checkout set payment
export const paymentAtom = atom<PaymentResponse | null>({
  key: 'payment',
  default: null,
});

//NOTE: order items state
export const ordersAtom = atom<OrderResponse[]>({
  key: 'orders',
  default: [],
});

export const ordersHasMoreAtom = atom({
  key: 'orders-has-more-atom',
  default: false,
});
export const ordersNextTokenAtom = atom({
  key: 'orders-next-token',
  default: null,
});

export const ordersFilterAtom = atom({
  key: 'orders-filter',
  default: 'all',
});

export const ordersDateRangeAtom = atom({
  key: 'orders-date-range',
  default: {
    start: null,
    end: null,
  },
});

export const autoPartsAtom = atom({
  key: 'auto-parts-list',
  default: [],
});

export const autoPartsSearchKeyAtom = atom({
  key: 'autoparts-search-key',
  default: '',
  effects_UNSTABLE: [localPersistEffect],
});

export const autopartSearchFiltersAtom = atom({
  key: 'autoparts-filters',
  default: null,
});

export const autoPartsSearchCarMakeFilterAtom = atom({
  key: 'autoparts-search-filter-car-make',
  default: '',
});

export const autoPartsSearchCarModelFilterAtom = atom({
  key: 'autoparts-search-filter-car-model',
  default: '',
});

export const autoPartsSearchCategoriesAtom = atom({
  key: 'autoparts-search-categores',
  default: [],
  effects_UNSTABLE: [localPersistEffect],
});

//NOTE: see active subcategory when user selects
export const activeSubCategoryAtom = atom<number>({
  key: 'active-sub-category',
  default: -1,
});

export const autoPartsSearchHasMoreAtom = atom({
  key: 'autoparts-search-has-more',
  default: false,
  effects_UNSTABLE: [],
});

export const shouldDeliverAtom = atom({
  key: 'shouldDeliver',
  default: false,
  effects_UNSTABLE: [localPersistEffect],
});

export const paymentProcessorAtom = atom({
  key: 'paymentProcessor',
  default: PaymentProcessors.MTN,
});

//NOTE check/set if user is authenticated
export const isAuthenticatedAtom = atom<boolean>({
  key: 'isAuthenticated',
  default: false,
  effects_UNSTABLE: [localPersistEffect],
});

//NOTE:check/set otp after signup and login
export const OTPPhoneNumberAtom = atom<string>({
  key: 'OTPPhoneNumber',
  default: '',
  effects_UNSTABLE: [localPersistEffect],
  dangerouslyAllowMutability,
});

//NOTE: sets the conversation the user wants to join
export const selectedConversationAtom = atom({
  key: 'selectedConversation',
  default: null,
});

//NOTE: state sending a message
export const postRenderMessagesAtom = atom({
  key: 'post-render-messages',
  default: [],
});

//NOTE: last message in a selected conversation
export const lastMessageAtom = atom({
  key: 'last-message',
  default: null,
});

//NOTE: sets partners details
export const selectedConversationPartnerAtom = atom({
  key: 'selected-conversation-partner',
  default: {},
  effects_UNSTABLE: [],
});

//NOTE set opening message
export const negotiationOpeningMessageAtom = atom({
  key: 'negotiation-opening-message',
  default: null,
  effects_UNSTABLE: [],
});

//NOTE: set chat drawer
export const negotiationsDrawerIsOpenedStateAtom = atom({
  key: 'negotiations-drawer-is-opened-state',
  default: false,
  effects_UNSTABLE: [],
});

//NOTE: shows a list of chat with partners
export const conversationsAtom = atom({
  key: 'conversations',
  default: [],
  dangerouslyAllowMutability,
  effects_UNSTABLE: [localPersistEffect],
});

//NOTE state for signed in user
export const cognitoUserAtom = atom<Customer | null>({
  key: 'cognitoUser',
  default: null,
  dangerouslyAllowMutability,
  effects_UNSTABLE: [localPersistEffect],
});

export const momoPhoneNumberAtom = atom<string | undefined>({
  key: 'momoPhoneNumber',
  default: undefined,
  dangerouslyAllowMutability,
});

export const carDetailsAtom = atom({
  key: 'carDetails',
  default: {
    make: '',
    model: '',
    year: 2012,
    fuel: '',
    gearbox: 'Automatic',
    VIN: '',
    engineNumber: '',
    photos: [],
  },
  effects_UNSTABLE: [localPersistEffect],
  dangerouslyAllowMutability,
});

export const deleteSectedPartIdAtom = atom({
  key: 'deleteSectedPartId',
  default: '',
  effects_UNSTABLE: [localPersistEffect],
  dangerouslyAllowMutability,
});

export const showAutoPartDrawerAtom = atom({
  key: 'show-autopart-drawer',
  default: false,
});

//NOTE: checks/ sets selected autopart before request creation
export const selectedAutopartAtom = atom<AutoPart | null>({
  key: 'selected-autopart',
  default: null,
});

//NOTE: autopart quantity when creating request and editing request
export const selectedAutopartQuantityAtom = atom<number>({
  key: 'selected-autopart-quantity',
  default: 1,
});

export const selectedAutoPartsAtom = atom({
  key: 'selected-autopart-de',
  default: null,
});

export const userContactDetailsAtom = atom({
  key: 'userContactDetails',
  default: {
    firstName: '',
    familyName: '',
    phoneNumber: '',
    email: '',
    id: '',
  },
  effects_UNSTABLE: [localPersistEffect],
  dangerouslyAllowMutability,
});

export const userAgreementAtom = atom({
  key: 'userAgreement',
  default: {
    termsAndConditions: null,
    privacyPolicy: null,
  },
});

//NOTE: set uploaded photo
export const uploadedPhotosAtom = atom({
  key: 'uploadedPhotos',
  default: [],
});

//NOTE: sets cart items
export const cartAtom = atom<CARTITEM[]>({
  key: 'cart',
  default: [],
  /* {
      quoteId: '',
      partName: '',
      quantity: '',
      price: '',
      currency: "",
      imageUrl: "",
      partnerId: "",
      partnerName: "",
    }, */
  effects_UNSTABLE: [localPersistEffect],
  dangerouslyAllowMutability,
});

//NOTE: checks if user has opened the cart
export const cartIsOpenAtom = atom<boolean>({
  key: 'cart-is-open',
  default: false,
});

//NOTE: checks/sets drawer if opened
export const requestDrawerIsOpenAtom = atom<boolean>({
  key: 'request-drawer-is-open',
  default: false,
});

//NOTE: checks/sets if user has opened the cart
export const cartOpenedAtom = atom<boolean>({
  key: 'cart-opened',
  default: false,
});

//NOTE: checks/sets if user has opened the payment drawer is opened
export const showPaymentPopUpAtom = atom<boolean>({
  key: 'show-payment-pop',
  default: false,
  effects_UNSTABLE: [localPersistEffect],
});

export const activeSettingsTabAtom = atom({
  key: 'active-settings-tab',
  default: 'personal',
  effects_UNSTABLE: [],
});

export const activePaymentsTabAtom = atom({
  key: 'active-payments-tab',
  default: 'bank',
  effects_UNSTABLE: [],
});

export const creditCardsAtom = atom({
  key: 'credit-cards',
  default: null,
  effects_UNSTABLE: [],
});

export const mobileMoneyAccountsAtom = atom({
  key: 'mobile-money-accounts',
  default: null,
  effects_UNSTABLE: [],
});

//NOTE: sets the active quote
export const quoteAtom = atom<QuoteResponse | null>({
  key: 'quote',
  default: null,
  effects_UNSTABLE: [],
});
