/* eslint-disable react-hooks/exhaustive-deps */
import {
  HStack,
  SimpleGrid,
  Tab,
  TabList,
  Tabs,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { Fragment, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { searchOrders } from '../api/queries';
import Order from '../components/cards/order';
import Main from '../components/layouts/Main';
import Skeletons from '../components/loaders/skeletons';
import { cognitoUserAtom } from '../recoil/atoms';
import { OrderResponse, Status } from '../API';
import PaymentMethodModal from '../components/paymentMethod/PaymentMethodModal';
import PromoCard from '../components/cards/promo';
import { useLocation } from 'react-router-dom';
import { getPinpointButtonClickEventProp } from '../utils/functions-ts';

//ANCHOR: initialize limit range for orders
const limit = 10;

//ANCHOR: orders component
const Orders: React.FC = (): JSX.Element => {
  //ANCHOR: initialize component state
  const user = useRecoilValue(cognitoUserAtom);
  const [status, setStatus] = useState('all');
  const { pathname } = useLocation();
  const { ref, inView } = useInView();

  //ANCHOR: initialize and destructure properties from use-infinite-query
  const { isLoading, data, hasNextPage, fetchNextPage } = useInfiniteQuery(
    ['orders', { status, user, limit }],
    searchOrders,
    {
      getNextPageParam: previousPage =>
        previousPage ? previousPage.nextToken : null,
      cacheTime: Infinity,
      refetchInterval: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  console.log(status);

  //ANCHOR: if user reaches the bottom page fetch more orders
  useEffect(() => {
    // fetch more orders whenever the last autopart comes into view;
    inView && hasNextPage && fetchNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, hasNextPage]);

  //ANCHOR: initialize style system responsive values
  const noOrderBG = useColorModeValue('gray.100', 'gray.700');
  const noOrdersColor = useColorModeValue('gray.600', 'gray.300');

  const tabProps = {
    py: 1,
    px: 2,
    borderRadius: '16px',
    _focus: {
      border: 'none',
    },
    color: useColorModeValue('gray.600', 'gray.200'),
    bg: useColorModeValue('white', 'gray.700'),
    cursor: 'pointer',
    _selected: {
      bg: 'brand.400',
      color: 'gray.800',
      fontWeight: 'bold',
    },
    fontWeight: 'normal',
    fontSize: '10px',
  };

  return (
    <Main>
      <PromoCard />
      <Text
        fontSize={'xl'}
        fontWeight={'bold'}
        color={useColorModeValue('gray.900', 'gray.50')}
      >
        {' '}
        My Orders{' '}
      </Text>

      <Tabs
        variant={'unstyled'}
        size={'sm'}
        width={'full'}
        overflowX={'scroll'}
        className={'no-scroll'}
      >
        <TabList
          as={HStack}
          overflow={'scroll'}
          width={'full'}
          p={'1px'}
          className={'no-scroll'}
        >
          <Tab
            {...tabProps}
            {...getPinpointButtonClickEventProp({
              command: 'get all order',
              currentPage: pathname,
            })}
            onClick={() => setStatus('all')}
          >
            {' '}
            all{' '}
          </Tab>

          <Tab
            {...tabProps}
            {...getPinpointButtonClickEventProp({
              command: 'get all closed orders',
              currentPage: pathname,
            })}
            onClick={() => setStatus(Status.CLOSED)}
          >
            {' '}
            completed{' '}
          </Tab>

          <Tab
            {...tabProps}
            {...getPinpointButtonClickEventProp({
              command: 'get all orders in progress',
              currentPage: pathname,
            })}
            onClick={() => setStatus(Status.IN_PROGRESS)}
          >
            {' '}
            delivering{' '}
          </Tab>

          <Tab
            {...tabProps}
            minW={'90px'}
            {...getPinpointButtonClickEventProp({
              command: 'get all open orders',
              currentPage: pathname,
            })}
            onClick={() => setStatus(Status.OPEN)}
          >
            {' '}
            awaiting payment{' '}
          </Tab>
        </TabList>
      </Tabs>

      <SimpleGrid
        columns={{
          base: 1,
          sm: 3,
          md: 4,
        }}
        spacing={4}
        width={'full'}
      >
        {data &&
          data.pages &&
          data.pages.map((page, pageIndex) => {
            return (
              <Fragment key={pageIndex}>
                {page &&
                  page.orders
                    .map((order: OrderResponse, orderIndex: number) => {
                      return (
                        <Order
                          key={order.order.id}
                          order={order}
                          ref={
                            pageIndex === data.pages.length - 1 &&
                            orderIndex + 1 === page.orders.length
                              ? ref
                              : null
                          }
                        />
                      );
                    })
                    .reverse()}
              </Fragment>
            );
          })}

        {!isLoading &&
          data &&
          (!data.pages.length || data.pages[0].orders.length < 1) && (
            <VStack
              spacing={2}
              p={4}
              py={12}
              borderRadius={'8px'}
              bg={noOrderBG}
              color={noOrdersColor}
              width={'full'}
            >
              <Text>
                {' '}
                {status === 'all'
                  ? 'You have not made any orders yet.'
                  : 'No orders found for your search criteria.'}{' '}
              </Text>
            </VStack>
          )}

        {isLoading && (
          <Skeletons height={'132px'} borderRadius={'4px'} count={10} />
        )}
      </SimpleGrid>

      <PaymentMethodModal />
    </Main>
  );
};

export default Orders;

/*


 <Main>
      <Stack
        spacing={5}
        maxW={"xl"}
        width={"full"}
      >
        <Heading
          width={"full"}
          textAlign={"center"}
        > Your Orders </Heading>
        <HStack
          px={4}
          width={"full"}
        >
          <InputGroup
            bg={useColorModeValue("white", "gray.700")}
            _focus={{
              bg: useColorModeValue("white", "gray.700")
            }}
            _hover={{
              bg: useColorModeValue("white", "gray.700")
            }}
            borderRadius={"md"}
          >
            <InputLeftAddon
              bg={useColorModeValue("white", "gray.700")}
              _focus={{
                bg: useColorModeValue("white", "gray.700")
              }}
              _hover={{
                bg: useColorModeValue("white", "gray.700")
              }}
              border={"none"}
              borderRadius={"md"}
            >
              <Icon as={FilterIcon} />
            </InputLeftAddon>
            <Select
              value={filter}
              onChange={
                e => setFilter(e.target.value)
              }
              bg={useColorModeValue("white", "gray.700")}
              _focus={{
                bg: useColorModeValue("white", "gray.700")
              }}
              _hover={{
                bg: useColorModeValue("white", "gray.700")
              }}
              placeholder={"Filter"}
              variant={"filled"}
            >
              <option value={"all"}> All </option>
              <option value={"open"}> Open </option>
              <option value={"closed"}> Closed </option>
              <option value={"in_progress"}> In Progress </option>
            </Select>
          </InputGroup>
          <Spacer />
          <DateRange
            submit={
              changeDateRange
            }
            triggerProps={{
              bg: useColorModeValue("white", "gray.700"),
              _hover: {
                bg: useColorModeValue("white", "gray.700")
              },
              _focus: {
                bg: useColorModeValue("white", "gray.700")
              }
            }}
          />
          </HStack>
          <VStack
            width={"full"}
            spacing={4}
          >
            {
              orders.map((order,i) => {
                return (
                  <Order
                    key={order.order.id}
                    ref={i === orders.length-1 ? lastOrderElementRef : undefined}
                    { ...order }
                  />
                )
              })
            }

            {
              !isLoading && !hasError && orders.length < 1 && (
                filter === "all" && (!dateRange.start || !dateRange.end) ?
                  <SearchBox
                    displayHeader
                    heading={"You have not made any orders yet!\nCreate a request ?"}
                  />
                  :
                  <Stack
                    spacing={3}
                    width={"full"}
                    py={10}
                    px={3}
                    bg={bg}
                    borderRadius={"lg"}
                    boxShadow={"sm"}
                  >
                    <Text
                      textAlign={"center"}
                      width={'full'}
                      fontWeight={"semibold"}
                    > Could not find quotes with your search criteria </Text>
                  </Stack>
              )
            }

            {
              hasError && (
                <ErrorAlert
                  description={"Encountered an error while fetching your orders"}
                  again={
                    () => setToken(token => token ? { ...token } : null)
                  }
                />
              )
            }
          {
            isLoading && Array(limit).fill(null).map((skeleton,i) => {
              return (
                <Skeleton
                  key={i}
                  width={"full"}
                  height={40}
                  borderRadius={"lg"}
                />
              )
            })
          }
          </VStack>
      </Stack>

  
*/

/*
export default function Orders({ small }) {
  const user = useRecoilValue(cognitoUserAtom);
  const [orders, setOrders] = useRecoilState(ordersAtom);
  const bg = useColorModeValue('white', 'gray.800');

  useEffect(() => {}, [user]);

  if (!orders) return <SearchBox heading="Oops, no orders yet" />;

  return (
    <Section>
      {orders.length > 0 && (
        <Box
          width={small ? 'full' : { md: '4xl', base: 'full' }}
          bg={bg}
          rounded={small ? 'sm' : 'lg'}
          p={small ? 1 : 6}
          textAlign={'center'}
        >
          <Heading
            fontSize={small ? 'xs' : '2xl'}
            fontFamily={'body'}
            my={small ? 0 : 3}
          >
            <Text width={small ? 'sm' : 'full'} textAlign={'center'}>
              Orders
            </Text>
          </Heading>
        </Box>
      )}
      <Suspense
        fallback={
          <Stack spacing={small ? 0.5 : 5}>
            <Skeleton
              borderRadius={small ? 'xs' : '2xl'}
              height={small ? '50px' : '200px'}
            />
            <Skeleton
              borderRadius={small ? 'xs' : '2xl'}
              height={small ? '50px' : '200px'}
            />
            <Skeleton
              borderRadius={small ? 'xs' : '2xl'}
              height={small ? '50px' : '200px'}
            />
          </Stack>
        }
      >
        {orders === null ? (
          <SearchBox heading="Oops, no orders yet" />
        ) : orders.length === 0 ? (
          <SearchBox heading="Oops, no orders yet" />
        ) : (
          <Accordion
            allowToggle={true}
            margin={2}
            allowMultiple
            width={small ? 'full' : { md: '4xl', base: 'full' }}
            maxHeight={small ? '350px' : 'auto'}
            overflowY={'scroll'}
          >
            {orders.map(
              ({ id, model, created, fuel, gearbox, make, status, year }) => (
                <AccordionItem key={id}>
                  <AccordionButton>
                    <RequestHeader
                      model={model}
                      created={created}
                      fuel={fuel}
                      gearbox={gearbox}
                      make={make}
                      status={status}
                      year={year}
                      isHorizontal={true}
                      small={small}
                    />
                  </AccordionButton>

                  <AccordionPanel>
                    <RequestDetails
                      displayHeader={false}
                      displayBorder={false}
                      id={id}
                    />
                  </AccordionPanel>
                </AccordionItem>
              )
            )}
          </Accordion>
        )}
      </Suspense>
    </Section>
  );
}
*/
