import { ScaleFade, VStack } from '@chakra-ui/react';

export default function Main({ children, ...rest }) {
  return (
    <VStack
      as={ScaleFade}
      in={true}
      role={'main'}
      width={'full'}
      flexDir={'column'}
      alignItems={'start'}
      justifyContent={'start'}
      alignSelf={'center'}
      minH={'100vh'}
      p={{
        base: 5,
        sm: 8,
        md: 12,
      }}
      pb={'120px'}
      spacing={4}
      {...rest}
    >
      {children}
    </VStack>
  );
}
