import Skeletons from '../../loaders/skeletons';

const Loading: React.FC = (): JSX.Element => {
  return (
    <>
      <Skeletons count={5} height={'100px'} borderRadius={'xs'} />
    </>
  );
};

export default Loading;
