import {
  HStack,
  Image,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';

import { RequestResponse } from '../../../API';
import EditRequest from '../../cards/request/edit-request';
import Carousel from '../../carousel';
import ImageContainer from '../../carousel/image-container';

interface Params {
  requestSelected: RequestResponse;
}

const Content: React.FC<Params> = ({ requestSelected }): JSX.Element => {
  const { autoPart, request } = requestSelected;

  return (
    <>
      <HStack width={'full'} alignItems={'start'}>
        <Carousel
          width={'94px'}
          height={'74px'}
          minW={'94px'}
          minH={'74px'}
          automatic={true}
          time={5000}
          displayButtons={false}
        >
          {autoPart.autoPart.src?.map((imageURL: string | null) => {
            return (
              <ImageContainer
                width={'full'}
                height={'full'}
                borderRadius={'4px'}
                key={imageURL}
              >
                <Image
                  src={imageURL || undefined}
                  width={'full'}
                  height={'full'}
                  borderRadius={'inherit'}
                />
              </ImageContainer>
            );
          })}
        </Carousel>
        <VStack
          width={'full'}
          justifyContent={'flex-start'}
          align={'start'}
          spacing={'1px'}
        >
          <Text
            fontWeight={'medium'}
            fontSize={'sm'}
            textTransform={'capitalize'}
          >
            {' '}
            {autoPart.autoPart.name}{' '}
          </Text>
          <Text
            textTransform={'capitalize'}
            color={useColorModeValue('gray.800', 'gray.300')}
            fontSize={'sm'}
          >
            {' '}
            {autoPart.autoPart.OEM === '-' ? '' : autoPart.autoPart.OEM}{' '}
          </Text>
        </VStack>
      </HStack>

      <EditRequest request={request} />
    </>
  );
};

export default Content;
