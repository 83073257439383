import {
  Avatar,
  HStack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { forwardRef, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import {
  postRenderMessagesAtom,
  selectedConversationAtom,
  openSocket,
} from '../../../recoil/atoms';
import { getPartnerFromConversationMembers } from '../../../utils/functions';

export default forwardRef(function ({ conversation, ...rest }, ref) {
  const { members } = conversation;

  const setSelectedConversation = useSetRecoilState(selectedConversationAtom);
  const setPostRenderMessages = useSetRecoilState(postRenderMessagesAtom);
  const setOpenSocket = useSetRecoilState(openSocket);
  const partner = useMemo(() => getPartnerFromConversationMembers(members), []);
  const [searchParams, setSearchParams] = useSearchParams();
  const isSelected = searchParams.get('conversation') === conversation.id;

  const shouldReplaceHistoryState = useBreakpointValue({
    base: false,
    md: true,
  });

  const open = () => {
    setSelectedConversation({
      conversation,
      partner: partner.id,
    });
    setOpenSocket(true);
    setPostRenderMessages([]);
    setSearchParams(
      { conversation: conversation.id },
      { replace: shouldReplaceHistoryState },
    );
  };

  return (
    <HStack
      cursor="pointer"
      width={'full'}
      spacing={3}
      p={4}
      borderRadius={'12px'}
      bg={useColorModeValue('white', 'gray.700')}
      ref={ref}
      boxShadow={isSelected ? '2xl' : 'sm'}
      borderWidth={isSelected ? '5px' : '0px'}
      borderColor={'brand-blue.300'}
      onClick={open}
      {...rest}
    >
      <Avatar width={'44px'} height={'44px'} src={partner.imageS3Name} />
      <VStack
        spacing={0}
        width={'full'}
        alignItems={'start'}
        justifyContent={'flex-start'}
      >
        <Text
          fontSize={'sm'}
          fontWeight={'semibold'}
          color={useColorModeValue('gray.900', 'white')}
        >
          {' '}
          {partner.name}{' '}
        </Text>
        <Text fontSize={'xs'} color={'green.300'}>
          {' '}
          online{' '}
        </Text>
      </VStack>
    </HStack>
  );
});
