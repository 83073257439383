import {
  Avatar,
  Flex,
  ScaleFade,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { forwardRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Link from '../../../../../components/buttons/link';
import { selectedConversationAtom } from '../../../../../recoil/atoms';
import {
  formatPrice,
  getPartnerFromConversationMembers,
} from '../../../../../utils/functions';

export default forwardRef(function (
  { message, isLastMessageBySenderInSubMessageList, ...rest },
  ref,
) {
  const conversation = useRecoilValue(selectedConversationAtom);
  const partner = useMemo(() =>
    getPartnerFromConversationMembers(conversation.conversation.members),
  );
  const { data } = message;
  const quantity =
    data &&
    data.quote &&
    data.quote.request &&
    data.quote.request.autoPart.quantity;

  const location = useLocation();

  const bg = useColorModeValue('gray.50', 'gray.800');
  const borderColor = useColorModeValue('gray.100', 'brand-blue.900');

  return (
    <Flex width={'full'} pr={10} ref={ref} {...rest}>
      <Flex minH={'full'} width={'13px'} align={'end'}>
        {isLastMessageBySenderInSubMessageList && (
          <Flex
            align={'center'}
            justify={'center'}
            p={1}
            transform={'translate(0px,12px)'}
            rounded={'full'}
            bg={bg}
            borderWidth={'1px'}
            borderColor={borderColor}
          >
            <Avatar width={'20px'} height={'20px'} src={partner.imageS3Name} />
          </Flex>
        )}
      </Flex>

      <VStack
        as={ScaleFade}
        in={true}
        maxW={{ base: '90%', md: '75%' }}
        borderTopRadius={'12px'}
        borderBottomRightRadius={'24px'}
        borderTopLeftRadius={'24px'}
        borderBottomLeftRadius={'0px'}
        bg={bg}
        borderWidth={'1px'}
        borderColor={borderColor}
        p={4}
      >
        <Text fontSize={'xs'} color={useColorModeValue('gray.800', 'gray.300')}>
          The supplier has declined your proposal of{' '}
          <Text as={'b'} color={useColorModeValue('gray.700', 'gray.300')}>
            {formatPrice(data && data.declinedAmount)}
          </Text>{' '}
          {quantity > 1 ? 'for each' : 'for this'} spare part.
          <br />
          {quantity > 1 && (
            <Text as={'span'}>
              Total Amount:{' '}
              <Text as={'span'} fontWeight={'black'}>
                {formatPrice(data.declinedAmount * quantity)}
              </Text>{' '}
            </Text>
          )}
        </Text>
      </VStack>
      <Spacer />
    </Flex>
  );
});

/*
{quantity > 1 && (
            <Text
              as={"span"}
            > Coming to a total of: <Text as={"span"} fontWeight={"black"}>{formatPrice((data && data.quote && data.quote.autoPart && data.quote.autoPart.quotedPrice) * quantity)}</Text> </Text>
          )}

*/
