import { TimeIcon } from '@chakra-ui/icons';
import { Box, Text, useColorModeValue, HStack } from '@chakra-ui/react';
import moment from 'moment';

interface MOMENTSAGO {
  children: any;
  date: string;
  [key: string]: any;
}

export const MomentsAgo: React.FC<Partial<MOMENTSAGO>> = ({
  children,
  date,
  ...rest
}): JSX.Element => {
  return (
    <HStack spacing={2} p={0} align={'center'} justify={'center'}>
      <TimeIcon w={3} h={3} />
      <Text
        fontSize={'12px'}
        fontWeight={'medium'}
        color={useColorModeValue('gray.600', 'gray.200')}
      >
        {' '}
        {moment(date).fromNow(true)}{' '}
      </Text>
    </HStack>
  );
};

export const ScrollFade = ({ ...props }) => {
  return (
    <Box
      display={{
        base: 'block',
      }}
      padding={'50px'}
      backgroundImage={useColorModeValue(
        'linear-gradient(to bottom, transparent, white)',
        'linear-gradient(to bottom, transparent, #1A202C)',
      )}
      width={'full'}
      position={'absolute'}
      left={0}
      zIndex={2}
      marginTop={'-100px'}
      {...props}
    />
  );
};
