import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { modalPaymentStateAtom } from '../../recoil/atoms';
import PaymentContent from './content';

/**
 *
 * @returns The payment modal when the recoil state is set to true
 */
const PaymentModal: React.FC = (): JSX.Element => {
  //ANCHOR: initialize component state
  const [open, setOpen] = useRecoilState(modalPaymentStateAtom);

  /**
   * @returns close function of the payment modal
   */

  const close = () => setOpen(false);

  return (
    <Modal isOpen={open} onClose={close}>
      <ModalOverlay />
      <ModalContent maxW={'90vw'}>
        <ModalHeader> Pay Now </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <PaymentContent />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PaymentModal;
