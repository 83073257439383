import { CloseIcon, Icon } from '@chakra-ui/icons';
import {
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalBody,
  Box,
  useBreakpointValue,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  TabPanels,
  Text,
  Button,
  HStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import PhotoUpload from '../../photos/upload';
import PhotoList from '../../photos/list';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  requestPrevPhotosAtom,
  requestUploadPhotosAtom,
  isAuthenticatedAtom,
} from '../../../recoil/atoms';
import { Photo } from '../../../API';
import { getPinpointButtonClickEventProp } from '../../../utils/functions-ts';
import { useLocation } from 'react-router-dom';

interface Params {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  heading: string;
  max: number;
}

const PhotoSelect: React.FC<Params> = ({
  isOpen,
  onOpen,
  onClose,
  heading = 'Images',
  max = 10,
}): JSX.Element => {
  const [, setTabIndex] = useState(0);
  const [requestPrevPhotos, setRequestPrevPhotos] = useRecoilState(
    requestPrevPhotosAtom,
  );
  const [requestUploadPhotos, setRequestUploadPhotos] = useRecoilState(
    requestUploadPhotosAtom,
  );
  const isAuthenticated = useRecoilValue(isAuthenticatedAtom);
  const { pathname } = useLocation();

  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={useBreakpointValue({
          base: 'full',
          md: '2xl',
          lg: '3xl',
        })}
        scrollBehavior={'inside'}
        isCentered
        blockScrollOnMount
        closeOnEsc
        motionPreset={useBreakpointValue({
          base: 'slideInBottom',
          md: 'scale',
        })}
      >
        <ModalOverlay
          bg="blackAlpha.500"
          backdropFilter="auto"
          backdropBlur="2px"
        />
        <ModalContent
          height={{
            base: '90vh',
            md: 'auto',
          }}
          p={{
            base: 0,
            md: 4,
          }}
        >
          <ModalHeader display={'flex'} alignItems={'center'}>
            <Text width={'full'}> Upload Part Image </Text>
            <Button
              data-btn="close-photo-upload"
              {...getPinpointButtonClickEventProp({
                command: 'Upload Image Modal Close',
                currentPage: pathname,
              })}
              as={ModalCloseButton}
            >
              <Icon as={CloseIcon} />
            </Button>
          </ModalHeader>

          <ModalBody>
            <Tabs
              isLazy
              lazyBehavior="keepMounted"
              variant={'soft-rounded'}
              onChange={(index: any) => setTabIndex(index)}
            >
              <TabList spacing={2} as={HStack}>
                <Tab
                  as={Button}
                  size={'xs'}
                  fontSize={'xs'}
                  _focus={{
                    outline: 'none',
                  }}
                  {...getPinpointButtonClickEventProp({
                    command: 'Upload New Images Tab',
                    currentPage: pathname,
                  })}
                >
                  {' '}
                  Upload Image{' '}
                </Tab>
                {isAuthenticated && (
                  <Tab
                    as={Button}
                    size={'xs'}
                    fontSize={'xs'}
                    _focus={{
                      outline: 'none',
                    }}
                    data-btn="previous-uploaded-photo"
                    {...getPinpointButtonClickEventProp({
                      command: 'Upload Previous Images Tab',
                      currentPage: pathname,
                    })}
                  >
                    {' '}
                    Previous Uploads{' '}
                  </Tab>
                )}
              </TabList>
              <TabPanels>
                <TabPanel>
                  <PhotoUpload
                    onClose={() => onClose()}
                    callback={(data: string[]) => {
                      setRequestUploadPhotos([...requestUploadPhotos, ...data]);
                    }}
                    data-btn="previous-uploaded-photo"
                    {...getPinpointButtonClickEventProp({
                      command: 'Add new photo to upload list',
                      currentPage: pathname,
                    })}
                    max={1}
                  />
                </TabPanel>

                <TabPanel>
                  <PhotoList
                    onClose={onClose}
                    onSelect={(data: Photo[]) => {
                      setRequestPrevPhotos([...requestPrevPhotos, ...data]);
                      //onClose();
                    }}
                    {...getPinpointButtonClickEventProp({
                      command: 'Add old photo to list',
                      currentPage: pathname,
                    })}
                    max={max}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PhotoSelect;
