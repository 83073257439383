import {
  Box,
  Button,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { MdCreditCard, MdPhoneIphone } from 'react-icons/md';
import CreditCards from './credit-card';
import MobileMoneyAccounts from './mobile-money';

export default function PaymentsInfo() {
  return (
    <Box>
      <Tabs
        isLazy
        lazyBehavior="keepMounted"
        display={'flex'}
        flexDir={{
          base: 'column',
          sm: 'row',
        }}
        alignItems={'start'}
        justifyContent={'start'}
        variant={'soft-rounded'}
        colorScheme={'green'}
        p={0}
      >
        <TabList
          flexDir={'column'}
          p={0}
          width={{
            base: 'full',
            sm: 'auto',
          }}
        >
          <Tab
            size={'sm'}
            width={'full'}
            as={Button}
            m={0}
            mb={3}
            _focus={{
              border: '0',
              fontWeight: 'bold',
              // border: "none"
            }}
            justifyContent={'space-between'}
            rightIcon={<Icon as={MdPhoneIphone} />}
          >
            {' '}
            Mobile Money{' '}
          </Tab>
          <Tab
            size={'sm'}
            width={'full'}
            as={Button}
            mb={3}
            _focus={{
              border: '0',
              fontWeight: 'bold',
              // border: "none"
            }}
            justifyContent={'space-between'}
            rightIcon={<Icon as={MdCreditCard} />}
          >
            {' '}
            Credit Cards{' '}
          </Tab>
        </TabList>
        <TabPanels m={0} p={0}>
          <TabPanel m={0} p={0}>
            <MobileMoneyAccounts />
          </TabPanel>
          <TabPanel m={0} p={0}>
            <CreditCards />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

/*


<Box
            width={"full"}
            as={Flex}
            alignItems={"start"}
            spacing={3}
            flexWrap={{
            base: "wrap",
            md: "nowrap"
            }}
        >
             <Tabs
                    variant={"soft-rounded"}
                    my={5}
                    p={4}
                    bg={useColorModeValue("white","gray.700")}
                    borderWidth={"1px"}
                    borderRadius={"xl"}
                    
                >
                    <TabList
                        as={Flex}
                        flexWrap={"wrap"}
                        className={"no-scroll"}
                    >
                        <Tab

                            size={"sm"}
                            width={"fit-content"}
                            as={Button}
                            mr={3}
                            mb={3}
                            _focus={{
                                border: "0",
                                fontWeight: "bold",

                            }}
                            rightIcon={"c"}
                        > Personal </Tab>
                    </TabList>
                    <TabPanels>
                        //
                    </TabPanels>
                </Tabs>
                                <VStack width={{
                                    base: "full",
                                    md: "200px"
                                }}
                                
                                >
                                    <Button
                                            width={"full"}
                                            size={"sm"}
                                            backgroundColor={activePaymentsTab === "bank" ? "blue.400" : "blue.100"}
                                            color={activePaymentsTab === "bank" ? "white" : "blue.400" }
                                            onClick={e => setActivePaymentsTab("bank") }> Bank Account </Button>
                                    <Button
                                            width={"full"}
                                            size={"sm"}
                                            backgroundColor={activePaymentsTab === "momo" ? "blue.400" : "blue.100"}
                                            color={activePaymentsTab === "momo" ? "white" : "blue.400" }
                                            onClick={e => setActivePaymentsTab("momo") }> Mobile Money </Button>
                                    <Button
                                            width={"full"}
                                            size={"sm"}
                                            backgroundColor={activePaymentsTab === "cash" ? "blue.400" : "blue.100"}
                                            color={activePaymentsTab === "cash" ? "white" : "blue.400" }
                                            onClick={e => setActivePaymentsTab("cash") }> Cash </Button>
                                </VStack>
                                <VStack
                                        bg={useColorModeValue("gray.100","gray.700")}
                                        rounded={"lg"}
                                        ml={{
                                            base: 0,
                                            md: 4 
                                        }}
                                        my={{
                                            base: 4,
                                            md: 0
                                        }}
                                        p={4}
                                        width={"full"}>
                                {
                                    activePaymentsTab === "bank" && <CreditCards />
                                }
                                {
                                    activePaymentsTab === "momo" && <MobileMoneyAccounts />
                                }
                                
                                    <Container width={"full"} display={{
                                base: activePaymentsTab === "cash" ? "block" : "none",
                                
                            }}>
                                <Text> Cash will be delivered to your store every friday. </Text>
                            </Container>
                                </VStack>
                            </Box>



                            */
