import { Stack, Text, useColorModeValue } from '@chakra-ui/react';
import Link from '../buttons/link';

export default function NavigationItem({ route }) {
  if (route.onclick) {
    route.rest = {
      onClick: route.onclick,
    };
  }

  return (
    <Link
      href={route.path}
      as={Stack}
      alignItems={'center'}
      direction={{
        base: 'column',
        md: 'row',
      }}
      spacing={{
        base: 1,
        md: 3,
      }}
      width={{ base: '70px', md: 'auto' }}
      px={{
        base: 0,
        md: 4,
      }}
      py={{
        base: 0,
        md: 2,
      }}
      _hover={{
        bg: {
          base: 'none',
          md: 'brand.50',
        },
        color: 'gray.900',
        borderRadius: '4px',
        fontWeight: 'bold',
      }}
      color={{
        base: useColorModeValue('gray.900', 'gray.50'),
      }}
      textDecoration={'none'}
      {...route.rest}
    >
      {route.more}
      {route.icon}
      <Text
        fontSize={{
          base: 'xs',
          md: '14px',
        }}
        fontWeight={'semibold'}
      >
        {route.text}
      </Text>
    </Link>
  );
}
