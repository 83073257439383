import { ChakraProvider } from '@chakra-ui/react';
import { Amplify, Analytics } from 'aws-amplify';
import 'car-makes-icons/dist/style.css';
import * as ReactDOM from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import queryClient from './api/query-client';
import App from './App';
import theme from '../src/chakra-theme';
import ErrorBoundary from './components/error/boundary';
import Config from './config/backend-config';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './service-worker-registration';
import { CypressHistorySupport } from 'cypress-react-router';
import 'tw-elements';
import { selectorPrefix } from './utils/constants';

const amplifyObject = {
  Auth: {
    mandatorySignIn: false,
    region: Config.cognito.REGION,
    userPoolId: Config.cognito.USER_POOL_ID,
    identityPoolId: Config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: Config.cognito.APP_CLIENT_ID,
    authenticationFlowType: 'CUSTOM_AUTH',
  },
  Storage: {
    AWSS3: {
      bucket: Config.s3.BUCKET,
      region: Config.s3.REGION,
    },
  },
  AWSPinpoint: {
    // Amazon Pinpoint App Client ID
    appId: Config.pinpoint.APP_ID,
    // Amazon service region
    region: Config.cognito.REGION,
    mandatorySignIn: false,
  },
  ...Config.appsync,
};
//console.log('🚀 ~ file: index.tsx:36 ~ amplifyObject', amplifyObject);
//console.log(Config.pinpoint);

try {
  Amplify.configure(amplifyObject);
  //NOTE: adding the analytics
  Analytics.autoTrack('session', {
    enable: true,
    attributes: {
      session_start: new Date(Date.now()).toLocaleDateString(),
    },
    provider: 'AWSPinpoint',
  });
  Analytics.autoTrack('pageView', {
    enable: true,
    eventName: 'pageView',
    attributes: {
      pageView_start: new Date(Date.now()).toLocaleDateString(),
    },
    type: 'SPA',
    provider: 'AWSPinpoint',
    getUrl: () => {
      return window.location.origin + window.location.pathname;
    },
  });

  Analytics.autoTrack('event', {
    enable: true,
    events: ['click'],
    selectorPrefix,
    provider: 'AWSPinpoint',
  });
} catch (error) {
  console.log(error);
}

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <CypressHistorySupport />
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </BrowserRouter>
      </ChakraProvider>
    </QueryClientProvider>
  </RecoilRoot>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
