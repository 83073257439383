import { useState } from 'react';
import {
  Select,
  InputGroup,
  InputLeftAddon,
  useColorModeValue,
} from '@chakra-ui/react';
import ReactCountryFlag from 'react-country-flag';
import { countryCodes } from '../../utils/constants';

export default function CountrySelect({
  register,
  value,
  disabled = false,
  ...rest
}) {
  const [country, setCountry] = useState(countryCodes[countryCodes.length - 2]);

  useState(() => {
    if (value) {
      let country = countryCodes.filter(country => {
        return Boolean(Object.values(country).indexOf(value) + 1);
      })[0];
      setCountry(country);
    }
  }, []);

  return (
    <InputGroup borderRadius={'md'} {...rest}>
      <InputLeftAddon bg={'inherit'}>
        <ReactCountryFlag
          countryCode={country.code}
          svg
          style={{
            width: '2em',
            height: '2em',
          }}
          title={country.code}
        />
      </InputLeftAddon>
      <Select
        value={JSON.stringify(country)}
        disabled={disabled}
        borderLeft={'0px'}
        borderLeftRadius={0}
        color={useColorModeValue('black', 'gray.500')}
        _placeholder={{
          color: useColorModeValue('black', 'gray.500'),
        }}
        bg={'inherit'}
        _focus={{}}
        {...register('country', {
          onChange: e => {
            setCountry(JSON.parse(e.target.value));
          },
        })}
      >
        {countryCodes.map(country => {
          return (
            <option key={country.code} value={JSON.stringify(country)}>
              {' '}
              {country.name}
            </option>
          );
        })}
      </Select>
    </InputGroup>
  );
}
