/* eslint-disable no-unused-vars */
export enum EntityType {
  CUSTOMER = 'CUSTOMER',
  PARTNER = 'PARTNER',
  REQUEST = 'REQUEST',
  QUOTE = 'QUOTE',
  CONVERSATION = 'CONVERSATIONS',
  MESSAGE = 'MESSAGE',
  AUTO_PART = 'AUTO_PART',
  DELIVERY = 'DELIVERY',
  FEEDBACK = 'DELIVERY',
  ORDER = 'ORDER',
  PAYMENT = 'PAYMENT',
  PAYOUT = 'PAYOUT',
  REVIEW = 'REVIEW',
  NOTIFICATION = 'NOTIFICATION',
  PHOTO = 'PHOTO',
  VEHICLE = 'VEHICLE',
  RELATIONSHIP = 'RELATIONSHIP',
  MOBILE_MONEY = 'MOBILE_MONEY',
  CARD = 'CARD',
  PARTNER_ORDER = 'PARTNER_ORDER',
  CONTACT_LIST = 'CONTACT_LIST',
  AUTO_PART_CATEGORY = 'AUTO_PART_CATEGORY',
  AUTO_PART_SUBCATEGORY = 'AUTO_PART_SUBCATEGORY',
  CONVERSATION_CONNECTION = 'CONVERSATION_CONNECTION',
  VEHICLE_TYPE = 'VEHICLE_TYPE',
  MAKE = 'MAKE',
  MODEL = 'MODEL',
  ENGINE = 'ENGINE',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum Paths {
  home = '/',
  carDetails = '/car-details',
  partnerLogin = '/partner-login',
  login = '/login',
  becomeAPartner = '/become-a-partner',
  partAndContactDetails = '/part-and-contact-details',
  selectPhotos = '/select-photos',
  summaryAndAdvice = '/summary-and-advice',
  orders = '/orders',
  order = '/order',
  register = '/register',
  partSelection = '/part-selection',
  contactDetails = '/contact-details',
  requests = '/requests',
  request = '/request',
  quotes = '/quotes',
  quote = '/quote',
  chat = '/chat',
  negotiation = '/negotiation',
  negotiations = '/negotiations',
  partnerRequests = '/partner-requests',
  createQuotes = '/create-quotes',
  otp = '/one-time-password',
  createOrder = '/create-order',
  settings = '/settings',
  terms = '/terms',
  refund = '/return',
}

export enum ToastStatus {
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
}

export enum StepText {
  AUTOPART = 'Auto Part Details',
  VEHICLE = 'Vehicle Details',
  VERIFY = 'Verify Order Details',
}
