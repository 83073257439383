import Auth from '@aws-amplify/auth';
import Compressor from 'compressorjs';
import { ulid } from 'ulid';

const CUSTOM_AUTH_TTL = 1000 * 60 * 60 * 24 * 30; // 30 days in Milliseconds

export function clearCustomAuthSession() {
  window.localStorage.removeItem('CustomAuthSession');
}

export function isValidEmail(email) {
  return /^([a-zA-Z0-9_.+-])+\/@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
    email,
  );
}

export const getCurrency = async () => {
  try {
    await fetch(
      ' https://v6.exchangerate-api.com/v6/56abcdb0e5912f85feed3bbc/latest/USD',
    );
  } catch (error) {
    console.log(error);
  }
};

export const getBase64 = file => {
  return new Promise(resolve => {
    let baseURL = '';
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object

      baseURL = reader.result;

      resolve(baseURL);
    };
  });
};

export const compressImage = file => {
  return new Promise(resolve => {
    new Compressor(file, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      // eslint-disable-next-line no-loop-func
      success: async compressedResult => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        resolve(compressedResult);
      },
    });
  });
};

//! FEES

export function calcFee(amount) {
  /**
   * https://support.stripe.com/questions/passing-the-stripe-fee-on-to-customers
   */

  var _fee = { Percent: 3.5, Fixed: 10 };

  var total =
    (amount + parseFloat(_fee.Fixed)) / (1 - parseFloat(_fee.Percent) / 100);

  var fee = total - amount;

  return {
    amount,
    fee,
    total,
    message:
      'You should ask: ' +
      total +
      ' to customer, to cover ' +
      fee +
      ' fee from ' +
      amount,
  };
}

export async function uploadToS3(filesToUpload, isAuthenticated) {
  try {
    let urls = [];
    for (var i = 0; i < filesToUpload.length; i++) {
      let url = await s3Upload(filesToUpload[i], isAuthenticated);
      if (!url) continue;
      urls = [...urls, url];
    }

    return urls;
  } catch (error) {
    console.log(error);
  }
}

export async function s3Upload(file, isAuthenticated) {
  const filename = `${ulid()}-${file.name.replace(' ', '_')}`;

  try {
    const stored = isAuthenticated
      ? await Storage.vault.put(filename, file, {
          contentType: file.type,
        })
      : await Storage.put(filename, file, {
          contentType: file.type,
        });

    return stored.key;
  } catch (error) {
    console.log(filename);
    console.log(
      '🚀 ~ file: UploadImage.js ~ line 29 ~ s3Upload ~ error',
      error,
    );
  }
}

export function getPartnerFromConversationMembers(members) {
  if (!Array.isArray(members)) return;
  if (members.length > 2) return;
  return members.find(m => m.entityType === 'PARTNER');
}
export function getCustomerFromConversationMembers(members) {
  if (!Array.isArray(members)) return;

  return members.find(m => m.entityType === 'CUSTOMER');
}

export const isInCart = (cart, item) => {
  // console.log(item)
  // console.log(cart.findIndex(x => x.quoteId === item.quoteId))
  return cart.findIndex(x => x.quoteId === item.quote.id) + 1 > 0;
};

export const addToCart = (cart, item) => {
  let newItem;

  const newCart = [...cart].filter(x => x.requestId !== item.requestId);
  const foundIndex = cart.findIndex(x => x.quoteId === item.quoteId);
  const foundItem = cart.find(x => x.quoteId === item.quoteId);

  // Increase quantity if existing
  if (foundIndex >= 0) {
    newItem = Object.assign({}, foundItem, { price: item.price });

    newCart[foundIndex] = newItem;
    /*     newCart[foundIndex] = {
      ...cart[foundIndex],
      quantity: cart[foundIndex].quantity + 1,
    }; */
    return newCart;
  }
  newCart.push(item);
  return newCart;

  // Add new item
};

export const testLogger = (testName, hasPassed, obj) =>
  console.log(
    `%c ${testName}`,
    `color: black; background: ${hasPassed ? '#66ff00' : 'red'}`,
    obj,
  );

export const isLastMessage = (messages, i, userId) => {
  return (
    i === messages.length - 1 &&
    messages[messages.length - 1].sender.id !== userId &&
    messages[messages.length - 1].sender.id
  );
};
export function loadCustomAuthSession() {
  const raw = window.localStorage.getItem('CustomAuthSession');

  if (!raw) {
    throw new Error('No custom auth session');
  }
  const storedSession = window.JSON.parse(raw);
  if (storedSession.expiresAt < window.Date.now()) {
    clearCustomAuthSession();
    throw new Error('Stored custom auth session has expired');
  }
  const username = storedSession.username;
  // Accessing private method of Auth here which is BAD, but it's still the
  // safest way to restore the custom auth session from local storage, as there
  // is no interface that lets us do it.
  // (If we created a new user pool object here instead to pass to a
  // CognitoUser constructor that would likely result in hard to catch bugs,
  // as Auth can assume that all CognitoUsers passed to it come from its pool
  // object.)
  const user = Auth.createCognitoUser(username);
  // Session is not exposed to TypeScript, but it's a public member in the
  // JS code.
  user.Session = storedSession.session;

  return user;
}

export function storeCustomAuthSession(cognitoUser) {
  // Session isn't exposed to TypeScript, but it's a public member in JS
  const session = cognitoUser.Session;
  const expiresAt = window.Date.now() + CUSTOM_AUTH_TTL;
  const otpSession = {
    session,
    expiresAt,
    username: cognitoUser.getUsername(),
  };

  const json = window.JSON.stringify(otpSession);
  window.localStorage.setItem('CustomAuthSession', json);
}

export function isULID(value) {
  const pattern = /[0-9A-HJKMNP-TV-Z]{26}/;
  return typeof value === 'string' && pattern.test(value);
}

export const textFromCamelCase = text =>
  text
    .replace(/([A-Z])/g, ' $1')
    // uppercase the first character
    .replace(/^./, str => str.toUpperCase());

export const capitalizeEveryFirstLetter = mySentence =>
  mySentence
    ? mySentence
        .toLowerCase()
        .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
    : '';
export function unStringfy(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}

export const consoleLog = x => {
  process.env.REACT_APP_STAGE === 'prod' || console.log(x);
};

export function getRandomString(bytes) {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join('');
}

export function intToHex(nr) {
  return nr.toString(16).padStart(2, '0');
}

export function validateEmail(mail) {
  let valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail); // eslint-disable-line
  if (valid) {
    return true;
  }
  return false;
}

export const formatDate = date => {
  let d = date ? date : new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const mapWithEmpty = (array, func = e => e) => {
  let mappedArray = [];
  for (let x = 0; x < array.length; x++) {
    mappedArray.push(func(array[x]));
  }
  return mappedArray;
};

export function formatPrice(value, opts = {}) {
  const { locale = 'en-ZM', currency = 'ZMW' } = opts;
  const formatter = new Intl.NumberFormat(locale, {
    currency,
    style: 'currency',
  });
  return formatter.format(value);
}

export const CountDown = () => {
  var deadline = new Date('Jan 5, 2018 15:37:25').getTime();
  var x = setInterval(function () {
    var now = new Date().getTime();
    var t = deadline - now;
    var days = Math.floor(t / (1000 * 60 * 60 * 24));
    var hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((t % (1000 * 60)) / 1000);
    if (t < 0) {
      clearInterval(x);
    }
    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }, 1000);

  console.log(x);
};
