import { StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';
import { QuoteResponse } from '../../../API';
import QuoteTableFooter from './QuoteTableFooter';
import QuoteTableHeader from './QuoteTableHeader';
import QuoteTableRow from './QuoteTableRow';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#FFA500',
  },
});

interface PartnerQuotesTableProps {
  quotesByPartner: QuoteResponse[];
}

const PartnerQuotesTable = ({ quotesByPartner }: PartnerQuotesTableProps) => (
  <View style={styles.tableContainer}>
    <QuoteTableHeader partner={quotesByPartner[0].partner} />
    <QuoteTableRow quotes={quotesByPartner} />
    <QuoteTableFooter quotes={quotesByPartner} />
  </View>
);

export default PartnerQuotesTable;
