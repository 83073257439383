import { Box, HStack, Spinner, Text, Center } from '@chakra-ui/react';
import React from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getVehiclesByCreator } from '../../api/queries';
import { cognitoUserAtom, selectedVehicle } from '../../recoil/atoms';
import { FaCheckCircle } from 'react-icons/fa';
import { VehiclePage } from '../../API';
import { getPinpointButtonClickEventProp } from '../../utils/functions-ts';
import { useLocation } from 'react-router-dom';

//NOTE: vehicle slider
const VehicleSlider = () => {
  const user = useRecoilValue(cognitoUserAtom);
  const { pathname } = useLocation();
  const [selectV, setSelectV] = useRecoilState(selectedVehicle);
  const { data, isLoading }: UseQueryResult<VehiclePage, Error> = useQuery(
    ['get-my-cars', { user }],
    getVehiclesByCreator,
    { enabled: true },
  );

  return (
    <HStack
      width={'full'}
      overflowX={
        data && data.vehicles && data.vehicles.length > 1 ? 'scroll' : 'hidden'
      }
      px={'3'}
      pb={'2'}
    >
      {user && isLoading && (
        <Center>
          <Spinner />
        </Center>
      )}

      {user && data && data.vehicles && data.vehicles.length > 0 && (
        <>
          {data.vehicles.map((vehicle, i) =>
            vehicle ? (
              <Box
                pos={'relative'}
                onClick={() => {
                  setSelectV(vehicle);
                }}
                {...getPinpointButtonClickEventProp({
                  command: 'select vehicle from list',
                  currentPage: pathname,
                })}
                key={vehicle?.vehicle.id}
                rounded={'md'}
                width={'auto'}
                minWidth={'fit-content'}
                py={'1'}
                px={'3'}
                bg={'gray.100'}
              >
                <Text fontSize={'xs'}>
                  <b> {vehicle.vehicle.make}</b>
                  <br />
                  {vehicle.vehicle.model}
                </Text>
                {vehicle.vehicle.id === selectV?.vehicle.id && (
                  <Box
                    rounded={'full'}
                    pos={'absolute'}
                    top={'0.5'}
                    right={'0.5'}
                    bg={'green.200'}
                  >
                    <FaCheckCircle size={'12px'} color="blue" />
                  </Box>
                )}
              </Box>
            ) : null,
          )}
        </>
      )}
    </HStack>
  );
};

export default VehicleSlider;
