import {
  Divider,
  HStack,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Fragment, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { byDateRangeInput, RequestResponse } from '../../API';
import { searchRequests } from '../../api/queries';
import FilterControl from '../../components/buttons/filter';
import PromoCard from '../../components/cards/promo';
import RequestDrawer from '../../components/drawers/request';
import ErrorAlert from '../../components/error/alert';
import Main from '../../components/layouts/Main';
import Skeletons from '../../components/loaders/skeletons';
import { cognitoUserAtom } from '../../recoil/atoms';
import Request from './request';

//ANCHOR: all request component page
const Requests: React.FC = (): JSX.Element => {
  //ANCHOR: initialize useful component state
  const user = useRecoilValue(cognitoUserAtom);
  const [dateRange, setDateRange] = useState<byDateRangeInput>({
    start: '',
    end: '',
  });
  const [status, setStatus] = useState<string>('all');
  const limit: number = 10;
  const noRequestColor = useColorModeValue('gray.900', 'gray.300');

  //ANCHOR: destructure properties from intersection observer hook
  const { ref, inView } = useInView();

  //ANCHOR: get all the request the users has
  const { isLoading, isError, data, hasNextPage, fetchNextPage, refetch } =
    useInfiniteQuery(
      ['requests', { status, dateRange, user, limit }],
      searchRequests,
      {
        getNextPageParam: previousPage =>
          previousPage ? previousPage.nextToken : null,
        refetchInterval: 0,
      },
    );

  useEffect(() => {
    //ANCHOR: fetch more autoparts whenever the last autopart comes into view;
    inView && hasNextPage && fetchNextPage();
  }, [inView, hasNextPage]);

  return (
    <>
      <Main>
        <PromoCard />
        <HStack width={'full'}>
          <Text
            fontSize={'xl'}
            fontWeight={'bold'}
            color={useColorModeValue('gray.900', 'gray.50')}
            data-heading="request"
          >
            {' '}
            My Requests{' '}
          </Text>
          <Spacer />
          <FilterControl
            apply={{ onEvent1: setDateRange, onEvent2: setStatus }}
          />
        </HStack>

        <SimpleGrid
          columns={{
            base: 1,
            md: 3,
            lg: 4,
            xl: 5,
          }}
          p={'1px'}
          spacing={4}
          width={'full'}
          overflowY={'scroll'}
          className={'no-scroll'}
          data-component="requests-container"
        >
          {!!data &&
            !!data.pages &&
            data.pages.map((page, pageIndex) => {
              return (
                <Fragment key={pageIndex}>
                  {page &&
                    page.requests &&
                    page.requests?.map(
                      (request: RequestResponse, requestIndex: number) => {
                        return (
                          <Request
                            request={request}
                            key={request.request.id}
                            pos={requestIndex}
                            ref={
                              pageIndex === data.pages.length - 1 &&
                              requestIndex === page.requests.length - 1
                                ? ref
                                : undefined
                            }
                          />
                        );
                      },
                    )}
                </Fragment>
              );
            })}

          {isLoading && (
            <Skeletons
              count={10}
              minH={'100px'}
              width={'full'}
              borderRadius={'8px'}
            />
          )}
        </SimpleGrid>

        {isError && <ErrorAlert again={refetch} />}

        {!isLoading &&
          !isError &&
          data &&
          (!data.pages.length || data.pages[0].requests.length < 1) && (
            <>
              <Divider />
              <Stack
                spacing={4}
                width={'full'}
                height={'320px'}
                justifyContent={'center'}
                alignContent={'center'}
                alignItems={'center'}
                bg={'whiteAlpha.900'}
                padding={'3'}
              >
                <Text
                  fontWeight={'normal'}
                  fontSize={'xl'}
                  color={noRequestColor}
                  textAlign={'center'}
                >
                  {status === 'all' && 'You have no requests yet...'}
                  {status === 'OPEN' && "You don't have open requests yet..."}
                  {status === 'CLOSED' && 'You have no closed requests yet...'}
                  {status === 'IN_PROGRESS' &&
                    'You have no requests in progress yet...'}
                </Text>
              </Stack>
            </>
          )}
      </Main>
      <RequestDrawer />
    </>
  );
};
export default Requests;

/*

{filter !== "all" || !!dateRange.start ? "No requests found for your search criteria, search for autoparts and create more requests below" : "You have not created any request, search for autoparts and create one below."}

*/
