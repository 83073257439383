/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!, $partnerId: String) {
    getOrder(id: $id, partnerId: $partnerId) {
      ... on OrderResponse {
        order {
          id
          created
          modified
          entityType
          quotes {
            partnerId
            quoteIds
          }
          customerId
          status
        }
        quotes {
          quote {
            id
            created
            modified
            entityType
            comment
            status
            expiryDate
            total
            isNew
          }
          partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          request {
            request {
              id
              created
              modified
              entityType
              make
              model
              year
              fuel
              gearbox
              variant
              VIN
              engineNumber
              status
              contactDetails
              otherDetails
              creatorId
              creatorType
              quoteIds
              vehicleId
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quantity
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
            vehicle {
              vehicle {
                id
                created
                modified
                discontinued
                entityType
                make
                model
                engineNumber
                variant
                year
                fuel
                gearbox
                plateNumber
                VIN
                creatorType
                creatorId
                otherDetails
              }
              owner {
                ... on Partner {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  email
                  phoneNumber
                  address
                  bankAccount
                  mainContactPerson
                  subscribedCategories
                  mobileMoneyDetails {
                    processor
                    name
                    number
                  }
                }
                ... on Customer {
                  id
                  created
                  modified
                  entityType
                  firstName
                  familyName
                  gender
                  imageS3Name
                  birthdate
                  phoneNumber
                  email
                }
              }
            }
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quotedPrice
            isSold
            soldPrice
            comment
            paymentOptions {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
        }
        payment {
          id
          created
          modified
          entityType
          orderId
          creatorId
          creatorType
          currency
          otherDetails
          referenceId
          transactionId
          notes
          momoPhoneNumber
          momoText
          status
          eventHistory {
            event
            date
            note
          }
          event
          processor
          fee {
            finalFee
            chargeFee
            serviceFee
            deliveryFee
            deliveryWithdrawalFee
            withdrawalFee
            processorChargePercentage
            processorChargeRate
            processorPayoutPercentage
            processorPayoutRate
            serviceFeeDate
            partnerPayoutAmount
            deliveryPayoutAmount
            isDeliveryIncluded
            processor
            quotedPrice
            payoutFee
          }
          receivedFees {
            receivedTotal
            overpayment
            underpayment
          }
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const searchOrders = /* GraphQL */ `
  query SearchOrders($input: searchInput!) {
    searchOrders(input: $input) {
      ... on OrderSearchResults {
        all {
          orders {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          nextToken
        }
        byStatus {
          orders {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          nextToken
        }
        byPartner {
          orders {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          nextToken
        }
        byCustomer {
          orders {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          nextToken
        }
        byDateRange {
          orders {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          nextToken
        }
        byPartnerAndStatus {
          orders {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          nextToken
        }
        byCustomerAndStatus {
          orders {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          nextToken
        }
        byStatusAndDateRange {
          orders {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          nextToken
        }
        byPartnerAndDateRange {
          orders {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          nextToken
        }
        byCustomerAndDateRange {
          orders {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          nextToken
        }
        byStatusAndDateRangeAndPartner {
          orders {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          nextToken
        }
        byStatusAndDateRangeAndCustomer {
          orders {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          nextToken
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const getInvalidPayment = /* GraphQL */ `
  query GetInvalidPayment($id: ID!) {
    getInvalidPayment(id: $id) {
      ... on InvalidPayment {
        id
        created
        modified
        entityType
        momoText
        momoNumber
        momoAmount
        momoName
        refNumber
        reason
        isOpen
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const getInvalidPayments = /* GraphQL */ `
  query GetInvalidPayments {
    getInvalidPayments {
      ... on InvalidPaymentPage {
        invalidPayments {
          id
          created
          modified
          entityType
          momoText
          momoNumber
          momoAmount
          momoName
          refNumber
          reason
          isOpen
        }
        nextToken
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      ... on PaymentResponse {
        payment {
          id
          created
          modified
          entityType
          orderId
          creatorId
          creatorType
          currency
          otherDetails
          referenceId
          transactionId
          notes
          momoPhoneNumber
          momoText
          status
          eventHistory {
            event
            date
            note
          }
          event
          processor
          fee {
            finalFee
            chargeFee
            serviceFee
            deliveryFee
            deliveryWithdrawalFee
            withdrawalFee
            processorChargePercentage
            processorChargeRate
            processorPayoutPercentage
            processorPayoutRate
            serviceFeeDate
            partnerPayoutAmount
            deliveryPayoutAmount
            isDeliveryIncluded
            processor
            quotedPrice
            payoutFee
          }
          receivedFees {
            receivedTotal
            overpayment
            underpayment
          }
        }
        order {
          order {
            id
            created
            modified
            entityType
            quotes {
              partnerId
              quoteIds
            }
            customerId
            status
          }
          quotes {
            quote {
              id
              created
              modified
              entityType
              comment
              status
              expiryDate
              total
              isNew
            }
            partner {
              id
              created
              modified
              entityType
              name
              imageS3Name
              email
              phoneNumber
              address
              bankAccount
              mainContactPerson
              subscribedCategories
              mobileMoneyDetails {
                processor
                name
                number
              }
            }
            request {
              request {
                id
                created
                modified
                entityType
                make
                model
                year
                fuel
                gearbox
                variant
                VIN
                engineNumber
                status
                contactDetails
                otherDetails
                creatorId
                creatorType
                quoteIds
                vehicleId
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quantity
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
              vehicle {
                vehicle {
                  id
                  created
                  modified
                  discontinued
                  entityType
                  make
                  model
                  engineNumber
                  variant
                  year
                  fuel
                  gearbox
                  plateNumber
                  VIN
                  creatorType
                  creatorId
                  otherDetails
                }
                owner {
                  ... on Partner {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    email
                    phoneNumber
                    address
                    bankAccount
                    mainContactPerson
                    subscribedCategories
                    mobileMoneyDetails {
                      processor
                      name
                      number
                    }
                  }
                  ... on Customer {
                    id
                    created
                    modified
                    entityType
                    firstName
                    familyName
                    gender
                    imageS3Name
                    birthdate
                    phoneNumber
                    email
                  }
                }
              }
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quotedPrice
              isSold
              soldPrice
              comment
              paymentOptions {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
          }
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
        }
        paymentMethod {
          ... on MobileMoney {
            id
            created
            modified
            entityType
            name
            phoneNumber
            country
            mobileMoneyProvider
            creatorId
            creatorType
          }
          ... on CreditCard {
            id
            created
            modified
            entityType
            name
            country
            cardProvider
            creatorId
            creatorType
            expMonth
            expYear
            last4
          }
        }
        creator {
          ... on Partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          ... on Customer {
            id
            created
            modified
            entityType
            firstName
            familyName
            gender
            imageS3Name
            birthdate
            phoneNumber
            email
          }
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const getPaymentByRefNumber = /* GraphQL */ `
  query GetPaymentByRefNumber($ref: String!) {
    getPaymentByRefNumber(ref: $ref) {
      ... on PaymentResponse {
        payment {
          id
          created
          modified
          entityType
          orderId
          creatorId
          creatorType
          currency
          otherDetails
          referenceId
          transactionId
          notes
          momoPhoneNumber
          momoText
          status
          eventHistory {
            event
            date
            note
          }
          event
          processor
          fee {
            finalFee
            chargeFee
            serviceFee
            deliveryFee
            deliveryWithdrawalFee
            withdrawalFee
            processorChargePercentage
            processorChargeRate
            processorPayoutPercentage
            processorPayoutRate
            serviceFeeDate
            partnerPayoutAmount
            deliveryPayoutAmount
            isDeliveryIncluded
            processor
            quotedPrice
            payoutFee
          }
          receivedFees {
            receivedTotal
            overpayment
            underpayment
          }
        }
        order {
          order {
            id
            created
            modified
            entityType
            quotes {
              partnerId
              quoteIds
            }
            customerId
            status
          }
          quotes {
            quote {
              id
              created
              modified
              entityType
              comment
              status
              expiryDate
              total
              isNew
            }
            partner {
              id
              created
              modified
              entityType
              name
              imageS3Name
              email
              phoneNumber
              address
              bankAccount
              mainContactPerson
              subscribedCategories
              mobileMoneyDetails {
                processor
                name
                number
              }
            }
            request {
              request {
                id
                created
                modified
                entityType
                make
                model
                year
                fuel
                gearbox
                variant
                VIN
                engineNumber
                status
                contactDetails
                otherDetails
                creatorId
                creatorType
                quoteIds
                vehicleId
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quantity
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
              vehicle {
                vehicle {
                  id
                  created
                  modified
                  discontinued
                  entityType
                  make
                  model
                  engineNumber
                  variant
                  year
                  fuel
                  gearbox
                  plateNumber
                  VIN
                  creatorType
                  creatorId
                  otherDetails
                }
                owner {
                  ... on Partner {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    email
                    phoneNumber
                    address
                    bankAccount
                    mainContactPerson
                    subscribedCategories
                    mobileMoneyDetails {
                      processor
                      name
                      number
                    }
                  }
                  ... on Customer {
                    id
                    created
                    modified
                    entityType
                    firstName
                    familyName
                    gender
                    imageS3Name
                    birthdate
                    phoneNumber
                    email
                  }
                }
              }
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quotedPrice
              isSold
              soldPrice
              comment
              paymentOptions {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
          }
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
        }
        paymentMethod {
          ... on MobileMoney {
            id
            created
            modified
            entityType
            name
            phoneNumber
            country
            mobileMoneyProvider
            creatorId
            creatorType
          }
          ... on CreditCard {
            id
            created
            modified
            entityType
            name
            country
            cardProvider
            creatorId
            creatorType
            expMonth
            expYear
            last4
          }
        }
        creator {
          ... on Partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          ... on Customer {
            id
            created
            modified
            entityType
            firstName
            familyName
            gender
            imageS3Name
            birthdate
            phoneNumber
            email
          }
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const getPaymentByTransactionId = /* GraphQL */ `
  query GetPaymentByTransactionId($id: ID!) {
    getPaymentByTransactionId(id: $id) {
      ... on PaymentResponse {
        payment {
          id
          created
          modified
          entityType
          orderId
          creatorId
          creatorType
          currency
          otherDetails
          referenceId
          transactionId
          notes
          momoPhoneNumber
          momoText
          status
          eventHistory {
            event
            date
            note
          }
          event
          processor
          fee {
            finalFee
            chargeFee
            serviceFee
            deliveryFee
            deliveryWithdrawalFee
            withdrawalFee
            processorChargePercentage
            processorChargeRate
            processorPayoutPercentage
            processorPayoutRate
            serviceFeeDate
            partnerPayoutAmount
            deliveryPayoutAmount
            isDeliveryIncluded
            processor
            quotedPrice
            payoutFee
          }
          receivedFees {
            receivedTotal
            overpayment
            underpayment
          }
        }
        order {
          order {
            id
            created
            modified
            entityType
            quotes {
              partnerId
              quoteIds
            }
            customerId
            status
          }
          quotes {
            quote {
              id
              created
              modified
              entityType
              comment
              status
              expiryDate
              total
              isNew
            }
            partner {
              id
              created
              modified
              entityType
              name
              imageS3Name
              email
              phoneNumber
              address
              bankAccount
              mainContactPerson
              subscribedCategories
              mobileMoneyDetails {
                processor
                name
                number
              }
            }
            request {
              request {
                id
                created
                modified
                entityType
                make
                model
                year
                fuel
                gearbox
                variant
                VIN
                engineNumber
                status
                contactDetails
                otherDetails
                creatorId
                creatorType
                quoteIds
                vehicleId
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quantity
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
              vehicle {
                vehicle {
                  id
                  created
                  modified
                  discontinued
                  entityType
                  make
                  model
                  engineNumber
                  variant
                  year
                  fuel
                  gearbox
                  plateNumber
                  VIN
                  creatorType
                  creatorId
                  otherDetails
                }
                owner {
                  ... on Partner {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    email
                    phoneNumber
                    address
                    bankAccount
                    mainContactPerson
                    subscribedCategories
                    mobileMoneyDetails {
                      processor
                      name
                      number
                    }
                  }
                  ... on Customer {
                    id
                    created
                    modified
                    entityType
                    firstName
                    familyName
                    gender
                    imageS3Name
                    birthdate
                    phoneNumber
                    email
                  }
                }
              }
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quotedPrice
              isSold
              soldPrice
              comment
              paymentOptions {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
          }
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
        }
        paymentMethod {
          ... on MobileMoney {
            id
            created
            modified
            entityType
            name
            phoneNumber
            country
            mobileMoneyProvider
            creatorId
            creatorType
          }
          ... on CreditCard {
            id
            created
            modified
            entityType
            name
            country
            cardProvider
            creatorId
            creatorType
            expMonth
            expYear
            last4
          }
        }
        creator {
          ... on Partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          ... on Customer {
            id
            created
            modified
            entityType
            firstName
            familyName
            gender
            imageS3Name
            birthdate
            phoneNumber
            email
          }
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const searchPayments = /* GraphQL */ `
  query SearchPayments($input: searchInput!) {
    searchPayments(input: $input) {
      all {
        payments {
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
          order {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          paymentMethod {
            ... on MobileMoney {
              id
              created
              modified
              entityType
              name
              phoneNumber
              country
              mobileMoneyProvider
              creatorId
              creatorType
            }
            ... on CreditCard {
              id
              created
              modified
              entityType
              name
              country
              cardProvider
              creatorId
              creatorType
              expMonth
              expYear
              last4
            }
          }
          creator {
            ... on Partner {
              id
              created
              modified
              entityType
              name
              imageS3Name
              email
              phoneNumber
              address
              bankAccount
              mainContactPerson
              subscribedCategories
              mobileMoneyDetails {
                processor
                name
                number
              }
            }
            ... on Customer {
              id
              created
              modified
              entityType
              firstName
              familyName
              gender
              imageS3Name
              birthdate
              phoneNumber
              email
            }
          }
        }
        nextToken
      }
      byStatus {
        payments {
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
          order {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          paymentMethod {
            ... on MobileMoney {
              id
              created
              modified
              entityType
              name
              phoneNumber
              country
              mobileMoneyProvider
              creatorId
              creatorType
            }
            ... on CreditCard {
              id
              created
              modified
              entityType
              name
              country
              cardProvider
              creatorId
              creatorType
              expMonth
              expYear
              last4
            }
          }
          creator {
            ... on Partner {
              id
              created
              modified
              entityType
              name
              imageS3Name
              email
              phoneNumber
              address
              bankAccount
              mainContactPerson
              subscribedCategories
              mobileMoneyDetails {
                processor
                name
                number
              }
            }
            ... on Customer {
              id
              created
              modified
              entityType
              firstName
              familyName
              gender
              imageS3Name
              birthdate
              phoneNumber
              email
            }
          }
        }
        nextToken
      }
      byCreator {
        payments {
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
          order {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          paymentMethod {
            ... on MobileMoney {
              id
              created
              modified
              entityType
              name
              phoneNumber
              country
              mobileMoneyProvider
              creatorId
              creatorType
            }
            ... on CreditCard {
              id
              created
              modified
              entityType
              name
              country
              cardProvider
              creatorId
              creatorType
              expMonth
              expYear
              last4
            }
          }
          creator {
            ... on Partner {
              id
              created
              modified
              entityType
              name
              imageS3Name
              email
              phoneNumber
              address
              bankAccount
              mainContactPerson
              subscribedCategories
              mobileMoneyDetails {
                processor
                name
                number
              }
            }
            ... on Customer {
              id
              created
              modified
              entityType
              firstName
              familyName
              gender
              imageS3Name
              birthdate
              phoneNumber
              email
            }
          }
        }
        nextToken
      }
      byDateRange {
        payments {
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
          order {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          paymentMethod {
            ... on MobileMoney {
              id
              created
              modified
              entityType
              name
              phoneNumber
              country
              mobileMoneyProvider
              creatorId
              creatorType
            }
            ... on CreditCard {
              id
              created
              modified
              entityType
              name
              country
              cardProvider
              creatorId
              creatorType
              expMonth
              expYear
              last4
            }
          }
          creator {
            ... on Partner {
              id
              created
              modified
              entityType
              name
              imageS3Name
              email
              phoneNumber
              address
              bankAccount
              mainContactPerson
              subscribedCategories
              mobileMoneyDetails {
                processor
                name
                number
              }
            }
            ... on Customer {
              id
              created
              modified
              entityType
              firstName
              familyName
              gender
              imageS3Name
              birthdate
              phoneNumber
              email
            }
          }
        }
        nextToken
      }
      byCreatorAndStatus {
        payments {
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
          order {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          paymentMethod {
            ... on MobileMoney {
              id
              created
              modified
              entityType
              name
              phoneNumber
              country
              mobileMoneyProvider
              creatorId
              creatorType
            }
            ... on CreditCard {
              id
              created
              modified
              entityType
              name
              country
              cardProvider
              creatorId
              creatorType
              expMonth
              expYear
              last4
            }
          }
          creator {
            ... on Partner {
              id
              created
              modified
              entityType
              name
              imageS3Name
              email
              phoneNumber
              address
              bankAccount
              mainContactPerson
              subscribedCategories
              mobileMoneyDetails {
                processor
                name
                number
              }
            }
            ... on Customer {
              id
              created
              modified
              entityType
              firstName
              familyName
              gender
              imageS3Name
              birthdate
              phoneNumber
              email
            }
          }
        }
        nextToken
      }
      byStatusAndDateRange {
        payments {
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
          order {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          paymentMethod {
            ... on MobileMoney {
              id
              created
              modified
              entityType
              name
              phoneNumber
              country
              mobileMoneyProvider
              creatorId
              creatorType
            }
            ... on CreditCard {
              id
              created
              modified
              entityType
              name
              country
              cardProvider
              creatorId
              creatorType
              expMonth
              expYear
              last4
            }
          }
          creator {
            ... on Partner {
              id
              created
              modified
              entityType
              name
              imageS3Name
              email
              phoneNumber
              address
              bankAccount
              mainContactPerson
              subscribedCategories
              mobileMoneyDetails {
                processor
                name
                number
              }
            }
            ... on Customer {
              id
              created
              modified
              entityType
              firstName
              familyName
              gender
              imageS3Name
              birthdate
              phoneNumber
              email
            }
          }
        }
        nextToken
      }
      byCreatorAndDateRange {
        payments {
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
          order {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          paymentMethod {
            ... on MobileMoney {
              id
              created
              modified
              entityType
              name
              phoneNumber
              country
              mobileMoneyProvider
              creatorId
              creatorType
            }
            ... on CreditCard {
              id
              created
              modified
              entityType
              name
              country
              cardProvider
              creatorId
              creatorType
              expMonth
              expYear
              last4
            }
          }
          creator {
            ... on Partner {
              id
              created
              modified
              entityType
              name
              imageS3Name
              email
              phoneNumber
              address
              bankAccount
              mainContactPerson
              subscribedCategories
              mobileMoneyDetails {
                processor
                name
                number
              }
            }
            ... on Customer {
              id
              created
              modified
              entityType
              firstName
              familyName
              gender
              imageS3Name
              birthdate
              phoneNumber
              email
            }
          }
        }
        nextToken
      }
      byStatusAndDateRangeAndCreator {
        payments {
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
          order {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          paymentMethod {
            ... on MobileMoney {
              id
              created
              modified
              entityType
              name
              phoneNumber
              country
              mobileMoneyProvider
              creatorId
              creatorType
            }
            ... on CreditCard {
              id
              created
              modified
              entityType
              name
              country
              cardProvider
              creatorId
              creatorType
              expMonth
              expYear
              last4
            }
          }
          creator {
            ... on Partner {
              id
              created
              modified
              entityType
              name
              imageS3Name
              email
              phoneNumber
              address
              bankAccount
              mainContactPerson
              subscribedCategories
              mobileMoneyDetails {
                processor
                name
                number
              }
            }
            ... on Customer {
              id
              created
              modified
              entityType
              firstName
              familyName
              gender
              imageS3Name
              birthdate
              phoneNumber
              email
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const getRefund = /* GraphQL */ `
  query GetRefund($id: ID!) {
    getRefund(id: $id) {
      ... on RefundResponse {
        payment {
          id
          created
          modified
          entityType
          orderId
          creatorId
          creatorType
          currency
          otherDetails
          referenceId
          transactionId
          notes
          momoPhoneNumber
          momoText
          status
          eventHistory {
            event
            date
            note
          }
          event
          processor
          fee {
            finalFee
            chargeFee
            serviceFee
            deliveryFee
            deliveryWithdrawalFee
            withdrawalFee
            processorChargePercentage
            processorChargeRate
            processorPayoutPercentage
            processorPayoutRate
            serviceFeeDate
            partnerPayoutAmount
            deliveryPayoutAmount
            isDeliveryIncluded
            processor
            quotedPrice
            payoutFee
          }
          receivedFees {
            receivedTotal
            overpayment
            underpayment
          }
        }
        invalid {
          id
          created
          modified
          entityType
          creatorId
          creatorType
          status
          paymentId
          refundAmount
          reason
          refundMethod {
            id
            type
          }
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const searchRefunds = /* GraphQL */ `
  query SearchRefunds($input: searchInput!) {
    searchRefunds(input: $input) {
      all {
        refunds {
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
          invalid {
            id
            created
            modified
            entityType
            creatorId
            creatorType
            status
            paymentId
            refundAmount
            reason
            refundMethod {
              id
              type
            }
          }
        }
        nextToken
      }
      byStatus {
        refunds {
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
          invalid {
            id
            created
            modified
            entityType
            creatorId
            creatorType
            status
            paymentId
            refundAmount
            reason
            refundMethod {
              id
              type
            }
          }
        }
        nextToken
      }
      byCustomer {
        refunds {
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
          invalid {
            id
            created
            modified
            entityType
            creatorId
            creatorType
            status
            paymentId
            refundAmount
            reason
            refundMethod {
              id
              type
            }
          }
        }
        nextToken
      }
      byDateRange {
        refunds {
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
          invalid {
            id
            created
            modified
            entityType
            creatorId
            creatorType
            status
            paymentId
            refundAmount
            reason
            refundMethod {
              id
              type
            }
          }
        }
        nextToken
      }
      byCustomerAndStatus {
        refunds {
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
          invalid {
            id
            created
            modified
            entityType
            creatorId
            creatorType
            status
            paymentId
            refundAmount
            reason
            refundMethod {
              id
              type
            }
          }
        }
        nextToken
      }
      byStatusAndDateRange {
        refunds {
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
          invalid {
            id
            created
            modified
            entityType
            creatorId
            creatorType
            status
            paymentId
            refundAmount
            reason
            refundMethod {
              id
              type
            }
          }
        }
        nextToken
      }
      byCustomerAndDateRange {
        refunds {
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
          invalid {
            id
            created
            modified
            entityType
            creatorId
            creatorType
            status
            paymentId
            refundAmount
            reason
            refundMethod {
              id
              type
            }
          }
        }
        nextToken
      }
      byStatusAndDateRangeAndCustomer {
        refunds {
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
          invalid {
            id
            created
            modified
            entityType
            creatorId
            creatorType
            status
            paymentId
            refundAmount
            reason
            refundMethod {
              id
              type
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const getPayout = /* GraphQL */ `
  query GetPayout($id: ID!) {
    getPayout(id: $id) {
      payout {
        id
        created
        modified
        entityType
        orderId
        partnerId
        status
        total
        currency
        paymentMethod {
          id
          type
        }
        transactionFee
        otherDetails
      }
      order {
        order {
          id
          created
          modified
          entityType
          quotes {
            partnerId
            quoteIds
          }
          customerId
          status
        }
        quotes {
          quote {
            id
            created
            modified
            entityType
            comment
            status
            expiryDate
            total
            isNew
          }
          partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          request {
            request {
              id
              created
              modified
              entityType
              make
              model
              year
              fuel
              gearbox
              variant
              VIN
              engineNumber
              status
              contactDetails
              otherDetails
              creatorId
              creatorType
              quoteIds
              vehicleId
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quantity
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
            vehicle {
              vehicle {
                id
                created
                modified
                discontinued
                entityType
                make
                model
                engineNumber
                variant
                year
                fuel
                gearbox
                plateNumber
                VIN
                creatorType
                creatorId
                otherDetails
              }
              owner {
                ... on Partner {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  email
                  phoneNumber
                  address
                  bankAccount
                  mainContactPerson
                  subscribedCategories
                  mobileMoneyDetails {
                    processor
                    name
                    number
                  }
                }
                ... on Customer {
                  id
                  created
                  modified
                  entityType
                  firstName
                  familyName
                  gender
                  imageS3Name
                  birthdate
                  phoneNumber
                  email
                }
              }
            }
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quotedPrice
            isSold
            soldPrice
            comment
            paymentOptions {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
        }
        payment {
          id
          created
          modified
          entityType
          orderId
          creatorId
          creatorType
          currency
          otherDetails
          referenceId
          transactionId
          notes
          momoPhoneNumber
          momoText
          status
          eventHistory {
            event
            date
            note
          }
          event
          processor
          fee {
            finalFee
            chargeFee
            serviceFee
            deliveryFee
            deliveryWithdrawalFee
            withdrawalFee
            processorChargePercentage
            processorChargeRate
            processorPayoutPercentage
            processorPayoutRate
            serviceFeeDate
            partnerPayoutAmount
            deliveryPayoutAmount
            isDeliveryIncluded
            processor
            quotedPrice
            payoutFee
          }
          receivedFees {
            receivedTotal
            overpayment
            underpayment
          }
        }
      }
      to {
        id
        created
        modified
        entityType
        name
        imageS3Name
        email
        phoneNumber
        address
        bankAccount
        mainContactPerson
        subscribedCategories
        mobileMoneyDetails {
          processor
          name
          number
        }
      }
    }
  }
`;
export const searchPayouts = /* GraphQL */ `
  query SearchPayouts($input: searchInput!) {
    searchPayouts(input: $input) {
      all {
        payouts {
          payout {
            id
            created
            modified
            entityType
            orderId
            partnerId
            status
            total
            currency
            paymentMethod {
              id
              type
            }
            transactionFee
            otherDetails
          }
          order {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          to {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
        }
        nextToken
      }
      byStatus {
        payouts {
          payout {
            id
            created
            modified
            entityType
            orderId
            partnerId
            status
            total
            currency
            paymentMethod {
              id
              type
            }
            transactionFee
            otherDetails
          }
          order {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          to {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
        }
        nextToken
      }
      byCreator {
        payouts {
          payout {
            id
            created
            modified
            entityType
            orderId
            partnerId
            status
            total
            currency
            paymentMethod {
              id
              type
            }
            transactionFee
            otherDetails
          }
          order {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          to {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
        }
        nextToken
      }
      byDateRange {
        payouts {
          payout {
            id
            created
            modified
            entityType
            orderId
            partnerId
            status
            total
            currency
            paymentMethod {
              id
              type
            }
            transactionFee
            otherDetails
          }
          order {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          to {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
        }
        nextToken
      }
      byCreatorAndStatus {
        payouts {
          payout {
            id
            created
            modified
            entityType
            orderId
            partnerId
            status
            total
            currency
            paymentMethod {
              id
              type
            }
            transactionFee
            otherDetails
          }
          order {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          to {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
        }
        nextToken
      }
      byStatusAndDateRange {
        payouts {
          payout {
            id
            created
            modified
            entityType
            orderId
            partnerId
            status
            total
            currency
            paymentMethod {
              id
              type
            }
            transactionFee
            otherDetails
          }
          order {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          to {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
        }
        nextToken
      }
      byCreatorAndDateRange {
        payouts {
          payout {
            id
            created
            modified
            entityType
            orderId
            partnerId
            status
            total
            currency
            paymentMethod {
              id
              type
            }
            transactionFee
            otherDetails
          }
          order {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          to {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
        }
        nextToken
      }
      byStatusAndDateRangeAndCreator {
        payouts {
          payout {
            id
            created
            modified
            entityType
            orderId
            partnerId
            status
            total
            currency
            paymentMethod {
              id
              type
            }
            transactionFee
            otherDetails
          }
          order {
            order {
              id
              created
              modified
              entityType
              quotes {
                partnerId
                quoteIds
              }
              customerId
              status
            }
            quotes {
              quote {
                id
                created
                modified
                entityType
                comment
                status
                expiryDate
                total
                isNew
              }
              partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              request {
                request {
                  id
                  created
                  modified
                  entityType
                  make
                  model
                  year
                  fuel
                  gearbox
                  variant
                  VIN
                  engineNumber
                  status
                  contactDetails
                  otherDetails
                  creatorId
                  creatorType
                  quoteIds
                  vehicleId
                }
                autoPart {
                  autoPart {
                    id
                    created
                    modified
                    entityType
                    name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    subCategory {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      category {
                        id
                        created
                        modified
                        entityType
                        name
                        imageS3Name
                        description
                      }
                      description
                    }
                    description
                    imageS3Name
                    fitmentNotes
                    weightG
                    widthMM
                    heightMM
                    lengthMM
                    isUniversal
                    modelCode
                    regYearAndmonth
                    mileage
                    transmissionType
                    engineModel
                    engineSize
                    fuel
                    drive
                    manufacturer
                    OEM
                    src
                    make
                    model
                  }
                  quantity
                }
                photos {
                  id
                  created
                  modified
                  entityType
                  imageS3Name
                  description
                  contentType
                  title
                  creatorType
                  creatorId
                }
                vehicle {
                  vehicle {
                    id
                    created
                    modified
                    discontinued
                    entityType
                    make
                    model
                    engineNumber
                    variant
                    year
                    fuel
                    gearbox
                    plateNumber
                    VIN
                    creatorType
                    creatorId
                    otherDetails
                  }
                  owner {
                    ... on Partner {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      email
                      phoneNumber
                      address
                      bankAccount
                      mainContactPerson
                      subscribedCategories
                      mobileMoneyDetails {
                        processor
                        name
                        number
                      }
                    }
                    ... on Customer {
                      id
                      created
                      modified
                      entityType
                      firstName
                      familyName
                      gender
                      imageS3Name
                      birthdate
                      phoneNumber
                      email
                    }
                  }
                }
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quotedPrice
                isSold
                soldPrice
                comment
                paymentOptions {
                  finalFee
                  chargeFee
                  serviceFee
                  deliveryFee
                  deliveryWithdrawalFee
                  withdrawalFee
                  processorChargePercentage
                  processorChargeRate
                  processorPayoutPercentage
                  processorPayoutRate
                  serviceFeeDate
                  partnerPayoutAmount
                  deliveryPayoutAmount
                  isDeliveryIncluded
                  processor
                  quotedPrice
                  payoutFee
                }
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
            }
            payment {
              id
              created
              modified
              entityType
              orderId
              creatorId
              creatorType
              currency
              otherDetails
              referenceId
              transactionId
              notes
              momoPhoneNumber
              momoText
              status
              eventHistory {
                event
                date
                note
              }
              event
              processor
              fee {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
              }
              receivedFees {
                receivedTotal
                overpayment
                underpayment
              }
            }
          }
          to {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const getPaymentMethod = /* GraphQL */ `
  query GetPaymentMethod($input: paymentMethodInput!) {
    getPaymentMethod(input: $input) {
      paymentMethod {
        ... on MobileMoney {
          id
          created
          modified
          entityType
          name
          phoneNumber
          country
          mobileMoneyProvider
          creatorId
          creatorType
        }
        ... on CreditCard {
          id
          created
          modified
          entityType
          name
          country
          cardProvider
          creatorId
          creatorType
          expMonth
          expYear
          last4
        }
      }
      creator {
        ... on Partner {
          id
          created
          modified
          entityType
          name
          imageS3Name
          email
          phoneNumber
          address
          bankAccount
          mainContactPerson
          subscribedCategories
          mobileMoneyDetails {
            processor
            name
            number
          }
        }
        ... on Customer {
          id
          created
          modified
          entityType
          firstName
          familyName
          gender
          imageS3Name
          birthdate
          phoneNumber
          email
        }
      }
    }
  }
`;
export const getPaymentMethods = /* GraphQL */ `
  query GetPaymentMethods($input: byCreatorInput!, $nextToken: String) {
    getPaymentMethods(input: $input, nextToken: $nextToken) {
      paymentMethods {
        paymentMethod {
          ... on MobileMoney {
            id
            created
            modified
            entityType
            name
            phoneNumber
            country
            mobileMoneyProvider
            creatorId
            creatorType
          }
          ... on CreditCard {
            id
            created
            modified
            entityType
            name
            country
            cardProvider
            creatorId
            creatorType
            expMonth
            expYear
            last4
          }
        }
        creator {
          ... on Partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          ... on Customer {
            id
            created
            modified
            entityType
            firstName
            familyName
            gender
            imageS3Name
            birthdate
            phoneNumber
            email
          }
        }
      }
      nextToken
    }
  }
`;
export const getVehicleCategoryTree = /* GraphQL */ `
  query GetVehicleCategoryTree($input: vehicleCategoryTreeInput) {
    getVehicleCategoryTree(input: $input) {
      ... on VehicleCategoryResponse {
        category {
          id
          created
          modified
          entityType
          name
          generation
        }
        ancestors {
          id
          created
          modified
          entityType
          name
          generation
        }
        descendants {
          categories {
            id
            created
            modified
            entityType
            name
            generation
          }
          generation
          nextToken
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const getVehicleCategoryByType = /* GraphQL */ `
  query GetVehicleCategoryByType($input: vehicleCategoryTypeInput) {
    getVehicleCategoryByType(input: $input) {
      ... on VehicleCategoryPage {
        categories {
          id
          created
          modified
          entityType
          name
          generation
        }
        generation
        nextToken
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      created
      modified
      entityType
      firstName
      familyName
      gender
      imageS3Name
      birthdate
      phoneNumber
      email
    }
  }
`;
export const getCustomers = /* GraphQL */ `
  query GetCustomers($limit: Int, $nextToken: String) {
    getCustomers(limit: $limit, nextToken: $nextToken) {
      customers {
        id
        created
        modified
        entityType
        firstName
        familyName
        gender
        imageS3Name
        birthdate
        phoneNumber
        email
      }
      nextToken
    }
  }
`;
export const getVehicle = /* GraphQL */ `
  query GetVehicle($id: ID!) {
    getVehicle(id: $id) {
      ... on VehicleResponse {
        vehicle {
          id
          created
          modified
          discontinued
          entityType
          make
          model
          engineNumber
          variant
          year
          fuel
          gearbox
          plateNumber
          VIN
          creatorType
          creatorId
          otherDetails
        }
        owner {
          ... on Partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          ... on Customer {
            id
            created
            modified
            entityType
            firstName
            familyName
            gender
            imageS3Name
            birthdate
            phoneNumber
            email
          }
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const getVehicles = /* GraphQL */ `
  query GetVehicles($limit: Int, $nextToken: String) {
    getVehicles(limit: $limit, nextToken: $nextToken) {
      vehicles {
        vehicle {
          id
          created
          modified
          discontinued
          entityType
          make
          model
          engineNumber
          variant
          year
          fuel
          gearbox
          plateNumber
          VIN
          creatorType
          creatorId
          otherDetails
        }
        owner {
          ... on Partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          ... on Customer {
            id
            created
            modified
            entityType
            firstName
            familyName
            gender
            imageS3Name
            birthdate
            phoneNumber
            email
          }
        }
      }
      nextToken
    }
  }
`;
export const getVehiclesByCreator = /* GraphQL */ `
  query GetVehiclesByCreator($input: byCreatorInput_!) {
    getVehiclesByCreator(input: $input) {
      ... on VehiclePage {
        vehicles {
          vehicle {
            id
            created
            modified
            discontinued
            entityType
            make
            model
            engineNumber
            variant
            year
            fuel
            gearbox
            plateNumber
            VIN
            creatorType
            creatorId
            otherDetails
          }
          owner {
            ... on Partner {
              id
              created
              modified
              entityType
              name
              imageS3Name
              email
              phoneNumber
              address
              bankAccount
              mainContactPerson
              subscribedCategories
              mobileMoneyDetails {
                processor
                name
                number
              }
            }
            ... on Customer {
              id
              created
              modified
              entityType
              firstName
              familyName
              gender
              imageS3Name
              birthdate
              phoneNumber
              email
            }
          }
        }
        nextToken
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const getPartner = /* GraphQL */ `
  query GetPartner($id: ID!) {
    getPartner(id: $id) {
      ... on Partner {
        id
        created
        modified
        entityType
        name
        imageS3Name
        email
        phoneNumber
        address
        bankAccount
        mainContactPerson
        subscribedCategories
        mobileMoneyDetails {
          processor
          name
          number
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const getPartners = /* GraphQL */ `
  query GetPartners($limit: Int, $nextToken: String) {
    getPartners(limit: $limit, nextToken: $nextToken) {
      partners {
        id
        created
        modified
        entityType
        name
        imageS3Name
        email
        phoneNumber
        address
        bankAccount
        mainContactPerson
        subscribedCategories
        mobileMoneyDetails {
          processor
          name
          number
        }
      }
      nextToken
    }
  }
`;
export const getAutoPart = /* GraphQL */ `
  query GetAutoPart($id: ID!) {
    getAutoPart(id: $id) {
      ... on AutoPart {
        id
        created
        modified
        entityType
        name
        category {
          id
          created
          modified
          entityType
          name
          imageS3Name
          description
        }
        subCategory {
          id
          created
          modified
          entityType
          name
          imageS3Name
          category {
            id
            created
            modified
            entityType
            name
            imageS3Name
            description
          }
          description
        }
        description
        imageS3Name
        fitmentNotes
        weightG
        widthMM
        heightMM
        lengthMM
        isUniversal
        modelCode
        regYearAndmonth
        mileage
        transmissionType
        engineModel
        engineSize
        fuel
        drive
        manufacturer
        OEM
        src
        make
        model
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const getAutoParts = /* GraphQL */ `
  query GetAutoParts($limit: Int, $nextToken: String) {
    getAutoParts(limit: $limit, nextToken: $nextToken) {
      ... on AutoPartPage {
        autoParts {
          id
          created
          modified
          entityType
          name
          category {
            id
            created
            modified
            entityType
            name
            imageS3Name
            description
          }
          subCategory {
            id
            created
            modified
            entityType
            name
            imageS3Name
            category {
              id
              created
              modified
              entityType
              name
              imageS3Name
              description
            }
            description
          }
          description
          imageS3Name
          fitmentNotes
          weightG
          widthMM
          heightMM
          lengthMM
          isUniversal
          modelCode
          regYearAndmonth
          mileage
          transmissionType
          engineModel
          engineSize
          fuel
          drive
          manufacturer
          OEM
          src
          make
          model
        }
        nextToken
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const getSms = /* GraphQL */ `
  query GetSms($id: ID!) {
    getSms(id: $id) {
      ... on Sms {
        id
        created
        modified
        entityType
        _id
        thread_id
        address
        person
        date
        date_sent
        protocol
        read
        status
        type
        body
        service_center
        locked
        error_code
        sub_id
        seen
        deletable
        sim_slot
        hidden
        app_id
        msg_id
        reserved
        pri
        teleservice_id
        svc_cmd
        roam_pending
        spam_report
        secret_mode
        safe_message
        favorite
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const getSmses = /* GraphQL */ `
  query GetSmses($limit: Int, $nextToken: String) {
    getSmses(limit: $limit, nextToken: $nextToken) {
      ... on SmsPage {
        smses {
          id
          created
          modified
          entityType
          _id
          thread_id
          address
          person
          date
          date_sent
          protocol
          read
          status
          type
          body
          service_center
          locked
          error_code
          sub_id
          seen
          deletable
          sim_slot
          hidden
          app_id
          msg_id
          reserved
          pri
          teleservice_id
          svc_cmd
          roam_pending
          spam_report
          secret_mode
          safe_message
          favorite
        }
        nextToken
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const getSmsesByDateRange = /* GraphQL */ `
  query GetSmsesByDateRange($input: byDateRangeInput!) {
    getSmsesByDateRange(input: $input) {
      ... on SmsPage {
        smses {
          id
          created
          modified
          entityType
          _id
          thread_id
          address
          person
          date
          date_sent
          protocol
          read
          status
          type
          body
          service_center
          locked
          error_code
          sub_id
          seen
          deletable
          sim_slot
          hidden
          app_id
          msg_id
          reserved
          pri
          teleservice_id
          svc_cmd
          roam_pending
          spam_report
          secret_mode
          safe_message
          favorite
        }
        nextToken
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const getAutoPartCategory = /* GraphQL */ `
  query GetAutoPartCategory($id: ID!) {
    getAutoPartCategory(id: $id) {
      id
      created
      modified
      entityType
      name
      imageS3Name
      description
    }
  }
`;
export const getAutoPartCategories = /* GraphQL */ `
  query GetAutoPartCategories($limit: Int, $nextToken: String) {
    getAutoPartCategories(limit: $limit, nextToken: $nextToken) {
      ... on AutoPartCategoryPage {
        autoPartCategories {
          id
          created
          modified
          entityType
          name
          imageS3Name
          description
        }
        nextToken
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const getAutoPartSubCategory = /* GraphQL */ `
  query GetAutoPartSubCategory($id: ID!) {
    getAutoPartSubCategory(id: $id) {
      id
      created
      modified
      entityType
      name
      imageS3Name
      category {
        id
        created
        modified
        entityType
        name
        imageS3Name
        description
      }
      description
    }
  }
`;
export const getAutoPartSubCategories = /* GraphQL */ `
  query GetAutoPartSubCategories($limit: Int, $nextToken: String) {
    getAutoPartSubCategories(limit: $limit, nextToken: $nextToken) {
      ... on AutoPartSubCategoryPage {
        autoPartSubCategories {
          id
          created
          modified
          entityType
          name
          imageS3Name
          category {
            id
            created
            modified
            entityType
            name
            imageS3Name
            description
          }
          description
        }
        nextToken
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const getAutoPartSubCategoriesByCategory = /* GraphQL */ `
  query GetAutoPartSubCategoriesByCategory(
    $categoryId: ID!
    $limit: Int
    $nextToken: String
  ) {
    getAutoPartSubCategoriesByCategory(
      categoryId: $categoryId
      limit: $limit
      nextToken: $nextToken
    ) {
      ... on AutoPartSubCategoryPage {
        autoPartSubCategories {
          id
          created
          modified
          entityType
          name
          imageS3Name
          category {
            id
            created
            modified
            entityType
            name
            imageS3Name
            description
          }
          description
        }
        nextToken
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const searchAutoParts = /* GraphQL */ `
  query SearchAutoParts($searchTerm: String) {
    searchAutoParts(searchTerm: $searchTerm) {
      id
      created
      modified
      entityType
      name
      category {
        id
        created
        modified
        entityType
        name
        imageS3Name
        description
      }
      subCategory {
        id
        created
        modified
        entityType
        name
        imageS3Name
        category {
          id
          created
          modified
          entityType
          name
          imageS3Name
          description
        }
        description
      }
      description
      imageS3Name
      fitmentNotes
      weightG
      widthMM
      heightMM
      lengthMM
      isUniversal
      modelCode
      regYearAndmonth
      mileage
      transmissionType
      engineModel
      engineSize
      fuel
      drive
      manufacturer
      OEM
      src
      make
      model
    }
  }
`;
export const getPhoto = /* GraphQL */ `
  query GetPhoto($id: ID!) {
    getPhoto(id: $id) {
      id
      created
      modified
      entityType
      imageS3Name
      description
      contentType
      title
      creatorType
      creatorId
    }
  }
`;
export const getPhotos = /* GraphQL */ `
  query GetPhotos($limit: Int, $nextToken: String) {
    getPhotos(limit: $limit, nextToken: $nextToken) {
      photos {
        id
        created
        modified
        entityType
        imageS3Name
        description
        contentType
        title
        creatorType
        creatorId
      }
      nextToken
    }
  }
`;
export const getPhotosByCreator = /* GraphQL */ `
  query GetPhotosByCreator($input: byCreatorInput) {
    getPhotosByCreator(input: $input) {
      photos {
        id
        created
        modified
        entityType
        imageS3Name
        description
        contentType
        title
        creatorType
        creatorId
      }
      nextToken
    }
  }
`;
export const getRequest = /* GraphQL */ `
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      request {
        id
        created
        modified
        entityType
        make
        model
        year
        fuel
        gearbox
        variant
        VIN
        engineNumber
        status
        contactDetails
        otherDetails
        creatorId
        creatorType
        quoteIds
        vehicleId
      }
      autoPart {
        autoPart {
          id
          created
          modified
          entityType
          name
          category {
            id
            created
            modified
            entityType
            name
            imageS3Name
            description
          }
          subCategory {
            id
            created
            modified
            entityType
            name
            imageS3Name
            category {
              id
              created
              modified
              entityType
              name
              imageS3Name
              description
            }
            description
          }
          description
          imageS3Name
          fitmentNotes
          weightG
          widthMM
          heightMM
          lengthMM
          isUniversal
          modelCode
          regYearAndmonth
          mileage
          transmissionType
          engineModel
          engineSize
          fuel
          drive
          manufacturer
          OEM
          src
          make
          model
        }
        quantity
      }
      photos {
        id
        created
        modified
        entityType
        imageS3Name
        description
        contentType
        title
        creatorType
        creatorId
      }
      vehicle {
        vehicle {
          id
          created
          modified
          discontinued
          entityType
          make
          model
          engineNumber
          variant
          year
          fuel
          gearbox
          plateNumber
          VIN
          creatorType
          creatorId
          otherDetails
        }
        owner {
          ... on Partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          ... on Customer {
            id
            created
            modified
            entityType
            firstName
            familyName
            gender
            imageS3Name
            birthdate
            phoneNumber
            email
          }
        }
      }
    }
  }
`;
export const getServiceFee = /* GraphQL */ `
  query GetServiceFee($date: String!) {
    getServiceFee(date: $date) {
      ... on ServiceFee {
        fee
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const searchRequests = /* GraphQL */ `
  query SearchRequests($input: searchInput!) {
    searchRequests(input: $input) {
      all {
        requests {
          request {
            id
            created
            modified
            entityType
            make
            model
            year
            fuel
            gearbox
            variant
            VIN
            engineNumber
            status
            contactDetails
            otherDetails
            creatorId
            creatorType
            quoteIds
            vehicleId
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quantity
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
          vehicle {
            vehicle {
              id
              created
              modified
              discontinued
              entityType
              make
              model
              engineNumber
              variant
              year
              fuel
              gearbox
              plateNumber
              VIN
              creatorType
              creatorId
              otherDetails
            }
            owner {
              ... on Partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              ... on Customer {
                id
                created
                modified
                entityType
                firstName
                familyName
                gender
                imageS3Name
                birthdate
                phoneNumber
                email
              }
            }
          }
        }
        nextToken
      }
      byStatus {
        requests {
          request {
            id
            created
            modified
            entityType
            make
            model
            year
            fuel
            gearbox
            variant
            VIN
            engineNumber
            status
            contactDetails
            otherDetails
            creatorId
            creatorType
            quoteIds
            vehicleId
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quantity
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
          vehicle {
            vehicle {
              id
              created
              modified
              discontinued
              entityType
              make
              model
              engineNumber
              variant
              year
              fuel
              gearbox
              plateNumber
              VIN
              creatorType
              creatorId
              otherDetails
            }
            owner {
              ... on Partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              ... on Customer {
                id
                created
                modified
                entityType
                firstName
                familyName
                gender
                imageS3Name
                birthdate
                phoneNumber
                email
              }
            }
          }
        }
        nextToken
      }
      byCustomer {
        requests {
          request {
            id
            created
            modified
            entityType
            make
            model
            year
            fuel
            gearbox
            variant
            VIN
            engineNumber
            status
            contactDetails
            otherDetails
            creatorId
            creatorType
            quoteIds
            vehicleId
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quantity
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
          vehicle {
            vehicle {
              id
              created
              modified
              discontinued
              entityType
              make
              model
              engineNumber
              variant
              year
              fuel
              gearbox
              plateNumber
              VIN
              creatorType
              creatorId
              otherDetails
            }
            owner {
              ... on Partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              ... on Customer {
                id
                created
                modified
                entityType
                firstName
                familyName
                gender
                imageS3Name
                birthdate
                phoneNumber
                email
              }
            }
          }
        }
        nextToken
      }
      byDateRange {
        requests {
          request {
            id
            created
            modified
            entityType
            make
            model
            year
            fuel
            gearbox
            variant
            VIN
            engineNumber
            status
            contactDetails
            otherDetails
            creatorId
            creatorType
            quoteIds
            vehicleId
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quantity
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
          vehicle {
            vehicle {
              id
              created
              modified
              discontinued
              entityType
              make
              model
              engineNumber
              variant
              year
              fuel
              gearbox
              plateNumber
              VIN
              creatorType
              creatorId
              otherDetails
            }
            owner {
              ... on Partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              ... on Customer {
                id
                created
                modified
                entityType
                firstName
                familyName
                gender
                imageS3Name
                birthdate
                phoneNumber
                email
              }
            }
          }
        }
        nextToken
      }
      byCustomerAndStatus {
        requests {
          request {
            id
            created
            modified
            entityType
            make
            model
            year
            fuel
            gearbox
            variant
            VIN
            engineNumber
            status
            contactDetails
            otherDetails
            creatorId
            creatorType
            quoteIds
            vehicleId
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quantity
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
          vehicle {
            vehicle {
              id
              created
              modified
              discontinued
              entityType
              make
              model
              engineNumber
              variant
              year
              fuel
              gearbox
              plateNumber
              VIN
              creatorType
              creatorId
              otherDetails
            }
            owner {
              ... on Partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              ... on Customer {
                id
                created
                modified
                entityType
                firstName
                familyName
                gender
                imageS3Name
                birthdate
                phoneNumber
                email
              }
            }
          }
        }
        nextToken
      }
      byStatusAndDateRange {
        requests {
          request {
            id
            created
            modified
            entityType
            make
            model
            year
            fuel
            gearbox
            variant
            VIN
            engineNumber
            status
            contactDetails
            otherDetails
            creatorId
            creatorType
            quoteIds
            vehicleId
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quantity
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
          vehicle {
            vehicle {
              id
              created
              modified
              discontinued
              entityType
              make
              model
              engineNumber
              variant
              year
              fuel
              gearbox
              plateNumber
              VIN
              creatorType
              creatorId
              otherDetails
            }
            owner {
              ... on Partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              ... on Customer {
                id
                created
                modified
                entityType
                firstName
                familyName
                gender
                imageS3Name
                birthdate
                phoneNumber
                email
              }
            }
          }
        }
        nextToken
      }
      byCustomerAndDateRange {
        requests {
          request {
            id
            created
            modified
            entityType
            make
            model
            year
            fuel
            gearbox
            variant
            VIN
            engineNumber
            status
            contactDetails
            otherDetails
            creatorId
            creatorType
            quoteIds
            vehicleId
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quantity
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
          vehicle {
            vehicle {
              id
              created
              modified
              discontinued
              entityType
              make
              model
              engineNumber
              variant
              year
              fuel
              gearbox
              plateNumber
              VIN
              creatorType
              creatorId
              otherDetails
            }
            owner {
              ... on Partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              ... on Customer {
                id
                created
                modified
                entityType
                firstName
                familyName
                gender
                imageS3Name
                birthdate
                phoneNumber
                email
              }
            }
          }
        }
        nextToken
      }
      byStatusAndDateRangeAndCustomer {
        requests {
          request {
            id
            created
            modified
            entityType
            make
            model
            year
            fuel
            gearbox
            variant
            VIN
            engineNumber
            status
            contactDetails
            otherDetails
            creatorId
            creatorType
            quoteIds
            vehicleId
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quantity
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
          vehicle {
            vehicle {
              id
              created
              modified
              discontinued
              entityType
              make
              model
              engineNumber
              variant
              year
              fuel
              gearbox
              plateNumber
              VIN
              creatorType
              creatorId
              otherDetails
            }
            owner {
              ... on Partner {
                id
                created
                modified
                entityType
                name
                imageS3Name
                email
                phoneNumber
                address
                bankAccount
                mainContactPerson
                subscribedCategories
                mobileMoneyDetails {
                  processor
                  name
                  number
                }
              }
              ... on Customer {
                id
                created
                modified
                entityType
                firstName
                familyName
                gender
                imageS3Name
                birthdate
                phoneNumber
                email
              }
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const getRequestsByCreator = /* GraphQL */ `
  query GetRequestsByCreator($id: ID, $creatorType: Creator!) {
    getRequestsByCreator(id: $id, creatorType: $creatorType) {
      id
      created
      modified
      entityType
      make
      model
      year
      fuel
      gearbox
      variant
      VIN
      engineNumber
      status
      contactDetails
      otherDetails
      creatorId
      creatorType
      quoteIds
      vehicleId
    }
  }
`;
export const getQuote = /* GraphQL */ `
  query GetQuote($id: ID!) {
    getQuote(id: $id) {
      quote {
        id
        created
        modified
        entityType
        comment
        status
        expiryDate
        total
        isNew
      }
      partner {
        id
        created
        modified
        entityType
        name
        imageS3Name
        email
        phoneNumber
        address
        bankAccount
        mainContactPerson
        subscribedCategories
        mobileMoneyDetails {
          processor
          name
          number
        }
      }
      request {
        request {
          id
          created
          modified
          entityType
          make
          model
          year
          fuel
          gearbox
          variant
          VIN
          engineNumber
          status
          contactDetails
          otherDetails
          creatorId
          creatorType
          quoteIds
          vehicleId
        }
        autoPart {
          autoPart {
            id
            created
            modified
            entityType
            name
            category {
              id
              created
              modified
              entityType
              name
              imageS3Name
              description
            }
            subCategory {
              id
              created
              modified
              entityType
              name
              imageS3Name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              description
            }
            description
            imageS3Name
            fitmentNotes
            weightG
            widthMM
            heightMM
            lengthMM
            isUniversal
            modelCode
            regYearAndmonth
            mileage
            transmissionType
            engineModel
            engineSize
            fuel
            drive
            manufacturer
            OEM
            src
            make
            model
          }
          quantity
        }
        photos {
          id
          created
          modified
          entityType
          imageS3Name
          description
          contentType
          title
          creatorType
          creatorId
        }
        vehicle {
          vehicle {
            id
            created
            modified
            discontinued
            entityType
            make
            model
            engineNumber
            variant
            year
            fuel
            gearbox
            plateNumber
            VIN
            creatorType
            creatorId
            otherDetails
          }
          owner {
            ... on Partner {
              id
              created
              modified
              entityType
              name
              imageS3Name
              email
              phoneNumber
              address
              bankAccount
              mainContactPerson
              subscribedCategories
              mobileMoneyDetails {
                processor
                name
                number
              }
            }
            ... on Customer {
              id
              created
              modified
              entityType
              firstName
              familyName
              gender
              imageS3Name
              birthdate
              phoneNumber
              email
            }
          }
        }
      }
      autoPart {
        autoPart {
          id
          created
          modified
          entityType
          name
          category {
            id
            created
            modified
            entityType
            name
            imageS3Name
            description
          }
          subCategory {
            id
            created
            modified
            entityType
            name
            imageS3Name
            category {
              id
              created
              modified
              entityType
              name
              imageS3Name
              description
            }
            description
          }
          description
          imageS3Name
          fitmentNotes
          weightG
          widthMM
          heightMM
          lengthMM
          isUniversal
          modelCode
          regYearAndmonth
          mileage
          transmissionType
          engineModel
          engineSize
          fuel
          drive
          manufacturer
          OEM
          src
          make
          model
        }
        quotedPrice
        isSold
        soldPrice
        comment
        paymentOptions {
          finalFee
          chargeFee
          serviceFee
          deliveryFee
          deliveryWithdrawalFee
          withdrawalFee
          processorChargePercentage
          processorChargeRate
          processorPayoutPercentage
          processorPayoutRate
          serviceFeeDate
          partnerPayoutAmount
          deliveryPayoutAmount
          isDeliveryIncluded
          processor
          quotedPrice
          payoutFee
        }
      }
      photos {
        id
        created
        modified
        entityType
        imageS3Name
        description
        contentType
        title
        creatorType
        creatorId
      }
    }
  }
`;
export const getQuotesByRequest = /* GraphQL */ `
  query GetQuotesByRequest($input: byRequestInput) {
    getQuotesByRequest(input: $input) {
      ... on QuotePage {
        quotes {
          quote {
            id
            created
            modified
            entityType
            comment
            status
            expiryDate
            total
            isNew
          }
          partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          request {
            request {
              id
              created
              modified
              entityType
              make
              model
              year
              fuel
              gearbox
              variant
              VIN
              engineNumber
              status
              contactDetails
              otherDetails
              creatorId
              creatorType
              quoteIds
              vehicleId
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quantity
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
            vehicle {
              vehicle {
                id
                created
                modified
                discontinued
                entityType
                make
                model
                engineNumber
                variant
                year
                fuel
                gearbox
                plateNumber
                VIN
                creatorType
                creatorId
                otherDetails
              }
              owner {
                ... on Partner {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  email
                  phoneNumber
                  address
                  bankAccount
                  mainContactPerson
                  subscribedCategories
                  mobileMoneyDetails {
                    processor
                    name
                    number
                  }
                }
                ... on Customer {
                  id
                  created
                  modified
                  entityType
                  firstName
                  familyName
                  gender
                  imageS3Name
                  birthdate
                  phoneNumber
                  email
                }
              }
            }
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quotedPrice
            isSold
            soldPrice
            comment
            paymentOptions {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
        }
        nextToken
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const searchQuotes = /* GraphQL */ `
  query SearchQuotes($input: searchInput!) {
    searchQuotes(input: $input) {
      all {
        quotes {
          quote {
            id
            created
            modified
            entityType
            comment
            status
            expiryDate
            total
            isNew
          }
          partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          request {
            request {
              id
              created
              modified
              entityType
              make
              model
              year
              fuel
              gearbox
              variant
              VIN
              engineNumber
              status
              contactDetails
              otherDetails
              creatorId
              creatorType
              quoteIds
              vehicleId
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quantity
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
            vehicle {
              vehicle {
                id
                created
                modified
                discontinued
                entityType
                make
                model
                engineNumber
                variant
                year
                fuel
                gearbox
                plateNumber
                VIN
                creatorType
                creatorId
                otherDetails
              }
              owner {
                ... on Partner {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  email
                  phoneNumber
                  address
                  bankAccount
                  mainContactPerson
                  subscribedCategories
                  mobileMoneyDetails {
                    processor
                    name
                    number
                  }
                }
                ... on Customer {
                  id
                  created
                  modified
                  entityType
                  firstName
                  familyName
                  gender
                  imageS3Name
                  birthdate
                  phoneNumber
                  email
                }
              }
            }
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quotedPrice
            isSold
            soldPrice
            comment
            paymentOptions {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
        }
        nextToken
      }
      byStatus {
        quotes {
          quote {
            id
            created
            modified
            entityType
            comment
            status
            expiryDate
            total
            isNew
          }
          partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          request {
            request {
              id
              created
              modified
              entityType
              make
              model
              year
              fuel
              gearbox
              variant
              VIN
              engineNumber
              status
              contactDetails
              otherDetails
              creatorId
              creatorType
              quoteIds
              vehicleId
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quantity
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
            vehicle {
              vehicle {
                id
                created
                modified
                discontinued
                entityType
                make
                model
                engineNumber
                variant
                year
                fuel
                gearbox
                plateNumber
                VIN
                creatorType
                creatorId
                otherDetails
              }
              owner {
                ... on Partner {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  email
                  phoneNumber
                  address
                  bankAccount
                  mainContactPerson
                  subscribedCategories
                  mobileMoneyDetails {
                    processor
                    name
                    number
                  }
                }
                ... on Customer {
                  id
                  created
                  modified
                  entityType
                  firstName
                  familyName
                  gender
                  imageS3Name
                  birthdate
                  phoneNumber
                  email
                }
              }
            }
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quotedPrice
            isSold
            soldPrice
            comment
            paymentOptions {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
        }
        nextToken
      }
      byPartner {
        quotes {
          quote {
            id
            created
            modified
            entityType
            comment
            status
            expiryDate
            total
            isNew
          }
          partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          request {
            request {
              id
              created
              modified
              entityType
              make
              model
              year
              fuel
              gearbox
              variant
              VIN
              engineNumber
              status
              contactDetails
              otherDetails
              creatorId
              creatorType
              quoteIds
              vehicleId
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quantity
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
            vehicle {
              vehicle {
                id
                created
                modified
                discontinued
                entityType
                make
                model
                engineNumber
                variant
                year
                fuel
                gearbox
                plateNumber
                VIN
                creatorType
                creatorId
                otherDetails
              }
              owner {
                ... on Partner {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  email
                  phoneNumber
                  address
                  bankAccount
                  mainContactPerson
                  subscribedCategories
                  mobileMoneyDetails {
                    processor
                    name
                    number
                  }
                }
                ... on Customer {
                  id
                  created
                  modified
                  entityType
                  firstName
                  familyName
                  gender
                  imageS3Name
                  birthdate
                  phoneNumber
                  email
                }
              }
            }
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quotedPrice
            isSold
            soldPrice
            comment
            paymentOptions {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
        }
        nextToken
      }
      byDateRange {
        quotes {
          quote {
            id
            created
            modified
            entityType
            comment
            status
            expiryDate
            total
            isNew
          }
          partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          request {
            request {
              id
              created
              modified
              entityType
              make
              model
              year
              fuel
              gearbox
              variant
              VIN
              engineNumber
              status
              contactDetails
              otherDetails
              creatorId
              creatorType
              quoteIds
              vehicleId
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quantity
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
            vehicle {
              vehicle {
                id
                created
                modified
                discontinued
                entityType
                make
                model
                engineNumber
                variant
                year
                fuel
                gearbox
                plateNumber
                VIN
                creatorType
                creatorId
                otherDetails
              }
              owner {
                ... on Partner {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  email
                  phoneNumber
                  address
                  bankAccount
                  mainContactPerson
                  subscribedCategories
                  mobileMoneyDetails {
                    processor
                    name
                    number
                  }
                }
                ... on Customer {
                  id
                  created
                  modified
                  entityType
                  firstName
                  familyName
                  gender
                  imageS3Name
                  birthdate
                  phoneNumber
                  email
                }
              }
            }
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quotedPrice
            isSold
            soldPrice
            comment
            paymentOptions {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
        }
        nextToken
      }
      byPartnerAndStatus {
        quotes {
          quote {
            id
            created
            modified
            entityType
            comment
            status
            expiryDate
            total
            isNew
          }
          partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          request {
            request {
              id
              created
              modified
              entityType
              make
              model
              year
              fuel
              gearbox
              variant
              VIN
              engineNumber
              status
              contactDetails
              otherDetails
              creatorId
              creatorType
              quoteIds
              vehicleId
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quantity
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
            vehicle {
              vehicle {
                id
                created
                modified
                discontinued
                entityType
                make
                model
                engineNumber
                variant
                year
                fuel
                gearbox
                plateNumber
                VIN
                creatorType
                creatorId
                otherDetails
              }
              owner {
                ... on Partner {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  email
                  phoneNumber
                  address
                  bankAccount
                  mainContactPerson
                  subscribedCategories
                  mobileMoneyDetails {
                    processor
                    name
                    number
                  }
                }
                ... on Customer {
                  id
                  created
                  modified
                  entityType
                  firstName
                  familyName
                  gender
                  imageS3Name
                  birthdate
                  phoneNumber
                  email
                }
              }
            }
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quotedPrice
            isSold
            soldPrice
            comment
            paymentOptions {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
        }
        nextToken
      }
      byStatusAndDateRange {
        quotes {
          quote {
            id
            created
            modified
            entityType
            comment
            status
            expiryDate
            total
            isNew
          }
          partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          request {
            request {
              id
              created
              modified
              entityType
              make
              model
              year
              fuel
              gearbox
              variant
              VIN
              engineNumber
              status
              contactDetails
              otherDetails
              creatorId
              creatorType
              quoteIds
              vehicleId
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quantity
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
            vehicle {
              vehicle {
                id
                created
                modified
                discontinued
                entityType
                make
                model
                engineNumber
                variant
                year
                fuel
                gearbox
                plateNumber
                VIN
                creatorType
                creatorId
                otherDetails
              }
              owner {
                ... on Partner {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  email
                  phoneNumber
                  address
                  bankAccount
                  mainContactPerson
                  subscribedCategories
                  mobileMoneyDetails {
                    processor
                    name
                    number
                  }
                }
                ... on Customer {
                  id
                  created
                  modified
                  entityType
                  firstName
                  familyName
                  gender
                  imageS3Name
                  birthdate
                  phoneNumber
                  email
                }
              }
            }
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quotedPrice
            isSold
            soldPrice
            comment
            paymentOptions {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
        }
        nextToken
      }
      byPartnerAndDateRange {
        quotes {
          quote {
            id
            created
            modified
            entityType
            comment
            status
            expiryDate
            total
            isNew
          }
          partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          request {
            request {
              id
              created
              modified
              entityType
              make
              model
              year
              fuel
              gearbox
              variant
              VIN
              engineNumber
              status
              contactDetails
              otherDetails
              creatorId
              creatorType
              quoteIds
              vehicleId
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quantity
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
            vehicle {
              vehicle {
                id
                created
                modified
                discontinued
                entityType
                make
                model
                engineNumber
                variant
                year
                fuel
                gearbox
                plateNumber
                VIN
                creatorType
                creatorId
                otherDetails
              }
              owner {
                ... on Partner {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  email
                  phoneNumber
                  address
                  bankAccount
                  mainContactPerson
                  subscribedCategories
                  mobileMoneyDetails {
                    processor
                    name
                    number
                  }
                }
                ... on Customer {
                  id
                  created
                  modified
                  entityType
                  firstName
                  familyName
                  gender
                  imageS3Name
                  birthdate
                  phoneNumber
                  email
                }
              }
            }
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quotedPrice
            isSold
            soldPrice
            comment
            paymentOptions {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
        }
        nextToken
      }
      byStatusAndDateRangeAndPartner {
        quotes {
          quote {
            id
            created
            modified
            entityType
            comment
            status
            expiryDate
            total
            isNew
          }
          partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          request {
            request {
              id
              created
              modified
              entityType
              make
              model
              year
              fuel
              gearbox
              variant
              VIN
              engineNumber
              status
              contactDetails
              otherDetails
              creatorId
              creatorType
              quoteIds
              vehicleId
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quantity
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
            vehicle {
              vehicle {
                id
                created
                modified
                discontinued
                entityType
                make
                model
                engineNumber
                variant
                year
                fuel
                gearbox
                plateNumber
                VIN
                creatorType
                creatorId
                otherDetails
              }
              owner {
                ... on Partner {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  email
                  phoneNumber
                  address
                  bankAccount
                  mainContactPerson
                  subscribedCategories
                  mobileMoneyDetails {
                    processor
                    name
                    number
                  }
                }
                ... on Customer {
                  id
                  created
                  modified
                  entityType
                  firstName
                  familyName
                  gender
                  imageS3Name
                  birthdate
                  phoneNumber
                  email
                }
              }
            }
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quotedPrice
            isSold
            soldPrice
            comment
            paymentOptions {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
        }
        nextToken
      }
    }
  }
`;
export const getConversationsByCreator = /* GraphQL */ `
  query GetConversationsByCreator(
    $id: ID
    $limit: Int
    $nextToken: String
    $senderType: Creator!
  ) {
    getConversationsByCreator(
      id: $id
      limit: $limit
      nextToken: $nextToken
      senderType: $senderType
    ) {
      conversations {
        conversation {
          id
          created
          modified
          entityType
          members {
            ... on Partner {
              id
              created
              modified
              entityType
              name
              imageS3Name
              email
              phoneNumber
              address
              bankAccount
              mainContactPerson
              subscribedCategories
              mobileMoneyDetails {
                processor
                name
                number
              }
            }
            ... on Customer {
              id
              created
              modified
              entityType
              firstName
              familyName
              gender
              imageS3Name
              birthdate
              phoneNumber
              email
            }
          }
        }
        messages {
          date
          message {
            id
            created
            modified
            entityType
            message
            conversationId
            senderId
            senderType
            imageS3Name
          }
          sender {
            ... on Partner {
              id
              created
              modified
              entityType
              name
              imageS3Name
              email
              phoneNumber
              address
              bankAccount
              mainContactPerson
              subscribedCategories
              mobileMoneyDetails {
                processor
                name
                number
              }
            }
            ... on Customer {
              id
              created
              modified
              entityType
              firstName
              familyName
              gender
              imageS3Name
              birthdate
              phoneNumber
              email
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      conversation {
        id
        created
        modified
        entityType
        members {
          ... on Partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          ... on Customer {
            id
            created
            modified
            entityType
            firstName
            familyName
            gender
            imageS3Name
            birthdate
            phoneNumber
            email
          }
        }
      }
      messages {
        date
        message {
          id
          created
          modified
          entityType
          message
          conversationId
          senderId
          senderType
          imageS3Name
        }
        sender {
          ... on Partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          ... on Customer {
            id
            created
            modified
            entityType
            firstName
            familyName
            gender
            imageS3Name
            birthdate
            phoneNumber
            email
          }
        }
      }
    }
  }
`;
export const getMessagesByConversation = /* GraphQL */ `
  query GetMessagesByConversation($id: ID!, $limit: Int, $nextToken: String) {
    getMessagesByConversation(id: $id, limit: $limit, nextToken: $nextToken) {
      messages {
        date
        message {
          id
          created
          modified
          entityType
          message
          conversationId
          senderId
          senderType
          imageS3Name
        }
        sender {
          ... on Partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          ... on Customer {
            id
            created
            modified
            entityType
            firstName
            familyName
            gender
            imageS3Name
            birthdate
            phoneNumber
            email
          }
        }
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      date
      message {
        id
        created
        modified
        entityType
        message
        conversationId
        senderId
        senderType
        imageS3Name
      }
      sender {
        ... on Partner {
          id
          created
          modified
          entityType
          name
          imageS3Name
          email
          phoneNumber
          address
          bankAccount
          mainContactPerson
          subscribedCategories
          mobileMoneyDetails {
            processor
            name
            number
          }
        }
        ... on Customer {
          id
          created
          modified
          entityType
          firstName
          familyName
          gender
          imageS3Name
          birthdate
          phoneNumber
          email
        }
      }
    }
  }
`;
