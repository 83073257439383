/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Heading, Select, useToast, VStack } from '@chakra-ui/react';
import { range } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import {
  createVehicleInput_ as createVehicleInput,
  GearBox,
} from '../../../API';
import {
  getCarEngines,
  getCarMakes,
  getCarModels,
  getCarModelVariants,
} from '../../../api/queries';
import Combobox from '../../../components/auto-complete';
import NextBtn from '../../../components/Stepper/next';
import VehicleList from '../../../components/vehicle-slider/vehicleList';
import useVehicle from '../../../hooks/useVehicle';
import {
  isAuthenticatedAtom,
  selectedVehicle,
  openCreateVehicle,
  requestStepAtom,
  partialVehicleAtom,
  makeValueAtom,
  modelValueAtom,
  yearValueAtom,
  variantValueAtom,
  engineValueAtom,
  isVehicleSelectedAtom,
  fuelAtom,
} from '../../../recoil/atoms';
import { CarField } from '../../../types/interfaces';
import { getPinpointButtonClickEventProp } from '../../../utils/functions-ts';
import { useLocation } from 'react-router-dom';

export interface Item {
  label: string;
  value: string;
}
const futureDate = new Date().getFullYear() + 1;

const MyCars: React.FC = (): JSX.Element => {
  // SECTION: initialize and set the state of the component
  const isAuthenticated = useRecoilValue(isAuthenticatedAtom);
  const [partialVehicle, setPartialVehicle] =
    useRecoilState(partialVehicleAtom);
  const { pathname } = useLocation();
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  // eslint-disable-next-line no-unused-vars
  const [clearForm, setClearForm] = useState<boolean>(false);
  const [makeValue, setMakeValue] = useRecoilState(makeValueAtom);
  const [modelValue, setModelValue] = useRecoilState(modelValueAtom);
  const [year, setYear] = useRecoilState(yearValueAtom);
  const [variantValue, setVariantValue] = useRecoilState(variantValueAtom);
  const [fuel, setFuel] = useRecoilState(fuelAtom);
  const [engineValue, setEngineValue] = useRecoilState(engineValueAtom);
  const selectV = useRecoilValue(selectedVehicle);
  const setIsVehicleSelected = useSetRecoilState(isVehicleSelectedAtom);
  const [showForm, setShowForm] = useRecoilState(openCreateVehicle);
  const [activeStep, setActiveStep] = useRecoilState(requestStepAtom);
  const toast = useToast();

  useEffect(() => {
    setBtnDisable(
      Boolean(!makeValue) ||
        Boolean(!modelValue) ||
        Boolean(!fuel) ||
        Boolean(!year) ||
        year === futureDate ||
        Boolean(!engineValue),
    );
  }, [makeValue, modelValue, year, fuel, engineValue]);
  //console.log(makeValue, modelValue, year, variantValue, engineValue);
  //console.log('MAKE::VALUE', makeValue === '');

  const carMakes = useQuery(['car-makes', { isAuthenticated }], getCarMakes);
  const carModels = useQuery(
    [
      'car-make-models',
      { isAuthenticated, makeValue, carMakes: carMakes.data },
    ],
    getCarModels,
    { enabled: carMakes.isSuccess && !!carMakes.data },
  );
  const carVariants = useQuery(
    [
      'car-make-model-variant',
      { isAuthenticated, modelValue, carModels: carModels.data },
    ],
    getCarModelVariants,
    { enabled: carModels.isSuccess && !!carModels.data },
  );
  const carEngines = useQuery(
    [
      'car-make-model-variant-engine',
      {
        isAuthenticated,
        modelValue,
        carModels: carModels.data,
        variantValue,
        carVariants: carVariants.data,
      },
    ],
    getCarEngines,
    { enabled: carVariants.isSuccess && !!carVariants.data },
  );

  // NOTE: initialize Vehicle object to be used in the createVehicle mutation
  const vehicle: createVehicleInput = {
    make: makeValue,
    model: modelValue,
    year,
    gearbox: GearBox.AUTOMATIC,
    variant: variantValue || ' ',
    plateNumber: '',
    fuel,
    VIN: '',
    engineNumber: engineValue,
  };

  if (isNaN(year)) {
    setYear(futureDate);
  }

  const CloseForm: () => void = () => {
    setShowForm(false);
  };

  const nextStep = () => {
    if (showForm && btnDisable) {
      toast({
        // NOTE: else show a toast when the user is not signed in successfully
        title: 'Please Enter all fields',
        description: 'make sure you provide all vehicle fields required',
        status: 'info',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
      return;
    }
    if (!btnDisable && showForm) {
      setPartialVehicle(vehicle);
      setIsVehicleSelected(false);
    }
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    if (partialVehicle !== null) {
      setMakeValue(partialVehicle.make);
      setModelValue(partialVehicle.model);
      setYear(partialVehicle.year as number);
      setVariantValue(partialVehicle.variant);
      setFuel(partialVehicle.fuel as string);
      setEngineValue(partialVehicle.engineNumber);
    }
  }, [partialVehicle]);

  useEffect(() => {
    if (!isAuthenticated || selectV === null) {
      setShowForm(true);
    }
  }, []);

  const { data, isError, isLoading, refetch } = useVehicle();

  const yearFunc = (year: number) => {
    if (year === futureDate) {
      return '';
    }
    return year;
  };

  //console.log('vehicle data', data);
  // NOTE: check if the vehicle was created successfully or not and render a toast message alerting the user the status
  return (
    <VStack
      w={{
        base: 'full',
        md: '30%',
      }}
      bg={'white'}
      justifyContent={'space-between'}
      alignItems={'center'}
      h={'370px'}
      rounded={'lg'}
      border={'1px'}
      borderColor={'gray.200'}
      data-component="create-vehicle"
      my={1}
    >
      <VStack w={'full'} p={'2'} rounded={'xl'}>
        {showForm && isAuthenticated && selectV !== null && (
          <div className="w-full px-1 flex justify-end items-center">
            <Button
              variant={'outline'}
              colorScheme={'blue'}
              size={'sm'}
              onClick={CloseForm}
              fontWeight={'bold'}
              data-btn="create-vehicle-select"
              {...getPinpointButtonClickEventProp({
                command: 'close vehicle form',
                currentPage: pathname,
              })}
            >
              Select from your Vehicles
            </Button>
          </div>
        )}
        <Heading
          data-heading="create-vehicle"
          fontWeight={'medium'}
          fontSize={'md'}
        >
          {!showForm && 'Select a Vehicle'}
        </Heading>
        {/* car make input */}
        {isAuthenticated && !showForm && data && data?.vehicles && (
          <VehicleList
            isLoading={isLoading}
            isError={isError}
            refetch={refetch}
            vehicles={data?.vehicles}
          />
        )}
        {showForm && (
          <>
            <Combobox
              clear={clearForm}
              items={
                carMakes.isSuccess
                  ? carMakes.data.map((x: CarField) => x.name)
                  : []
              }
              callback={e => {
                //console.log('xxx', e === '');
                setMakeValue(e);
              }}
              placeholder={'Make: Toyota, DAF, Jeep...'}
              dataAttr={'create-vehicle-make'}
              isEnabled={Boolean(makeValue)}
              defaultValue={
                (makeValue === '' ? '' : makeValue) || partialVehicle?.make
              }
            />

            {/* car model input */}
            <Combobox
              clear={clearForm}
              items={
                carModels.isSuccess
                  ? carModels.data.map((x: CarField) => x.name)
                  : []
              }
              callback={setModelValue}
              placeholder={'Model: Corolla, 400-Series, Grand Cherokee...'}
              dataAttr={'create-vehicle-model'}
              isEnabled={Boolean(modelValue)}
              defaultValue={modelValue || partialVehicle?.model}
            />
            {/* year input */}
            <Combobox
              clear={clearForm}
              items={range(1970, new Date().getFullYear()).map(x =>
                x.toString(),
              )}
              callback={e => {
                setYear(parseInt(e));
              }}
              placeholder={'Year: 1993, 2005, 2015'}
              dataAttr={'create-vehicle-year'}
              isEnabled={!(year === futureDate)}
              defaultValue={yearFunc(year) || (partialVehicle?.year as number)}
            />

            {/* variant input */}
            <Combobox
              clear={clearForm}
              items={
                carEngines.isSuccess
                  ? carEngines.data.map((x: CarField) => x.name)
                  : []
              }
              callback={setEngineValue}
              placeholder={'Engine: 1.6 SI (84 kW), 3.5 4WD (206 kW)'}
              dataAttr={'create-vehicle-engine'}
              isEnabled={Boolean(engineValue)}
              defaultValue={engineValue || partialVehicle?.engineNumber}
            />
            <Select
              onChange={(e: any) => setFuel(e.target.value)}
              placeholder="Select Fuel Type"
              value={fuel || (partialVehicle?.fuel as string)}
              data-input={'create-vehicle-select'}
            >
              <option value="Petrol">Petrol</option>
              <option value="Diesel">Diesel</option>
              <option value="Electric">Electric</option>
              <option value="Hybrid">Hybrid</option>
            </Select>
          </>
        )}
      </VStack>
      <div className="w-full p-2 flex justify-between items-center">
        <NextBtn
          dataAtr="vehicle-form-back"
          {...getPinpointButtonClickEventProp({
            command: 'got to previous step from step ' + activeStep,
            currentPage: pathname,
          })}
          isPrev
          callback={() => setActiveStep(activeStep - 1)}
        />
        <NextBtn
          dataAtr="vehicle-form-next"
          {...getPinpointButtonClickEventProp({
            command: 'got to next step from step ' + activeStep,
            currentPage: pathname,
          })}
          isNext={true}
          callback={() => nextStep()}
        />
      </div>
    </VStack>
  );
};

export default React.memo(MyCars);

//disabled={Boolean(!makeValue) || Boolean(!modelValue) || Boolean(!year) || Boolean(!variantValue) || Boolean(!engineValue)}
/*
{/ variant input /}
<Combobox
clear={clearForm}
items={
  carEngines.isSuccess
    ? carEngines.data.map((x: CarField) => x.name)
    : []
}
callback={setEngineValue}
placeholder={'Engine: 1.6 SI (84 kW), 3.5 4WD (206 kW)'}
dataAttr={'create-vehicle-engine'}
isEnabled={Boolean(engineValue)}
defaultValue={engineValue || partialVehicle?.engineNumber}
/>

{/ year input /}
<Combobox
clear={clearForm}
items={range(1970, new Date().getFullYear()).map(x =>
  x.toString(),
)}
callback={e => {
  setYear(parseInt(e));
}}
placeholder={'Year: 1993, 2005, 2015'}
dataAttr={'create-vehicle-year'}
isEnabled={!(year === futureDate)}
defaultValue={yearFunc(year) || (partialVehicle?.year as number)}
/>

*/
