import { Auth } from '@aws-amplify/auth';
import {
  Avatar,
  Button,
  Divider,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import { useRef } from 'react';
import { useMutation } from 'react-query';
//import { FaHandshake } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { cognitoUserAtom, isAuthenticatedAtom } from '../../recoil/atoms';
import { paths } from '../../utils/constants';
import { clearCustomAuthSession } from '../../utils/functions';
import Link from '../buttons/link';

import { PhoneIcon } from '@chakra-ui/icons';
import { FaHandsHelping } from 'react-icons/fa';
import { MdHelp } from 'react-icons/md';
import { getPinpointButtonClickEventProp } from '../../utils/functions-ts';
import Logo from '../logo';
import Navigation from '../navigation';
const {
  REACT_APP_STAGE = 'prod',
  REACT_APP_SALES_NUMBER = '+260968318158',
  REACT_APP_TECHNICAL_NUMBER = '+260967575223',
} = process.env;

export default function Header() {
  const [user, setUser] = useRecoilState(cognitoUserAtom);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [authenticated, setIsAuthenticated] =
    useRecoilState(isAuthenticatedAtom);
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const toast = useToast();

  const accountMenuInitialFocusRef = useRef<any>(null);

  const signOutMutation = useMutation(async () => await Auth.signOut(), {
    onSuccess: (data: any) => {
      setUser(null);
      setIsAuthenticated(false);
      clearCustomAuthSession();
      localStorage.clear();
      window.location.reload();
      navigate(paths.home);
    },
    onError: (data: any) => {
      console.log('🚀 ~ file: index.tsx:60 ~ signOutMutation ~ data', data);

      toast({
        title: 'Error Occured!',
        description: 'Failed Sign Out',
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'bottom',
      });
    },
  });

  //const placement = window.innerWidth;
  const linkColor = useColorModeValue('black', 'white');
  const textColor = useColorModeValue('gray.900', 'gray.300');

  const callSupport = async (isSales: boolean) => {
    const num = isSales ? REACT_APP_SALES_NUMBER : REACT_APP_TECHNICAL_NUMBER;
    if (window.innerWidth > 500) {
      try {
        await navigator.clipboard.writeText(num);
        toast({
          status: 'success',
          title: 'Phone copied to clipboard',
          duration: 3000,
          isClosable: true,
          position: 'bottom',
        });
      } catch (err) {
        toast({
          status: 'success',
          title: 'Failed to copy to clipboard',
          duration: 3000,
          isClosable: true,
          position: 'bottom',
        });
      }

      return;
    }

    window.location.href = 'tel:' + num;
  };

  return (
    <HStack
      as={'header'}
      position={'sticky'}
      top={0}
      zIndex={1000}
      width={'100vw'}
      spacing={{
        base: 4,
        md: 6,
      }}
      bg={useColorModeValue('white', 'gray.800')}
      boxShadow={'sm'}
      py={1}
      px={{
        base: 4,
        md: 8,
      }}
    >
      <HStack spacing={2}>
        <div className="flex w-full flex-col justify-center items-center">
          <div className="flex w-full">
            <Logo />
            <Text
              fontSize={'xl'}
              letterSpacing={'0.2'}
              fontWeight={'bold'}
              color={REACT_APP_STAGE !== 'prod' ? 'red' : textColor}
              ml={'2'}
            >
              Riaparts {`${REACT_APP_STAGE !== 'prod' ? 'DEV' : ''}`}
            </Text>
          </div>
          <Text
            fontSize={{
              base: 'xx-small',
              md: 'lg',
            }}
            fontWeight={'medium'}
            color={'#FF7F00'}
            data-heading="home"
          >
            <b>WE HELP YOU FIND AUTO PARTS</b>
          </Text>
        </div>
      </HStack>
      <Spacer />
      <Button
        variant={'outline'}
        color={'brand.500'}
        leftIcon={<PhoneIcon />}
        onClick={onOpen}
        {...getPinpointButtonClickEventProp({
          command: 'call support',
          currentPage: pathname,
        })}
        size={'sm'}
      >
        Call Us
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Who do you want to talk to?</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <HStack width="full">
              <VStack>
                <Button
                  color={'brand.500'}
                  variant={'outline'}
                  leftIcon={<FaHandsHelping />}
                  onClick={() => {
                    callSupport(true);
                  }}
                  {...getPinpointButtonClickEventProp({
                    command: 'call sales support',
                    currentPage: pathname,
                  })}
                >
                  Sales
                </Button>
                <Text
                  fontWeight={'black'}
                  textAlign={'center'}
                  color={'brand.500'}
                >
                  {REACT_APP_SALES_NUMBER}
                </Text>
              </VStack>
              <Spacer />
              <VStack>
                <Button
                  color={'brand.500'}
                  variant={'outline'}
                  leftIcon={<MdHelp />}
                  onClick={() => {
                    callSupport(false);
                  }}
                  {...getPinpointButtonClickEventProp({
                    command: 'call technical support',
                    currentPage: pathname,
                  })}
                >
                  Technical Help
                </Button>{' '}
                <Text
                  fontWeight={'black'}
                  textAlign={'center'}
                  color={'brand.500'}
                >
                  {REACT_APP_TECHNICAL_NUMBER}
                </Text>
              </VStack>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Navigation />
      {authenticated ? (
        <Menu>
          <MenuButton
            as={Avatar}
            src={user?.imageS3Name}
            size={'sm'}
            cursor={'pointer'}
            {...getPinpointButtonClickEventProp({
              command: 'navigation header open menu',
              currentPage: pathname,
            })}
          />
          <MenuList
            as={VStack}
            spacing={2}
            p={4}
            alignItems={'start'}
            width={'full'}
          >
            <Link
              href={paths.settings}
              {...getPinpointButtonClickEventProp({
                command: 'navigation header link to account settings',
                currentPage: pathname,
              })}
              color={{
                base: linkColor,
                md: 'black',
              }}
              fontWeight={'medium'}
              ref={accountMenuInitialFocusRef}
            >
              <Text fontWeight={'medium'}>Account Settings </Text>
            </Link>
            <Link
              href={paths.terms}
              {...getPinpointButtonClickEventProp({
                command: 'navigation header link to terms and conditions',
                currentPage: pathname,
              })}
              color={{
                base: linkColor,
                md: 'black',
              }}
              fontWeight={'medium'}
              ref={accountMenuInitialFocusRef}
            >
              {' '}
              <Text fontWeight={'medium'}>Terms & Conditions</Text>
            </Link>
            <Link
              href={paths.refund}
              {...getPinpointButtonClickEventProp({
                command: 'navigation header link to return and refund policy',
                currentPage: pathname,
              })}
              color={{
                base: linkColor,
                md: 'black',
              }}
              fontWeight={'medium'}
              ref={accountMenuInitialFocusRef}
            >
              {' '}
              <Text fontWeight={'medium'}>Return & Refund Policy</Text>
            </Link>
            <Divider />
            <Button
              size={'sm'}
              width={'full'}
              colorScheme={'red'}
              onClick={() => {
                signOutMutation.mutate();
              }}
              {...getPinpointButtonClickEventProp({
                command: 'navigation header link to logout',
                currentPage: pathname,
              })}
              isLoading={signOutMutation.isLoading}
            >
              Log out
            </Button>
          </MenuList>
        </Menu>
      ) : (
        <Link
          as={'button'}
          href={paths.login}
          bg={'green.400'}
          color={'white'}
          fontSize={'sm'}
          fontWeight={'semibold'}
          letterSpacing={'1px'}
          py={'6px'}
          px={4}
          borderRadius={'4px'}
        >
          {' '}
          Login{' '}
        </Link>
      )}
    </HStack>
  );
}
