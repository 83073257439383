import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: 'column',
    marginTop: 12,
  },
  reportTitle: {
    fontSize: 12,
    textAlign: 'center',
    marginTop: 24,
  },
});

const ThankYouMsg = () => (
  <View style={styles.titleContainer}>
    <Text>The Prices above do not include delivery cost</Text>
    <Text style={styles.reportTitle}>Thank you for your business</Text>
  </View>
);

export default ThankYouMsg;
