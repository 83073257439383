import {
  Box,
  Button,
  Flex,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import {
  updateCustomCustomerAttributes,
  updateCustomer,
} from '../../api/mutations';
import { getPinpointButtonClickEventProp } from '../../utils/functions-ts';

export default function PersonalInfo({ user }) {
  const toast = useToast();
  const { pathname } = useLocation();
  const { handleSubmit, register } = useForm();

  const updateUser = useMutation(updateCustomer);
  const updateCustomAttributes = useMutation(updateCustomCustomerAttributes);

  const updatePrimaryInfo = async formData => {
    let input = {
      ...formData,
      //id: user.id || user.sub,
    };
    input.gender = input.gender ? input.gender.toUpperCase() : input.gender;
    if (!input.birthdate) delete input.birthdate;
    const userData = await updateUser.mutateAsync(input);
    console.log('returned data here', userData);
  };
  // update custom cognito user attributes after update user details.
  useEffect(() => {
    if (updateUser.isSuccess) {
      const formData = updateUser.data;
      let attributes = {};
      if (formData.email) attributes.email = formData.email;
      if (formData.firstName)
        attributes['custom:givenName'] = formData.firstName;
      if (formData.lastName) attributes['custom:lastName'] = formData.lastName;
      updateCustomAttributes.mutate(attributes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUser.isSuccess]);

  useEffect(() => {
    if (updateCustomCustomerAttributes.isSuccess) {
      toast({
        status: 'info',
        title: 'Update was successful',
        duration: 3000,
        position: 'bottom',
      });
    } else if (updateCustomAttributes.isError) {
      toast({
        status: 'error',
        title: 'Update was unsuccesful.',
        duration: 3000,
        position: 'bottom',
      });
    }
  }, [
    updateCustomAttributes.isSuccess,
    updateCustomCustomerAttributes.isError,
  ]);

  return (
    <Box as={'form'} onSubmit={handleSubmit(updatePrimaryInfo)}>
      <VStack spacing={3} width={'full'} as={Flex} align={'flex-start'}>
        <HStack spacing={2} width={'full'}>
          <Text py={0} as={FormLabel} width={'full'} htmlFor="firstName">
            {' '}
            Name:{' '}
          </Text>
          <HStack width={'full'}>
            <Input
              backgroundColor={useColorModeValue('gray.100', 'gray.700')}
              _placeholder={{
                color: useColorModeValue('gray.700', 'gray.400'),
              }}
              id="firstName"
              type={'name'}
              defaultValue={user.firstName || ''}
              disabled={user.firstName === undefined}
              required={true}
              placeholder="First Name"
              {...register('firstName', {
                //
              })}
            />
            <Input
              backgroundColor={useColorModeValue('gray.100', 'gray.700')}
              _placeholder={{
                color: useColorModeValue('gray.700', 'gray.400'),
              }}
              id="lastName"
              type={'name'}
              required={true}
              defaultValue={user.familyName || ''}
              disabled={user.familyName === undefined}
              placeholder="Last Name"
              {...register('familyName', {})}
            />
          </HStack>
        </HStack>
        <HStack spacing={2} width={'full'}>
          <Text py={0} as={FormLabel} width={'full'} htmlFor="phoneNumber">
            {' '}
            Phone Number:{' '}
          </Text>
          <InputGroup>
            <InputRightElement> </InputRightElement>
            <Input
              backgroundColor={useColorModeValue('gray.100', 'gray.700')}
              _placeholder={{
                color: useColorModeValue('gray.700', 'gray.400'),
              }}
              id="phoneNumber"
              type={'tel'}
              required={true}
              defaultValue={user.phoneNumber}
              disabled={user.phoneNumber === undefined}
              placeholder="Enter Phone Number"
              {...register('phoneNumber', {})}
            />
          </InputGroup>
        </HStack>
        <HStack spacing={3} width={'full'}>
          <Text as={FormLabel} width={'full'} htmlFor="gender">
            {' '}
            Gender{' '}
          </Text>
          <InputGroup>
            <InputRightElement></InputRightElement>
            <Select
              backgroundColor={useColorModeValue('gray.100', 'gray.700')}
              _placeholder={{
                color: useColorModeValue('gray.700', 'gray.400'),
              }}
              id="gender"
              required={true}
              defaultValue={user.gender || ''}
              disabled={user.gender === undefined}
              {...register('gender', {
                //
              })}
            >
              <option value={'male'}> Male </option>
              <option value={'female'}> Female </option>
              <option value={'other'}> Other </option>
            </Select>
          </InputGroup>
        </HStack>
        <HStack spacing={3} width={'full'}>
          <Text as={FormLabel} width={'full'} htmlFor="email">
            {' '}
            Email Address:{' '}
          </Text>
          <InputGroup>
            <InputRightElement></InputRightElement>
            <Input
              backgroundColor={useColorModeValue('gray.100', 'gray.700')}
              _placeholder={{
                color: useColorModeValue('gray.700', 'gray.400'),
              }}
              type={'text'}
              id="email"
              defaultValue={user.email}
              disabled={user.email === undefined}
              placeholder="Enter Email Address"
              {...register('email', {
                //
              })}
            />
          </InputGroup>
        </HStack>
        <HStack spacing={3} width={'full'}>
          <Text as={FormLabel} width={'full'} htmlFor="birthdate">
            {' '}
            Birthdate{' '}
          </Text>
          <InputGroup>
            <InputRightElement></InputRightElement>
            <Input
              backgroundColor={useColorModeValue('gray.100', 'gray.700')}
              _placeholder={{
                color: useColorModeValue('gray.700', 'gray.400'),
              }}
              type={'date'}
              id="birthdate"
              defaultValue={user.birthdate}
              disabled={user.birthdate === undefined}
              placeholder="Enter Birth Date"
              {...register('birthdate', {
                // required: true
              })}
            />
          </InputGroup>
        </HStack>
      </VStack>
      <Button
        type="submit"
        my={6}
        color={'white'}
        width={'full'}
        colorScheme={'green'}
        isLoading={updateUser.isLoading || updateCustomAttributes.isLoading}
        {...getPinpointButtonClickEventProp({
          command: 'save personal account changes',
          currentPage: pathname,
        })}
      >
        Save Changes
      </Button>
    </Box>
  );
}
