import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Divider,
  InputGroup,
  InputLeftAddon,
  Input,
  HStack,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import MethodCard from './methodCard';
import { useRecoilState } from 'recoil';
import { methodPayment, methodPaymentSelected } from '../../recoil/atoms';
import { changePaymentProcessorInput, PaymentProcessors } from '../../API';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {
  isMTNNumber,
  isAirtelNumber,
  getPinpointButtonClickEventProp,
} from '../../utils/functions-ts';
import { useMutation } from 'react-query';
import { changePaymentMethod } from '../../api/mutations';
import { useLocation } from 'react-router-dom';

function PaymentMethodModal() {
  const [showModal, setShowModal] = useRecoilState(methodPayment);
  const { pathname } = useLocation();
  const [paymentSelected, setPaymentSelected] = useRecoilState(
    methodPaymentSelected,
  );
  const [processor, setProcessor] = useState<PaymentProcessors | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const toast = useToast();

  const changePaymentMethodMutation = useMutation(changePaymentMethod);

  const wrongPhoneNumberToast = () => {
    toast({
      status: 'info',
      title: `Phone number must be ${processor}`,
      position: 'bottom',
      duration: 3000,
      isClosable: true,
    });
  };

  const errorToast = (msg: string) => {
    toast({
      status: 'info',
      title: msg,
      position: 'bottom',
      duration: 3000,
      isClosable: true,
    });
  };

  //: : changePaymentProcessorInput //
  const onClose = () => {
    setShowModal(false);
    setProcessor(null);
    setPaymentSelected('');
  };

  const SubmitUpdate = async () => {
    const phoneNumberWithCountryCode =
      phoneNumber?.length === 10 ? '+26' + phoneNumber : '+260' + phoneNumber;
    const phoneNumberValidated: string = isValidPhoneNumber(
      phoneNumberWithCountryCode,
    )
      ? phoneNumberWithCountryCode
      : isValidPhoneNumber(phoneNumber)
      ? phoneNumber
      : '';

    //NOTE -  INITIALIZE payment object
    const changePaymentProcessorObj: changePaymentProcessorInput = {
      id: paymentSelected,
      momoPhoneNumber: phoneNumberValidated.replace('+', ''),
      processor: processor as PaymentProcessors,
    };

    if (!processor) {
      // NOTE: call a toast when the phone number is invalid
      return errorToast('Select Mobile money provider');
    }

    if (
      !phoneNumberValidated ||
      phoneNumber.length < 9 ||
      phoneNumber.length > 10
    ) {
      // NOTE: call a toast when the phone number is invalid
      return errorToast('Enter a valid phone number');
    }

    if (
      isMTNNumber(phoneNumberValidated.replace('+', '')) &&
      processor === PaymentProcessors.AIRTEL
    ) {
      return wrongPhoneNumberToast();
    }

    if (
      isAirtelNumber(phoneNumberValidated.replace('+', '')) &&
      processor === PaymentProcessors.MTN
    ) {
      return wrongPhoneNumberToast();
    }

    const changeUpdated = await changePaymentMethodMutation.mutateAsync(
      changePaymentProcessorObj,
    );

    if (
      changeUpdated &&
      ('error' in changeUpdated || 'code' in changeUpdated)
    ) {
      toast({
        status: 'error',
        title: 'Update payment failed',
        description: changeUpdated
          ? changeUpdated?.error
          : "Couldn't Update payment processor",
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
      return;
    }

    if (
      changeUpdated &&
      !('error' in changeUpdated || 'code' in changeUpdated)
    ) {
      toast({
        status: 'success',
        title: 'Successfully Updated payment processor',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
      onClose();
    }

    console.log('I am the response', changeUpdated);
  };

  return (
    <Modal
      size={{
        base: 'xs',
        md: 'md',
      }}
      isOpen={showModal}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Payment Method</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack
            w={'full'}
            p={'2'}
            justifyContent={'space-around'}
            alignItems={'baseline'}
          >
            <MethodCard
              src={'/mtn.svg'}
              callback={() => {
                setProcessor(PaymentProcessors.MTN);
              }}
              isSelected={processor === PaymentProcessors.MTN}
            />
            <MethodCard
              src={'/airtel.svg'}
              callback={() => {
                setProcessor(PaymentProcessors.AIRTEL);
              }}
              isSelected={processor === PaymentProcessors.AIRTEL}
            />
          </HStack>
          <Divider />
          <InputGroup my={'3'}>
            <InputLeftAddon children="+260" />
            <Input
              minLength={9}
              type="text"
              placeholder="9(7/6)0000000"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPhoneNumber(e.target.value);
              }}
              defaultValue={phoneNumber}
            />
          </InputGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme={'brand'}
            mr={3}
            w={'full'}
            size={'sm'}
            onClick={SubmitUpdate}
            isLoading={changePaymentMethodMutation.isLoading}
            {...getPinpointButtonClickEventProp({
              command: 'update payment method',
              currentPage: pathname,
            })}
          >
            Update payment method
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default PaymentMethodModal;

/*!SECTION

<SimpleGrid alignItems={"center"} justifyContent={"center"} w={"full"} my={"2"} spacing={"20px"} columns={[2, null, 3]}> 
              
</SimpleGrid>

*/
