import { Client } from 'typesense';

const {
  REACT_APP_TYPESENSE_HOST,
  REACT_APP_TYPESENSE_PORT,
  REACT_APP_TYPESENSE_PROTOCOL,
  REACT_APP_TYPESENSE_SEARCH_ONLY_KEY,
} = process.env;

const config = {
  nodes: [
    {
      host: REACT_APP_TYPESENSE_HOST, // For Typesense Cloud use xxx.a1.typesense.net
      port: REACT_APP_TYPESENSE_PORT, // For Typesense Cloud use 443
      protocol: REACT_APP_TYPESENSE_PROTOCOL, // For Typesense Cloud use https
    },
  ],
  apiKey: REACT_APP_TYPESENSE_SEARCH_ONLY_KEY,
  connectionTimeoutSeconds: 2,
  numRetries: 8,
};

const searchClient = new Client(config);

export default searchClient;
