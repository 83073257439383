import { Box, Button } from '@chakra-ui/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  AutopartListAtom,
  requestStepAtom,
  AutopartSelectedAtom,
} from '../../recoil/atoms';

const AutoPartList: React.FC = (): JSX.Element => {
  // SECTION: initialize and set the state of the component
  //const user = useRecoilValue(cognitoUserAtom);
  // eslint-disable-next-line no-unused-vars
  const [autopartList, setAutopartList] = useRecoilState(AutopartListAtom);
  const activeStep = useRecoilValue(requestStepAtom);
  const [selectedAutopart, setSelectedAutopart] =
    useRecoilState(AutopartSelectedAtom);
  /*
        **************************** SECTION ************************
        Fetch Vehicles from the database and set the return type to a list of Vehicles using UseQueryResult
  */
  //NOTE: select vehicle if there's no selected vehicle in localStorage  or recoil state

  const deleteAutopart = (id: number) => {
    const findAutopart = autopartList.find(auto => auto.id === id);
    if (findAutopart && selectedAutopart) {
      if (findAutopart.id === selectedAutopart.id) setSelectedAutopart(null);
    }
    const newAutopartArray = autopartList.filter(auto => auto.id !== id);
    //selectedAutopart
    setAutopartList(newAutopartArray);
  };

  const editAutopart = (id: number) => {
    const newAutopart = autopartList.find(auto => auto.id === id);
    if (newAutopart) setSelectedAutopart(newAutopart);
  };

  return (
    <Box
      width={'full'}
      overflow={'scroll'}
      maxH={'140px'}
      padding={'1'}
      data-component="autopart-form-autopart-list"
    >
      {autopartList && (
        <table
          className="w-full border-collapse"
          data-component="autopart-form-autopart-list-table"
        >
          <tbody>
            {autopartList.map((autopart, i) => (
              <tr
                data-component={`autopart-form-autopart-list-table-row-${
                  i + 1
                }`}
                key={i}
                className="border-b border-gray-300 flex items-center justify-between"
              >
                <td
                  data-text={`autopart-form-autopart-list-table-row-quantity-${
                    i + 1
                  }`}
                  className="flex items-center gap-3 mr-2"
                >
                  <p>{autopart.quantity}</p>
                  <p className=" font-medium">&times;</p>
                </td>
                <td
                  data-text={`autopart-form-autopart-list-table-row-name-${
                    i + 1
                  }`}
                  className="text-left"
                >
                  <p className="text-left self-start justify-self-start">
                    {autopart.name}
                  </p>
                </td>
                {activeStep !== 3 && selectedAutopart?.id !== autopart.id && (
                  <td className="text-sm p-1 text-center">
                    <Button
                      variant={'link'}
                      size={'xs'}
                      colorScheme={'blue'}
                      onClick={() => editAutopart(autopart.id as number)}
                      data-btn={`autopart-form-autopart-list-table-row-edit-${
                        i + 1
                      }`}
                    >
                      Edit
                    </Button>
                    {' | '}
                    <Button
                      variant={'link'}
                      size={'xs'}
                      colorScheme={'red'}
                      onClick={() => deleteAutopart(autopart.id as number)}
                      data-btn={`autopart-form-autopart-list-table-row-del-${
                        i + 1
                      }`}
                    >
                      Delete
                    </Button>{' '}
                  </td>
                )}

                {activeStep !== 3 &&
                  selectedAutopart !== null &&
                  selectedAutopart.id === autopart.id && (
                    <td className="border text-sm p-1 text-center">
                      <Button
                        variant={'link'}
                        size={'xs'}
                        colorScheme={'blue'}
                        onClick={() => setSelectedAutopart(null)}
                        data-btn={'autopart-form-autopart-list-table-cancel'}
                      >
                        Cancel Edit
                      </Button>
                    </td>
                  )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Box>
  );
};

export default AutoPartList;
