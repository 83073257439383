import {
  Avatar,
  Box,
  Flex,
  HStack,
  ScaleFade,
  Spacer,
  Spinner,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { sendMessage, updateQuote } from '../../../../../api/mutations';
import { useMutation } from 'react-query';
import {
  selectedConversationAtom,
  cognitoUserAtom,
} from '../../../../../recoil/atoms';
import {
  formatPrice,
  getPartnerFromConversationMembers,
} from '../../../../../utils/functions';
import { paths } from '../../../../../utils/constants';

export default forwardRef(function (
  { message, isLastMessageBySenderInSubMessageList, ...rest },
  ref,
) {
  const user = useRecoilValue(cognitoUserAtom);
  const conversation = useRecoilValue(selectedConversationAtom);
  const partner = getPartnerFromConversationMembers(
    conversation.conversation.members,
  );
  const { data } = message;
  const quantity =
    data && data.quote && data.quote.autoPart && data.quote.autoPart.quantity;
  const navigate = useNavigate();

  const updateQuoteMutation = useMutation(updateQuote);
  const sendMessageMutation = useMutation(sendMessage);

  async function acceptProposal() {
    try {
      const quote = await updateQuoteMutation.mutateAsync({
        id: data.quote.id,

        autoPartQuote: {
          autoPartId: data.quote.autoPart.autoPart.id,
          quotedPrice: data.offeredAmount,
        },
      });
      console.log(quote.quote);
      sendMessageMutation.mutate({
        message: JSON.stringify({
          type: 'customer-accepted',
          quote,
          acceptedAmount: data.proposedAmount,
        }),
        senderId: user.id,
        senderType: 'CUSTOMER',
        conversationId: conversation.conversation.id,
      });
      navigate(paths.quotes + '/' + quote.quote.id);
    } catch (error) {
      console.log(error);
    }
  }

  async function declineProposal() {
    try {
      await sendMessageMutation.mutateAsync({
        message: JSON.stringify({
          type: 'customer-declined',
          quote: data.quote,
          acceptedAmount: data.proposedAmount,
        }),
        senderId: user.id,
        senderType: 'CUSTOMER',
        conversationId: conversation.conversation.id,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const bg = useColorModeValue('gray.50', 'gray.800');
  const borderColor = useColorModeValue('gray.100', 'brand-blue.900');

  return (
    <Flex width={'full'} pr={10} ref={ref} {...rest}>
      <Flex minH={'full'} width={'13px'} align={'end'}>
        {isLastMessageBySenderInSubMessageList && (
          <Flex
            align={'center'}
            justify={'center'}
            p={1}
            transform={'translate(0px,12px)'}
            rounded={'full'}
            bg={bg}
            borderWidth={'1px'}
            borderColor={borderColor}
          >
            <Avatar width={'20px'} height={'20px'} src={partner.imageS3Name} />
          </Flex>
        )}
      </Flex>
      <VStack
        as={ScaleFade}
        in={true}
        maxW={{ base: '90%', md: '75%' }}
        borderTopRadius={'12px'}
        borderBottomRightRadius={'24px'}
        borderTopLeftRadius={'24px'}
        borderBottomLeftRadius={'0px'}
        p={4}
        bg={bg}
        borderWidth={'1px'}
        borderColor={borderColor}
      >
        <Text fontSize={'xs'} color={useColorModeValue('gray.700', 'gray.400')}>
          The supplier has changed the price to{' '}
          <Text as={'b'} color={useColorModeValue('gray.700', 'gray.300')}>
            {formatPrice(data.offeredAmount)}
          </Text>{' '}
          {quantity > 1 ? 'for each' : 'for this'} spare part.
          {quantity > 1 && (
            <Text as={'b'}>
              {' '}
              Total = {formatPrice(data.offeredAmount * quantity)}{' '}
            </Text>
          )}
        </Text>
        <HStack width={'full'} justifyContent={'center'}>
          {sendMessageMutation.isLoading || updateQuoteMutation.isLoading ? (
            <Spinner colorScheme={'blue'} size={'xs'} />
          ) : (
            <>
              <Box
                as={'button'}
                color={'red'}
                fontSize={'xs'}
                fontWeight={'semibold'}
                onClick={declineProposal}
              >
                {' '}
                decline{' '}
              </Box>
              <Spacer />
              <Box
                as={'button'}
                color={'blue.300'}
                fontSize={'xs'}
                fontWeight={'semibold'}
                onClick={acceptProposal}
              >
                {' '}
                accept{' '}
              </Box>
            </>
          )}
        </HStack>
      </VStack>
      <Spacer />
    </Flex>
  );
});
