import { Text, VStack } from '@chakra-ui/react';
import { Component } from 'react';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      return (
        this.props.fallback || (
          <VStack
            align={'center'}
            justify={'center'}
            width={'100%'}
            height={'100%'}
            bg={'lightBackground'}
            position="fixed"
            top={'0'}
            bottom={'0'}
            p={5}
            zIndex={100000}
          >
            <Text fontSize={'54px'} fontWeight={'black'} color={'red.400'}>
              {' '}
              Oops!{' '}
            </Text>
            <Text fontSize={'md'} fontWeight={'medium'}>
              {' '}
              Something went wrong{' '}
            </Text>
            <Text fontSize={'xs'} fontWeight={'normal'} textAlign={'center'}>
              {' '}
              Please call{' '}
              <Text as={'a'} fontWeight={'bold'} href={'tel:+260975356099'}>
                +260975356099
              </Text>{' '}
              for assistance.
            </Text>
          </VStack>
        )
      );
    } else return this.props.children;
  }
}
