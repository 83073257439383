import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { Fees, QuoteResponse } from '../../../API';
import { capitalizeEveryFirstLetter } from '../../../utils/functions';
import moment from 'moment';

const borderColor = '#FFA500';
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#FFA500',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontStyle: 'bold',
  },
  description: {
    width: '48%',
    textAlign: 'left',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  qty: {
    width: '7%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
  },
  rate: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
  },
  amount: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 8,
  },
});

interface QuoteTableRowProps {
  quotes: QuoteResponse[];
}

const QuoteTableRow = ({ quotes }: QuoteTableRowProps) => {
  const rows = quotes.map(item => {
    //* Only show the price without delivery.
    const price = item.autoPart.paymentOptions.filter(
      (option: Fees) =>
        !option.isDeliveryIncluded && option.processor === 'MTN',
    )[0];

    return (
      <View style={styles.row} key={item.quote.id}>
        <Text style={styles.description}>
          {capitalizeEveryFirstLetter(item.autoPart.autoPart.name)}
        </Text>
        <Text style={styles.qty}>{item.request.autoPart.quantity}</Text>
        <Text style={styles.rate}>{price.finalFee}</Text>
        <Text style={styles.rate}>
          {moment(item.quote.expiryDate).format('MMM Do YY')}
        </Text>
        <Text style={styles.amount}>{Math.round(price.finalFee)}</Text>
      </View>
    );
  });

  return <Fragment>{rows}</Fragment>;
};

export default QuoteTableRow;
