import {
  Icon,
  Menu,
  MenuButton,
  MenuList,
  Select,
  Text,
  VStack,
  HStack,
  Input,
} from '@chakra-ui/react';
import { useState } from 'react';
import { GoSettings } from 'react-icons/go';
import { ButtonPrimary } from '.';
import { formatDate } from '../../utils/functions';
import { byDateRangeInput } from '../../API';
import { useLocation } from 'react-router-dom';
import { getPinpointButtonClickEventProp } from '../../utils/functions-ts';

//ANCHOR: declare prop types for the component
interface Params {
  apply?: {
    onEvent1: (prop: byDateRangeInput) => void;
    onEvent2: (prop: string) => void;
  };
}

//ANCHOR: filter component for filtering by data-range and status
const FilterControl: React.FC<Params> = ({ apply }): JSX.Element => {
  //ANCHOR: initialize comp states
  const [dateRange, setDateRange] = useState<byDateRangeInput>({
    start: formatDate(),
    end: formatDate(),
  });
  const [status, setStatus] = useState<string>('OPEN');
  const { pathname } = useLocation();

  return (
    <Menu>
      <MenuButton
        as={ButtonPrimary}
        size={'sm'}
        borderRadius={'8px'}
        data-component="FilterControl"
      >
        <Icon h={'18px'} w={'18px'} as={GoSettings} />
      </MenuButton>
      <MenuList
        as={VStack}
        justifyContent={'flex-start'}
        alignItems={'start'}
        spacing={3}
        p={4}
        maxW={'300px'}
      >
        <Text> FIlter By Status: </Text>
        <Select
          size={'sm'}
          onChange={
            //ANCHOR: change selected status
            e => {
              setStatus(e.target.value);
            }
          }
        >
          <option value={'OPEN'}> Open </option>
          <option value={'CLOSED'}> Closed </option>
          <option value={'IN_PROGRESS'}> In Progress </option>
        </Select>
        <Text> Filter By Date Range: </Text>
        <HStack spacing={2} mb={5}>
          <Input
            type={'date'}
            size={'xs'}
            fontSize={'10px'}
            value={dateRange.start}
            onChange={
              //ANCHOR: set start date for filtering
              e => {
                let r = Object.assign({}, dateRange);
                let start = e.target.value;
                let startValue = new Date(start).toJSON();
                let endValue = new Date(r.end).toJSON();
                // return if start date is greater than end date
                if (startValue > endValue) return;
                setDateRange({
                  start: start,
                  end: dateRange.end,
                });
              }
            }
          />
          <Text fontSize={'xs'}> to </Text>
          <Input
            type={'date'}
            size={'xs'}
            fontSize={'10px'}
            value={dateRange.end}
            onChange={
              //ANCHOR: set end date for filtering
              e => {
                let r = Object.assign({}, dateRange);
                let end = e.target.value;
                let endValue = new Date(end).toJSON();
                let startValue = new Date(r.start).toJSON();
                // return if end date is lesser than start date
                if (endValue < startValue) return;

                setDateRange({
                  start: dateRange.start,
                  end: end,
                });
              }
            }
          />
        </HStack>
        <ButtonPrimary
          size={'xs'}
          width={'full'}
          onClick={() => {
            apply?.onEvent1(dateRange);
            apply?.onEvent2(status);
          }}
          {...getPinpointButtonClickEventProp({
            command: 'apply search filters',
            currentPage: pathname,
          })}
        >
          {' '}
          apply filters{' '}
        </ButtonPrimary>
      </MenuList>
    </Menu>
  );
};

export default FilterControl;
