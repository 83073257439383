/* eslint-disable no-unused-vars */
import {
  Box,
  FormControl,
  HStack,
  IconButton,
  Input,
  PinInput,
  PinInputField,
  Spinner,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';

import { Analytics, Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Link from '../components/buttons/link';
import CountrySelect from '../components/inputs/country-select';
import Main from '../components/layouts/Main';
import {
  OTPPhoneNumberAtom,
  cognitoUserAtom,
  isAuthenticatedAtom,
  userContactDetailsAtom,
} from '../recoil/atoms';
import { CountryCodeType } from '../types/interfaces';
import { paths } from '../utils/constants';
import {
  loadCustomAuthSession,
  storeCustomAuthSession,
} from '../utils/functions';

import { GrNext } from 'react-icons/gr';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastStatus } from '../types/enums';
import { getPinpointButtonClickEventProp } from '../utils/functions-ts';

//* NOTE Interfaces and initial values
interface IOTP {
  0: number | undefined;
  1: number | undefined;
  2: number | undefined;
  3: number | undefined;
  4: number | undefined;
  5: number | undefined;
}

//* NOTE: Messages
enum Message {
  NOTSIGNIN = 'Enter Phone Number',
  SIGNEDIN = 'You have logged in successfully',
  SIGNEDOUT = 'You have logged out successfully',
  WAITINGFOROTP = 'Enter OTP number',
  CHECKSMS = 'Check your SMS for the OTP',
  SIGNUP = 'Enter your full name to sign up',
  RETRYOTP = 'Try entering the correct OTP',
  RETRYSIGNIN = 'Please re-enter your your number and try again',
  RESENDOTP = "Too many wrong attempts. Click on 'Resend OTP'",
  NAN = '',
}

enum ToastTitles {
  NOACCOUNT = "Oops, looks like you don't have an account yet!",
  APOLOGY = 'Sorry about that!',
  USEREXISTS = "You already have an account, no worries, we'll sign you in",
  MAXATTEMPTS = 'Too Many attempts',
  WRONGOTP = 'OTP is wrong',
}

const initOTPState: IOTP = {
  0: undefined,
  1: undefined,
  2: undefined,
  3: undefined,
  4: undefined,
  5: undefined,
};

export default function NewLogin() {
  /**
   ** Hooks State
   */
  const toast = useToast();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  /**
   ** Global State
   */
  const [user, setCognitoUser] = useRecoilState(cognitoUserAtom);
  const [phoneNumber, setPhoneNumber] = useRecoilState(OTPPhoneNumberAtom);
  const [userDetails, setCognitoUserDetails] = useRecoilState(
    userContactDetailsAtom,
  );

  /**
   ** Local State
   */
  const [errors, setErrors] = useState({
    phoneNumber: '',
    otp: '',
  });
  const [message, setMessage] = useState<Message>(Message.NAN);
  const [country, setCountry] = useState<CountryCodeType | null>(null);
  const [session, setSession] = useState(null);
  const [otp, setOtp] = useState<IOTP>(initOTPState);
  const [number, setNumber] = useState('');
  const setIsAuthenticated = useSetRecoilState(isAuthenticatedAtom);
  const [shouldSignUp, setShouldSignup] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>('');

  /**
   ** Constants
   */
  const OTP = Object.values(otp).join('');

  /**
   ** React Query Functions
   */

  const signIn = useMutation({
    mutationFn: async () => {
      setMessage(Message.CHECKSMS);
      return await Auth.signIn(phoneNumber);
    },
    onSuccess: async result => {
      //console.log('🚀 ~ file: NewLogin.tsx:130 ~ NewLogin ~ result', result);
      storeCustomAuthSession(result);
      setSession(result);
      setMessage(Message.WAITINGFOROTP);
    },
    onError: async (e: any) => {
      if (
        e.code === 'UserNotFoundException' ||
        e.code === 'NotAuthorizedException'
      ) {
        setMessage(Message.SIGNUP);
        setShouldSignup(true);
        toast({
          status: ToastStatus.INFO,
          title: ToastTitles.NOACCOUNT,
          description: Message.SIGNUP,
          position: 'bottom',
          duration: 7000,
          isClosable: true,
        });
      } else {
        setMessage(Message.RETRYSIGNIN);
        console.error(e);
        toast({
          status: ToastStatus.INFO,
          title: ToastTitles.APOLOGY,
          description: Message.RETRYSIGNIN,
          position: 'bottom',
          duration: 7000,
          isClosable: true,
        });
      }
    },
  });

  const verifyAuth = useMutation({
    mutationFn: async () => {
      await Auth.currentSession();
      return await Auth.currentAuthenticatedUser();
    },
    onSuccess: async currentUser => {
      const { attributes } = currentUser;
      setCognitoUserDetails({
        email: attributes['custom:email']
          ? attributes['custom:email']
          : userDetails.email,
        familyName: attributes['custom:familyName'],
        firstName: attributes['custom:givenName'],
        id: attributes['sub'],
        phoneNumber: attributes['phone_number'],
      });
      Analytics.record({
        name: 'LoginSuccessful',
        attributes: {
          email: attributes['custom:email']
            ? attributes['custom:email']
            : userDetails.email,
          familyName: attributes['custom:familyName'],
          firstName: attributes['custom:givenName'],
          id: attributes['sub'],
          phoneNumber: attributes['phone_number'],
        },
      });
      // TODO: UNCOMMENT CODE BELOW AND MAKE SURE IT WORKS
      /*
      const dbUser = await _getUser(currentUser.attributes.sub);
      if (dbUser) setCognitoUser({ ...currentUser, dbUser: Object.fromEntries(Object.entries(dbUser).filter(([_, v]) => v != null)) });
      setCognitoUser(prevValue =>
              prevValue ? { ...prevValue, ...attributes } : { ...attributes },
            );
      */
      setCognitoUser(attributes);
      setIsAuthenticated(true);
      setMessage(Message.SIGNEDIN);
      setSession(null);
      setIsAuthenticated(true);
      navigate(paths.home);
    },
    onError: async (e: any) => {
      console.log('🚀 ~ file: NewLogin.tsx:165 ~ onError: ~ e', e);
      setMessage(Message.NOTSIGNIN);
      if (!user && session && e === 'No current user')
        setMessage(Message.RETRYOTP);
      setCognitoUser(null);
      setIsAuthenticated(false);
    },
  });

  const signUp = useMutation({
    mutationFn: async () => {
      const splitedFullName = fullName.split(' ');
      const firstName = splitedFullName.shift();
      const familyName = splitedFullName.join(' ');
      const password = Math.round(Math.random() * 10000000) + '#AbCd';

      return await Auth.signUp({
        username: phoneNumber,
        password,
        attributes: {
          'custom:givenName': firstName,
          'custom:familyName': familyName,
          'custom:userType': 'CUSTOMER',
        },
      });
    },
    onSuccess: async res => {
      console.log('🚀 ~ file: NewLogin.tsx:211 ~ signUp ~ res', res);
      setMessage(Message.WAITINGFOROTP);
      Analytics.record({
        name: 'SignUpSuccessful',
      });
      signIn.mutateAsync();
    },
    onError: async (err: any) => {
      console.log('🚀 ~ file: NewLogin.tsx:159 ~ signUp ~ error', err);
      if (err.code === 'UsernameExistsException') {
        toast({
          status: ToastStatus.INFO,
          title: ToastTitles.USEREXISTS,
          description: 'We have sent an OTP to ' + phoneNumber,
          position: 'bottom',
          duration: 7000,
          isClosable: true,
        });
        await signIn.mutateAsync();
      }
      //ANCHOR: let user now if it's a network issue
      else {
        toast({
          status: ToastStatus.INFO,
          title: ToastTitles.MAXATTEMPTS,
          description: 'Please Try Again',
          duration: 9000,
          position: 'bottom',
          isClosable: true,
        });
      }
    },
  });

  const verifyOtp = useMutation({
    mutationFn: async () => {
      if (OTP.length < 1) {
        toast({
          status: ToastStatus.ERROR,
          title: ToastTitles.WRONGOTP,
          description: 'Put in the correct OTP',
          position: 'bottom',
          duration: 7000,
          isClosable: true,
        });
        return;
      }
      return await Auth.sendCustomChallengeAnswer(session, OTP);
    },
    onSuccess: async () => {
      await verifyAuth.mutateAsync();
    },
    onError: async (error: any) => {
      try {
        if (OTP.length < 1) {
          toast({
            status: ToastStatus.ERROR,
            title: ToastTitles.WRONGOTP,
            description: 'Put in the correct OTP, you only have 3 attempts',
            position: 'bottom',
            duration: 7000,
            isClosable: true,
          });
          return;
        }

        const user = loadCustomAuthSession();
        await Auth.sendCustomChallengeAnswer(user, OTP);
        await verifyAuth.mutateAsync();
      } catch (err: any) {
        console.log('🚀 ~ file: NewLogin.tsx:289 ~ verifyOtp ~ err', err);
        setMessage(Message.RESENDOTP);
        setOtp(initOTPState);
        console.log(err);
        toast({
          status: ToastStatus.ERROR,
          title: ToastTitles.WRONGOTP,
          description: "Try again or click 'Resend OTP'",
          position: 'bottom',
          duration: 7000,
          isClosable: true,
        });
      }
    },
  });

  /**
   ** Effects
   */
  useEffect(() => {
    /**
     ** Verify auth on signup
     */
    verifyAuth.mutateAsync();
  }, []);

  /**
   ** Effects
   */
  useEffect(() => {
    /**
     **
     */
    if (pathname === paths.register) {
      setShouldSignup(true);
    } else {
      setShouldSignup(false);
    }
  }, [pathname]);

  useEffect(() => {
    /**
     ** Validate phone number
     */
    if (!number) return;
    const phoneNumberWithPrependedCountryCode =
      number.length === 10 ? '+26' + number : country?.dial_code + number;
    const phoneNum = isValidPhoneNumber(phoneNumberWithPrependedCountryCode)
      ? phoneNumberWithPrependedCountryCode
      : isValidPhoneNumber(number)
      ? number
      : null;

    if (!phoneNum) {
      setErrors({
        ...errors,
        phoneNumber: 'Number is not a valid phone number',
      });

      return;
    }

    setErrors({
      ...errors,
      phoneNumber: '',
    });

    setPhoneNumber(phoneNum);
  }, [number]);

  return (
    <Main spacing={5} justifyContent={'center'} alignItems={'center'}>
      <Text color="orange.500" textAlign={'center'}>
        {message}
      </Text>

      <Box width={'full'} maxW={'lg'} className={'bxs-uh'} px={4} py={6}>
        <FormControl
          as={VStack}
          spacing={3}
          alignItems={'start'}
          justifyContent={'flex-start'}
        >
          <HStack spacing={1} width={'full'}>
            <CountrySelect
              mini={true}
              onChangeCallback={(country: CountryCodeType) =>
                setCountry(country)
              }
              data-input="country-code"
            />
            <Input
              type={'tel'}
              id={'phoneNumber'}
              fontSize={'xs'}
              width={'75%'}
              bg="white"
              placeholder={'(9/7)XXXXXXXX'}
              onChange={e => setNumber(e.target.value)}
              value={number}
            />
            <IconButton
              onClick={async () => {
                await signIn.mutateAsync();
              }}
              {...getPinpointButtonClickEventProp({
                command: 'log in',
                currentPage: pathname,
              })}
              isLoading={signIn.isLoading}
              bg="orange"
              aria-label="sign in"
              icon={<GrNext />}
            />
          </HStack>

          {shouldSignUp && (
            <HStack spacing={1} width={'full'}>
              <Input
                type={'text'}
                id={'sign-up'}
                fontSize={'xs'}
                width={'full'}
                bg="white"
                placeholder={'Full Name'}
                onChange={e => setFullName(e.target.value)}
                value={fullName}
              />
              <IconButton
                onClick={async () => {
                  await signUp.mutateAsync();
                }}
                {...getPinpointButtonClickEventProp({
                  command: 'sign up',
                  currentPage: pathname,
                })}
                isLoading={signUp.isLoading}
                bg="orange"
                aria-label="sign up"
                icon={<GrNext />}
              />
            </HStack>
          )}

          {!user && session && (
            <>
              <HStack spacing={1} width={'full'}>
                <PinInput otp id="otp">
                  <PinInputField
                    bg="white"
                    value={otp[0] || ''}
                    onChange={e => {
                      setOtp({ ...otp, 0: parseInt(e.target.value) });
                    }}
                  />
                  <PinInputField
                    bg="white"
                    value={otp[1]}
                    onChange={e => {
                      setOtp({ ...otp, 1: parseInt(e.target.value) });
                    }}
                  />
                  <PinInputField
                    bg="white"
                    value={otp[2]}
                    onChange={e => {
                      setOtp({ ...otp, 2: parseInt(e.target.value) });
                    }}
                  />
                  <PinInputField
                    bg="white"
                    value={otp[3]}
                    onChange={e => {
                      setOtp({ ...otp, 3: parseInt(e.target.value) });
                    }}
                  />
                  <PinInputField
                    bg="white"
                    value={otp[4]}
                    onChange={e => {
                      setOtp({ ...otp, 4: parseInt(e.target.value) });
                    }}
                  />
                  <PinInputField
                    bg="white"
                    value={otp[5]}
                    onChange={e => {
                      setOtp({ ...otp, 5: parseInt(e.target.value) });
                    }}
                  />
                </PinInput>
                <IconButton
                  onClick={async () => {
                    await verifyOtp.mutateAsync();
                  }}
                  {...getPinpointButtonClickEventProp({
                    command: 'verify OTP',
                    currentPage: pathname,
                  })}
                  isLoading={verifyOtp.isLoading}
                  bg="orange"
                  aria-label="verify otp"
                  icon={<GrNext />}
                />
              </HStack>

              {signIn.isLoading ? (
                <Spinner size={'sm'} />
              ) : (
                <Text
                  my={2}
                  color={'blue'}
                  _hover={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={async () => {
                    await signIn.mutateAsync();
                  }}
                  {...getPinpointButtonClickEventProp({
                    command: 'Resend OTP',
                    currentPage: pathname,
                  })}
                >
                  {' '}
                  Resend OTP{' '}
                </Text>
              )}
            </>
          )}

          {errors.otp && <Text color={'red'}>{errors.otp}</Text>}
          {errors.phoneNumber && (
            <Text color={'red'}>{errors.phoneNumber}</Text>
          )}

          {pathname === paths.login && (
            <Text
              fontSize={'sm'}
              {...getPinpointButtonClickEventProp({
                command: 'go to sign up',
                currentPage: pathname,
              })}
            >
              Do not have an account with us ?{' '}
              <Link fontWeight={'medium'} href={paths.register}>
                Register
              </Link>
            </Text>
          )}

          {pathname === paths.register && (
            <Text
              fontSize={'sm'}
              {...getPinpointButtonClickEventProp({
                command: 'go to login',
                currentPage: pathname,
              })}
            >
              You already have an account ?{' '}
              <Link fontWeight={'medium'} href={paths.login}>
                Login
              </Link>
            </Text>
          )}
        </FormControl>
      </Box>
    </Main>
  );
}
