import { CloseIcon, CopyIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Icon,
  Image,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  TagLabel,
  TagRightIcon,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { MdDialpad } from 'react-icons/md';
import PaymentIcon from 'react-payment-icons';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getPinpointButtonClickEventProp } from '../../../utils/functions-ts';

export default function SelectPaymentMethod({ isOpen, onClose }) {
  const toast = useToast();
  const { pathname } = useLocation();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={useBreakpointValue({
        base: 'full',
        md: '2xl',
        lg: '3xl',
      })}
      scrollBehavior={'inside'}
      isCentered
      blockScrollOnMount
      closeOnEsc
      motionPreset={useBreakpointValue({
        base: 'slideInBottom',
        md: 'scale',
      })}
    >
      <ModalOverlay
        bg="blackAlpha.400"
        backdropFilter="auto"
        backdropBlur="5px"
      />
      <ModalContent
        height={{
          base: '90vh',
          md: 'auto',
        }}
        position={{
          base: '10vh',
        }}
        p={{
          base: 0,
          md: 4,
        }}
      >
        <ModalHeader display={'flex'} alignItems={'center'}>
          <Text width={'full'}> Select Payment Method </Text>
          <Button as={ModalCloseButton}>
            <Icon as={CloseIcon} />
          </Button>
        </ModalHeader>

        <ModalBody>
          <Tabs isLazy lazyBehaviour="keepMounted" variant={'soft-rounded'}>
            <SimpleGrid
              spacing={2}
              as={TabList}
              columns={{
                base: 2,
                md: 3,
              }}
              width={'full'}
            >
              <Tab
                as={Flex}
                flexDirection={'column'}
                alignItems={'center'}
                borderRadius={'lg'}
                cursor={'pointer'}
                bg={useColorModeValue('gray.100', 'gray.800')}
                width={'full'}
                height={32}
                _focus={{
                  outline: 'none',
                }}
              >
                <Image
                  src={'https://www.airtel.co.zm/assets/images/airtel-logo.png'}
                  height={30}
                  objectFit={'contain'}
                />
                <Text mt={3} textAlign={'center'}>
                  Airtel Money
                </Text>
              </Tab>
              <Tab
                as={Flex}
                flexDirection={'column'}
                alignItems={'center'}
                borderRadius={'lg'}
                cursor={'pointer'}
                bg={useColorModeValue('gray.100', 'gray.800')}
                width={'full'}
                height={32}
                _focus={{
                  outline: 'none',
                }}
              >
                <Image
                  src={
                    'https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/MTN_Logo.svg/300px-MTN_Logo.svg.png?20190223171412'
                  }
                  height={30}
                  objectFit={'contain'}
                />
                <Text mt={3} textAlign={'center'}>
                  MTN Money
                </Text>
              </Tab>
              <Tab
                as={Flex}
                flexDirection={'column'}
                alignItems={'center'}
                borderRadius={'lg'}
                cursor={'pointer'}
                bg={useColorModeValue('gray.100', 'gray.800')}
                width={'full'}
                height={32}
                _focus={{
                  outline: 'none',
                }}
              >
                <PaymentIcon id={'visa'} style={{ width: '50px' }} />
                <Text mt={3} textAlign={'center'}>
                  Card
                </Text>
              </Tab>
            </SimpleGrid>

            <TabPanels
              my={3}
              p={3}
              py={5}
              borderRadius={'lg'}
              borderWidth={'1px'}
            >
              <TabPanel p={0}>
                <Text fontWeight={'bold'} fontSize={'lg'} mb={2}>
                  Steps to take to pay via mobile money
                </Text>
                <OrderedList spacing={3}>
                  <ListItem
                    textTransform={'capitalize'}
                    fontSize={'md'}
                    fontWeight={'semibold'}
                  >
                    Dial *778*4*5*1#
                  </ListItem>

                  <ListItem
                    textTransform={'capitalize'}
                    fontSize={'md'}
                    fontWeight={'semibold'}
                  >
                    enter merchant code{' '}
                    <Tag
                      colorScheme={'blue'}
                      size={'md'}
                      cursor={'pointer'}
                      onClick={() => {
                        if (!navigator.clipboard) return;
                        navigator.clipboard
                          .writeText('RIAWORK')
                          .then(() => {
                            toast({
                              status: 'info',
                              title:
                                'merchant code RIAWORK copied to clipboard',
                              duration: 2000,
                              position: 'bottom',
                              isClosable: true,
                            });
                          })
                          .catch(() => {});
                      }}
                      {...getPinpointButtonClickEventProp({
                        command: 'copy USSD code',
                        currentPage: pathname,
                      })}
                    >
                      <TagLabel> RIAWORK </TagLabel>
                      <TagRightIcon as={CopyIcon} />
                    </Tag>
                  </ListItem>

                  <ListItem
                    textTransform={'capitalize'}
                    fontSize={'md'}
                    fontWeight={'semibold'}
                  >
                    enter 6 digit order number{' '}
                    <Tag
                      colorScheme={'blue'}
                      size={'md'}
                      cursor={'pointer'}
                      onClick={() => {
                        if (!navigator.clipboard) return;
                        navigator.clipboard
                          .writeText('123456')
                          .then(() => {
                            toast({
                              status: 'info',
                              title: 'copied to clipboard',
                              duration: 2000,
                              position: 'bottom',
                              isClosable: true,
                            });
                          })
                          .catch(error => {
                            console.log(error);
                          });
                      }}
                      {...getPinpointButtonClickEventProp({
                        command: 'copy order id',
                        currentPage: pathname,
                      })}
                    >
                      <TagLabel> 123456 </TagLabel>
                      <TagRightIcon as={CopyIcon} />
                    </Tag>
                  </ListItem>

                  <ListItem
                    textTransform={'capitalize'}
                    fontSize={'md'}
                    fontWeight={'semibold'}
                  >
                    Pay <b>K100</b> amount
                  </ListItem>
                  <ListItem
                    textTransform={'capitalize'}
                    fontSize={'md'}
                    fontWeight={'semibold'}
                  >
                    Return to riaworx.com and enter order number
                  </ListItem>
                </OrderedList>

                {useBreakpointValue({
                  base: (
                    <Button
                      as={'a'}
                      href={'tel:*778*4*5*1#'}
                      colorScheme={'blue'}
                      width={'full'}
                      my={3}
                      rightIcon={<MdDialpad />}
                      justifyContent={'space-between'}
                    >
                      {' '}
                      Dial now{' '}
                    </Button>
                  ),
                  md: '',
                })}
              </TabPanel>

              <TabPanel p={0}>
                <Text fontWeight={'bold'} fontSize={'lg'} mb={2}>
                  Steps to take to pay via MTN mobile money.
                </Text>
                <OrderedList spacing={3}>
                  <ListItem
                    textTransform={'capitalize'}
                    fontSize={'md'}
                    fontWeight={'semibold'}
                  >
                    dial *303*5*1#
                  </ListItem>

                  <ListItem
                    textTransform={'capitalize'}
                    fontSize={'md'}
                    fontWeight={'semibold'}
                  >
                    enter merchant code{' '}
                    <Tag
                      colorScheme={'blue'}
                      size={'md'}
                      cursor={'pointer'}
                      onClick={() => {
                        if (!navigator.clipboard) return;
                        navigator.clipboard
                          .writeText('448338')
                          .then(() => {
                            toast({
                              status: 'info',
                              title: 'merchant code copied to clipboard',
                              duration: 2000,
                              position: 'bottom',
                              isClosable: true,
                            });
                          })
                          .catch(error => {
                            console.log(error);
                          });
                      }}
                      {...getPinpointButtonClickEventProp({
                        command: 'copy merchant code',
                        currentPage: pathname,
                      })}
                    >
                      <TagLabel> 448338 </TagLabel>
                      <TagRightIcon as={CopyIcon} />
                    </Tag>
                  </ListItem>

                  <ListItem
                    textTransform={'capitalize'}
                    fontSize={'md'}
                    fontWeight={'semibold'}
                  >
                    enter 6 digit order number{' '}
                    <Tag
                      colorScheme={'blue'}
                      size={'md'}
                      cursor={'pointer'}
                      onClick={() => {
                        if (!navigator.clipboard) return;
                        navigator.clipboard
                          .writeText('123456')
                          .then(() => {
                            toast({
                              status: 'info',
                              title: 'copied to clipboard',
                              duration: 2000,
                              position: 'bottom',
                              isClosable: true,
                            });
                          })
                          .catch(error => {
                            console.log(error);
                          });
                      }}
                      {...getPinpointButtonClickEventProp({
                        command: 'copy order id',
                        currentPage: pathname,
                      })}
                    >
                      <TagLabel> 123456 </TagLabel>
                      <TagRightIcon as={CopyIcon} />
                    </Tag>
                  </ListItem>

                  <ListItem
                    textTransform={'capitalize'}
                    fontSize={'md'}
                    fontWeight={'semibold'}
                  >
                    Pay <b>K100</b> amount
                  </ListItem>
                  <ListItem
                    textTransform={'capitalize'}
                    fontSize={'md'}
                    fontWeight={'semibold'}
                  >
                    Return to riaworx.com and enter order number
                  </ListItem>
                </OrderedList>

                {useBreakpointValue({
                  base: (
                    <Button
                      as={'a'}
                      href={'tel:*303*5*1#'}
                      colorScheme={'blue'}
                      width={'full'}
                      my={3}
                      rightIcon={<MdDialpad />}
                      justifyContent={'space-between'}
                      {...getPinpointButtonClickEventProp({
                        command: 'go to dial pad',
                        currentPage: pathname,
                      })}
                    >
                      {' '}
                      Pay Now{' '}
                    </Button>
                  ),
                  md: '',
                })}
              </TabPanel>

              <TabPanel>
                <Card />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

const Card = function () {
  useEffect(() => (window.location = 'https://flutterwave.com/pay'));
  return '';
};
