import { PaymentProcessors, Fees } from '../API';
import { Prices } from '../types/interfaces';
import { selectorPrefix } from './constants';

export const getPaymentOptionByProcessorAndDelivery = (
  myProcessor: PaymentProcessors,
  paymentOptionsList: Fees[],
  shouldDeliver: boolean,
): Fees => {
  return paymentOptionsList
    .filter(option => option.processor === myProcessor)
    .filter(option => option.isDeliveryIncluded === shouldDeliver)[0];
};

export const toPascalCase = (sentence: string): string =>
  sentence
    .toLowerCase()
    .split(' ')
    .map(x => removeNonAlphanumerics(x))
    .filter(x => x)
    .map(word => word[0].toUpperCase() + word.slice(1))
    .join('');

export const toKebabCase = (sentence: string): string =>
  sentence
    .toLowerCase()
    .split(' ')
    .map(x => removeNonAlphanumerics(x))
    .filter(x => x)
    .join('-');

export const removeNonAlphanumerics = (sentence: string): string =>
  sentence.replace(/[^A-Za-z0-9]/g, '');

export const getPinpointButtonClickEventProp = ({
  command,
  currentPage,
}: {
  command: string;
  currentPage?: string;
}): { [key: string]: string } => {
  /** 
   * INPUTS:
        command: The action the button executes. EG create request
        currentPage: The page on which the button is on. EG: /orders#cart
    * OUTPUTS: {data-pinpoint-analytics-create-request: OrdersCartPage_CreateRequest}

*/
  let value = toPascalCase(command);
  if (currentPage) {
    currentPage = currentPage.replace(/[^A-Za-z0-9]/g, ' ');
    value = currentPage.toLowerCase().includes('page')
      ? toPascalCase(currentPage) + '_' + value
      : toPascalCase(currentPage + ' Page') + '_' + value;
  }
  return { [selectorPrefix + toKebabCase(command)]: value };
};

export const getPrices = (options: Fees[]): Prices => {
  let delivery = options.find(p => p.isDeliveryIncluded);
  let withoutDelivery = options.find(p => !p.isDeliveryIncluded);

  if (!delivery) {
    delivery = options[0];
  }

  if (!withoutDelivery) {
    withoutDelivery = options[1];
  }

  return {
    withDelivery: delivery.finalFee,
    withoutDelivery: withoutDelivery.finalFee,
  };
};

export function isMTNNumber(num: string): boolean {
  return ['26076', '26096'].includes(num.slice(0, 5));
}

export function isAirtelNumber(num: string): boolean {
  return ['26077', '26097'].includes(num.slice(0, 5));
}

export const truncate = (str: string, len: number): string => {
  if (str.length > len) {
    return str.substring(0, len) + '...';
  } else {
    return str;
  }
};

export const getPinpointDataAttr = (srt: string): string => {
  return `data-pinpoint-analytics-${srt}`;
};
