import {
  Box,
  Button,
  Flex,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { HiTrash } from 'react-icons/hi';
import { API } from 'aws-amplify';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  createPaymentMethod,
  deletePaymentMethod,
  updatePaymentMethod,
} from '../../graphql/mutations';
import { cognitoUserAtom, mobileMoneyAccountsAtom } from '../../recoil/atoms';
import { unStringfy } from '../../utils/functions';
import CountrySelect from '../inputs/selects';

export default function MobileMoney({ account = { paymentMethod: {} } }) {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm();

  const toast = useToast();

  const user = useRecoilValue(cognitoUserAtom);
  const [mobileMoneyAccounts, setMobileMoneyAccounts] = useRecoilState(
    mobileMoneyAccountsAtom,
  );
  const [name, setName] = useState(account.paymentMethod.name || '');
  const country = useState(account.paymentMethod.country)[0];
  const [accountProvider, setAccountProvider] = useState(
    account.paymentMethod.mobileMoneyProvider || '',
  );
  const [phoneNumber, setPhoneNumber] = useState(
    account.paymentMethod.phoneNumber || '',
  );
  const creatorType = 'CUSTOMER';

  const addMobileMoneyAccount = async formData => {
    formData.country = unStringfy(formData.country).name;
    try {
      const input = {
        mobileMoney: {
          ...formData,
          creatorId: user.sub,
          creatorType,
        },
      };
      const { data } = await API.graphql({
        query: createPaymentMethod,
        variables: {
          input,
        },
      });
      let account = data.createPaymentMethod[0];
      setMobileMoneyAccounts([
        { paymentMethod: account },
        ...mobileMoneyAccounts,
      ]);
    } catch (error) {
      toast({
        status: 'error',
        title: 'Something went wrong',
        duration: 3000,
        isClosable: true,
      });
      console.log(error);
    }
  };

  const updateMobileMoneyAccount = async formData => {
    formData.country = unStringfy(formData.country).name;
    try {
      const input = {
        mobileMoney: {
          id: account.paymentMethod.id,
          ...formData,
          creatorId: user.sub,
          creatorType: 'PARTNER',
        },
      };

      const { data } = await API.graphql({
        query: updatePaymentMethod,
        variables: {
          input,
        },
      });
      console.log(data);
      if (data.updatePaymentMethod) {
        let newMobileMoneyAccounts = [...mobileMoneyAccounts].map(acc => {
          // prolly an easier way to do this but this works, soo...
          if (acc.paymentMethod.id === account.paymentMethod.id) {
            let paymentMethod = Object.assign({}, acc.paymentMethod, formData);
            return Object.assign({}, acc, { paymentMethod });
          } else return acc;
        });
        setMobileMoneyAccounts(newMobileMoneyAccounts);
      } else {
        // show toast
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteMobileMoneyAccount = async () => {
    try {
      const variables = {
        creator: {
          id: user.sub,
          creatorType: 'PARTNER',
        },
        provider: {
          id: account.paymentMethod.id,
          type: 'MOBILE_MONEY',
        },
      };
      const { data } = await API.graphql({
        query: deletePaymentMethod,
        variables,
      });
      if (data.deletePaymentMethod) {
        setMobileMoneyAccounts(
          mobileMoneyAccounts.filter(
            acc => acc.paymentMethod.id !== account.paymentMethod.id,
          ),
        );
      } else throw new Error();
    } catch (error) {
      toast({
        status: 'Error',
        title: 'There was a network error',
        duration: 3000,
        isClosable: true,
      });
      console.log(error);
    }
  };

  return (
    <Box
      minW={'full'}
      as={'form'}
      p={3}
      onSubmit={handleSubmit(
        account.paymentMethod.id
          ? updateMobileMoneyAccount
          : addMobileMoneyAccount,
      )}
    >
      <VStack spacing={3} width={'full'} as={Flex} align={'flex-start'}>
        <HStack spacing={2} width={'full'}>
          <Text py={0} as={FormLabel} width={'full'} htmlFor="name">
            {' '}
            Name:{' '}
          </Text>
          <InputGroup>
            <InputRightElement> </InputRightElement>
            <Input
              backgroundColor={useColorModeValue('white', 'gray.900')}
              _placeholder={{
                color: useColorModeValue('gray.700', 'gray.400'),
              }}
              id="name"
              type={'text'}
              placeholder="Enter Name For Card"
              disabled={isSubmitting}
              value={name}
              {...register('name', {
                onChange: e => {
                  setName(e.target.value);
                },
              })}
            />
          </InputGroup>
        </HStack>
        <HStack spacing={3} width={'full'}>
          <Text as={FormLabel} width={'full'} htmlFor="accountProvider">
            {' '}
            Phone Number:
          </Text>
          <InputGroup>
            <InputRightElement></InputRightElement>
            <Input
              backgroundColor={useColorModeValue('white', 'gray.900')}
              _placeholder={{
                color: useColorModeValue('gray.700', 'gray.400'),
              }}
              id="phoneNumber"
              type={'tel'}
              value={phoneNumber}
              disabled={isSubmitting}
              placeholder="Phone Number"
              {...register('phoneNumber', {
                required: 'Enter a valid phone number',
                validate: value => {
                  console.log(isValidPhoneNumber(value));
                  return isValidPhoneNumber(value);
                },
                minLength: {
                  value: 10,
                  message: 'Minimum length should be 10',
                },
              })}
              onChange={e => {
                setPhoneNumber(e.target.value);
              }}
            />
          </InputGroup>
        </HStack>
        <HStack spacing={2} width={'full'}>
          <Text py={0} as={FormLabel} width={'full'} htmlFor="name">
            {' '}
            Country:{' '}
          </Text>
          <CountrySelect
            register={register}
            value={country}
            disabled={isSubmitting}
            backgroundColor={useColorModeValue('white', 'gray.900')}
          />
        </HStack>

        <HStack spacing={3} width={'full'}>
          <Text as={FormLabel} width={'full'} htmlFor="mobileMoneyProvider">
            {' '}
            Provider:
          </Text>
          <InputGroup>
            <InputRightElement></InputRightElement>
            <Select
              backgroundColor={useColorModeValue('white', 'gray.900')}
              _placeholder={{
                color: useColorModeValue('gray.700', 'gray.400'),
              }}
              id="mobileMoneyProvider"
              value={accountProvider}
              disabled={isSubmitting}
              {...register('mobileMoneyProvider', {
                required: true,
                onChange: e => {
                  setAccountProvider(e.target.value);
                },
              })}
            >
              <option value={'AIRTEL'}> AIRTEL </option>
              <option value={'MTN'}> MTN </option>
              <option value={'ZAMTEL'}> ZAMTEL </option>
            </Select>
          </InputGroup>
        </HStack>

        <HStack width={'full'}>
          {account.paymentMethod.id && (
            <IconButton
              size="md"
              fontSize="xs"
              p={2}
              colorScheme={'red'}
              disabled={isSubmitting}
              onClick={handleSubmit(deleteMobileMoneyAccount)}
              icon={<HiTrash />}
            />
          )}
          <Button
            type="submit"
            width={'full'}
            my={5}
            backgroundColor={'blue.500'}
            color={'white'}
            _hover={{
              bg: 'blue.500',
            }}
            isLoading={isSubmitting}
          >
            {' '}
            Submit{' '}
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
}
