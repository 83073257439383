import { Button } from '@chakra-ui/react';
import React from 'react';

interface Params {
  callback: () => void;
  isNext?: boolean;
  isPrev?: boolean;
  isDisabled?: boolean;
  dataAtr?: string;
}

const NextBtn: React.FC<Params> = ({
  callback,
  isNext,
  isPrev,
  isDisabled,
  dataAtr,
}): JSX.Element => {
  return (
    <Button
      variant={isNext ? 'solid' : 'ghost'}
      color={isNext ? 'aliceblue' : '#FE6401'}
      alignSelf={isNext ? 'flex-end' : 'flex-start'}
      disabled={isDisabled}
      bg={isNext ? '#FE6401' : 'gray.200'}
      mb={'1'}
      paddingX={'6'}
      fontSize={'sm'}
      paddingY={'2'}
      size={'sm'}
      onClick={() => {
        callback();
      }}
      data-btn={`${dataAtr}`}
    >
      {isNext ? 'Next' : 'Back'}
    </Button>
  );
};

export default NextBtn;
