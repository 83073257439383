import {
  Box,
  Button,
  Center,
  Icon,
  IconButton,
  ScaleFade,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Fragment, useState } from 'react';
import { MdCheck, MdForward } from 'react-icons/md';
import { useInfiniteQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getPhotos } from '../../api/queries';
import { cognitoUserAtom, requestPhotoPreview } from '../../recoil/atoms';
import { getPinpointButtonClickEventProp } from '../../utils/functions-ts';
import Skeletons from '../loaders/skeletons';

export default function PhotoList({ max, onSelect, onClose }) {
  const user = useRecoilValue(cognitoUserAtom);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [, setPreview] = useRecoilState(requestPhotoPreview);
  const { pathname } = useLocation();
  const limit = 6;

  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['photos', { user, limit }],
    getPhotos,
    {
      getNextPageParam: previousPage => previousPage.nextToken,
      retryDelay: 0,
    },
  );

  console.log(data);

  return (
    <VStack spacing={4} align={'start'}>
      <Text fontSize={'sm'} fontWeight={'medium'}>
        {' '}
        Select Photos By Clicking On Them.{' '}
      </Text>
      <SimpleGrid
        columns={{
          base: 3,
          md: 5,
        }}
        spacing={2}
        width={'full'}
      >
        {data &&
          data.pages &&
          data.pages.map((page, pageIndex) => {
            return (
              <Fragment key={pageIndex}>
                {page.photos.map((photo, index) => {
                  photo = Object.assign({}, photo);
                  return (
                    <Box
                      as={ScaleFade}
                      in={true}
                      key={photo.id}
                      height={'84'}
                      width={'full'}
                      backgroundImage={photo.imageS3Name}
                      backgroundSize={'cover'}
                      backgroundRepeat={'no-repeat'}
                      backgroundPosition={'center'}
                      backgroundColor={'blackAlpha.200'}
                      borderRadius={'4px'}
                      cursor={'pointer'}
                      data-component={`uploaded-photo${index + 1}`}
                      {...getPinpointButtonClickEventProp({
                        command: 'upload photo',
                        currentPage: pathname,
                      })}
                      onClick={() => {
                        setPreview(photo.imageS3Name);
                        setSelectedPhotos(selectedPhotos => {
                          if (max < 2) {
                            onSelect(photo);
                            return [photo];
                          }
                          let photos = selectedPhotos.filter(
                            selectedPhoto => selectedPhoto.id !== photo.id,
                          );
                          if (photos.length === selectedPhotos.length)
                            photos.push(photo);
                          return photos;
                        });
                      }}
                    >
                      {selectedPhotos.some(p => p.id === photo.id) && (
                        <Center
                          width={'full'}
                          height={'full'}
                          backgroundColor={'blackAlpha.700'}
                          borderRadius={'inherit'}
                        >
                          <IconButton
                            icon={
                              <Icon as={MdCheck} w={8} h={8} color={'white'} />
                            }
                            opacity={1}
                            rounded={'full'}
                            bg={'blue.400'}
                          />
                        </Center>
                      )}
                    </Box>
                  );
                })}
              </Fragment>
            );
          })}
        {isLoading && (
          <Skeletons count={limit} height={'100px'} borderRadius={'8px'} />
        )}
      </SimpleGrid>
      {hasNextPage && (
        <Button
          variant={'link'}
          fontSize={'xs'}
          fontWeight={'normal'}
          colorScheme={'blue'}
          isLoading={isLoading}
          onClick={fetchNextPage}
          {...getPinpointButtonClickEventProp({
            command: 'load more images',
            currentPage: pathname,
          })}
        >
          {' '}
          load more images{' '}
        </Button>
      )}
      <Button
        width={'full'}
        height={'44px'}
        rounded={'4px'}
        colorScheme={'green'}
        justifyContent={'space-between'}
        rightIcon={<Icon as={MdForward} />}
        textAlign={'center'}
        disabled={selectedPhotos.length < 1}
        data-btn="uploaded-photo"
        onClick={() => {
          onSelect(selectedPhotos);
          onClose();
        }}
        {...getPinpointButtonClickEventProp({
          command: 'proceed with uploading photos',
          currentPage: pathname,
        })}
        zIndex={100}
      >
        {' '}
        Proceed{' '}
      </Button>
    </VStack>
  );
}
