/* eslint-disable no-irregular-whitespace */
import Main from '../components/layouts/Main';
import { COMPANY } from '../utils/constants';
import { Heading, Text, VStack } from '@chakra-ui/react';

/**
 *
 * @returns Terms & Condition component for the refund policy
 */
//ANCHOR: Terms & Condition

const Terms: React.FC = (): JSX.Element => {
  return (
    <Main>
      <VStack
        width={{
          base: '100%',
          sm: '90%',
          md: '70%',
          lg: '60%',
        }}
        height="auto"
        mx={'auto'}
      >
        <Heading fontSize={{ base: 'xl', md: '2xl', lg: '4xl' }}>
          {COMPANY.name} Terms & Conditions
        </Heading>
        <Text fontSize={{ base: 13, sm: 15, md: 16, lg: 18 }}>
          By accessing this website and/or placing a request, you agree to be
          bound by these terms & conditions and our privacy policy.
          <br />
          <br />
          By placing a request and entering personal information, you are giving{' '}
          {COMPANY.name} express consent to provide your personal data to{' '}
          {COMPANY.name} network of independent suppliers for the purpose of
          quoting and supplying the goods requested.
          <br />
          <br />
          {COMPANY.name} auto parts finder service, is an advertising platform
          which allows independent third party suppliers (our members) the
          option to quote to supply customers with the items requested via our
          website. {COMPANY.name} is a free forum service for members of the
          public and trade to enter requests for parts required. While
          {COMPANY.name} tries to ensure a high quality level of supplier, we do
          not recommend or endorse any supplier, so it is down to the customer’s
          own discretion to choose whether to purchase and which supplier to //
          eslint-disable-next-line no-irregular-whitespace purchase from. While{' '}
          {COMPANY.name} as a platform provider helps facilitate transactions
          and creates a venue for suppliers and customers to complete
          transactions, {COMPANY.name} is neither the buyer nor the seller of
          the seller's items. When you purchase your part, you will be entering
          a contract with your selected independent supplier and not with{' '}
          {COMPANY.name} itself.
          {COMPANY.name} are unable to accept any responsibility or liability
          whatsoever for any parts purchased via this network, or for any damage
          or loss that may result from their subsequent use.
          <br />
          <br />
          When placing a request, it is the sole responsibility of any customer
          to ensure all information regarding their vehicle and parts are
          entered correctly. Any specific vehicle/part information, must be
          entered by the customer (e.g. part, part number, colour, size, type,
          etc.). Reg lookup data is provided by numerous 3rd party data
          suppliers. Whilst every effort is made to make sure the information is
          correct, please double check with the supplier that the vehicle and
          parts details are correct before final purchase.
          <br />
          <br />
          It is recommended that all goods be inspected upon delivery before
          signing. If a parcel appears damaged on arrival, please DECLINE
          acceptance of goods. If you cannot decline, then please sign the POD
          as DAMAGED. If parcels opened on arrival and found to be damaged,
          please sign the POD as DAMAGED. If the driver will not let you open
          packaging, please sign for parcels as DAMAGED. We advise the customer
          contact the supplier immediately. If goods arrive but are found to be
          incorrect the supplier must be contacted within seven days from
          receipt of the goods.
          <br />
          <br />
          No parts can be removed/dismantled from parts supplied from the
          independent supplier, without prior permission by email/post from the
          supplier. All members adhere to the Consumer Contracts Regulations and
          Consumer Rights Act.
        </Text>
        <br />
        <br />
        <Text mt={4}>
          &copy;2021 July 8<sup>th</sup> {COMPANY.name}. All Rights Reserved.
        </Text>
      </VStack>
    </Main>
  );
};

export default Terms;
