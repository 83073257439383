import { Heading, Text } from '@chakra-ui/react';
import { paths } from '../utils/constants';
import Link from '../components/buttons/link';
import Main from '../components/layouts/Main';

export default function NotFound() {
  return (
    <Main
      justifyContent={'center'}
      alignItems={'center'}
      height={'100vh'}
      p={12}
    >
      <Heading fontSize={'112px'}> 404 </Heading>
      <Text textAlign={'center'}>
        {' '}
        This page does not exists. Please send the url to Riaparts.
      </Text>
      <Link href={paths.home}> Head To Home ? </Link>
    </Main>
  );
}
