import {
  Checkbox,
  Flex,
  Text,
  useColorModeValue as mode,
  useColorModeValue,
  useDisclosure,
  VStack,
  Divider,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { PaymentProcessors } from '../../API';
import useCostCalculation from '../../hooks/use-cost-calculation';
import useDateCompare from '../../hooks/use-date';
import {
  cartAtom,
  paymentProcessorAtom,
  momoPhoneNumberAtom,
  shouldDeliverAtom,
} from '../../recoil/atoms';
import { formatPrice } from '../../utils/functions';
import SelectPaymentMethod from '../modals/select-payment-method';
import PhoneNumberSelect from './phoneNumberSelect';
import { getPinpointButtonClickEventProp } from '../../utils/functions-ts';
import { useLocation } from 'react-router-dom';

interface Params {
  label: string;
  value: string;
  children?: React.ReactNode;
  conditional: boolean;
  attr: string;
  conditionalCallback: (condition: boolean) => void;
}

export const OrderSummaryItem: React.FC<Params> = ({
  label,
  value,
  children,
  attr,
  conditional = false,
  conditionalCallback = () => {},
}): JSX.Element => {
  const [show, setShow] = useState(true);

  const { pathname } = useLocation();
  useEffect(() => {
    conditionalCallback(show);
  }, [show]);

  return (
    <Flex
      justify="space-between"
      fontSize="md"
      opacity={show ? 1 : 0.5}
      width={'full'}
    >
      {conditional && (
        <>
          <Checkbox
            defaultChecked={show}
            onChange={() => setShow(!show)}
            {...getPinpointButtonClickEventProp({
              command: 'toggle items deliverability',
              currentPage: pathname,
            })}
            data-input={`check-${attr}`}
            mr={3}
          />
        </>
      )}
      <Text color={mode('gray.600', 'gray.400')} width={'full'} fontSize={'xs'}>
        {label}
      </Text>

      {value ? (
        <Text fontWeight="bold" fontSize={'xs'}>
          {value}
        </Text>
      ) : (
        children
      )}
    </Flex>
  );
};

export const CartOrderSummary = () => {
  const cart = useRecoilValue(cartAtom);
  const [paymentProcessor, setPaymentProcessor] =
    useRecoilState(paymentProcessorAtom);

  const [shouldDeliver, setShouldDeliver] = useRecoilState(shouldDeliverAtom);

  const [changePhoneNumber, setChangePhoneNumber] =
    useRecoilState(momoPhoneNumberAtom);
  const { total, delivery } = useCostCalculation(
    cart,
    shouldDeliver,
    paymentProcessor,
  );
  const startDate = '2022-11-25';
  const endDate = '2022-11-27';

  const { displayPromotionCalculation } = useDateCompare(startDate, endDate);

  const discountedAmount = (num: number, percentage: number) => {
    return num * percentage;
  };

  const changeNumber = (e: string) => {
    setChangePhoneNumber(e);
  };

  useEffect(() => {
    if (!changePhoneNumber) return;
    if (changePhoneNumber.length > 1) {
      setPaymentProcessor(
        ['96', '76'].includes(changePhoneNumber.slice(0, 2))
          ? PaymentProcessors.MTN
          : PaymentProcessors.MANUAL,
      );
    }
  }, [changePhoneNumber]);

  const disclosure = useDisclosure();
  const disableCheckout = paymentProcessor === 'MTN' ? true : false;

  return (
    <VStack
      spacing={1}
      width="full"
      backgroundColor={useColorModeValue('white', 'gray.700')}
      data-component="cart-summary-container"
    >
      <OrderSummaryItem
        label="Delivery"
        attr={'delivery'}
        value={formatPrice(delivery)}
        conditional
        conditionalCallback={(condition: boolean) => {
          console.log({ condition });
          setShouldDeliver(condition);
        }}
      />

      <Divider my={3} />

      <Flex width={'full'} justify="space-between">
        <Text fontSize={displayPromotionCalculation ? 'sm' : 'lg'}>
          Total:{' '}
        </Text>

        <Text
          data-heading="cart-total"
          fontSize={displayPromotionCalculation ? 'sm' : 'lg'}
          fontWeight="extrabold"
        >
          {displayPromotionCalculation ? (
            <s>{formatPrice(total > 1000 ? 1.1 * total : total)}</s>
          ) : (
            <>{formatPrice(total)}</>
          )}
        </Text>
      </Flex>
      {Boolean(displayPromotionCalculation) && (
        <Flex width={'full'} justify="space-between">
          <Text fontSize="lg" fontWeight={'bold'} color={'green.400'}>
            Discounted Price:{' '}
          </Text>

          <Text data-heading="cart-total" fontSize="lg" fontWeight="extrabold">
            {formatPrice(discountedAmount(total, total > 1000 ? 1 : 0.9))}
          </Text>
        </Flex>
      )}
      <Text
        fontSize={'xx-small'}
        color={disableCheckout ? 'brand.700' : 'red.400'}
        width={'full'}
      >
        <b> NOTE:</b> Only available with <b>MoMo</b> or <b>Airtel Money</b>.
      </Text>

      <PhoneNumberSelect
        changeNumber={changeNumber}
        phoneNumber={changePhoneNumber}
      />

      <SelectPaymentMethod {...disclosure} />
    </VStack>
  );
};
