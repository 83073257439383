import { HStack, Image, Spacer, Text, VStack } from '@chakra-ui/react';
import { QuoteResponse, OrderResponse } from '../../API';
import Carousel from '../../components/carousel';
import ImageContainer from '../../components/carousel/image-container';
import { formatPrice } from '../../utils/functions';
import { Prices } from '../../types/interfaces';
import { getPrices } from '../../utils/functions-ts';

//ANCHOR: declare types for props passed in
interface Params {
  QUOTE: QuoteResponse;
  order: OrderResponse;
}

/**
 *
 * @param {QUOTE}
 * @returns Returns detail section component for the quotes
 */
const OrderDetailsQuote: React.FC<Params> = ({ QUOTE, order }): JSX.Element => {
  // ANCHOR: destructure properties for the quotes
  const { request, autoPart, photos } = QUOTE;
  const { payment } = order;
  const prices: Prices = getPrices(autoPart.paymentOptions);

  //TODO - Add the functionality for view quotes details from the code below

  return (
    <VStack
      align={'start'}
      width={'200px'}
      minW={'200px'}
      spacing={2}
      p={3}
      borderRadius={'8px'}
      bg={'transparent'}
      shadow={'md'}
    >
      {photos && photos.length > 0 && (
        <Carousel width={'full'} height={'140px'} borderRadius={'4px'}>
          {photos?.map(photo => {
            return (
              <ImageContainer key={photo.imageS3Name}>
                <Image
                  src={photo.imageS3Name}
                  objectFit={'cover'}
                  width={'full'}
                  height={'full'}
                />
              </ImageContainer>
            );
          })}
        </Carousel>
      )}

      <VStack spacing={1} align={'start'}>
        <Text fontSize={'xs'} fontWeight={'medium'}>
          {request.autoPart.quantity}
          <Text as={'span'} fontWeight={'light'}>
            {' '}
            &times;{' '}
          </Text>
          {autoPart.autoPart.name}
        </Text>
        <Text fontSize={'10px'}>
          {' '}
          {request.request.make} - {request.request.model}
          {`${request.request.variant ? ' - ' + request.request.variant : ''}`}
          {request.request.variant}{' '}
        </Text>
      </VStack>

      {payment ? (
        <HStack width={'full'}>
          <Text fontSize={'xs'}>Price:</Text>
          <Spacer />
          <Text fontSize={'xs'} fontWeight={'extrabold'}>
            {payment.fee.isDeliveryIncluded
              ? formatPrice(prices.withDelivery)
              : formatPrice(prices.withoutDelivery)}
          </Text>
        </HStack>
      ) : (
        <>
          <HStack width={'full'}>
            <Text fontSize={'xs'}>Delivery:</Text>
            <Spacer />
            <Text fontSize={'xs'} fontWeight={'extrabold'}>
              {formatPrice(prices.withDelivery)}{' '}
            </Text>
          </HStack>
          <HStack width={'full'}>
            <Text fontSize={'xs'}>No Delivery:</Text>
            <Spacer />
            <Text fontSize={'xs'} fontWeight={'extrabold'}>
              {' '}
              {formatPrice(prices.withoutDelivery)}{' '}
            </Text>
          </HStack>
        </>
      )}
    </VStack>
  );
};

export default OrderDetailsQuote;

//TODO - Add the functionality for view quotes details from the code below
/*

{
          location.pathname !== `${location.pathname+"#quote-"+quote.id}` && (      
        <Link
        href={location.pathname+"#quote-"+quote.id}
        onClick={ (e: React.ChangeEvent) => {
            e.preventDefault();
            setQuote(QUOTE);
            navigate(location.pathname+"#quote-"+quote.id);
          }
        }
        fontSize={"xs"}
      > more details </Link>
          )
}

*/
