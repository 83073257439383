//NOTE: This component gives an overview of the current items in a Cart
import {
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useMemo, useRef } from 'react';
import { FiShoppingBag } from 'react-icons/fi';
import { HiChevronDown } from 'react-icons/hi';
import { useMutation } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  createOrder,
  createPayment,
  createPaymentMethod,
} from '../../api/mutations';
import {
  cartAtom,
  cartIsOpenAtom,
  cognitoUserAtom,
  disableCheckoutAtom,
  momoPhoneNumberAtom,
  orderAtom,
  paymentAtom,
  paymentProcessorAtom,
  shouldDeliverAtom,
  stepAtom,
} from '../../recoil/atoms';
import { ButtonPrimary } from '../buttons';
import PaymentContent from '../make-payment/content';
import { ScrollFade } from '../miscellaneous/Scroll';
import CartItem from './item';
import { CartOrderSummary } from './summary';

import { uniq, uniqBy } from 'lodash';
import {
  createPaymentInput,
  Creator,
  OrderResponse,
  PaymentProcessors,
  PaymentResponse,
  Status,
} from '../../API';
import { paths } from '../../utils/constants';
import { getPinpointButtonClickEventProp } from '../../utils/functions-ts';

const Cart: React.FC = (): JSX.Element => {
  //NOTE: initialize component state

  const user = useRecoilValue(cognitoUserAtom);
  const [opened, setOpened] = useRecoilState(cartIsOpenAtom);
  const shouldDeliver = useRecoilValue(shouldDeliverAtom);
  const processor = useRecoilValue(paymentProcessorAtom);
  const [cart, setCart] = useRecoilState(cartAtom);
  const setOrder = useSetRecoilState(orderAtom);
  const setPayment = useSetRecoilState(paymentAtom);
  const [step, setStep] = useRecoilState(stepAtom);
  const momoPhoneNumber = useRecoilValue(momoPhoneNumberAtom);
  //NOTE: initialize creation functions
  const createOrderMutation = useMutation(createOrder);
  const createPaymentMethodMutation = useMutation(createPaymentMethod);
  const createPaymentMutation = useMutation(createPayment);
  const [disableCheckout, setDisableCheckout] =
    useRecoilState(disableCheckoutAtom);
  // get expired items
  const todaysDateValue = new Date().valueOf();
  const expiredQuotes = useMemo(
    () =>
      cart.filter(
        item => todaysDateValue > new Date(item.expiryDate).valueOf(),
      ),
    [cart],
  );
  const hasExpiredQuotes = Boolean(expiredQuotes.length);

  const initialFocus = useRef<HTMLDivElement>();
  const toast = useToast();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  //NOTE: Initialize express boolean

  // const open = () => setOpened(true);
  const close = () => {
    navigate(-1);
  };

  //NOTE: remove items from cart
  const removeExpiredItemsFromCart = () => {
    setCart(cart => cart.filter(item => !(expiredQuotes.indexOf(item) + 1)));
  };

  //TODO: user to request newDate for expired
  const requestNewDatesForExpiredQuotes = () => {};

  useEffect(() => {
    setDisableCheckout(cart.length < 1);
  }, [cart]);

  //NOTE: cart item checkout function
  async function checkout() {
    try {
      //NOTE: If no user, throw an error
      if (!user) throw new Error('No current user before checkout');
      //NOTE: If no phonnumber, throw an error
      if (!momoPhoneNumber) throw new Error('No MoMo Phone number');

      //NOTE: check if the qoute has an expired date
      if (hasExpiredQuotes)
        return toast({
          status: 'warning',
          title: 'Can not create an order with expired quotes.',
          description:
            'Please remove the items from the cart and create a new request.',
          duration: 3500,
          isClosable: true,
          position: 'bottom',
        });

      const uniqPartners = uniqBy(cart, 'partnerId');
      const quotes = uniqPartners.map(partner => {
        let quote = {
          partnerId: partner.partnerId,
          quoteIds: uniq(
            cart
              .filter(item => item.partnerId === partner.partnerId)
              .map(item => item.quoteId),
          ),
        };
        return quote;
      });

      //NOTE: call creation functions and store the response
      createOrderMutation
        .mutateAsync({
          quotes,
          customerId: (user as any).sub || user?.id,
          status: Status.OPEN,
        })
        .then(order => {
          console.log('order::', order);
          if (!order) throw new Error('Order creation failed');
          if ('error' in order || order.__typename === 'ErrorResponse') {
            toast({
              title: 'Can not Create order',
              description: 'Please try again',
              duration: 3000,
              isClosable: true,
              position: toastPosition > 500 ? 'bottom-left' : 'bottom',
            });

            throw new Error(order.error || order.code);
          }

          if (Boolean(order) || order.__typename === 'OrderResponse') {
            //NOTE: call creation functions and store the response
            const paymentInput: createPaymentInput = {
              orderId: order?.order.id,
              creatorId: (user as any).sub || user?.id,
              creatorType: Creator.CUSTOMER,
              processor,
              momoPhoneNumber: `260${momoPhoneNumber}`,
              isDeliveryIncluded: shouldDeliver,
            };

            createPaymentMutation.mutateAsync(paymentInput).then(payment => {
              //ANCHOR: check if there is a payment
              console.log('payment::', payment);
              if (!payment) {
                toast({
                  title: 'Can not Create Payment',
                  description: 'Please try again',
                  duration: 3000,
                  isClosable: true,
                  position: toastPosition > 500 ? 'bottom-left' : 'bottom',
                });
                throw new Error('Cannot Create Payment');
              }

              //ANCHOR: throw error if backend responds with an error
              if (
                'error' in payment ||
                payment.__typename === 'ErrorResponse'
              ) {
                toast({
                  title: 'Can not Create Payment',
                  description: 'Please try again',
                  duration: 3000,
                  isClosable: true,
                  position: toastPosition > 500 ? 'bottom-left' : 'bottom',
                });
                throw new Error(payment.error || payment.code);
              }

              if (processor === PaymentProcessors.MTN) {
                setPayment(payment as PaymentResponse);
                setOrder(order as OrderResponse);
                setCart([]);
                navigate(paths.orders);
                return;
              }
              setPayment(payment as PaymentResponse);
              setOrder(order as OrderResponse);
              setStep(2);
              setCart([]);
            });
          }
        });
    } catch (error) {
      console.log(error);
      //NOTE: show a user if there was an error
      toast({
        title: 'There was an error trying to check out.',
        description: 'Please try again',
        duration: 3000,
        isClosable: true,
        position: toastPosition > 500 ? 'bottom-left' : 'bottom',
      });
    }
  }

  const placement = window.innerWidth;

  const toastPosition = window.innerWidth;

  //NOTE: initialize responsive properties
  const zeroItemsInCart = useColorModeValue('gray.100', 'gray.800');
  const zeroItemsInCartTextColor = useColorModeValue('gray.600', 'gray.50');
  const expiredQuotesMessageBG = useColorModeValue('gray.100', 'gray.800');
  const expiredQuotesMessageTextColor = useColorModeValue(
    'gray.700',
    'gray.200',
  );

  return (
    <Box key={'cart'}>
      {/* button  */}
      <Box
        as={'a'}
        display={'block'}
        href={pathname + '#cart'}
        width={'64px'}
        height={0}
        position={{
          base: 'relative',
          md: 'fixed',
        }}
        {...getPinpointButtonClickEventProp({
          command: 'open cart drawer',
          currentPage: pathname,
        })}
        bottom={{
          base: 0,
          md: '50px',
        }}
        right={{
          base: 0,
          md: '50px',
        }}
        onClick={() => {
          setOpened(true);
          navigate(pathname + '#cart');
        }}
      >
        <Box
          position={'absolute'}
          width={'min-content'}
          p={{
            base: '6px',
            md: '12px',
          }}
          transform={{
            base: 'translate(0px, -50px)',
            md: 'translate(-0px, -55px)',
          }}
          borderRadius={'full'}
          bg={{
            base: useColorModeValue('light-background', 'dark-background'),
            md: 'brand.300',
          }}
          borderBottomWidth={{ base: '1px', md: '0px' }}
          borderWidth={{ base: '0px', md: '1px' }}
          boxShadow={{ base: 'none', md: 'lg' }}
        >
          <Box
            as={'span'}
            display={'flex'}
            width={4}
            height={4}
            position={'absolute'}
            transform={{
              base: 'translate(35px,0)',
              md: 'translate(43px, -10px)',
            }}
            data-btn="cart"
          >
            <Text
              as={'span'}
              position={'absolute'}
              display={'inline-flex'}
              w={'full'}
              h={'full'}
              rounded={'full'}
              bg={'green.300'}
              opacity={0.75}
              className={cart.length ? 'animate-ping' : ''}
            />
            <Text
              as={'span'}
              position={'relative'}
              display={'inline-flex'}
              rounded={'full'}
              w={4}
              h={4}
              bg={cart.length ? 'green.300' : 'green.200'}
              fontSize={'9px'}
              fontWeight={'bold'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              {' '}
              {cart.length}{' '}
            </Text>
          </Box>
          <Flex
            as={'button'}
            width={'50px'}
            height={'50px'}
            rounded={'full'}
            align={'center'}
            justify={'center'}
            bg={'brand.400'}
          >
            <FiShoppingBag size={'18px'} color={'aliceblue'} />
          </Flex>
        </Box>
      </Box>
      {/* drawer */}
      <Drawer
        isOpen={opened}
        placement={placement > 500 ? 'right' : 'bottom'}
        size={{
          md: 'sm',
        }}
        onClose={close}
      >
        <DrawerOverlay />
        <DrawerContent
          borderTopRadius={{
            base: '20px',
            md: 0,
          }}
          bg={useColorModeValue('white', 'gray.700')}
          p={2}
          width={'full'}
          maxH={{
            base: '97%',
            md: '100vh',
          }}
          height={'full'}
          margin={{
            base: 0,
            md: '0px',
          }}
        >
          <DrawerCloseButton
            fontSize={'xs'}
            fontWeight={'bold'}
            color={'gray.700'}
          />
          <Text height={'5px'}></Text>

          <DrawerBody
            as={VStack}
            justifyContent={'flex-start'}
            alignItems={'start'}
            spacing={6}
            py={6}
            width={'full'}
            px={4}
            data-component="cart"
          >
            <HStack width={'full'} spacing={2}>
              <Text
                fontSize={'sm'}
                fontWeight={'bold'}
                width={'full'}
                color={useColorModeValue('gray.800', 'gray.50')}
                data-heading="cart"
              >
                {' '}
                {step === 1 ? '1. Accepted Quotes' : '2. Payment'}{' '}
              </Text>
              <Text
                fontSize={'10'}
                minW={'fit-content'}
                color={useColorModeValue('gray.500', 'gray.500')}
                data-heading="cart-items"
              >
                {' '}
                <b>{cart.length}</b> item{cart.length > 1 && 's'}
              </Text>
            </HStack>
            <Box
              bg={useColorModeValue('gray.100', 'gray.900')}
              height={'4px'}
              borderRadius={'lg'}
              width={'full'}
            >
              <Box
                width={((step - 1) / 2) * 100 + '%'}
                minW={'10%'}
                height={'full'}
                borderRadius={'lg'}
                bg={'brand.400'}
                transition={'width 0.3s ease'}
              />
            </Box>

            {step === 1 && (
              <VStack
                height={'full'}
                maxH={'fit-container'}
                spacing={4}
                overflow={'scroll'}
                width={'full'}
                className={'no-scroll'}
                alignItems={'center'}
                justifyContent={'flex-start'}
              >
                <VStack
                  spacing={4}
                  width={'full'}
                  overflow={'scroll'}
                  className={'no-scroll'}
                >
                  {!cart.length && (
                    <Center
                      width={'full'}
                      py={24}
                      px={4}
                      bg={zeroItemsInCart}
                      flexDir={'column'}
                      borderRadius={'4px'}
                    >
                      <HStack>
                        <Text
                          fontWeight={'medium'}
                          fontSize={'xs'}
                          color={zeroItemsInCartTextColor}
                          textAlign={'center'}
                        >
                          {' '}
                          No quotes have been accepted.{' '}
                        </Text>
                        <Text
                          fontWeight={'medium'}
                          fontSize={'xs'}
                          color={'blue'}
                          textAlign={'center'}
                        >
                          <Link to={paths.requests}>See Quotes</Link>{' '}
                        </Text>
                      </HStack>
                    </Center>
                  )}
                  {cart.map((item, i) => {
                    return (
                      <CartItem
                        item={item}
                        key={i}
                        isExpired={Boolean(expiredQuotes.indexOf(item) + 1)}
                      />
                    );
                  })}

                  {cart.length > 5 && <Box minH={'50px'} width={'full'} />}
                </VStack>
              </VStack>
            )}

            {step === 1 && cart.length > 5 && (
              <Box width={'full'} bg={'red'}>
                <ScrollFade
                  height={'80px'}
                  marginTop={'-80px'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  pt={6}
                  children={
                    <Button
                      size={'xs'}
                      fontSize={'8px'}
                      backgroundColor={'white'}
                      borderWidth={'0.33px'}
                      borderColor={'gray.100'}
                      padding={2}
                      rightIcon={<Icon as={HiChevronDown} size={'12'} />}
                      className={'animate-bounce'}
                    >
                      {' '}
                      5 more items{' '}
                    </Button>
                  }
                />
              </Box>
            )}

            {hasExpiredQuotes && step === 1 && (
              <VStack spacing={1} p={3} bg={expiredQuotesMessageBG}>
                <Text fontSize={'xs'} color={expiredQuotesMessageTextColor}>
                  Your cart has some expired items in it. You can{' '}
                  <Text
                    as={'span'}
                    role={'button'}
                    cursor={'pointer'}
                    textDecor={'underline'}
                    fontWeight={'semibold'}
                    color={'brand-pink.400'}
                    onClick={removeExpiredItemsFromCart}
                  >
                    remove
                  </Text>{' '}
                  the expired items or{' '}
                  <Text
                    as={'span'}
                    role={'button'}
                    cursor={'pointer'}
                    textDecor={'underline'}
                    fontWeight={'semibold'}
                    color={'brand-blue.400'}
                    onClick={requestNewDatesForExpiredQuotes}
                  >
                    request
                  </Text>{' '}
                  the supplier to update their expiry date.
                </Text>
              </VStack>
            )}

            {step === 1 && !!cart.length && (
              <>
                <Divider />
                <CartOrderSummary />
              </>
            )}

            {step === 2 && <PaymentContent />}
          </DrawerBody>
          {step === 1 && (
            <DrawerFooter width={'full'} px={4}>
              <ButtonPrimary
                width={'full'}
                ref={initialFocus}
                onClick={checkout}
                isLoading={
                  createOrderMutation.isLoading ||
                  createPaymentMethodMutation.isLoading ||
                  createPaymentMutation.isLoading
                }
                disabled={disableCheckout}
                data-btn="checkout"
              >
                {' '}
                Next{' '}
              </ButtonPrimary>
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Cart;
