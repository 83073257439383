/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useDisclosure, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import PhotoSelect from '../../../components/modals/select-and-upload-image';
import NextBtn from '../../../components/Stepper/next';
import {
  requestStepAtom,
  requestPrevPhotosAtom,
  requestUploadPhotosAtom,
  selectedAutopartQuantityAtom,
  AutopartListAtom,
  AutopartSelectedAtom,
} from '../../../recoil/atoms';
import { IoIosAddCircle } from 'react-icons/io';
import AutoPartList from '../../../components/autopartList';
import EditAutopart from '../../../components/autopartList/editautopart';
import { ImCamera } from 'react-icons/im';
import PhotoList from './PhotoList';
import { Photo } from '../../../API';
import { getPinpointButtonClickEventProp } from '../../../utils/functions-ts';
import { useLocation } from 'react-router-dom';
export interface Item {
  label: string;
  value: string;
}

const AutopartForm: React.FC = (): JSX.Element => {
  // SECTION: initialize and set the state of the component
  //const isAuthenticated = useRecoilValue(isAuthenticatedAtom);
  //const setSelectV = useSetRecoilState(selectedVehicle);
  const [selectedAutopart, setSelectedAutopart] =
    useRecoilState(AutopartSelectedAtom);
  const { pathname } = useLocation();

  const setQuantity = useSetRecoilState(selectedAutopartQuantityAtom);
  //const color = useColorModeValue('gray.700', 'gray.400');
  const [requestPreviousPhotos, setRequestPreviousPhotos] = useRecoilState(
    requestPrevPhotosAtom,
  );
  const [requestUploadPhotos, setRequestUploadPhotos] = useRecoilState(
    requestUploadPhotosAtom,
  );
  const [autopartList, setAutopartList] = useRecoilState(AutopartListAtom);
  const [activeStep, setActiveStep] = useRecoilState(requestStepAtom);

  const [autoNameInput, setAutoNameInput] = useState<string>('');
  const [autoNumberInput, setAutoNumberInput] = useState<string>('');
  const [quantityNumber, setQuantityNumber] = useState<number>(1);
  const [autoDescription, setAutoDescription] = useState<string>('');

  const test = () => {
    console.log(autopartList);
  };

  useEffect(() => {
    window.addEventListener('load', test);

    return () => {
      window.removeEventListener('load', test);
    };
  }, []);

  const toast = useToast();
  const photosDisclosure = useDisclosure();

  //ANCHOR - reset state function
  const resetState = () => {
    setAutoNameInput('');
    setAutoNumberInput('');
    setRequestPreviousPhotos([]);
    setRequestUploadPhotos([]);
    setQuantityNumber(1);
    setQuantity(1);
  };

  let photos: string[] = [
    ...requestPreviousPhotos.map(({ imageS3Name }) => imageS3Name),
    ...requestUploadPhotos,
  ];

  //Handle a partial photo deletion
  const handleDeletePhoto = (photoURL: string) => {
    //console.log('I am logging here for the photo', photoURL);
    let isLocalPhoto: boolean = false;
    let isPrevPhoto: boolean = false;
    const newLocalPhotoArray: string[] = requestUploadPhotos.filter(url => {
      const isTrue: boolean = url !== photoURL;
      if (!isTrue) {
        isLocalPhoto = true;
      }
      return isTrue;
    });
    if (isLocalPhoto) {
      //console.log('here', newLocalPhotoArray);
      setRequestUploadPhotos([...newLocalPhotoArray]);
      return;
    }

    const newNetworkPhotoArray: Photo[] = requestPreviousPhotos.filter(
      photo => {
        const isTrue: boolean = photo.imageS3Name !== photoURL;
        if (!isTrue) {
          isPrevPhoto = true;
        }
        return isTrue;
      },
    );

    if (isPrevPhoto) {
      setRequestPreviousPhotos([...newNetworkPhotoArray]);
      return;
    }
  };

  const nonAlphaNumericRegex = /^[^a-zA-Z0-9]+/;

  const quantityNum = (valueNum: number): void => {
    //const valueNum = parseInt(value);
    if (isNaN(valueNum)) {
      setQuantityNumber(Num => (Num = 1));
      setQuantity(1);
      return;
    } else {
      setQuantityNumber(Num => (Num = valueNum));
      setQuantity(quantityNumber);
    }
  };

  const addAutopart = () => {
    if (!autoNameInput) {
      toast({
        // NOTE: else show a toast when the user is not signed in successfully
        title: 'Autopart name is required ',
        status: 'info',
        duration: 1500,
        isClosable: true,
        position: 'bottom',
      });
      return;
    }
    if (quantityNumber < 1) {
      toast({
        // NOTE: else show a toast when the user is not signed in successfully
        title: 'Enter a valid Quantity number ',
        status: 'info',
        duration: 1500,
        isClosable: true,
        position: 'bottom',
      });
      return;
    }
    if (nonAlphaNumericRegex.test(autoNameInput)) {
      toast({
        // NOTE: else show a toast when the user is not signed in successfully
        title: 'Make sure Autopart name starts with an alpha-numeric !!',
        status: 'warning',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
      return;
    }
    if (selectedAutopart !== null) {
      let newAutopartArray = autopartList.map((autopart, index) => {
        if (autopart.id === selectedAutopart.id) {
          return {
            ...autopart,
            name: autoNameInput,
            OEM: autoNumberInput,
            quantity: quantityNumber,
            description: autoDescription,
            uploadedPhotos: [
              ...requestPreviousPhotos.map(({ imageS3Name }) => imageS3Name),
            ],
            requestPhotos: [...requestUploadPhotos],
            src: photos,
          };
        }
        return autopart;
      });
      setAutopartList(newAutopartArray);
      setSelectedAutopart(null);
      resetState();
      return;
    }
    setAutopartList([
      ...autopartList,
      {
        name: autoNameInput,
        OEM: autoNumberInput,
        src: photos,
        uploadedPhotos: [
          ...requestPreviousPhotos.map(({ imageS3Name }) => imageS3Name),
        ],
        requestPhotos: [...requestUploadPhotos],
        quantity: quantityNumber,
        id: Date.now(),
      },
    ]);
    resetState();
  };
  const nextStep = () => {
    if (autopartList.length < 1 && !autoNameInput) {
      toast({
        // NOTE: else show a toast when the user is not signed in successfully
        title: 'Autopart Name is required',
        status: 'info',
        duration: 1500,
        isClosable: true,
        position: 'bottom',
      });
      return;
    }
    if (quantityNumber < 1) {
      toast({
        // NOTE: else show a toast when the user is not signed in successfully
        title: 'Enter a valid Quantity number ',
        status: 'info',
        duration: 1500,
        isClosable: true,
        position: 'bottom',
      });
      return;
    }
    if (nonAlphaNumericRegex.test(autoNameInput)) {
      toast({
        // NOTE: else show a toast when the user is not signed in successfully
        title: 'Autopart name must be alpha-numeric.',
        description:
          'Remove all spaces and Special Characters from start of the name',
        status: 'warning',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
      return;
    }
    if (autoNameInput && selectedAutopart === null) {
      setAutopartList([
        ...autopartList,
        {
          name: autoNameInput,
          OEM: autoNumberInput,
          src: photos,
          uploadedPhotos: [
            ...requestPreviousPhotos.map(({ imageS3Name }) => imageS3Name),
          ],
          requestPhotos: [...requestUploadPhotos],
          quantity: quantityNumber,
          id: Date.now(),
        },
      ]);
    }
    setActiveStep(activeStep + 1);
    resetState();
    setSelectedAutopart(null);
  };
  return (
    <div
      className="sm:w-[80%] md:w-[30%] h-[370px] rounded-md mb-8 mt-2 shadow-sm border border-gray-200 flex justify-between items-center p-1  flex-col md:my:4 sm:my-0"
      data-component="autopart-form"
    >
      {selectedAutopart !== null && <EditAutopart />}
      <div className="w-full">
        {autopartList && autopartList.length > 0 && <AutoPartList />}
        <div className="my-4 w-full flex items-center justify-between gap-3 p-2">
          <input
            type="number"
            id="tentacles"
            name="tentacles"
            min="10"
            max="100"
            value={quantityNumber}
            onChange={(e: any) => {
              quantityNum(e.target.value);
              /*
            if (isNaN(parseInt(e.target.value))) {
              quantityNum(0);
            } else {
              quantityNum(e.target.value);
            }
            */
            }}
            data-input="autopart-form-quantity"
            className="bg-gray-50 w-[20%] text-center border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-2 focus:border-blue-500 block p-1.5"
          />
          <p className="text-lg font-bold">X</p>
          <div className="w-full relative flex items-center bg-white border border-gray-300 rounded-lg overflow-hidden">
            <ImCamera
              onClick={photosDisclosure.onOpen}
              className="text-gray-600 absolute right-2"
              size={24}
              data-btn="autopart-form-camera"
              {...getPinpointButtonClickEventProp({
                command: 'add photos to request',
                currentPage: pathname,
              })}
            />
            <input
              type="text"
              id="autopartName"
              className=" text-gray-900 text-sm focus:border-2 rounded-md focus:border-blue-500 block w-[80%] p-1.5"
              placeholder="Enter part name"
              required
              onChange={(e: any) => {
                setAutoNameInput(e.target.value);
              }}
              value={autoNameInput}
              data-input="autopart-form-name"
            />
          </div>
          <button
            onClick={() => addAutopart()}
            data-btn="autopart-form-add"
            {...getPinpointButtonClickEventProp({
              command: 'add autopart to list',
              currentPage: pathname,
            })}
            className="hover:bg-gray-400 bg-brand-400 text-slate-900 font-bold p-1 rounded inline-flex items-center justify-center"
          >
            <IoIosAddCircle size={26} color={'aliceblue'} />
          </button>
        </div>
        <PhotoList
          showDeleteIcon
          callback={e => handleDeletePhoto(e)}
          photos={photos}
        />
      </div>

      <PhotoSelect
        onOpen={photosDisclosure.onOpen}
        isOpen={photosDisclosure.isOpen}
        onClose={photosDisclosure.onClose}
        heading={'Add Image to request'}
        max={Infinity}
        data-component="photo-select"
        {...getPinpointButtonClickEventProp({
          command: 'select photo',
          currentPage: pathname,
        })}
      />
      <div className="w-full flex flex-row items-end justify-between gap-4 px-2">
        <NextBtn
          {...getPinpointButtonClickEventProp({
            command:
              'from step ' +
              activeStep +
              'to previous step of the create request stepper',
            currentPage: pathname,
          })}
          dataAtr="autopart-form-back"
          isDisabled
          callback={() => {}}
        />
        <NextBtn
          dataAtr="autopart-form-next"
          {...getPinpointButtonClickEventProp({
            command:
              'from step ' +
              activeStep +
              'to next step of the create request stepper',
            currentPage: pathname,
          })}
          isNext
          callback={() => nextStep()}
        />
      </div>
    </div>
  );
};

export default React.memo(AutopartForm);

/*\
{autopartList.length < 3 && photos.length < 1 ? (
        <div className="w-[90%] my-auto mx-auto h-auto flex justify-center items-center p-2 border border-gray-300 shadow-sm rounded-md">
          <PartnerNames />
        </div>
      ) : null}

<Button
          mb={'1'}
          size={'sm'}
          colorScheme={'brand'}
          data-btn="autopart-form-add-autopart"
        >
          {selectedAutopart !== null
            ? 'Update Autopart'
            : autopartList.length > 0
            ? 'Add Another Autopart'
            : 'Add Autopart'}
        </Button>



 {
        <SimpleGrid
          p={3}
          spacing={4}
          borderRadius={'8px'}
          borderWidth={'1px'}
          columns={3}
          width={'full'}
          data-component="autopart-form-images-place-holder"
        >
          <VStack
            as={Button}
            spacing={3}
            width={'80px'}
            height={'80px'}
            borderRadius={'8px'}
            alignItems={'center'}
            justifyContent={'center'}
            onClick={photosDisclosure.onOpen}
            data-btn="upload-photo"
          >
            <IoMdAddCircle size={'20px'} />
            <Text fontSize={'10px'}> Add Images </Text>
          </VStack>

          {photos.map((photo, index) => {
            return (
              <Image
                src={photo}
                key={index}
                width={'full'}
                height={'80px'}
                objectFit={'cover'}
                borderRadius={'4px'}
              />
            );
          })}
        </SimpleGrid>
      }s

*/
