/* eslint-disable react/jsx-key */
import {
  Divider,
  Flex,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Request } from '../../../API';
import {
  selectedRequestAtom,
  selectedVehicle,
  updateRequestInputAtom,
  partUpdateAtom,
} from '../../../recoil/atoms';
import { EditablePartDetails } from '../../../types/interfaces';
import { textFromCamelCase } from '../../../utils/functions';
import { ButtonSoftPrimaryGreen } from '../../buttons';
import VehicleSlider from '../../vehicle-slider';
import { getPinpointButtonClickEventProp } from '../../../utils/functions-ts';
import { useLocation } from 'react-router-dom';

interface Props {
  request: Request;
}

const EditRequest = ({ request }: Props) => {
  const mySelectedVehicle = useRecoilValue(selectedVehicle);
  const selectedRequest = useRecoilValue(selectedRequestAtom);
  const [changeVehicle, setChangeVehicle] = useState<boolean>(false);
  const [quantity, setQuantity] = useState(selectedRequest?.autoPart.quantity);
  const setUpdateRequestInput = useSetRecoilState(updateRequestInputAtom);
  const [partDetails, setPartDetails] = useRecoilState(partUpdateAtom);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!selectedRequest) return;
    setUpdateRequestInput({
      id: request.id,
      vehicleId: mySelectedVehicle?.vehicle.id,
      requestedPart: {
        quantity: quantity || 1,
        autoPartId: selectedRequest.autoPart.autoPart.id,
      },
      make: mySelectedVehicle?.vehicle.make || selectedRequest.request.make,
      model: mySelectedVehicle?.vehicle.make || selectedRequest.request.make,
      year:
        mySelectedVehicle?.vehicle.year || selectedRequest.request.year || 0,
      fuel: mySelectedVehicle?.vehicle.fuel || selectedRequest.request.fuel,
      gearbox:
        mySelectedVehicle?.vehicle.gearbox || selectedRequest.request.gearbox,
      VIN: mySelectedVehicle?.vehicle.make || selectedRequest.request.make,
      engineNumber:
        mySelectedVehicle?.vehicle.make || selectedRequest.request.make,
    });
  }, [request.id, mySelectedVehicle, quantity, selectedRequest]);

  const displayVehicle = (pathname: string) => {
    if (!mySelectedVehicle) return null;

    const {
      VIN,
      engineNumber,
      make,
      model,
      year,
      plateNumber,
      gearbox,
      variant,
      fuel,
      otherDetails,
    } = mySelectedVehicle.vehicle;

    const details: any = otherDetails
      ? JSON.parse(otherDetails)
      : { description: '' };

    const forDisplay = {
      make,
      model,
      year,
      numberPlate: plateNumber,
      specification: variant,
      gearbox,
      fuel,
      chassis: VIN,
      engineNumber,
      description: details.description,
    };

    return (
      <>
        <Text fontSize={'sm'} color={'gray.700'}></Text>
        <Flex w={'full'} px={'2'}>
          <Text fontSize={'sm'} color={'gray.700'}>
            <b> {changeVehicle ? 'Choose Vehicle' : 'Vehicle Details '}</b>
          </Text>
          <Spacer />
          <ButtonSoftPrimaryGreen
            onClick={() => {
              setChangeVehicle(!changeVehicle);
            }}
            {...getPinpointButtonClickEventProp({
              command: 'change vehicle',
              currentPage: pathname,
            })}
            size={'xs'}
          >
            {changeVehicle ? 'Cancel' : 'Change Vehicle'}
          </ButtonSoftPrimaryGreen>
        </Flex>
        {changeVehicle && <VehicleSlider />}
        {Object.entries(forDisplay).map(([key, value]) =>
          value ? (
            <Flex w={'full'} px={'2'}>
              <Text fontSize={'sm'} color={'gray.700'}>
                {textFromCamelCase(key)}
              </Text>
              <Spacer />
              <b> {value} </b>
            </Flex>
          ) : null,
        )}
      </>
    );
  };

  const onPartDetailChange = (
    key: string,
    value: string | number,
    newString: string,
  ) => {
    setPartDetails({ ...partDetails, [key]: newString });
  };

  useEffect(() => {
    displayRequest();
  }, []);

  const displayRequest = () => {
    if (!selectedRequest) return null;
    console.log(selectedRequest);
    const { OEM, description, name } = selectedRequest.autoPart.autoPart;

    const forDisplay: EditablePartDetails = {
      name,
      description: description || '',
      partNumber: OEM || '',
    };

    setPartDetails(forDisplay);
  };

  return (
    <VStack
      alignItems={'start'}
      spacing={1.5}
      justifyContent={'flex-start'}
      width={'full'}
      px={'2'}
      overflowY={'scroll'}
    >
      <VStack
        alignItems={'start'}
        spacing={0.5}
        justifyContent={'flex-start'}
        width={'full'}
        px={'2'}
      >
        <Text fontSize={'sm'} color={'gray.700'}>
          <b>Autopart Details</b>
        </Text>
        <Flex w={'full'} px={'2'}>
          <Text fontSize={'sm'} color={'gray.700'}>
            {' '}
            Quantity{' '}
          </Text>
          <Spacer />
          <NumberInput
            size={'sm'}
            width={'90px'}
            borderRadius={'16px'}
            value={quantity}
            placeholder={'optional'}
            variant={'filled'}
            onChange={(value: string, valueNum: number) =>
              setQuantity(valueNum)
            }
          >
            <NumberInputField borderRadius={'12px'} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
        {Object.entries(partDetails).map(([key, value]) => (
          <Flex w={'full'} px={'2'}>
            <Text fontSize={'sm'} color={'gray.700'}>
              {textFromCamelCase(key)}
            </Text>
            <Spacer />
            <Input
              onChange={e => {
                onPartDetailChange(key, value, e.target.value);
              }}
              variant={'outline'}
              type={'text'}
              value={value}
              width={'60%'}
              height={'30px'}
              data-input="part-number"
            />
          </Flex>
        ))}
      </VStack>
      <Divider />
      {displayVehicle(pathname)}
    </VStack>
  );
};

export default EditRequest;
