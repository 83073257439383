import {
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  Text,
} from '@chakra-ui/react';

export default function ErrorAlert({
  title = 'Something went wrong',
  description = 'We encountered an error while processing your request',
  again = () => {},
}) {
  return (
    <Alert
      status="error"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flexDir={'column'}
      textAlign="center"
      minH="200px"
      borderRadius={'lg'}
    >
      <AlertIcon />
      <AlertTitle> {title} </AlertTitle>
      <AlertDescription>
        {description} <br />
        <Text
          as={'span'}
          fontWeight={'semibold'}
          letterSpacing={'0px'}
          wordBreak={1}
          cursor={'pointer'}
          _hover={{
            textDecor: 'underline',
          }}
          onClick={again}
        >
          try again
        </Text>{' '}
        ?
      </AlertDescription>
    </Alert>
  );
}
