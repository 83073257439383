import { GRAPHQL_AUTH_MODE, API } from '@aws-amplify/api';
import { Auth } from '@aws-amplify/auth';
import { CognitoUser } from 'amazon-cognito-identity-js';
import {
  createAutoPartInput,
  CreateAutoPartMutation,
  createConversationInput,
  CreateConversationMutation,
  createOrderInput,
  CreateOrderMutation,
  createPaymentInput,
  createPaymentMethodInput,
  CreatePaymentMutation,
  CreateRequestMutation,
  createVehicleInput_ as createVehicleInput,
  CreateVehicleMutation,
  Creator,
  updateRequestInput,
  UpdateRequestMutation,
  updateVehicleInput,
  UpdateVehicleMutation,
  CreatePaymentMethodMutation,
  UpdateQuoteMutation,
  updateQuoteInput,
  UpdateCustomerMutation,
  updateCustomerInput_ as updateCustomerInput,
  CreateMessageMutation,
  RetryPaymentMutationVariables,
  RetryPaymentMutation,
  UpdateAutoPartMutation,
  DeleteVehicleMutation,
  changePaymentProcessorInput,
  ChangePaymentProcessorMutation,
  VehicleOutput,
  VehicleResponse,
  RequestResponse,
  AutoPart,
  createRequestInput,
  createMessageInput,
  updateAutoPartInput,
} from '../API';
import {
  createAutoPart as createAutoPartMutation,
  createRequest as createRequestMutation,
  createConversation as createConversationMutation,
  createMessage,
  createPaymentMethod as createPaymentMethodMutation,
  createPayment as createPaymentMutation,
  createOrder as createOrderMutation,
  updateQuote as updateQuoteMutation,
  updateCustomer as updateCustomerMutation,
  updateRequest,
  createVehicle as createVehicleMutation,
  updateVehicle as updateVehicleMutation,
  retryPayment,
  updateAutoPart,
  deleteVehicle as deleteVehicleMutation,
  changePaymentProcessor,
} from '../graphql/mutations';
import { compressImage, getBase64 } from '../utils/functions';

import { UploadPhoto, UploadPhotoResult } from '../types/interfaces';

export async function changePaymentMethod(input: changePaymentProcessorInput) {
  const { data } = (await API.graphql({
    query: changePaymentProcessor,
    variables: { input },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as { data: ChangePaymentProcessorMutation };
  return data.changePaymentProcessor;
}

export async function reInitializePayment(
  input: RetryPaymentMutationVariables,
) {
  const { data } = (await API.graphql({
    query: retryPayment,
    variables: { ...input },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as { data: RetryPaymentMutation };
  return data.retryPayment;
}

export async function signIn({
  phoneNumber,
}: {
  phoneNumber: string;
}): Promise<CognitoUser> {
  return Auth.signIn(phoneNumber);
}

export async function createAutoPart(input: createAutoPartInput) {
  const { data } = (await API.graphql({
    query: createAutoPartMutation,
    variables: { input },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as { data: CreateAutoPartMutation };
  console.log(data.createAutoPart);
  return data.createAutoPart;
}

export async function updateVehicle(input: updateVehicleInput) {
  const { data } = (await API.graphql({
    query: updateVehicleMutation,
    variables: { input },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as { data: UpdateVehicleMutation };
  return data.updateVehicle as VehicleOutput;
}

export async function createVehicle(input: createVehicleInput) {
  console.log('inputVehicle::', input);
  const { data } = (await API.graphql({
    query: createVehicleMutation,
    variables: { input },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as { data: CreateVehicleMutation };
  console.log('returned vehicle data::', data);
  return data.createVehicle as VehicleResponse;
}

export async function createRequest(input: createRequestInput) {
  const { data } = (await API.graphql({
    query: createRequestMutation,
    variables: { input },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as { data: CreateRequestMutation };
  //console.log('request response', data);
  return data.createRequest;
}

export async function editRequest(input: updateRequestInput) {
  const { data } = (await API.graphql({
    query: updateRequest,
    variables: {
      input,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as { data: UpdateRequestMutation };
  return data.updateRequest as RequestResponse;
}

export async function editAutopart(input: updateAutoPartInput) {
  const { data } = (await API.graphql({
    query: updateAutoPart,
    variables: {
      input,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as { data: UpdateAutoPartMutation };

  return data.updateAutoPart as AutoPart;
}

export async function deleteVehicle(id: string) {
  const { data } = (await API.graphql({
    query: deleteVehicleMutation,
    variables: {
      id,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as { data: DeleteVehicleMutation };
  console.log(
    '🚀 ~ file: mutations.ts ~ line 153 ~ deleteVehicle ~ data',
    data,
  );

  return data.deleteVehicle;
}

export async function createConversation(input: createConversationInput) {
  const { data } = (await API.graphql({
    query: createConversationMutation,
    variables: {
      input,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as { data: CreateConversationMutation };
  return { conversation: data.createConversation };
}

export async function createOrder(input: createOrderInput) {
  const { data } = (await API.graphql({
    query: createOrderMutation,
    variables: {
      input,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as { data: CreateOrderMutation };
  return data.createOrder;
}

export async function createPaymentMethod(input: createPaymentMethodInput) {
  const { data } = (await API.graphql({
    query: createPaymentMethodMutation,
    variables: {
      input,
    },
  })) as { data: CreatePaymentMethodMutation };
  return data.createPaymentMethod;
}

export async function createPayment(input: createPaymentInput) {
  const { data } = (await API.graphql({
    query: createPaymentMutation,
    variables: {
      input,
    },
  })) as { data: CreatePaymentMutation };
  return data.createPayment;
}

export async function updateQuote(input: updateQuoteInput) {
  const { data } = (await API.graphql({
    query: updateQuoteMutation,
    variables: {
      input,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as { data: UpdateQuoteMutation };
  return data.updateQuote;
}

export async function updateCustomer(input: updateCustomerInput) {
  const { data } = (await API.graphql({
    query: updateCustomerMutation,
    variables: {
      input,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as { data: UpdateCustomerMutation };
  return data.updateCustomer;
}

export async function updateCustomCustomerAttributes({ attributes }: any) {
  const customer = await Auth.currentAuthenticatedUser();
  return await Auth.updateUserAttributes(customer, attributes);
}

export async function uploadPhoto({
  uploadUrl,
  data,
}: {
  data: UploadPhoto;
  uploadUrl: string;
}) {
  const results = await fetch(uploadUrl, {
    method: 'post',
    body: JSON.stringify({ ...data }),
  });
  console.log(results);
  return (await results.json()) as UploadPhotoResult;
}

export async function compressAndUploadPhoto({
  user,
  file,
}: any): Promise<UploadPhotoResult> {
  const compressedImage = await compressImage(file);
  const base64 = await getBase64(compressedImage);
  const data = await uploadPhoto({
    uploadUrl: `${process.env.REACT_APP_REST_ENDPOINT}/image-upload`,
    data: {
      description: 'abc',
      contentType: file.type,
      file: base64,
      title: 'abc',
      creatorType: Creator.CUSTOMER,
      creatorId: user.sub || user.id,
    },
  });
  return data;
}

export async function compressAndUploadPhotos({ user, files }: any) {
  console.log('trying to upload photos...', files);
  let responses = [];
  while (responses.length < files.length) {
    responses.push(
      await compressAndUploadPhoto({ user, file: files[responses.length] }),
    );
  }
  return responses;
}

export async function sendMessage(input: createMessageInput) {
  const { data } = (await API.graphql({
    query: createMessage,
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    variables: {
      input,
    },
  })) as { data: CreateMessageMutation };
  return data.createMessage;
}
