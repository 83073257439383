import React from 'react';
import { Box, Image, Stack } from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import { getPinpointButtonClickEventProp } from '../../utils/functions-ts';
import { useLocation } from 'react-router-dom';

interface Params {
  src: string;
  callback: () => void;
  isSelected: boolean;
}

const MethodCard: React.FC<Params> = ({
  src,
  callback,
  isSelected,
}): JSX.Element => {
  const { pathname } = useLocation();

  return (
    <Stack
      width={{
        base: '50%',
        md: '40%',
      }}
      height={'120px'}
      rounded={'xl'}
      overflow={'hidden'}
      borderWidth={isSelected ? 'medium' : ''}
      borderColor={isSelected ? 'blue.400' : ''}
      justifyContent={'center'}
      alignItems={'center'}
      bg={'gray.50'}
      onClick={() => callback()}
      pos={'relative'}
      {...getPinpointButtonClickEventProp({
        command: 'payment method tab',
        currentPage: pathname,
      })}
    >
      <Image h={'80%'} w={'full'} src={src} objectFit={'contain'} />
      {isSelected && (
        <Box
          rounded={'full'}
          pos={'absolute'}
          top={'0'}
          right={'1'}
          bg={'green.200'}
        >
          <FaCheckCircle size={'16px'} color="blue" />
        </Box>
      )}
    </Stack>
  );
};

export default MethodCard;
