import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { Fees, QuoteResponse } from '../../../API';

const borderColor = '#FFA500';
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#FFA500',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontSize: 12,
    fontStyle: 'bold',
  },
  description: {
    width: '85%',
    textAlign: 'right',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
  },
  total: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 8,
  },
});

interface QuoteTableFooterProps {
  quotes: QuoteResponse[];
}

const QuoteTableFooter = ({ quotes }: QuoteTableFooterProps) => {
  const total = quotes
    .map(item => {
      const price = item.autoPart.paymentOptions.filter(
        (option: Fees) =>
          !option.isDeliveryIncluded && option.processor === 'MTN',
      )[0];

      return price.finalFee;
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return (
    <View style={styles.row}>
      <Text style={styles.description}>TOTAL</Text>
      <Text style={styles.total}>{Math.round(total)}</Text>
    </View>
  );
};

export default QuoteTableFooter;
