import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import NavigationContent from './content';

export default function Navigation() {
  return (
    <Flex
      width={{
        base: '100vw',
        md: 'auto',
      }}
      direction={'column'}
      position={{
        base: 'fixed',
        md: 'relative',
      }}
      bottom={0}
      right={0}
      bg={{
        base: useColorModeValue('white', 'gray.900'),
        md: 'none',
      }}
      p={0}
    >
      <Box
        width={'full'}
        height={'8px'}
        display={{ base: 'block', md: 'none' }}
        bg={useColorModeValue('light-background', 'dark-background')}
      />
      <NavigationContent />
    </Flex>
  );
}
